import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import practice_show from 'assets/branch/receipt/practice_show.png'
import practice_noneshow from 'assets/branch/receipt/practice_noneshow.png'

const OpPracticeReceipt = () => {
    const { t } = useTranslation()
    const optionPracticeReceipt = [
        {
            name: t('BRANCH.PRACTICE_RECEIPT.SHOW'), value: "1",
            component: <PracticeReceiptBox title={t('BRANCH.PRACTICE_RECEIPT.SHOW')} images={[practice_show]} />,
        },
        {
            name: t('BRANCH.PRACTICE_RECEIPT.NONE_SHOW'), value: "0",
            component: <PracticeReceiptBox title={t('BRANCH.PRACTICE_RECEIPT.NONE_SHOW')} images={[practice_noneshow]} />,
        },
    ]
    return (optionPracticeReceipt)
}

export default OpPracticeReceipt

interface PracticeReceiptBoxProps {
    title: string
    images: string[]
}

const PracticeReceiptBox = (props: PracticeReceiptBoxProps) => {
    const { title, images } = props

    const { t } = useTranslation()
    return (
        <Box display='flex' flexDirection='column' gap='16px' height='100%'>
            <Box >
                <Typography fontSize={'20px'} fontWeight={'500'} marginBottom='8px'>
                    {t('BRANCH.PRACTICE_RECEIPT.TITLE')}
                </Typography>
            </Box>

            <Box>
                <Box marginBottom='16px'>
                    <Typography fontWeight={'500'} marginBottom='8px'>
                        {title}
                    </Typography>
                </Box>

                <Box display='flex' alignItems={'center'} gap={'16px'} flexDirection={'column'} >
                    {images.map((item, index) => (
                        <Box key={index} textAlign='center' sx={{ img: { width: '80%' } }}>
                            <img src={item} alt="" />
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}
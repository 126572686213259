import { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Dropdown } from 'react-bootstrap';
import { routeName } from 'routes/routes-name';
import { useHistory } from 'react-router-dom';
import { Box, MenuItem } from '@mui/material';
import _ from 'lodash';

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/**API */
import { getBranch } from 'utils/app.utils';
import ClinicApi, { BranchInterface } from 'api/master/clinic.api';
import ReportApi, { FindAllReportInterface } from 'api/report.api';

/**COMPONENT */
import Loading from 'component/Loading';
import PatientList from './PatientReceiveServiceList';
import FucntionPrint from "component/Print/print";
import HeaderCard from 'component/CardCustom/HeaderCard';
import FilterSelect from 'component/Select/FilterSelect';
import { useDownloadExcel } from 'component/Excel/hooks/useExcel';

/**STYLE */
import * as UseStyled from 'features/report/useStyled';
import InputTextSearch from 'component/Input/InputTextSearch';
import OperativeTypeApi, { OperativeTypeInterface } from 'api/setting/treatments/operative-type.api';
import OperativeApi from 'api/setting/treatments/operative.api';
import ButtonCustom from 'component/Button/ButtonCustom';
import { SORT_TYPE } from 'constants/common';
import InputSecondNewDatePicker, { PICKER_VIEWS } from 'component/Input/InputSecondNewDatePicker';
import moment from 'moment';
import RadioButton from 'new-components/buttons/radio-button';

export default function PatientReceiveService() {
    const { t } = useTranslation();
    const history = useHistory();
    const componentRef = useRef<HTMLDivElement>(null);

    const [branchId, setBranchId] = useState<any>(getBranch());
    const [branches, setBranches] = useState<any[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const momentNow = moment().format('YYYY-MM-DD')
    const [date, setDate] = useState<string>(momentNow)
    const [sortBy, setSortBy] = useState<string>('patient_cases.createdAt')
    const [sortType, setSortType] = useState<SORT_TYPE>(SORT_TYPE.DESC)

    const [operativeTypes, setOperativeTypes] = useState<any[]>()
    const [operative, setOperative] = useState<any[]>()
    const [operativeTypeId, setOperativeTypeId] = useState<string>('ALL')
    const [operativeId, setOperativeId] = useState<string>('ALL')

    const [patientData, setPatientData] = useState<any[]>([]);
    const [patientDataExport, setPatientDataExport] = useState<any[]>([]);
    const [patientPage, setPatientPage] = useState<number>(1);
    const [patientLimitPage, setPatientLimitPage] = useState<number>(10);
    const [search, setSearch] = useState<string>();
    const [serviceStatus, setServiceStatus] = useState('LAST_SERVICE');


    const [year, setYear] = useState<any>(1);
    const [month, setMonth] = useState<any>(0);
    const [day, setDay] = useState<any>(0);
    const [fetch, setFetch] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const verifyPermission = async () => {
        const condition: FindAllReportInterface = {
            page: 1, pageLimit: 200
        }
        ReportApi.reportList(condition)
            .then(({ data }) => {
                const check = _.find(data, (val: any) => String(val.reportKey) === 'PATIENT_RECEIVE_SERVICE') ? true : false
                if (!check) {
                    history.push(routeName.report)
                    return false
                }
            })

        ClinicApi.findAllBranches()
            .then(({ data }) => setBranches(data))
            .catch((e) => { return })

        OperativeTypeApi.findAll({ status: 'ACTIVE', pageLimit: 50 })
            .then(({ data }) => {
                setOperativeTypes(data)
            })
            .catch((e) => { return })
        return true
    }

    useEffect(() => {
        verifyPermission()
    }, [])

    const fetchPatientReceiveService = useCallback(async () => {
        await verifyPermission()
        let condition: FindAllReportInterface = {
            page: patientPage, pageLimit: patientLimitPage,
            branchId: branchId,
            date: date,
            month: month,
            year: year,
            day: day
        }
        if (search) condition = { ...condition, search: search }
        if (operativeTypeId && operativeTypeId !== 'ALL') condition = { ...condition, operativeTypeId: operativeTypeId }
        if (operativeId && operativeId !== 'ALL') condition = { ...condition, operativeId: operativeId }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }

        ReportApi.reportPatientReceiveService(condition)
            .then(({ headers, data }) => {
                setPatientData(data.rows)
                setRowCount(headers['x-total'])
            }).catch((e) => setLoading(false))
            .finally(() => setLoading(false))
        setFetch(false)

    }, [fetch, patientPage, patientLimitPage, sortBy, sortType])

    const fetchPatientReceiveServiceExport = useCallback(async () => {
        let condition: FindAllReportInterface = {
            page: 1, pageLimit: 10000,
            branchId: branchId,
            date: date,
            month: month,
            year: year,
            day: day,
            serviceStatus: serviceStatus
        }
        if (search) condition = { ...condition, search: search }
        if (operativeTypeId && operativeTypeId !== 'ALL') condition = { ...condition, operativeTypeId: operativeTypeId }
        if (operativeId && operativeId !== 'ALL') condition = { ...condition, operativeId: operativeId }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }

        ReportApi.reportPatientReceiveService(condition)
            .then(({ data }) => {
                setPatientDataExport(data.rows)
            }).catch((e) => setLoading(false))
            .finally(() => setLoading(false))

    }, [fetch, sortBy, sortType])

    const loadOperative = useEffect(() => {
        OperativeApi.findAll({ status: 'ACTIVE', pageLimit: 100, operativeTypeId: operativeTypeId })
            .then(({ data }) => {
                setOperative(data);
            })
            .catch((e) => {
                console.error('Error:', e);
            });

    }, [operativeTypeId])
    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`REPORT.REPORT_NAME.PATIENT_RECEIVE_SERVICE`)}`,
        sheetName: `${t(`REPORT.REPORT_NAME.PATIENT_RECEIVE_SERVICE`)}`
    })

    const onRequestSort = (sortByVal: string, sortTypeVal: any) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const getAllFilter = () => {
        return [{
            label: t('REPORT.FILTER.BRANCH'),
            value: _.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')
        },
        {
            label: t('REPORT.FILTER.TYPE_OPERATION'),
            value: operativeTypeId === 'ALL' ? t('REPORT.FILTER.ALL') : _.get(_.find(operativeTypes, { operativeTypeId: operativeTypeId }), 'operativeTypeName' || 'operativeTypeNameEn')
        },
        {
            label: t('REPORT.FILTER.OPERATION'),
            value: operativeId === 'ALL' ? t('REPORT.FILTER.ALL') : _.get(_.find(operative, { operativeId: operativeId }), 'operativeName' || 'operativeNameEn')
        },
        {
            label: t('REPORT.FILTER.YEAR'),
            value: year
        },
        {
            label: t('REPORT.FILTER.MONTH'),
            value: month
        }
        ]

    }
    const handleChangeRowsPerPage = (limit: number) => setPatientLimitPage(limit)

    const handleChangePage = (val: number) => setPatientPage(val)

    /** UseEffect */
    useEffect(() => {
        fetchPatientReceiveService()
    }, [fetchPatientReceiveService])

    useEffect(() => {
        fetchPatientReceiveServiceExport()
    }, [fetchPatientReceiveServiceExport])

    return (
        <>
            <Card className={'border-0 h-100'}>
                <HeaderCard text={t(`REPORT.REPORT_NAME.PATIENT_RECEIVE_SERVICE`)} />
                <Card.Body>
                    <Card.Title className={'mb-0'}>
                        <Row className='mx-0'>
                            <RadioButton
                                name=''
                                onChange={(e) => setServiceStatus(e.target.value)}
                                options={[
                                    { label: 'รายชื่อผู้ป่วยที่เข้ารับบริการตามช่วงเวลา', value: 'LAST_SERVICE', tooltip: 'ระบบจะแสดงรายชื่อผู้ป่วยที่เข้ารับบริการภายในช่วง เวลา โดยนับจากวันที่กำหนด' },
                                    { label: 'รายชื่อผู้ป่วยที่ขาดการรับบริการตามช่วงเวลา', value: 'NO_SERVICE', tooltip: 'ระบบจะแสดงรายชื่อผู้ป่วยที่ไม่ได้เข้ารับบริการตาม ช่วงเวลา โดยนับจากวันที่กำหนด' }
                                ]}
                                row
                                toolTip
                                defaultValue='LAST_SERVICE'
                            />
                        </Row>
                        <Row className='mx-0'>
                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <InputTextSearch
                                    keyInput={'search'}
                                    label={t('REPORT.REPORT_NAME.SEARCH_PATIENT_RECEIVE_SERVICE')}
                                    value={search}
                                    onchange={(event) => {
                                        setSearch(event.target.value)
                                    }}
                                    onClear={(event) => {
                                        setSearch('')
                                    }}
                                />
                            </Col>
                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        setBranchId(e.target.value)
                                        setPatientPage(1)
                                    }}
                                    renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                                    label={''}
                                    selectId="select-branch"
                                    labelId="label-branch"
                                    value={branchId}
                                    options={
                                        _.map(branches, (b: BranchInterface, index) => {
                                            return (
                                                <MenuItem key={b.branchId} value={b.branchId}>
                                                    {b.branchName || b.branchNameEn}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>
                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        setOperativeTypeId(e.target.value)
                                        setOperativeId('ALL')
                                        setPatientPage(1)
                                    }}
                                    renderValue={() => `${t('DASHBOARD.OPERATIVE_TYPE')}: ${operativeTypeId === 'ALL' ? t('REPORT.FILTER.ALL') : `${_.get(_.find(operativeTypes, { operativeTypeId: operativeTypeId }), 'operativeTypeName' || '')}`}`}
                                    label={''}
                                    selectId="select-branch"
                                    labelId="label-branch"
                                    value={operativeTypeId}
                                    options={[
                                        <MenuItem key="ALL" value="ALL">
                                            {t('REPORT.FILTER.ALL')}
                                        </MenuItem>,
                                        ..._.map(operativeTypes, (b: any, index) => {
                                            return (
                                                <MenuItem key={b.operativeTypeId} value={b.operativeTypeId}>
                                                    {b.operativeTypeName}
                                                </MenuItem>
                                            )
                                        })
                                    ]}
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>

                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        setOperativeId(e.target.value)
                                        setPatientPage(1)
                                    }}
                                    renderValue={() => `${t('DASHBOARD.HEADER.OPERATIVE')}: ${operativeId === 'ALL' ? t('REPORT.FILTER.ALL') : `${_.get(_.find(operative, { operativeId: operativeId }), 'operativeName' || '')}`}`}
                                    label={''}
                                    selectId="select-branch"
                                    labelId="label-branch"
                                    value={operativeId}
                                    options={[
                                        <MenuItem key="ALL" value="ALL">
                                            {t('REPORT.FILTER.ALL')}
                                        </MenuItem>,
                                        ..._.map(operative, (b: any, index) => {
                                            return (
                                                <MenuItem key={b.operativeId} value={b.operativeId}>
                                                    {b.operativeName}
                                                </MenuItem>
                                            )
                                        })
                                    ]}
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                    disabled={operativeTypeId === "ALL"}
                                />
                            </Col>
                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <InputSecondNewDatePicker
                                    dateFormat={'DD/MM/YYYY'}
                                    placeholder={t('REPORT.FILTER.DATE')}
                                    label={''}
                                    inputHeight={32}
                                    value={date}
                                    onchange={(e: any) => {
                                        setDate(moment(e).format('YYYY-MM-DD'))
                                    }}
                                    views={PICKER_VIEWS.DAY}
                                />
                            </Col>
                            <Col sm={1} md={1} xl={1} className={'pt-2 px-1'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        const newYear = e.target.value;
                                        // const newDay = newMonth === 0 ? 1 : 0;
                                        setYear(newYear);
                                        setMonth(1);
                                        setDay(1)
                                        setPatientPage(1);
                                    }}

                                    label={''}
                                    selectId="select-year"
                                    labelId="label-year"
                                    value={year}
                                    options={Array.from({ length: 11 }, (_, i) => (
                                        <MenuItem key={i} value={i}>
                                            {i} ปี
                                        </MenuItem>
                                    ))}
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>

                            <Col sm={1} md={1} xl={1} className={'pt-2 px-1'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        const selectedValue = e.target.value;
                                        setMonth(selectedValue);
                                        setDay(1)
                                        setPatientPage(1);
                                    }}
                                    label=""
                                    selectId="select-year"
                                    labelId="label-year"
                                    value={month}
                                    options={Array.from({ length: year > 0 ? 13 : 12 }, (_, i) => {
                                        const monthValue = year > 0 ? i : i + 1;
                                        return (
                                            <MenuItem key={monthValue} value={monthValue}>
                                                {monthValue} {t('REPORT.FILTER.MONTH')}
                                            </MenuItem>
                                        );
                                    })}
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>
                            <Col sm={1} md={1} xl={1} className={'pt-2 px-1'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        setDay(e.target.value)
                                        setPatientPage(1);
                                    }}

                                    label={''}
                                    selectId="select-day"
                                    labelId="label-day"
                                    value={day}
                                    options={Array.from({ length: 31 }, (_, i) => (
                                        <MenuItem key={i} value={i}>
                                            {i} วัน
                                        </MenuItem>
                                    ))}
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>
                            <Col md={1} xl={1} className={'pt-1 '}>
                                <ButtonCustom onClick={() => {
                                    setFetch(true);
                                    setPatientPage(1);
                                    setLoading(true);
                                }}
                                    textButton={t('INPUT.SEARCH')} style={{ height: '30px', marginTop: '5px' }} />
                            </Col>
                        </Row>
                        <Row className='mx-0'>
                            <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                                <UseStyled.DropdownDocument>
                                    <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                                        {t('PRINT_DOCUMENT')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={onDownload}>
                                            {t('Excel')}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </UseStyled.DropdownDocument>
                            </Col>
                        </Row>
                    </Card.Title>
                    <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
                        <PatientList
                            filter={getAllFilter}
                            page={patientPage}
                            pageLimit={patientLimitPage}
                            rowCount={rowCount}
                            sortBy={sortBy}
                            sortType={sortType}
                            setSortBy={setSortBy}
                            setSortType={setSortType}
                            data={patientData}
                            onRequestSort={onRequestSort}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleChangePage={handleChangePage}
                            componentRef={componentRef}
                            exportData={patientDataExport} />
                    </Box>
                </Card.Body>
                <Loading show={loading} type='fullLoading' />
            </Card>
        </>
    )
}
import { Box, Grid, IconButton, Typography } from "@mui/material"
import InputSecondNewDatePicker from "component/Input/InputSecondNewDatePicker"
import { icons } from "constants/images"
import { colors } from "constants/theme"
import moment from "moment"
import DateInput from "new-components/inputs/date-input"
import SelectInput from "new-components/inputs/select-input"
import TextInput from "new-components/inputs/text-input"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { DateObject } from "react-multi-date-picker"
import { numberFormat } from "utils/app.utils"

export interface PaymentInstallmentListProps {
    installmentId: number | null
    paidDate: string
    optionPrice: PercentOp
    price: number
    installmentNumber: number
    status: PaymentInstallmentListStatus
}

export enum PaymentInstallmentListStatus {
    PENDING = 'PENDING',
    PAID = 'PAID',
    DELETE = 'DELETE'
}

export interface PaymentInstallmentBoxProps {
    operativeTotal: number
    paymentInstallmentList: PaymentInstallmentListProps[]
    setPaymentInstallmentList: React.Dispatch<React.SetStateAction<PaymentInstallmentListProps[]>>
    autoScroll?: () => void
    disabled?: boolean
}

export enum PercentOp {
    AVERAGE = "AVERAGE",
    CUSTOM = "CUSTOM",
    PER10 = "PER10",
    PER20 = "PER20",
    PER30 = "PER30",
    PER40 = "PER40",
    PER50 = "PER50",
    PER60 = "PER60",
    PER70 = "PER70",
    PER80 = "PER80",
    PER90 = "PER90",
}

const PaymentInstallmentBox = (props: PaymentInstallmentBoxProps) => {
    const { t } = useTranslation()
    const { operativeTotal, paymentInstallmentList, setPaymentInstallmentList, autoScroll } = props

    const [isPay, setIsPay] = useState<number[]>([])
    const [isEdit, setIsEdit] = useState<boolean>(false)

    const percentOption = [
        { name: t('TREATMENT_COURSE.FORM.PAYMENT.PAYMENT_METHOD_AVERAGE'), value: PercentOp.AVERAGE },
        { name: t('TREATMENT_COURSE.FORM.PAYMENT.PAYMENT_METHOD_CUSTOM'), value: PercentOp.CUSTOM },
        { name: t('10%'), value: PercentOp.PER10 },
        { name: t('20%'), value: PercentOp.PER20 },
        { name: t('30%'), value: PercentOp.PER30 },
        { name: t('40%'), value: PercentOp.PER40 },
        { name: t('50%'), value: PercentOp.PER50 },
        { name: t('60%'), value: PercentOp.PER60 },
        { name: t('70%'), value: PercentOp.PER70 },
        { name: t('80%'), value: PercentOp.PER80 },
        { name: t('90%'), value: PercentOp.PER90 },
    ]

    const calPercent = (optionPrice: string) => {
        const perNum = optionPrice.split('PER')[1]
        return Number(perNum || 0) / 100
    }

    const calAmountPaymentInstallmentList = (prev: PaymentInstallmentListProps[], status?: string) => {
        let payLater = 0
        let payLaterAver = 0
        let payPer = 0
        const newPrev = prev.filter((item) => {
            if (item.status !== PaymentInstallmentListStatus.PAID) {
                return item
            } else {
                if (item.optionPrice === PercentOp.AVERAGE) {
                    payLaterAver += item.price
                } else if (item.optionPrice !== PercentOp.CUSTOM) {
                    payPer += item.price
                }
                payLater += item.price
            }

        })
        let totalCustom = 0
        const totalPercent = prev.reduce((total, item) => {
            if (item.optionPrice.includes("PER")) {
                return total += Number(item.optionPrice.split('PER')[1] || 0)
            }
            return total
        }, 0)

        prev.forEach((item) => {
            if (item.optionPrice === PercentOp.CUSTOM) {
                totalCustom += item.price
            }
        })
        // - payLater
        const opTotal = operativeTotal

        const newTotalCustom = opTotal - totalCustom
        const newTotalPer = newTotalCustom * (totalPercent / 100)
        const totalAll = newTotalCustom - newTotalPer - payLaterAver

        const countAdd = status === "NEW" ? 1 : 0
        const countAverage = newPrev.filter((item) => item.optionPrice === PercentOp.AVERAGE)?.length + countAdd
        const amountAerage = totalAll / countAverage

        let newTotal = 0
        let currentAverage = 0
        let newTotalAverage = 0
        const dataPrev = prev.map((info) => {
            if (info.status === PaymentInstallmentListStatus.PAID) {
                newTotal += info.price
                return info
            }

            if (info.optionPrice === PercentOp.AVERAGE) {
                currentAverage += 1
                const newAmount = countAverage === currentAverage ? totalAll - newTotalAverage : Math.ceil(amountAerage)
                newTotalAverage += Math.ceil(amountAerage)
                newTotal += Number(newAmount.toFixed(2))
                return { ...info, price: Number(newAmount.toFixed(2)) }
            } else if (info.optionPrice.includes('PER')) {
                const newAmount = newTotalCustom * calPercent(info.optionPrice)
                newTotal += Number(newAmount.toFixed(2))
                return { ...info, price: Number(newAmount.toFixed(2)) }
            }
            newTotal += info.price
            return info
        })

        return { dataPrev, newTotal }
    }

    const handleAddPaymentInstallmentList = () => {
        setPaymentInstallmentList((prev) => {
            const { dataPrev, newTotal } = calAmountPaymentInstallmentList(prev, 'NEW')
            const newData = {
                paidDate: '',
                optionPrice: PercentOp.AVERAGE,
                price: operativeTotal - newTotal,
                installmentNumber: paymentInstallmentList.length + 1,
                installmentId: null,
                status: PaymentInstallmentListStatus.PENDING
            }
            autoScroll && autoScroll()
            return [...dataPrev, newData]
        })
    }

    const handleFloatNumber = (input: number) => {
        if (input?.toString()?.length && input?.toString()[input?.toString()?.length - 1] === '.') {
            const newInput: number = input
            return newInput
        } else {
            return Number(input)
        }
    }

    const handleChangePaymentInstallmentList = (index: number, type: string, value: any) => {
        setPaymentInstallmentList((prev) => {
            const newPrev = { ...prev[index] }
            if (type === 'optionPrice') {
                if (![PercentOp.AVERAGE, PercentOp.CUSTOM].includes(value)) {
                    const totalMax = operativeTotal - prev.reduce((total, item, i) => {
                        if (item.optionPrice === PercentOp.CUSTOM && i !== index || item.status === PaymentInstallmentListStatus.PAID) {
                            return total + item.price
                        }
                        return total
                    }, 0)
                    newPrev.price = Number((totalMax * calPercent(value)).toFixed(2))
                }
                newPrev[type] = value
                prev[index] = newPrev
                const { dataPrev } = calAmountPaymentInstallmentList(prev)
                return dataPrev

            } else if (type === 'price') {
                const totalMax = operativeTotal - prev.reduce((total, item, i) => {
                    if (item.optionPrice === PercentOp.CUSTOM && i !== index || item.status === PaymentInstallmentListStatus.PAID) {
                        return total + item.price
                    }
                    return total
                }, 0)
                newPrev.optionPrice = PercentOp.CUSTOM
                let newValue = value
                const pattern = /^[0-9.]+$/;
                if (pattern.test(newValue) && !isNaN(newValue)) {
                    const inputValue = newValue
                    newValue = handleFloatNumber(inputValue)
                }
                newPrev[type] = totalMax < value ? totalMax : newValue
                prev[index] = newPrev
                const { dataPrev } = calAmountPaymentInstallmentList(prev)
                return dataPrev
            }

            prev[index] = { ...prev[index], [type]: value }
            autoScroll && autoScroll()
            return [...prev]
        })
    }

    const handleDeletePaymentInstallmentList = (index: number) => {
        setPaymentInstallmentList((prev) => {
            const newData = prev.filter((_, num) => num !== index)
            const { dataPrev } = calAmountPaymentInstallmentList(newData)
            return dataPrev
        })
    }

    const handleChangePaymentInstallmentListAverage = () => {
        setPaymentInstallmentList((prev) => {
            const newData = prev.map((item) => {
                if (item.status === PaymentInstallmentListStatus.PAID) {
                    return item
                }
                return ({ ...item, optionPrice: PercentOp.AVERAGE })
            })
            const { dataPrev } = calAmountPaymentInstallmentList(newData)
            autoScroll && autoScroll()
            return dataPrev
        })
    }

    const renderDateMaxMin = useCallback((index: number) => {
        if (index || index === 0) {
            const newDate = paymentInstallmentList[index]?.paidDate
            if (newDate) {
                return new DateObject(newDate)
            } else {
                return ''
            }
        } else {
            return ''
        }

    }, [paymentInstallmentList])

    const renderPaymentInstallmentListTotal = useCallback(() => {
        const newPayment = paymentInstallmentList.reduce((total, item) => total + item.price, 0)
        return newPayment
    }, [paymentInstallmentList])

    const renderPercentOption = useCallback((optionPrice: string) => {
        let percentCurrent = 0
        if (optionPrice.includes("PER")) {
            percentCurrent = Number(optionPrice.split('PER')[1] || 0)
        }

        const newPercent = ((100) + percentCurrent) - paymentInstallmentList.reduce((total, item) => {
            if (item.optionPrice.includes("PER")) {
                return total += Number(item.optionPrice.split('PER')[1] || 0)
            }
            return total
        }, 0)
        const newOp = percentOption.map((item: { name: string, value: string }) => {
            if (item.value.includes("PER")) {
                return { ...item, disabled: (newPercent < Number(item.value.split('PER')[1] || 0)) }
            } else {
                return item
            }
        })
        return newOp.filter((item) => {
            if ((isEdit && (item.value === PercentOp.AVERAGE || item.value === PercentOp.CUSTOM)) || !isEdit) {
                return item
            }
        })
    }, [paymentInstallmentList, isEdit])

    useEffect(() => {
        setPaymentInstallmentList((prev) => {
            const { dataPrev } = calAmountPaymentInstallmentList(prev)
            return dataPrev
        })
    }, [operativeTotal])

    useEffect(() => {
        if (paymentInstallmentList?.length && paymentInstallmentList.some((item) => item.status === PaymentInstallmentListStatus.PAID)) {
            setIsEdit(true)
            paymentInstallmentList.forEach((item, index) => {
                if (item.status === PaymentInstallmentListStatus.PAID) {
                    setIsPay((prev) => [...prev, item.installmentNumber])
                }
            })
        }
    }, [])

    return (
        <Box>
            {!props.disabled &&
                <Box
                    display='flex'
                    alignItems='center'
                    gap='8px'
                    height='24px'
                    width='fit-content'
                    sx={{ cursor: 'pointer', filter: !(paymentInstallmentList?.length < 10) ? 'grayscale(1)' : '' }}
                    onClick={() => paymentInstallmentList?.length < 10 && handleAddPaymentInstallmentList()}
                >
                    <Box color={colors.themeSecondColor} fontWeight={500} fontSize={25}>
                        +
                    </Box>
                    <Typography className="mt-2" sx={{ fontWeight: 500, color: colors.themeSecondColor }}>
                        {t('TREATMENT_COURSE.FORM.PAYMENT.LNSTALLMENT_ADD')}
                    </Typography>
                </Box>
            }
            <Box display='flex' flexDirection='column' gap='16px' padding='16px 0'>
                {paymentInstallmentList.map((item, index: number) => {
                    const isDisabled = isPay.includes(item.installmentNumber)
                    const isDisDelete = item.status === PaymentInstallmentListStatus.PENDING && index === (paymentInstallmentList.filter((info) => info.status === PaymentInstallmentListStatus.PAID)?.length || 0)
                    const isDisStatus = item.status === PaymentInstallmentListStatus.PAID

                    return (
                        <Grid container key={index} xs={12} alignItems='center' gap={'16px 0'}>
                            <Grid item md={1} xs={12} padding="0 8px 0px" whiteSpace={'nowrap'}>
                                {t(`TREATMENT_COURSE.FORM.PAYMENT.LNSTALLMENT_TIMES`, { index: index + 1 })}
                            </Grid>
                            <Grid item md={3} xs={6} padding="0 8px">
                                <DateInput
                                    label={t('TREATMENT_COURSE.FORM.PAYMENT.LNSTALLMENT_DATE')}
                                    onChange={(e: any) => handleChangePaymentInstallmentList(index, 'paidDate', moment(e).format('YYYY-MM-DD'))}
                                    value={item.paidDate}
                                    onClear={() => handleChangePaymentInstallmentList(index, 'paidDate', '')}
                                    disabled={isDisabled || props.disabled}
                                    fullWidth
                                    disabledDatePast
                                    startDate={moment(paymentInstallmentList[index - 1]?.paidDate).isBefore(moment(new Date()).format('yyyy-MM-DD')) ? moment(new Date()).format('yyyy-MM-DD') : paymentInstallmentList[index - 1]?.paidDate}
                                />
                                {/* <InputSecondNewDatePicker
                                    label={t('TREATMENT_COURSE.FORM.PAYMENT.LNSTALLMENT_DATE')}
                                    onchange={(e: any) => handleChangePaymentInstallmentList(index, 'paidDate', moment(e).format('YYYY-MM-DD'))}
                                    value={item.paidDate}
                                    allowClear={!isDisabled}
                                    onClear={() => handleChangePaymentInstallmentList(index, 'paidDate', '')}
                                    disableFuture
                                    maxDate={renderDateMaxMin(index + 1)}
                                    disablePast
                                    minDate={index === 0 ? new Date() : renderDateMaxMin(index - 1)}
                                    disabled={isDisabled}
                                /> */}
                            </Grid>
                            <Grid item md={2} xs={6} padding="0 8px">
                                <SelectInput
                                    label={t('TREATMENT_COURSE.FORM.PAYMENT.LNSTALLMENT_PER')}
                                    options={renderPercentOption(item.optionPrice)}
                                    onChange={(e) => handleChangePaymentInstallmentList(index, 'optionPrice', e.target.value)}
                                    value={item.optionPrice}
                                    disabled={isDisabled || props.disabled}
                                />
                            </Grid>
                            <Grid item md xs={8} padding="0 8px">
                                <Box display='flex' alignItems='center' gap='8px'>
                                    <TextInput
                                        fullWidth
                                        label={t('TREATMENT_COURSE.FORM.PAYMENT.LNSTALLMENT_AMOUNT')}
                                        onChange={(e) => {
                                            const newValue = e.target.value
                                            handleChangePaymentInstallmentList(index, 'price', newValue || 0)
                                        }}
                                        value={item?.price || 0}
                                        disabled={isDisabled || props.disabled}
                                        numberOnly
                                    />
                                    <Typography>
                                        {t('TREATMENT_COURSE.FORM.PAYMENT.BATH')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={2} xs={4} padding="0 0xp 0px 8px">
                                {index === 0 || !props.disabled &&
                                    <Typography sx={{ textDecoration: 'underline', color: colors.themeSecondColor, cursor: 'pointer' }} whiteSpace={'nowrap'} onClick={handleChangePaymentInstallmentListAverage}>
                                        {t('TREATMENT_COURSE.FORM.PAYMENT.LNSTALLMENT_NEW_AVERAGE')}
                                    </Typography>
                                }
                                {index > 1 &&
                                    <IconButton
                                        onClick={() => (!isDisDelete && !isDisStatus) && handleDeletePaymentInstallmentList(index)}
                                        sx={(isDisDelete || isDisStatus) ? {
                                            img: {
                                                filter: 'grayscale(1) invert(1)',
                                                opacity: 0.6
                                            }
                                        } :
                                            {}}
                                    >
                                        <img src={icons.btnTrash} alt="trash" />
                                    </IconButton>
                                }
                            </Grid>
                        </Grid>
                    )
                }
                )}
                <Grid container xs={12} alignItems='center'>
                    <Grid item xs={6} padding="0 8px" textAlign={'right'}>
                        {t('TREATMENT_COURSE.FORM.PAYMENT.LNSTALLMENT_TOTAL')}
                    </Grid>
                    <Grid item xs padding="0 8px">
                        <Box display='flex' alignItems='center' gap='8px'>
                            <TextInput
                                fullWidth
                                label={t('')}
                                onChange={(e) => null}
                                value={numberFormat(renderPaymentInstallmentListTotal())}
                                disabled
                            />
                            <Typography>
                                {t('TREATMENT_COURSE.FORM.PAYMENT.BATH')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} padding="0 0xp 0px 8px"></Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default PaymentInstallmentBox
import { TableRow } from '@material-ui/core'
import { dateTimeToView } from 'utils/date.utils'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'

/** CONSTANT */
import { colors } from 'constants/theme'
import { numberFormat, padCode } from 'utils/app.utils'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

const TableRowCustom = styled(TableRow)<{ rowLink?: boolean }>(({ theme, rowLink }) => ({
    '& td': { verticalAlign: 'middle !important' },
    '&.row-link:hover  td:not(.no-link)': {
        color: colors.themeSecondColor, textDecoration: 'underline', cursor: 'pointer'
    }
}))

type TableRowCommonProps = {
    key: string
    id: string
    obj: any
    columns: any
    fontSize?: number
}

export default function TableRowExport(props: TableRowCommonProps) {
    const { t } = useTranslation()
    const col = props.columns
    const columns = col.filter((colItem: any) => colItem.label !== 'action')


    return (
        <TableRowCustom key={props.key} style={{ height: 'auto' }} >
            {columns.map((column: any) => {
                return (
                    (column.option === 'TEXT' && column.format === 'TEXT' && (
                        <td style={{ textAlign: column.align ? column.align : 'left', border: `0px ridge ${colors.black}`, fontSize: props.fontSize || 10, ...column.style }}>{'=TEXT(' + column.label + ',"' + padCode(column.label.toString().length, 0) + '")'}</td>
                    )) ||
                    (column.option === 'TEXT' && column.format === 'CODE' && (
                        <td style={{ textAlign: column.align ? column.align : 'left', border: `0px ridge ${colors.black}`, fontSize: props.fontSize || 10, ...column.style }}> <pre> {column.label ? column.label.toString() : ''}</pre> </td>
                    )) ||
                    (column.option === 'TEXT' && (
                        <td style={{ textAlign: column.align ? column.align : 'left', border: `0px ridge ${colors.black}`, fontSize: props.fontSize || 10, ...column.style }} className={`${column?.class || ''}`}>{column.label}</td>
                    )) ||
                    (column.option === 'PRICE' && (
                        <td style={{ textAlign: column.align ? column.align : 'left', border: `0px solid ${colors.black}`, fontSize: props.fontSize || 10, ...column.style }}>{numberFormat(column.label)}</td>
                    )) ||
                    (column.option === 'STATUS' && (
                        <td style={{ textAlign: column.align ? column.align : 'left', border: `0px solid ${colors.black}`, fontSize: props.fontSize || 10, ...column.style }}><span className="ml-2">{t(`STATUS.${column.label}`)}</span></td>
                    )) ||
                    (column.option === 'UPDATE_BY' && (
                        <td style={{ textAlign: column.align ? column.align : 'left', border: `0px solid ${colors.black}`, fontSize: props.fontSize || 10, ...column.style }} >
                            <Box className={`position-relative text-center text-ellipsis ${column.align === 'center' ? 'mx-auto' : ''}`}>
                                {column.label.updatedBy}
                                {!column.newExport && <br />}
                                <span style={{ color: colors.black }}>{dateTimeToView(column.label.updatedAt, false)}</span>
                            </Box>
                        </td>
                    )) ||
                    (column.option === 'COMPONENT' && (
                        <td className={`${column.class}`} style={{ width: column?.width || 160, textDecoration: 'none', textAlign: column.align ? column.align : 'left', border: `0px solid ${colors.black}`, fontSize: props.fontSize || 10, ...column.style }}>
                            {column.component}
                        </td>
                    )) || (column.option === 'MERGE' && (
                        <td align={column.align || 'left'} colSpan={column.colSpan} style={{ border: `0px solid ${colors.black}`, ...column.style, }} className={`${column?.class || ''}`}>{column.label || ``}</td>
                    ))
                )
            })}
        </TableRowCustom >
    )
}

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { numberFormat, toBuddhistYear } from "utils/app.utils";
import { UseSnCode } from "utils/useSnCode";

interface RemunerationTotalIncomeSummaryProps {
    startDate: string
    endDate: string
    data: any
    page?: any
    pageLimit?: any
    snType?: any
    doctors?: any
}

export default function RemunerationTotalIncomeSummary(props: RemunerationTotalIncomeSummaryProps) {

    const { t } = useTranslation()

    const renderTotal = () => {

        let sumAmountCash = 0
        let sumAmountTransfer = 0
        let sumAmountDebit = 0
        let sumAmountCredit = 0
        let sumAmountTrementRight = 0
        let sumAmountAdvance = 0
        let sumLab = 0
        let sumAmountPaid = 0
        let sumDf = 0
        let dfNet = 0
        let dfLabFee = 0
        _.map(props.data?.rows, (salary, indexSalary: number) => {
            sumAmountPaid += salary?.table.map((amountPaidData: any) => amountPaidData.amountPaid).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
            sumAmountCash += salary?.table.map((amountCashData: any) => amountCashData.amountCash).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
            sumAmountTransfer += salary?.table.map((amountTransferData: any) => amountTransferData.amountTransfer).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
            sumAmountDebit += salary?.table.map((amountDebitData: any) => amountDebitData.amountDebit).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
            sumAmountCredit += salary?.table.map((amountCreditData: any) => amountCreditData.amountCredit).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
            sumAmountTrementRight += salary?.table.map((amountTreatmentRightData: any) => amountTreatmentRightData.amountTrementRight).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
            sumAmountAdvance += salary?.table.map((amountAdvanceData: any) => amountAdvanceData.amountAdvance).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
        })
        _.map(props.data?.rowLab, (lab, indexLab: number) => {
            sumLab += lab?.table.map((sumLabData: any) => sumLabData.labFee).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
        })

        if (props.data?.rowDf !== undefined) {
            if (props.data?.rowDf.length > 0) {
                sumDf += props.data?.rowDf ? props.data?.rowDf.map((rowDfData: any) => rowDfData.dfLostCreditAndTreatment).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0)) : 0
                dfNet += props.data?.rowDf ? props.data?.rowDf.map((rowDfData: any) => rowDfData.dfNet).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0)) : 0
                dfLabFee += props.data?.rowDf ? props.data?.rowDf.map((rowDfData: any) => rowDfData.dfLabFee).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0)) : 0
            }
        }
        const netClinic = sumAmountPaid - dfNet < 0 ? 0 : sumAmountPaid - dfNet
        return (
            <TableRow style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(sumAmountPaid || 0)}</TableCell>
                <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(netClinic || 0)}</TableCell>
                <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(dfNet || 0)}</TableCell>
                <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(dfNet + dfLabFee || 0)}</TableCell>
                <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(dfLabFee || 0)}</TableCell>
                <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400, color: '#007BFF' }}>{numberFormat(sumAmountCash || 0)}</TableCell>
                <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400, color: '#007BFF' }}>{numberFormat(sumAmountTransfer || 0)}</TableCell>
                <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400, color: '#007BFF' }}>{numberFormat(sumAmountDebit || 0)}</TableCell>
                <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400, color: '#007BFF' }}>{numberFormat(sumAmountCredit || 0)}</TableCell>
                <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400, color: '#007BFF' }}>{numberFormat(sumAmountTrementRight || 0)}</TableCell>
                <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400, color: '#007BFF' }}>{numberFormat(sumAmountAdvance || 0)}</TableCell>
            </TableRow>
        )
    }

    return (
        <Box marginY="16px">
            <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>Total Income Summary</Typography>
            <Box sx={{ overflowX: 'auto', overflowY: 'hidden', marginTop: '16px' }}>
                <TableContainer className={'custom-scroll'} style={{ boxShadow: 'none', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                    <Table aria-label="simple table" sx={{ width: '100%' }}>
                        <TableHead sx={{ backgroundColor: "#2F4269", color: 'white', borderRadius: "8px", }}>
                            <TableRow>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.INCOME')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.NET_CLINIC')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.DF_NET')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.DENTIST_FREE')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.DF_LAB')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.CASH')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.TRANSFER_')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.DEBIT')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.CREDIT')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.ADVANCE')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderTotal()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}
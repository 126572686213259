import { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Grid, MenuItem } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useHistory } from 'react-router-dom';
import _, { forEach, forOwn } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDownloadExcel } from 'component/Excel/hooks/useExcel';
import { Dropdown } from 'react-bootstrap';

/** COMPONENT */
import HeaderCard from "component/CardCustom/HeaderCard";
import MainButton from "new-components/buttons/main-button";
import DateInput from "new-components/inputs/date-input";
import TextInput from "new-components/inputs/text-input";
import PaginationTable from "new-components/pagination/pagination";
import SelectInput from 'new-components/inputs/select-input';
import InputSecondNewDatePicker from 'new-components/inputs/component/date-picker';
import { PICKER_VIEWS } from 'component/Input/InputSecondNewDatePicker';
import Loading from 'component/Loading';
import FilterSelect from 'component/Select/FilterSelect';
import FucntionPrint from 'component/Print/print'

/** API */
import ClinicApi, { BranchInterface, BillInterface } from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'

/** CONSTANTS */
import { routeName } from 'routes/routes-name'
import { getBranch, numberFormat } from 'utils/app.utils';
import { dateToView } from 'utils/date.utils';
import operative from 'assets/icon/carlendar/operative.svg';

import * as UseStyled from 'features/report/useStyled'
import { ExportProps } from '../report-constants';


export default function PartialProcedureSummary() {
    const { t } = useTranslation()
    const history = useHistory()
    const componentRef = useRef<HTMLDivElement>(null)
    const componentRefPDF = useRef<HTMLDivElement>(null)

    const [branches, setBranches] = useState<any[]>([])
    const [filter, setFilter] = useState<{ loading: boolean, branchId: any, date: string, productTypeId: number, productId: number }>({ loading: true, branchId: getBranch(), date: moment().format('YYYY-MM-DD'), productTypeId: 0, productId: 0 });
    const [loading, setLoading] = useState<boolean>(true)
    const [dataRow, setDataRow] = useState<any>([])

    const [incomeData, setIncomeData] = useState<any[]>([])
    const [incomeRowCount, setIncomeRowCount] = useState<number>(0)


    const verifyPermission = async () => {
        const condition: FindAllReportInterface = {
            page: 1,
            pageLimit: 200
        }
        ReportApi.reportList(condition).then(({ data }) => {
            const check = _.find(data, (val: any) => String(val.reportKey) === 'PARTIAL_PROCEDURE_SUMMARY') ? true : false
            if (!check) {
                history.push(routeName.report)
                return false
            }
        })

        /** MASTER DATA */
        ClinicApi.findAllBranches()
            .then(({ data }) => setBranches(data.map((item: any) => ({ name: item?.branchName, value: item?.branchId }))))
            .catch((e) => {
                return
            })
        return true
    }


    useEffect(() => {
        verifyPermission()
    }, [])

    /** TABLE 1 */
    const fetchIncome = useCallback(() => {

        if (filter.loading) ReportApi.reportPartialProcedureSummary({ branchId: filter.branchId, date: filter.date, })
            .then(({ headers, data }) => {
                console.log('data', data);
                setIncomeData(data)
            })
            .catch((e) => setFilter((old) => ({ ...old, loading: false })))
            .finally(() => setFilter((old) => ({ ...old, loading: false })))
    }, [filter.loading])

    /** UseEffect */
    useEffect(() => {
        fetchIncome()
    }, [fetchIncome])

    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`รายงานสรุปยอดหัตถการเฉพาะส่วน`)}`,
        sheetName: `${t(`รายงานสรุปยอดหัตถการเฉพาะส่วน`)}`
    })


    useEffect(() => {
        const dates = _.groupBy(incomeData.map((value, key) => {
            let fixType = ''
            let sorting = 0
            if (value.operativeTypeName.search(/Retainer/i) !== -1 || value.operativeTypeName.search(/รีเทนเนอร์/i) !== -1) {
                fixType = 'รีเทนเนอร์'
                sorting = 1
            }
            else if (value.operativeTypeName.search(/จัดฟัน/i) !== -1 && value.operativeTypeName.search(/จัดฟันแบบใส/i) === -1) {
                fixType = 'จัดฟัน'
                sorting = 2
            }
            else if (value.operativeTypeName.search(/จัดฟัน/i) === -1 && value.operativeTypeName.search(/จัดฟันแบบใส/i) !== -1) {
                fixType = 'จัดฟันแบบใส'
                sorting = 3
            }
            else if (value.operativeTypeName.search(/รากฟันเทียม/i) !== -1) {
                fixType = 'รากฟันเทียม'
                sorting = 4
            }
            else if (value.operativeTypeName.search(/ใส่ฟัน/i) !== -1) {
                fixType = 'ใส่ฟัน'
                sorting = 5
            }
            else if (value.operativeTypeName.search(/วีเนียร์/i) !== -1 || value.operativeTypeName.search(/veneer/i) !== -1) {
                fixType = 'วีเนียร์'
                sorting = 6
            }
            else if (value.operativeTypeName.search(/ฟอกสีฟัน/i) !== -1) {
                fixType = 'ฟอกสีฟัน'
                sorting = 7
            }
            else {
                fixType = value.operativeTypeName
                sorting = 8
            }

            let fixOperative = ''
            if (value.operativeName.search(/พิมพ์ปาก/i) !== -1) fixOperative = 'พิมพ์ปาก'
            else if (value.operativeName.search(/รากปักใหม่/i) !== -1) fixOperative = 'รากปักใหม่'
            else if (value.operativeName.search(/รากปักแก้/i) !== -1) fixOperative = 'รากปักแก้'
            else if (value.operativeName.search(/ติดเครื่องมือ/i) !== -1) fixOperative = 'ติดเครื่องมือ'
            else if (value.operativeName.search(/ถอดเครื่องมือ/i) !== -1) fixOperative = 'ถอดเครื่องมือ'
            else if (value.operativeName.search(/เปลี่ยนยาง/i) !== -1) fixOperative = 'เปลี่ยนยาง'
            else if (value.operativeName.search(/Kit/i) !== -1) fixOperative = 'Kit'
            else fixOperative = value.operativeName
            return { fixType, fixOperative: fixOperative, ...value, sorting }
        })
            , 'date')


        const dataRender: any = []
        _.map(dates, (operativeTypes, date) => {
            const aptDentalImplant = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'รากฟันเทียม') return Number(o.apt)
                else return 0
            })
            const aptDentalImplantDone = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'รากฟันเทียม') return Number(o.aptDone)
                else return 0
            })
            const dentalImplantImpression = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'รากฟันเทียม' && o.fixOperative === 'พิมพ์ปาก') return Number(o.qtyTx)
                else return 0
            })
            const dentalImplantCase2 = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'รากฟันเทียม' && o.fixOperative === 'รากปักใหม่') return Number(o.qtyTx)
                else return 0
            })
            const dentalImplantCase3 = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'รากฟันเทียม' && o.fixOperative === 'รากปักแก้') return Number(o.qtyTx)
                else return 0
            })
            const dentalImplantCase4 = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'รากฟันเทียม') return Number(o.qtyTx)
                else return 0
            })
            const dentalImplantCase5 = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'รากฟันเทียม' && o.fixOperative === 'กระดูก') return Number(o.qtyTx)
                else return 0
            })
            const dentalImplantCase6 = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'รากฟันเทียม' && o.fixOperative === 'เมมเบรน') return Number(o.qtyTx)
                else return 0
            })
            const aptBraces = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'จัดฟัน') return Number(o.apt)
                else return 0
            })
            const aptBracesDone = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'จัดฟัน') return Number(o.aptDone)
                else return 0
            })
            const bracesImpression = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'จัดฟัน' && o.fixOperative === 'พิมพ์ปาก') return Number(o.qtyTx)
                else return 0
            })
            const braces1 = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'จัดฟัน' && o.fixOperative === 'ติดเครื่องมือ') return Number(o.qtyTx)
                else return 0
            })
            const braces2 = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'จัดฟัน' && o.fixOperative === 'ถอนเครื่องมือ') return Number(o.qtyTx)
                else return 0
            })
            const braces3 = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'จัดฟัน' && o.fixOperative === 'เปลี่ยนยาง') return Number(o.qtyTx)
                else return 0
            })
            const aptInvisalign = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'จัดฟันแบบใส') return Number(o.apt)
                else return 0
            })
            const aptInvisalignDone = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'จัดฟันแบบใส') return Number(o.aptDone)
                else return 0
            })

            const invisalignImpression = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'จัดฟันแบบใส' && o.fixOperative === 'พิมพ์ปาก') return Number(o.qtyTx)
                else return 0
            })
            const aptRetainer = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'จัดฟันแบบใส') return Number(o.apt)
                else return 0
            })
            const aptRetainerDone = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'จัดฟันแบบใส') return Number(o.aptDone)
                else return 0
            })

            const retainerImpression = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'จัดฟันแบบใส' && o.fixOperative === 'พิมพ์ปาก') return Number(o.qtyTx)
                else return 0
            })

            const aptTeethWhitening = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'ใส่ฟัน') return Number(o.apt)
                else return 0
            })
            const aptTeethWhiteningDone = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'ใส่ฟัน') return Number(o.aptDone)
                else return 0
            })

            const teethWhiteningImpression = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'ใส่ฟัน' && o.fixOperative === 'พิมพ์ปาก') return Number(o.qtyTx)
                else return 0
            })

            const aptVeneer = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'วีเนียร์') return Number(o.apt)
                else return 0
            })
            const aptVeneerDone = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'วีเนียร์') return Number(o.aptDone)
                else return 0
            })

            const veneerImpression = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'วีเนียร์' && o.fixOperative === 'พิมพ์ปาก') return Number(o.qtyTx)
                else return 0
            })

            const qtyLast = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'ฟอกสีฟัน') return Number(o.qtyTx)
                else return 0
            })

            const qtyKit = _.sumBy(operativeTypes, (o: any) => {
                if (o.fixType === 'ฟอกสีฟัน' && o.fixOperative === 'Kit') return Number(o.qtyTx)
                else return 0
            })

            dataRender.push({
                date: dateToView(date),
                aptDentalImplant,
                aptDentalImplantDone,
                aptDentalImplantPercent: `${numberFormat(((aptDentalImplantDone / aptDentalImplant) * 100) || 0)}%`,
                dentalImplantImpression,
                dentalImplantImpression1: `${numberFormat(((dentalImplantImpression / aptDentalImplant) * 100) || 0)}%`,
                dentalImplantImpression2: `${numberFormat(((dentalImplantImpression / aptDentalImplantDone) * 100) || 0)}%`,
                dentalImplantCase2,
                dentalImplantCase3,
                dentalImplantCase4,
                dentalImplantCase5,
                dentalImplantCase6,
                dentalImplantCase7: '-',
                aptBraces,
                aptBracesDone,
                aptBracesDonePercent: `${numberFormat(((aptBracesDone / aptBraces) * 100) || 0)}%`,
                bracesImpression,
                bracesImpression1: `${numberFormat(((bracesImpression / aptBraces) * 100) || 0)}%`,
                bracesImpression2: `${numberFormat(((bracesImpression / aptBracesDone) * 100) || 0)}%`,
                braces1,
                braces2,
                braces3,
                aptInvisalign,
                aptInvisalignDone,
                aptInvisalignDonePercent: `${numberFormat(((aptInvisalignDone / aptInvisalign) * 100) || 0)}%`,
                invisalignImpression,
                invisalignImpression1: `${numberFormat(((invisalignImpression / aptInvisalign) * 100) || 0)}%`,
                invisalignImpression2: `${numberFormat(((invisalignImpression / aptInvisalignDone) * 100) || 0)}%`,
                aptRetainer,
                aptRetainerDone,
                aptRetainerDonePercent: `${numberFormat(((aptRetainerDone / aptRetainer) * 100) || 0)}%`,
                retainerImpression,
                retainerImpression1: `${numberFormat(((retainerImpression / aptRetainer) * 100) || 0)}%`,
                retainerImpression2: `${numberFormat(((retainerImpression / aptRetainerDone) * 100) || 0)}%`,
                aptTeethWhitening,
                aptTeethWhiteningDone,
                aptTeethWhiteningDonePercent: `${numberFormat(((aptTeethWhiteningDone / aptTeethWhitening) * 100) || 0)}%`,
                teethWhiteningImpression,
                teethWhiteningImpression1: `${numberFormat(((teethWhiteningImpression / aptTeethWhitening) * 100) || 0)}%`,
                teethWhiteningImpression2: `${numberFormat(((teethWhiteningImpression / aptTeethWhiteningDone) * 100) || 0)}%`,
                aptVeneer,
                aptVeneerDone,
                aptVeneerDonePercent: `${numberFormat(((aptVeneerDone / aptVeneer) * 100) || 0)}%`,
                veneerImpression,
                veneerImpression1: `${numberFormat(((veneerImpression / aptVeneer) * 100) || 0)}%`,
                veneerImpression2: `${numberFormat(((veneerImpression / aptVeneerDone) * 100) || 0)}%`,
                qtyLast,
                qtyKit
            })
        })

        dataRender.push({
            date: 'รวม',
            aptDentalImplant: numberFormat(_.sumBy(dataRender, 'aptDentalImplant'), 0),
            aptDentalImplantDone: numberFormat(_.sumBy(dataRender, 'aptDentalImplantDone'), 0),
            aptDentalImplantPercent: `${numberFormat(((_.sumBy(dataRender, 'aptDentalImplantDone') / _.sumBy(dataRender, 'aptDentalImplant')) * 100) || 0)}%`,
            dentalImplantImpression: numberFormat(_.sumBy(dataRender, 'dentalImplantImpression'), 0),
            dentalImplantImpression1: `${numberFormat(((_.sumBy(dataRender, 'dentalImplantImpression') / _.sumBy(dataRender, 'aptDentalImplant')) * 100) || 0)}%`,
            dentalImplantImpression2: `${numberFormat(((_.sumBy(dataRender, 'dentalImplantImpression') / _.sumBy(dataRender, 'aptDentalImplantDone')) * 100) || 0)}%`,
            dentalImplantCase2: _.sumBy(dataRender, 'dentalImplantCase2'),
            dentalImplantCase3: _.sumBy(dataRender, 'dentalImplantCase3'),
            dentalImplantCase4: _.sumBy(dataRender, 'dentalImplantCase4'),
            dentalImplantCase5: _.sumBy(dataRender, 'dentalImplantCase5'),
            dentalImplantCase6: _.sumBy(dataRender, 'dentalImplantCase6'),
            dentalImplantCase7: '-',
            aptBraces: _.sumBy(dataRender, 'aptBraces'),
            aptBracesDone: _.sumBy(dataRender, 'aptBracesDone'),
            aptBracesDonePercent: `${numberFormat(((_.sumBy(dataRender, 'aptBracesDone') / _.sumBy(dataRender, 'aptBraces')) * 100) || 0)}%`,
            bracesImpression: _.sumBy(dataRender, 'bracesImpression'),
            bracesImpression1: `${numberFormat(((_.sumBy(dataRender, 'bracesImpression') / _.sumBy(dataRender, 'aptBraces')) * 100) || 0)}%`,
            bracesImpression2: `${numberFormat(((_.sumBy(dataRender, 'bracesImpression') / _.sumBy(dataRender, 'aptBracesDone')) * 100) || 0)}%`,
            braces1: _.sumBy(dataRender, 'braces1'),
            braces2: _.sumBy(dataRender, 'braces2'),
            braces3: _.sumBy(dataRender, 'braces3'),
            aptInvisalign: _.sumBy(dataRender, 'aptInvisalign'),
            aptInvisalignDone: _.sumBy(dataRender, 'aptInvisalignDone'),
            aptInvisalignDonePercent: `${numberFormat(((_.sumBy(dataRender, 'aptInvisalignDone') / _.sumBy(dataRender, 'aptInvisalign')) * 100) || 0)}%`,
            invisalignImpression: _.sumBy(dataRender, 'invisalignImpression'),
            invisalignImpression1: `${numberFormat(((_.sumBy(dataRender, 'invisalignImpression') / _.sumBy(dataRender, 'aptInvisalign')) * 100) || 0)}%`,
            invisalignImpression2: `${numberFormat(((_.sumBy(dataRender, 'invisalignImpression') / _.sumBy(dataRender, 'aptInvisalignDone')) * 100) || 0)}%`,
            aptRetainer: _.sumBy(dataRender, 'aptRetainer'),
            aptRetainerDone: _.sumBy(dataRender, 'aptRetainerDone'),
            aptRetainerDonePercent: `${numberFormat(((_.sumBy(dataRender, 'aptRetainerDone') / _.sumBy(dataRender, 'aptRetainer')) * 100) || 0)}%`,
            retainerImpression: _.sumBy(dataRender, 'retainerImpression'),
            retainerImpression1: `${numberFormat(((_.sumBy(dataRender, 'retainerImpression') / _.sumBy(dataRender, 'aptRetainer')) * 100) || 0)}%`,
            retainerImpression2: `${numberFormat(((_.sumBy(dataRender, 'retainerImpression') / _.sumBy(dataRender, 'aptRetainerDone')) * 100) || 0)}%`,
            aptTeethWhitening: _.sumBy(dataRender, 'aptTeethWhitening'),
            aptTeethWhiteningDone: _.sumBy(dataRender, 'aptTeethWhiteningDone'),
            aptTeethWhiteningDonePercent: `${numberFormat(((_.sumBy(dataRender, 'aptTeethWhiteningDone') / _.sumBy(dataRender, 'aptTeethWhitening')) * 100) || 0)}%`,
            teethWhiteningImpression: _.sumBy(dataRender, 'teethWhiteningImpression'),
            teethWhiteningImpression1: `${numberFormat(((_.sumBy(dataRender, 'teethWhiteningImpression') / _.sumBy(dataRender, 'aptTeethWhitening')) * 100) || 0)}%`,
            teethWhiteningImpression2: `${numberFormat(((_.sumBy(dataRender, 'teethWhiteningImpression') / _.sumBy(dataRender, 'aptTeethWhiteningDone')) * 100) || 0)}%`,
            aptVeneer: _.sumBy(dataRender, 'aptVeneer'),
            aptVeneerDone: _.sumBy(dataRender, 'aptVeneerDone'),
            aptVeneerDonePercent: `${numberFormat(((_.sumBy(dataRender, 'aptVeneerDone') / _.sumBy(dataRender, 'aptVeneer')) * 100) || 0)}%`,
            veneerImpression: _.sumBy(dataRender, 'veneerImpression'),
            veneerImpression1: `${numberFormat(((_.sumBy(dataRender, 'veneerImpression') / _.sumBy(dataRender, 'aptVeneer')) * 100) || 0)}%`,
            veneerImpression2: `${numberFormat(((_.sumBy(dataRender, 'veneerImpression') / _.sumBy(dataRender, 'aptVeneerDone')) * 100) || 0)}%`,
            qtyLast: _.sumBy(dataRender, 'qtyLast'),
            qtyKit: _.sumBy(dataRender, 'qtyKit')
        })
        setDataRow(dataRender)
    }, [incomeData])


    const renderData = (first?: boolean) => {
        if (!dataRow || !dataRow.length) {
            return []
        }

        return [..._.map(dataRow, ({ date, ...info }: any, index: number) => {
            const isSum = index === (dataRow.length - 1) ? { background: "#E9E9E9" } : {}

            return (
                <TableRow key={index} style={{ fontSize: '10px', ...isSum }}>
                    <TableCell style={{ textAlign: 'center' }}>{date}</TableCell>
                    {Object.values(info).map((i: any, num: number) => (<TableCell key={num} style={{ textAlign: 'center' }} >{i}</TableCell>))}
                </TableRow>
            )
        }),
        ]
    }

    return (
        <Box>
            <HeaderCard text="รายงานสรุปยอดหัตถการเฉพาะส่วน" />
            <Grid container spacing="16px" paddingX="24px" paddingY="16px">
                <Grid item xs={3}>
                    <SelectInput
                        label={t('NAV.BRANCH')}
                        onChange={(e) => setFilter((old) => ({ ...old, branchId: e.target.value }))}
                        options={branches}
                        value={filter.branchId.toString()}
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputSecondNewDatePicker
                        allowClear
                        views={PICKER_VIEWS.MONTH}
                        onchange={(e: any) => {
                            setFilter((old) => ({ ...old, date: moment(e).format('YYYY-MM-DD') }))
                        }}
                        value={filter.date}
                        position={{ style: { width: '100%' } }}
                        dateFormat="MMMM YYYY"
                        inputHeight={32}
                        label={t('วันที่')} />
                </Grid>
                <Grid item xs={3}>
                    <MainButton onClick={() => setFilter((old) => ({ ...old, loading: true }))} title="" type="primary" variant="contained" style={{ width: '120px' }} />
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end" >

                    <Box>
                        <UseStyled.DropdownDocument>
                            <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                                {t('PRINT_DOCUMENT')}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                                <FucntionPrint
                                    content={() => componentRefPDF.current}
                                    removeIfram
                                    trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                                    style={`@page { size: 1122.519685px 793.7007874px ;  margin: 0.5cm;  } @media print { body { -webkit-print-color-adjust: exact; }`}
                                />
                            </Dropdown.Menu>
                        </UseStyled.DropdownDocument>
                    </Box>

                </Grid>
            </Grid>

            <Box marginX="24px">
                <TableContainer
                    component={Paper}
                    style={{ overflow: "auto", maxWidth: "100%", borderRadius: "10px" }}
                >
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: "#2F4269" }}>
                                <TableCell rowSpan={2} style={{ color: "white", fontWeight: "bold", textAlign: "center", borderRight: "1px solid white" }}>วันที่</TableCell>
                                <TableCell colSpan={12} style={{ color: "white", fontWeight: "bold", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>รากฟันเทียม Dental Implant</TableCell>
                                <TableCell colSpan={9} style={{ color: "white", fontWeight: "bold", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>จัดฟัน</TableCell>
                                <TableCell colSpan={6} style={{ color: "white", fontWeight: "bold", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>จัดฟันแบบใส</TableCell>
                                <TableCell colSpan={6} style={{ color: "white", fontWeight: "bold", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>รีเทนเนอร์</TableCell>
                                <TableCell colSpan={6} style={{ color: "white", fontWeight: "bold", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>ใส่ฟัน</TableCell>
                                <TableCell colSpan={6} style={{ color: "white", fontWeight: "bold", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>วีเนียร์</TableCell>
                                <TableCell colSpan={2} style={{ color: "white", fontWeight: "bold", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>ฟอกสีฟัน</TableCell>
                            </TableRow>
                            <TableRow style={{ backgroundColor: "#2F4269" }}>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>A นัดปรึกษา</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '120px', padding: '8px' }}>B มาตามนัด</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", padding: '8px' }}>%A</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>C พิมพ์ปาก</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", padding: '8px' }}>%A</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", padding: '8px' }}>%B</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '135px', padding: '8px' }}>จำนวนรากปักใหม่</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '130px', padding: '8px' }}>จำนวนรากปักแก้</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>จำนวนที่ใช้</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", padding: '8px' }}>กระดูก</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>เมมเบรน</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>หมายเหตุ</TableCell>

                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>A นัดปรึกษา</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>B มาตามนัด</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", padding: '8px' }}>%A</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>C พิมพ์ปาก</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", padding: '8px' }}>%A</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", padding: '8px' }}>%B</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '120px', padding: '8px' }}>ติดเครื่องมือ(ครั้ง)</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>ถอนเครื่องมือ</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", borderRight: "1px solid white", minWidth: '100px', padding: '8px' }}>เปลี่ยนยาง</TableCell>

                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>A นัดปรึกษา</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>B มาตามนัด</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", padding: '8px' }}>%A</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>C พิมพ์ปาก</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", padding: '8px' }}>%A</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>%B</TableCell>

                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>A นัดปรึกษา</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>B มาตามนัด</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", padding: '8px' }}>%A</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>C พิมพ์ปาก</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", padding: '8px' }}>%A</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>%B</TableCell>

                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>A นัดปรึกษา</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>B มาตามนัด</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", padding: '8px' }}>%A</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>C พิมพ์ปาก</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", padding: '8px' }}>%A</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>%B</TableCell>

                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>A นัดปรึกษา</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>B มาตามนัด</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", padding: '8px' }}>%A</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>C พิมพ์ปาก</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", padding: '8px' }}>%A</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>%B</TableCell>

                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>จำนวนเคส</TableCell>
                                <TableCell style={{ color: "white", textAlign: "center", minWidth: '100px', padding: '8px' }}>จำนวน Kit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderData()}
                        </TableBody>

                    </Table>
                </TableContainer>
            </Box>
            <Loading show={filter.loading} type="fullLoading" />

            <div className="print-show" ref={componentRef}>
                <ExportData
                    headCells={t(`รายงานสรุปยอดหัตถการเฉพาะส่วน`)}
                    componant={
                        <>
                            <UseStyled.Styles className="pt-3">
                                <Table>
                                    <TableHead>
                                        <TableRow >
                                            <TableCell rowSpan={2} style={{ fontWeight: "bold", textAlign: "center", borderRight: "1px solid white" }}>วันที่</TableCell>
                                            <TableCell colSpan={12} style={{ fontWeight: "bold", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>รากฟันเทียม Dental Implant</TableCell>
                                            <TableCell colSpan={9} style={{ fontWeight: "bold", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>จัดฟัน</TableCell>
                                            <TableCell colSpan={6} style={{ fontWeight: "bold", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>จัดฟันแบบใส</TableCell>
                                            <TableCell colSpan={6} style={{ fontWeight: "bold", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>รีเทนเนอร์</TableCell>
                                            <TableCell colSpan={6} style={{ fontWeight: "bold", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>ใส่ฟัน</TableCell>
                                            <TableCell colSpan={6} style={{ fontWeight: "bold", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>วีเนียร์</TableCell>
                                            <TableCell colSpan={2} style={{ fontWeight: "bold", textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>ฟอกสีฟัน</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>A นัดปรึกษา</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '120px', padding: '8px' }}>B มาตามนัด</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: '8px' }}>%A</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>C พิมพ์ปาก</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: '8px' }}>%A</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: '8px' }}>%B</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '135px', padding: '8px' }}>จำนวนรากปักใหม่</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '130px', padding: '8px' }}>จำนวนรากปักแก้</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>จำนวนที่ใช้</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: '8px' }}>กระดูก</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>เมมเบรน</TableCell>
                                            <TableCell style={{ textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>หมายเหตุ</TableCell>

                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>A นัดปรึกษา</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>B มาตามนัด</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: '8px' }}>%A</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>C พิมพ์ปาก</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: '8px' }}>%A</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: '8px' }}>%B</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '120px', padding: '8px' }}>ติดเครื่องมือ(ครั้ง)</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>ถอนเครื่องมือ</TableCell>
                                            <TableCell style={{ textAlign: "center", borderRight: "1px solid white", minWidth: '100px', padding: '8px' }}>เปลี่ยนยาง</TableCell>

                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>A นัดปรึกษา</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>B มาตามนัด</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: '8px' }}>%A</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>C พิมพ์ปาก</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: '8px' }}>%A</TableCell>
                                            <TableCell style={{ textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>%B</TableCell>

                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>A นัดปรึกษา</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>B มาตามนัด</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: '8px' }}>%A</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>C พิมพ์ปาก</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: '8px' }}>%A</TableCell>
                                            <TableCell style={{ textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>%B</TableCell>

                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>A นัดปรึกษา</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>B มาตามนัด</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: '8px' }}>%A</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>C พิมพ์ปาก</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: '8px' }}>%A</TableCell>
                                            <TableCell style={{ textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>%B</TableCell>

                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>A นัดปรึกษา</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>B มาตามนัด</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: '8px' }}>%A</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>C พิมพ์ปาก</TableCell>
                                            <TableCell style={{ textAlign: "center", padding: '8px' }}>%A</TableCell>
                                            <TableCell style={{ textAlign: "center", borderRight: "1px solid white", padding: '8px' }}>%B</TableCell>

                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>จำนวนเคส</TableCell>
                                            <TableCell style={{ textAlign: "center", minWidth: '100px', padding: '8px' }}>จำนวน Kit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {renderData()}
                                    </TableBody>

                                </Table>
                            </UseStyled.Styles>

                        </>
                    }
                />
            </div>
            <div className="print-show" ref={componentRefPDF}>
                <ExportPDFData
                    headCells={t(`รายงานสรุปยอดหัตถการเฉพาะส่วน`)}
                    rowsData={incomeData}
                />
            </div>
        </Box>

    )
}


export function ExportData(props: ExportProps & { incomeData?: any }) {
    const { t } = useTranslation()
    return (
        <>
            <div className="large">{props.headCells}</div>
            {props.componant}
        </>
    )
}

export function ExportPDFData(props: ExportProps & { incomeData?: any }) {

    const { t } = useTranslation()

    const commonColFix = ['A', 'B', '%A', 'พิมพ์ปาก', 'C_%A', 'C_%B']
    const commonColOpFix = ['พิมพ์ปาก', 'รากปักใหม่', 'รากปักแก้', 'กระดูก', 'เมฆเบรน', 'ติดเครื่องมือ', 'ถอดเครื่องมือ', 'เปลี่ยนยาง']
    const colFix_1 = [
        {
            col: 'รากฟันเทียม',
            sub: [...commonColFix, 'รากปักใหม่', 'รากปักแก้', 'ที่ใช้', 'กระดูก', 'เมฆเบรน', 'หมายเหตุ']
        },
        {
            col: 'จัดฟัน',
            sub: [...commonColFix, 'ติดเครื่องมือ', 'ถอดเครื่องมือ', 'เปลี่ยนยาง']
        },
        {
            col: 'จัดฟันแบบใส',
            sub: [...commonColFix,]
        },
        {
            col: 'รีเทนเนอร์',
            sub: [...commonColFix,]
        }
    ]
    const colFix_2 = [
        { col: 'ใส่ฟัน', sub: [...commonColFix,] },
        {
            col: 'สีเนียร์', sub: [...commonColFix,]
        },
        { col: 'ฟอกสีฟัน', sub: [...commonColFix,] },
        { col: 'ฟอกสีฟัน_', sub: ['เคส', 'เคสkit'] }
    ]

    const [dataRow, setDataRow] = useState<any>([])

    const handleChangeOpName = (text: string): string => {
        const index = commonColOpFix.findIndex((i: string) => text.includes(i))
        if (index < 0) {
            return text
        }
        return commonColOpFix[index]
    }

    useEffect(() => {
        const data: any = []
        props.rowsData.forEach((i: any) => {

            const item = { ...i, operativeName: handleChangeOpName(i?.operativeName?.toString()) }
            if (data.every((info: any) => info?.date !== item.date)) {
                data.push({
                    date: item.date,
                    operativeType: [
                        {
                            operativeTypeName: item.operativeTypeName,
                            operative: [
                                {
                                    operativeName: item.operativeName,
                                    cTx: item.cTx,
                                }
                            ],
                            apt: item.apt,
                            aptDone: Number(item.aptDone || 0)
                        }
                    ]
                })
            } else {
                const indexDate = data.findIndex((info: any) => info?.date === item.date)
                const dataDate = data[indexDate]
                if (dataDate?.operativeType.every((info: any) => info?.operativeTypeName !== item.operativeTypeName)) {
                    dataDate.operativeType.push({
                        operativeTypeName: item.operativeTypeName,
                        operative: [
                            {
                                operativeName: item.operativeName,
                                cTx: item.cTx,
                            }
                        ],
                        apt: item.apt,
                        aptDone: item.aptDone
                    })

                } else {
                    const indexOpType = dataDate?.operativeType.findIndex((info: any) => info?.operativeTypeName === item.operativeTypeName)
                    const dataOpType = dataDate?.operativeType[indexOpType]
                    if (dataOpType?.operative.every((info: any) => info?.operativeName !== item.operativeName)) {
                        dataOpType.operative.push({
                            operativeName: item.operativeName,
                            cTx: item.cTx,
                        })
                    } else {
                        dataOpType.operative[indexOpType].cTx += item.cTx
                    }
                    dataOpType['apt'] = (Number(dataOpType['apt']) || 0) + Number(item.apt)
                    dataOpType['aptDone'] = (Number(dataOpType['aptDone']) || 0) + Number(item?.aptDone || 0)
                }
            }
        })

        const colSort = [...colFix_1, ...colFix_2]
        const newTableData = data.map((item: any) => {
            let opType: any = {}
            colSort.forEach((col: any) => {
                const info = item?.operativeType?.find((i: any) => i?.operativeTypeName === col?.col)
                if (info) {
                    const opTypeName = info.operativeTypeName
                    const opApt = (info.apt || 0)
                    const opAptDone = (info.aptDone || 0)
                    const opC = info.operative.find((i: any) => i?.operativeName === 'พิมพ์ปาก')?.cTx || 0

                    const prev: any = {}

                    prev[`${opTypeName}_A`] = opApt
                    prev[`${opTypeName}_B`] = opAptDone
                    prev[`${opTypeName}_%B`] = `${numberFormat(!opAptDone ? 0 : (opApt / opAptDone) * 100)}%`
                    prev[`${opTypeName}_พิมพ์ปาก`] = opC
                    prev[`${opTypeName}_C_%A`] = `${numberFormat(!opC ? 0 : (opApt / opC) * 100)}%`
                    prev[`${opTypeName}_C_%B`] = `${numberFormat(!opC ? 0 : (opAptDone / opC) * 100)}%`

                    col.sub.forEach((sub: any) => {
                        if (commonColFix.every((i: string) => i !== sub)) {
                            if (sub === 'ที่ใช้') {
                                const opC_1 = info.operative.find((i: any) => i?.operativeName === 'รากปักใหม่')?.cTx || 0
                                const opC_2 = info.operative.find((i: any) => i?.operativeName === 'รากปักแก้')?.cTx || 0
                                prev[`${opTypeName}_${sub}`] = opC_1 + opC_2
                            } else if (sub === 'หมายเหตุ') {
                                prev[`${opTypeName}_${sub}`] = '-'
                            } else {
                                const opC = info.operative.find((i: any) => i?.operativeName === sub)?.cTx || 0
                                prev[`${opTypeName}_${sub}`] = opC
                            }
                        }
                    })

                    opType = { ...opType, ...prev }
                } else {
                    const prev: any = {}
                    col.sub.forEach((sub: any) => {
                        if (sub.includes('%')) {
                            prev[`${col.col}_${sub}`] = `${numberFormat(0)}%`
                        } else {
                            prev[`${col.col}_${sub}`] = 0
                        }
                    })
                    opType = { ...opType, ...prev }
                }
            })

            return {
                date: item.date,
                ...opType
            }
        })
        setDataRow(newTableData)
    }, [])

    const headCellsFirst = [
        [
            { id: '0', disablePadding: false, labelKey: 'วันที่', align: 'center', class: 'text-nowrap', rowSpan: 2, style: { width: '70px' } },
            { id: '1', disablePadding: false, labelKey: 'รากฟันเทียม', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 12, },
            { id: '2', disablePadding: false, labelKey: 'จัดฟัน', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 9, },
            { id: '3', disablePadding: false, labelKey: 'จัดฟันแบบใส', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 6, },
            { id: '4', disablePadding: false, labelKey: 'รีเทนเนอร์', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 6, },
        ]
        ,
        [
            { id: '1_1', disablePadding: false, labelKey: 'A\nนัดปรึกษา', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1_2', disablePadding: false, labelKey: 'B\nมาตามนัด', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1_3', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1_4', disablePadding: false, labelKey: 'C\nพิมพ์ปาก', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1_5', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1-6', disablePadding: false, labelKey: '%B', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1_7', disablePadding: false, labelKey: 'จำนวน\nรากปักใหม่', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1_8', disablePadding: false, labelKey: 'จำนวน\nรากปักแก้', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1_9', disablePadding: false, labelKey: 'จำนวน\nที่ใช้', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1_10', disablePadding: false, labelKey: 'กระดูก', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1_11', disablePadding: false, labelKey: 'เมฆเบรน', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1_12', disablePadding: false, labelKey: 'หมาย\nเหตุ', align: 'center', class: 'text-nowrap', row: 1 },

            { id: '2_1', disablePadding: false, labelKey: 'A\nนัดปรึกษา', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '2_2', disablePadding: false, labelKey: 'B\nมาตามนัด', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '2_3', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '2_4', disablePadding: false, labelKey: 'C\nพิมพ์ปาก', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '2_5', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '2-6', disablePadding: false, labelKey: '%B', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '2_7', disablePadding: false, labelKey: 'ติดเครื่อง\nมือ(ครั้ง)', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '2_8', disablePadding: false, labelKey: 'ถอด\nเตรื่องมือ', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '2-9', disablePadding: false, labelKey: 'เปลี่ยน\nยาง', align: 'center', class: 'text-nowrap', row: 1 },

            { id: '3_1', disablePadding: false, labelKey: 'A\nนัดปรึกษา', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '3_2', disablePadding: false, labelKey: 'B\nมาตามนัด', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '3_3', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '3_4', disablePadding: false, labelKey: 'C\nพิมพ์ปาก', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '3_5', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '3-6', disablePadding: false, labelKey: '%B', align: 'center', class: 'text-nowrap', row: 1 },

            { id: '4_1', disablePadding: false, labelKey: 'A\nนัดปรึกษา', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '4_2', disablePadding: false, labelKey: 'B\nมาตามนัด', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '4_3', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '4_4', disablePadding: false, labelKey: 'C\nพิมพ์ปาก', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '4_5', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '4-6', disablePadding: false, labelKey: '%B', align: 'center', class: 'text-nowrap', row: 1 },
        ]
    ]

    const headCellsSec = [
        [
            { id: '0', disablePadding: false, labelKey: 'วันที่', align: 'center', class: 'text-nowrap', rowSpan: 2, style: { width: '70px' } },
            { id: '1', disablePadding: false, labelKey: 'ใส่ฟัน', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 6, },
            { id: '2', disablePadding: false, labelKey: 'สีเนียร์', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 6, },
            { id: '3', disablePadding: false, labelKey: 'ฟอกสีฟัน', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 6, },
            { id: '4', disablePadding: false, labelKey: 'ฟอกสีฟัน', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 2, },
        ]
        ,
        [
            { id: '1_1', disablePadding: false, labelKey: 'A\nนัดปรึกษา', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1_2', disablePadding: false, labelKey: 'B\nมาตามนัด', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1_3', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1_4', disablePadding: false, labelKey: 'C\nพิมพ์ปาก', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1_5', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '1-6', disablePadding: false, labelKey: '%B', align: 'center', class: 'text-nowrap', row: 1 },

            { id: '2_1', disablePadding: false, labelKey: 'A\nนัดปรึกษา', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '2_2', disablePadding: false, labelKey: 'B\nมาตามนัด', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '2_3', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '2_4', disablePadding: false, labelKey: 'C\nพิมพ์ปาก', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '2_5', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '2-6', disablePadding: false, labelKey: '%B', align: 'center', class: 'text-nowrap', row: 1 },

            { id: '3_1', disablePadding: false, labelKey: 'A\nนัดปรึกษา', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '3_2', disablePadding: false, labelKey: 'B\nมาตามนัด', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '3_3', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '3_4', disablePadding: false, labelKey: 'C\nพิมพ์ปาก', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '3_5', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '3-6', disablePadding: false, labelKey: '%B', align: 'center', class: 'text-nowrap', row: 1 },

            { id: '4_1', disablePadding: false, labelKey: 'จำนวน\nเคส', align: 'center', class: 'text-nowrap', row: 1 },
            { id: '4_2', disablePadding: false, labelKey: 'เคส\nKit', align: 'center', class: 'text-nowrap', row: 1 },
        ]
    ]

    const renderDataFirst = (first?: boolean) => {
        const lenCol_1 = colFix_1.reduce((a: number, b: any) => a + b.sub.length, 0)
        const lenCol_2 = colFix_2.reduce((a: number, b: any) => a + b.sub.length, 0)
        const indexStart = first ? 0 : lenCol_1
        const indexEnd = first ? lenCol_1 : lenCol_1 + lenCol_2
        if (!dataRow || !dataRow.length) {
            return []
        }

        return [..._.map(dataRow, ({ date, ...info }: any, index: number) => {
            return (
                <tr key={index} style={{ fontSize: '10px' }}>
                    <td style={{ textAlign: 'center' }}>{dateToView(date)}</td>
                    {Object.values(info).splice(indexStart, indexEnd).map((i: any, num: number) => (<td key={num} style={{ textAlign: 'center' }} >{i}</td>))}
                </tr>
            )
        }),
        <tr key={props?.incomeData?.length} style={{ fontSize: '10px' }}>
            <td style={{ textAlign: 'right' }}>{t('รวม')}</td>
            {Array(first ? lenCol_1 : lenCol_2).fill(0).map((item: any, num: number) => (<td key={num} style={{ textAlign: 'center' }} >{'-'}</td>))}
        </tr>
        ]
    }

    return (
        <>
            <div className="large">{props.headCells}</div>
            <Box sx={{
                'td': {
                    border: '1px ridge rgb(0, 0, 0) !important',
                    padding: '1px 4px !important'
                },
            }}>
                <table className={'mb-3'}>
                    <thead>
                        {_.map(headCellsFirst, (info: any, index: number) => (
                            <tr key={index}>
                                {info.map((val: any, num: number) => {
                                    return (
                                        <td
                                            key={num}
                                            style={{ textAlign: val.align, fontSize: 9, fontWeight: 500, whiteSpace: 'pre-line', ...val?.style }} rowSpan={val?.rowSpan} colSpan={val?.colSpan} > {val.labelKey}</td>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {renderDataFirst(true)}
                    </tbody>
                </table>

                <table>
                    <thead>
                        {_.map(headCellsSec, (info: any, index: number) => (
                            <tr key={index}>
                                {info.map((val: any, num: number) => {
                                    return (
                                        <td
                                            key={num}
                                            style={{ textAlign: val.align, fontSize: 9, fontWeight: 500, whiteSpace: 'pre-line', ...val?.style }} rowSpan={val?.rowSpan} colSpan={val?.colSpan} > {val.labelKey}</td>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {renderDataFirst()}
                    </tbody>
                </table>
            </Box>

        </>
    )
}

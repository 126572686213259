import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import { Card, Form } from 'react-bootstrap'
import { Box, Grid, Typography, } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** UTILS || SLICE */

/** API */
import NewsApi, { NewsType, ObjImage } from 'api/application/news.api'
import { fileUrl } from 'api/api'

/** COMPONENT */
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import InputTextField from 'component/Input/InputTextField'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import InputTimePicker from 'component/Input/InputTimePicker'
import UploadImage from 'component/Upload/UploadImage'
import InputTextEditor from 'component/Input/InputTextEditor'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import { DateObject } from 'react-multi-date-picker'

/** STYLE */
import * as PromotionUseStyled from 'features/application/promotions/useStyled'
import { colors } from 'constants/theme'
import HelperText from 'component/Input/HelperText'

type ErrorMessage = {
  EMPTY_SUBJECT?: string
  EMPTY_DATE_PUBLIC?: string
  EMPTY_TIME_PUBLIC?: string
  EMPTY_TIME_END?: string
  EMPTY_IMAGE?: string
  EMPTY_CONTENT?: string
}

export default function FormNews() {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const maxHeight = 450
  const maxWidth = 700

  const objImage: ObjImage = {
    fileId: null,
    base64: '',
    type: '',
    fileExtension: '',
    fileSize: ''
  }

  const [newsId, setNewsId] = useState<number>(0);
  const [news, setNews] = useState<NewsType>(
    {
      subject: '',
      content: '',
      datePublic: '',
      timePublic: '',
      dateEnd: '',
      timeEnd: '',
      image: objImage
    })
  const [previewImage, setPreviewImage]: any = useState()
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({});
  const [isDeleteImage, setIsDeleteImage] = useState(false);

  const loadData = async (id: number) => {
    await NewsApi.findById(id).then((resp) => {
      if (!_.isEmpty(resp.data)) {
        const data: any = resp.data
        setNews({
          subject: data.subject,
          content: data.content,
          datePublic: data.datePublic,
          timePublic: data.timePublic ? data.timePublic.substring(0, 5) : '',
          dateEnd: data.dateEnd,
          timeEnd: data.timeEnd ? data.timeEnd.substring(0, 5) : '',
          status: data.status,
          image: {
            fileId: data?.image?.fileId || 0,
            base64: data?.image?.base64 || '',
            type: data?.image?.type || '',
            fileExtension: data?.image?.fileExtension || '',
            fileSize: data?.image?.fileSize || ''
          }
        })
        setPreviewImage(`${fileUrl}/${data?.image.filePath}/${data?.image.fileName}`)
      }
    }).catch((e) => {
      return
    })
  }

  const submit = () => {
    const checkImg = (!isDeleteImage && newsId && !news.image.fileId) || (isDeleteImage && newsId && !news.image.base64) || (!newsId && !news.image.base64)
    const checkContent = !news.content || news.content === `<p></p>\n` || news.content === `<p>&nbsp;</p>\n`
    if (!news.subject || !news.datePublic || news.datePublic === '0000-00-00' || !news.timePublic || news.timePublic === '00:00:00' || (news.dateEnd && (!news.timeEnd || news.timeEnd === '00:00:00')) || checkImg || checkContent) {
      return setErrorMessage({
        EMPTY_SUBJECT: !news.subject ? t('NEWS.MESSAGE.ERROR.EMPTY_SUBJECT') : '',
        EMPTY_DATE_PUBLIC: !news.datePublic || news.datePublic === '0000-00-00' ? t('NEWS.MESSAGE.ERROR.EMPTY_DATE_PUBLIC') : '',
        EMPTY_TIME_PUBLIC: !news.timePublic || news.timePublic === '00:00:00' ? t('NEWS.MESSAGE.ERROR.EMPTY_TIME_PUBLIC') : '',
        EMPTY_TIME_END: (news.dateEnd && (!news.timeEnd || news.timeEnd === '00:00:00')) ? t('NEWS.MESSAGE.ERROR.EMPTY_TIME_END') : '',
        EMPTY_IMAGE: checkImg ? `${t('NEWS.MESSAGE.ERROR.EMPTY_IMAGE')}` : '',
        EMPTY_CONTENT: checkContent ? `${t('NEWS.MESSAGE.ERROR.EMPTY_CONTENT')}` : ''
      })
    } else {
      newsId ? update() : create()
    }
  }

  useEffect(() => {
    if (news.image.base64) {
      setPreviewImage(`data:image/jpeg;base64,${news.image.base64}`);
    }
  }, [news.image.base64]);


  const create = async () => {
    await NewsApi.createNews(news).then((e) => {
      notiSuccess(t('NEWS.MESSAGE.SUCCESS.CREATE'))
      history.push(routeName.news)
    }).catch((e) => {
      notiError(t('NEWS.MESSAGE.ERROR.DEFAULT'))
    })
  }



  const update = async () => {
    try {
      if (isDeleteImage || news.image.base64) {
        await uploadNewsImage(news.image);
      }
      await NewsApi.updateNews(newsId, news);
      notiSuccess(t('NEWS.MESSAGE.SUCCESS.UPDATE'));
      history.push(routeName.news);
    } catch (e) {
      notiError(t('NEWS.MESSAGE.ERROR.DEFAULT'));
    }
  };


  const uploadNewsImage = (files: any) => {
    if (files && files[0]) {
      const rawFile = files[0];
      const fileName = rawFile.name.split('.');

      const file: any = new window.Image();
      file.src = window.URL.createObjectURL(rawFile);

      file.onload = () => {
        if (file.width > maxWidth || file.height > maxHeight) {
          return notiError(t('NEWS.FORM.IMAGE_SIZE'));
        } else {
          const reader: any = new FileReader();
          reader.onload = () => {
            const base64 = reader.result.split(',');
            const base64String = base64.length > 0 ? base64[base64.length - 1] : reader.result;
            setNews(prev => {
              setIsDeleteImage(true);
              const fileId = prev.image?.fileId && prev.image.fileId > 0
                ? 0
                : prev.image?.fileId || 0;

              const detailImage = {
                fileId: fileId,
                base64: base64String,
                type: '',
                fileExtension: fileName[fileName.length - 1],
                fileSize: rawFile.size.toString(),
              };

              return {
                ...prev,
                image: detailImage,
              };
            });


            setPreviewImage(reader.result);
          };

          reader.readAsDataURL(rawFile);
        }
      };
    }
  };



  // const uploadNewsImage = (files: any) => {
  //   if (files && files[0]) {
  //     const rawFile = files[0];
  //     const fileName = rawFile.name.split('.');

  //     const file: any = new window.Image();
  //     file.src = window.URL.createObjectURL(rawFile);

  //     file.onload = () => {
  //       if (file.width > 700 || file.height > 450) {
  //         return notiError(t('NEWS.FORM.IMAGE_SIZE'));
  //       } else {
  //         const detailImage = {
  //           fileId: news.image.fileId || 0,
  //           base64: '',
  //           type: '',
  //           fileExtension: fileName[fileName.length - 1],
  //           fileSize: rawFile.size.toString(),
  //         };

  //         const reader: any = new FileReader();

  //         reader.onload = () => {
  //           const base64 = reader.result.split(',');
  //           const base64String = base64.length > 0 ? base64[base64.length - 1] : reader.result;

  //           // อัปเดต state ของ news image และ preview image
  //           setNews(prev => ({
  //             ...prev,
  //             image: {
  //               ...detailImage,
  //               base64: base64String,
  //             }
  //           }));

  //           setPreviewImage(reader.result);
  //         };

  //         reader.readAsDataURL(rawFile);
  //       }
  //     };
  //   }
  // };


  const clearErrorMessage = () => {
    setErrorMessage({})
  }

  useEffect(() => {
    const valueNewsId: any = location.state
    if (!_.isEmpty(valueNewsId) && valueNewsId.newsId) {
      loadData(valueNewsId.newsId)
      setNewsId(valueNewsId.newsId)
    }
  }, [location])

  const renderTimePicker = (date: any, time?: string) => {
    if (moment(date).isSame(moment(), 'day')) {
      const newDate = moment().toString().split(" ")
      if (time && time !== "" && newDate[4]) {
        newDate[4] = `${time}:00`
      }
      return newDate.join(" ")
    }
    return ''
  }

  const handleTimeOfFive = (hour: number, num: number): { hour: number, minute: number | '00' } => {
    if (num === 60) {
      return { hour: hour + 1, minute: `00` }
    }

    if (num % 5 === 0) {
      return { hour, minute: num };
    } else if (num > 55 && num < 59) {
      return { hour, minute: 55 }
    }

    return { hour, minute: Math.ceil(num / 5) * 5 }
  };

  const handleChangeTimePicker = (date: any, value: string, time?: string) => {
    const dateCurrent = renderTimePicker(date, time)
    if (dateCurrent !== '') {
      const newValue = value?.split(':')
      if (moment(dateCurrent).hour() <= Number(newValue[0])) {
        if (moment(dateCurrent).hour() === Number(newValue[0]) && !(moment(dateCurrent).minute() <= Number(newValue[1]))) {
          const { hour, minute } = handleTimeOfFive(Number(newValue[0]), moment(dateCurrent).minute())
          return `${hour}:${minute}`
        }
        return value
      } else {
        return ''
      }
    }
    return value
  }


  return (
    <div className={`container-card`}>
      <div className="d-flex flex-column">
        <HeaderLabel
          text={`${newsId ? t('NEWS.FORM.FORM_EDIT') : t('NEWS.FORM.FORM_ADD')}`}
          navigator={{ previousTo: routeName.news, previousTitle: t('NEWS.HEADER'), currentTitle: newsId ? t('NEWS.FORM.FORM_EDIT') : t('NEWS.FORM.FORM_ADD') }}
          goBack={routeName.news}
          onSubmit={submit}
        />
        <Card className="border shadow-sm mx-4 mb-4 h-auto card">
          <Card.Body className="h-100 p-3">
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} className='w-100'>
                <InputTextField
                  required
                  label={t('NEWS.FORM.TITLE')}
                  value={news.subject}
                  onchange={(e: any) => {
                    setNews({ ...news, subject: e.target.value })
                    clearErrorMessage()
                  }}
                  helperText={errorMessage.EMPTY_SUBJECT || ''}
                />
              </Grid>
              <Grid item className='w-100'>
                <Typography className='mt-2' sx={{ fontSize: 18, fontWeight: 500 }}>{t('NEWS.FORM.TITLE_DATE')}</Typography>
              </Grid>
              <Grid item className='w-100'>
                <Grid container spacing={2}>
                  <Grid item xs={7} md={5} lg={4} className='w-100'>
                    <InputSecondNewDatePicker
                      required
                      label={t('NEWS.FORM.DATE_START')}
                      value={news.datePublic && news.datePublic !== '0000-00-00' ? news.datePublic : ''}
                      onchange={(e: any) => {
                        setNews({ ...news, datePublic: moment(e).format('YYYY-MM-DD'), timePublic: '' })
                        clearErrorMessage()
                      }}
                      helperText={errorMessage.EMPTY_DATE_PUBLIC || ''}
                      disablePast
                      minDate={new DateObject()}
                      disableFuture
                      maxDate={news.dateEnd || ''}
                    />
                  </Grid>
                  <Grid item xs={5} md={3} lg={3} className='w-100'>
                    <InputTimePicker
                      required
                      key={'timePublic'}
                      label={t('NEWS.FORM.TIME_START')}
                      value={news.timePublic && news.timePublic !== '00:00:00' ? news.timePublic : ''}
                      helperText={errorMessage.EMPTY_TIME_PUBLIC || ''}
                      onChange={(e: any) => {
                        const value = moment(e).format('HH:mm')
                        const newValue = handleChangeTimePicker(news.datePublic, value)
                        setNews({ ...news, timePublic: newValue, timeEnd: '' })
                        clearErrorMessage()
                      }}
                      scopeTimeStart={renderTimePicker(news.datePublic)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className='w-100'>
                <Grid container spacing={2}>
                  <Grid item xs={7} md={5} lg={4} className='w-100'>
                    <InputSecondNewDatePicker
                      allowClear
                      label={t('NEWS.FORM.DATE_END')}
                      value={news.dateEnd && news.dateEnd !== '0000-00-00' ? news.dateEnd : ''}
                      disablePast
                      datePublic={news.datePublic && news.datePublic !== '0000-00-00' ? news.datePublic : ''}
                      onchange={(e: any) => {
                        setNews({ ...news, dateEnd: moment(e).format('YYYY-MM-DD'), timeEnd: '' })
                        clearErrorMessage()
                      }}
                      onClear={() => {
                        setNews({ ...news, dateEnd: '', timeEnd: '' })
                        clearErrorMessage()
                      }}
                      minDate={news.datePublic ? new DateObject(news.datePublic) : new DateObject()}
                    />
                  </Grid>
                  <Grid item xs={5} md={3} lg={3} className='w-100'>
                    <InputTimePicker
                      key={'timeEnd'}
                      label={t('NEWS.FORM.TIME_END')}
                      value={news.timeEnd && news.timeEnd !== '00:00:00' ? news.timeEnd : ''}
                      onChange={(e: any) => {
                        const value = moment(e).format('HH:mm')
                        const newValue = handleChangeTimePicker(news.dateEnd, value, news.timePublic)
                        setNews({ ...news, timeEnd: newValue })
                        clearErrorMessage()
                      }}
                      helperText={errorMessage.EMPTY_TIME_END || ''}
                      scopeTimeStart={news?.dateEnd ? renderTimePicker(news.dateEnd, news.timePublic) : ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {newsId && (
                <Grid item className='w-100'>
                  <Box className='d-flex align-items-center justify-content-between justify-content-sm-start mt-2'>
                    <Typography className='mr-3' sx={{ fontSize: 18, fontWeight: 500 }}>{t('NEWS.FORM.STATUS')}</Typography>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      onChange={() => setNews({ ...news, status: news.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' })}
                      checked={news.status === 'ACTIVE' ? true : false}
                    />
                  </Box>
                </Grid>
              ) || <></>}
              <Grid item className='w-100'>
                <Typography className='mt-2' sx={{ fontSize: 18, fontWeight: 500 }}>{t('NEWS.FORM.IMAGE')}<span style={{ color: colors.statusInactive }}>*</span></Typography>
              </Grid>
              <Grid item className='w-100'>
                <PromotionUseStyled.UploadImage>
                  <UploadImage
                    setValue={uploadNewsImage}
                    pathFile={previewImage}
                    canDelete
                    onDelete={() => {
                      setNews(prev => ({
                        ...prev,
                        image: objImage
                      }));
                      setIsDeleteImage(true)
                      setPreviewImage()
                    }}
                  />
                </PromotionUseStyled.UploadImage>
                <Typography className='mt-1' sx={{ fontSize: 16, color: colors.disabledGray }}>({t('NEWS.FORM.IMAGE_SIZE')})</Typography>
                {errorMessage?.EMPTY_IMAGE && <HelperText label={errorMessage?.EMPTY_IMAGE || ''} />}
              </Grid>
              <Grid item className='w-100'>
                <Typography className='mt-2' sx={{ fontSize: 18, fontWeight: 500 }}>{t('NEWS.FORM.CONTENT')}<span style={{ color: colors.statusInactive }}>*</span></Typography>
              </Grid>
              <Grid item className='w-100'>
                {(newsId && news.content) && (
                  <InputTextEditor
                    value={news.content}
                    setValue={(val: string) => setNews({ ...news, content: val })}
                  />
                ) || <></>}
                {(!newsId) && (
                  <InputTextEditor
                    setValue={(val: string) => setNews({ ...news, content: val })}
                  />
                ) || <></>}
                {errorMessage?.EMPTY_CONTENT && <HelperText label={errorMessage?.EMPTY_CONTENT || ''} />}
              </Grid>
            </Grid>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

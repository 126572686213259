import { Box, Typography } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useRef, useState } from "react";
import _ from "lodash";

interface DropdownButtonProps {
    onChange: (value: any) => void
    options: Array<any>
    disabled?: boolean
    title?: string
}

export default function DropdownButton(props: DropdownButtonProps) {
    ////-------------------
    ////    REF
    ////-------------------
    const ref = useRef<HTMLDivElement | null>(null)

    ////-------------------
    ////    STATE
    ////-------------------
    const [isOpen, setIsOpen] = useState(false)

    const handleOnChange = (value: string) => {
        props.onChange(value)
        setIsOpen(false)
    }

    const handleOnClick = () => {
        if (!props.disabled) {
            setIsOpen(!isOpen)
        }
    }

    return (
        <Box position="relative">
            <Box display="flex" flexDirection="row">
                <Box
                    onClick={handleOnClick}
                    borderRadius="4px"
                    border={1}
                    height="40px"
                    display="flex"
                    alignItems="center"
                    paddingX="10px"
                    justifyContent="center"
                    gap="8px"
                    sx={{
                        cursor: props.disabled ? 'not-allowed' : 'pointer',
                        color: props.disabled ? '#808080' : "#1CB99A",
                        borderColor: props.disabled ? '#BBBBBB' : "#1CB99A",
                        backgroundColor: props.disabled ? '#F0F0F0' : 'white'
                    }}>
                    <Typography fontSize='16px'>{props.title}</Typography>
                    {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Box>
            </Box>
            {isOpen &&
                <Box ref={ref} position="absolute" width="100%" bgcolor="white" border={1} borderColor="#E5E5E5" borderRadius="4px" paddingY="8px" zIndex={50}>
                    {_.map(props.options, (item, index) =>
                        <Box onClick={() => {
                            handleOnChange(item.value)
                        }} key={index} paddingX="16px" height="40px" display="flex" alignItems="center" fontSize="16px" sx={{
                            "&:hover": {
                                backgroundColor: '#EBF2F0'
                            }
                        }} >
                            {item.name}
                        </Box>
                    )}
                </Box>

            }
        </Box>
    )
}
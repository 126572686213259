import { Box, Typography, Grid, Tooltip, Table, TableHead, TableRow, TableCell, TableBody, IconButton, InputAdornment, MenuItem } from "@mui/material"
import Loading from "component/Loading"
import CheckboxButton from "new-components/buttons/checkbox-button"
import MainButton from "new-components/buttons/main-button"
import RadioButton from "new-components/buttons/radio-button"
import DateInput from "new-components/inputs/date-input"
import TextAreaInput from "new-components/inputs/text-area-input"
import TextInput from "new-components/inputs/text-input"
import { useHistory, useLocation } from "react-router-dom"
import { routeName } from "routes/routes-name"
import InfoIcon from '@mui/icons-material/Info'
import SelectInput from "new-components/inputs/select-input"
import { useEffect, useRef, useState } from "react"
import { format } from 'date-fns'
import VoucherApi from "api/voucher/voucher.api"
import _ from "lodash"
import SnackbarComponent from "new-components/snackbar/snackbar-component"
import OperativeTypeApi from "api/setting/treatments/operative-type.api"
import ProductTypesApi from "api/setting/default-products/product-type.api"
import OperativeApi from "api/setting/treatments/operative.api"
import { getBranch } from "utils/app.utils"
import ProductsApi from "api/warehouses/product.api"
import ButtonExport from "component/Button/ButtonExport"
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TableCustom from "component/Table/TableCustom"
import TableRowCommon from "component/Table/TableRowCommon"
import { dateToView } from "utils/date.utils"
import DateRangeInput from "new-components/inputs/date-range-input"
import PageModal from "new-components/modal/page-modal"
import SelectCheckBoxInput from "new-components/inputs/select_checkbox_input"
import ComponentApi from "api/components-api"
import AlertModal from "new-components/modal/alert-modal"
import moment from "moment"
import { icons } from "constants/images"
import TableRowExport from "component/Pdf/TableRowExport"
import AutocompleteSelect from "component/Select/AutocompleteSelect"
import ClearIcon from '@mui/icons-material/Clear'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useTranslation } from "react-i18next"
import SelectPrimaryInput from "new-components/inputs/select-primary-input"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import IconDropdownButton from "new-components/buttons/icon-dropdown-button"

const FormVoucher = () => {

    const history = useHistory()
    const { t }: any = useTranslation()
    const location = useLocation()
    const branchIdCurrent = getBranch()
    const componentExportRef = useRef<HTMLDivElement>(null)

    const [isOpenSnack, setIsOpenSnack] = useState(false)
    const [typeSnack, setTypeSnack] = useState<any>("success")
    const [labelSnack, setLabelSnack] = useState('')
    const [loading, setLoading] = useState(false)
    const [voucherType, setVoucherType] = useState<any>()
    const [optionsType, setOptionsType] = useState<any>([])
    const [listType, setListType] = useState<any>()



    const [listProduct, setListProduct] = useState(false)
    const [dataVoucher, setDataVoucher] = useState<any>({
        voucherName: '',
        voucherNameEn: '',
        voucherTypeId: '',
        voucherPriceType: 'DISCOUNT',
        voucherPrice: 0,
        voucherPriceUnit: 'BAHT',
        voucherUsage: 'ALL',
        minimum: 0,
        voucherPrefix: '',
        startDate: '',
        endDate: '',
        detail: '',
        voucherGroup: 'ALL',
        voucherGroups: [],
        voucherItems: [],
        qty: 0,
        connectPatient: '0'
    })
    const [errorMessage, setErrorMessage] = useState<any>({})
    const [subValue, setSubValue] = useState('ALL')
    const [isPrefixGV, setIsPrefixGV] = useState(false)
    const [isPatient, setIsPatient] = useState(false)
    const [dataVoucherList, setDataVoucherList] = useState<any>([])
    const [isDupicate, setIsDupicate] = useState(false)

    const [dataVoucherListExport, setDataVoucherListExport] = useState<any>([])

    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('approvalId')
    const [sortType, setSortType] = useState('ASC')
    const [search, setSearch] = useState('')
    const [voucherId, setVoucherId] = useState(0)
    const [status, setStatus] = useState<any>(['WAITING', 'READY', 'USED', 'EXPIRED'])
    const [resultStatus, setResultStatus] = useState('')
    const [optionLotNumber, setOptionLotNumber] = useState([])
    const [lotNumber, setLotNumber] = useState<any>([])
    const [resultLotNumber, setResultLotNumber] = useState('')

    const [isChecks, setIsChecks] = useState([] as any)
    const [isCheckAll, setIsCheckAll] = useState('')

    const [countWaiting, setCountWaiting] = useState('')
    const [countReady, setCountReady] = useState('')
    const [countUsed, setCountUsed] = useState('')
    const [countExpired, setCountExpired] = useState('')
    const [optionSubGroup, setOptionSubGroup] = useState([])
    const [groupPatient, setGroupPatient] = useState('')
    const [subGroup, setSubGroup] = useState<any>([])
    const [totalCount, setTotalCount] = useState(0)

    const [isOpenPageModal, setIsOpenPageModal] = useState(false)
    const [voucherTotal, setVoucherTotal] = useState(0)
    const [dataStartDate, setDataStartDate] = useState('')
    const [dataEndDate, setDataEndDate] = useState('')
    const [isDelete, setIsDelete] = useState(false)
    const [name, setName] = useState('')
    const [voucherListId, setVoucherListId] = useState(0)
    const [rangeDate, setRangeDate] = useState<string[]>(['', ''])

    const [statusDelete, setStatusDelete] = useState('')

    const [copy, setCopy] = useState(0)

    const preparationVoucherType = async () => {
        const condition = {
            page: 1,
            pageLimit: 1000,
            SEARCH: '',
            sortBy: 'approvalId',
            sortType: 'ASC',
            STATUS: 'ACTIVE'
        }
        await VoucherApi.findAllType(condition).then((resp) => {
            if (resp.status === 200) {
                const result = _.map(resp.data, (item) => ({ name: item.voucherTypeName, value: item.voucherTypeId }))
                setVoucherType(result)
            }
        })
    }

    const preparationById = async (id: number, isCopy?: boolean) => {
        await VoucherApi.findVoucherById(id).then((resp) => {
            if (resp.data?.voucherUsage === "ALL") {
                setSubValue('ALL')
            } else {
                setSubValue('SUB')
            }
            if (resp.data?.voucherPrefix) {
                setIsPrefixGV(true)
            }
            if (resp.data?.voucherItems?.length !== 0) {
                setListProduct(true)
                if (isCopy) {
                    preparationOptionType('OPERATIVE')
                    preparationOptionType('PRODUCT')
                    preparationOptionType('MEDICINE')
                    _.map(resp.data?.voucherItems, (item: any) => {
                        preparationListType(item)
                    })
                }

            }
            if (resp.data?.voucherGroups?.length !== 0) {
                setIsPatient(true)
                setGroupPatient(resp.data?.voucherGroups?.[0]?.groupType)
                preparationPatient(resp.data?.voucherGroups?.[0]?.groupType)
                setSubGroup(_.map(resp.data?.voucherGroups, (item) => item.groupTypeId))
            }
            const result = {
                ...resp.data,
                startDate: isCopy ? '' : moment(resp.data?.startDate).format('yyyy-MM-DD'),
                endDate: isCopy ? '' : moment(resp.data?.endDate).format('yyyy-MM-DD'),
                voucherName: isCopy ? `${resp.data?.voucherName}(copy)` : resp.data?.voucherName,
                voucherNameEn: isCopy ? resp.data?.voucherNameEn && `${resp.data?.voucherNameEn}(copy)` : resp.data?.voucherNameEn,
                branchId: isCopy && 0,
                connectPatient: resp.data?.connectPatient === '' ? '0' : resp.data?.connectPatient,
                voucherPrefix: isCopy ? '' : resp.data?.voucherPrefix,
            }
            delete result.createdAt
            delete result.createdBy
            delete result.updatedAt
            delete result.updatedBy
            delete result.voucherType
            setDataStartDate(moment(resp.data?.startDate).format('yyyy-MM-DD'))
            setDataEndDate(moment(resp.data?.endDate).format('yyyy-MM-DD'))
            setDataVoucher(result)
        })
    }

    const preparationVoucherId = async (id: number, pageId?: number, checkExport?: boolean) => {
        setLoading(true)
        await VoucherApi.findVoucherListId(id, { page: pageId ? pageId : page, pageLimit: pageLimit, STATUS: resultStatus, SEARCH: search, lotNumber: resultLotNumber, startDate: rangeDate[0], endDate: rangeDate[1], EXPORT: checkExport ? '1' : '0' }).then((resp: any) => {
            if (resp.status === 200) {
                if (checkExport) {
                    setDataVoucherListExport(resp.data.rows)
                } else {
                    const statusReady = _.find(resp.data.statusCounts, (item) => item.status === 'READY')
                    const statusWaiting = _.find(resp.data.statusCounts, (item) => item.status === 'WAITING')
                    const statusUsed = _.find(resp.data.statusCounts, (item) => item.status === 'USED')
                    const statusExpired = _.find(resp.data.statusCounts, (item) => item.status === 'EXPIRED')
                    const lotNumbers: any = _.map(resp.data?.lotNumbers, (item) => ({ name: item.lotNumber.toString().padStart(4, '0'), id: item.lotNumber }))
                    const number: any = _.map(resp.data?.lotNumbers, (item) => item.lotNumber)
                    setRowCount(resp?.headers['x-total'] || 0)
                    setDataVoucherList(resp.data.rows)
                    setCountWaiting(statusWaiting?.count)
                    setCountReady(statusReady?.count)
                    setCountUsed(statusUsed?.count)
                    setCountExpired(statusExpired?.count)
                    setOptionLotNumber(lotNumbers)
                    setLotNumber(number)
                    setTotalCount(resp.data?.count)
                }
            }
        })
        setLoading(false)
    }

    const preparationPatient = async (data: string) => {
        if (data === 'RANK') {
            const condition: any = { page: 1, pageLimit: 50, sortType: 'ASC', sortBy: 'createdAt', status: 'ACTIVE' }
            await VoucherApi.findAllRankVoucher(condition).then((resPatientTypes) => {
                if (resPatientTypes.status === 200) {
                    const newData = resPatientTypes?.data.map((item: any) => {
                        return { name: item?.rankName, id: item?.rankId }
                    })
                    setOptionSubGroup(newData)
                }
            })
        } else {
            const condition: any = { page: 1, pageLimit: 50, sortType: 'ASC', sortBy: 'createdAt', status: 'ACTIVE' }
            await ComponentApi.findAllPatientType(condition).then((resPatientTypes) => {
                if (resPatientTypes.status === 200) {
                    const newData = resPatientTypes?.data.map((item: any) => {
                        return { name: item?.patientTypeName, id: item?.patientTypeId }
                    })
                    setOptionSubGroup(newData)
                }
            })
        }
    }

    const preparationOptionType = async (itemType: string) => {
        if (itemType === 'OPERATIVE') {
            const res = await OperativeTypeApi.findAll({ pageLimit: 30, status: 'ACTIVE' })
            if (res.status === 200) {
                const newData = res.data.map((value: any) => {
                    return { itemTypeName: value.operativeTypeName, itemTypeId: value.operativeTypeId }
                })
                setOptionsType(newData)
            }
        } else {
            const condition: any = { group: itemType, pageLimit: 10, status: 'ACTIVE' }
            const res = await ProductTypesApi.findAll(condition)
            if (res.status === 200) {
                const newData = res.data.map((value: any) => {
                    return { itemTypeName: value.productTypeName, itemTypeId: value.productTypeId }
                })
                setOptionsType(newData)
            }
        }
    }

    const preparationListType = async (value: any) => {
        if (value.itemType === 'OPERATIVE') {
            const res = await OperativeApi.findAll({ pageLimit: 30, status: 'ACTIVE', operativeTypeId: value.itemTypeId })
            if (res.status === 200) {
                const newData = res.data.map((value: any) => {
                    return { itemName: value.operativeName, itemId: value.operativeId, price: value.salePrice }
                })
                setListType(newData)
            }
        } else {
            const condition: any = { group: value.itemType, status: 'ACTIVE', page: 1, pageLimit: 10, itemTypeId: value.itemTypeId }
            const res = await ProductsApi.findAll(condition)
            if (res.status === 200) {
                const newData = res.data.map((value: any) => {
                    return { itemName: value.productName, itemId: value.productId, price: value?.salePrice || 0 }
                })
                setListType(newData)
            }
        }
    }

    useEffect(() => {
        preparationVoucherType()
        if (location.state) {
            const dataEdit: any = location?.state
            setVoucherId(dataEdit?.voucherId)
            if (dataEdit?.copy) {
                setVoucherId(0)
                preparationById(dataEdit.voucherId, true)
            } else {
                setVoucherId(dataEdit?.voucherId)
                preparationById(dataEdit.voucherId)
            }


        }
    }, [])

    useEffect(() => {
        if (location.state) {
            const dataEdit: any = location?.state
            preparationVoucherId(dataEdit.voucherId)
        }
        return () => {
            setSearch('')
            setStatus([])
            setLotNumber([])
            setRangeDate(['', ''])
        }
    }, [page, pageLimit])

    useEffect(() => {
        if (location.state) {
            const dataEdit: any = location?.state
            preparationVoucherId(dataEdit.voucherId, page, true)
        }
    }, [])

    const onCheckErrorSubmit = () => {
        let newError: any = {
            "VOUCHERNAME": !dataVoucher.voucherName ? t('VOUCHER.ERROR.VOUCHERNAME') : '',
            "VOUCHERTYPEID": !dataVoucher.voucherTypeId ? t('VOUCHER.ERROR.VOUCHERTYPEID') : '',
            "DISCOUNT": dataVoucher.voucherPriceType === 'DISCOUNT' && !dataVoucher.voucherPrice ? t('VOUCHER.ERROR.DISCOUNT') : '',
            "SALEPRICE": dataVoucher.voucherPriceType === 'SALEPRICE' && !dataVoucher.voucherPrice ? t('VOUCHER.ERROR.SALEPRICE') : '',
            "MINIMUM": dataVoucher.minimum === "" ? 'กรุณากรอก ยอดขั้นต่ำในการใช้งาน' : '',
            "QTY": !dataVoucher.qty ? t('VOUCHER.ERROR.QTY') : dataVoucher?.qty > 2000 ? t('VOUCHER.ERROR.QTY_LENGTH') : '',
            "STARTDATE": !dataVoucher.startDate ? t('VOUCHER.ERROR.STARTDATE') : '',
            "ENDDATE": !dataVoucher.endDate ? t('VOUCHER.ERROR.ENDDATE') : '',
            "VOUCHERPREFIX": isPrefixGV && !dataVoucher.voucherPrefix ? t('VOUCHER.ERROR.VOUCHERPREFIX') : '',
            "GROUPTYPE": isPatient && !dataVoucher.voucherGroups[0]?.groupType ? t('VOUCHER.ERROR.GROUPTYPE') : '',
            "GROUPTYPEID": isPatient && !dataVoucher.voucherGroups[0]?.groupTypeId ? t('VOUCHER.ERROR.GROUPTYPEID') : '',
        }
        dataVoucher?.voucherItems?.map((item: any, index: number) => {
            if (!item?.itemType || !item?.itemId || !item?.qty) {
                newError = {
                    ...newError,
                    'ITEMTYPE': dataVoucher?.voucherItems.some((item: any) => !item?.itemType) ? t('VOUCHER.ERROR.ITEMTYPE') : '',
                    'ITEMID': dataVoucher?.voucherItems.some((item: any) => !item?.itemId) ? t('VOUCHER.ERROR.ITEMID') : '',
                    'QTYGV': dataVoucher?.voucherItems.some((item: any) => !item?.qty) ? t('VOUCHER.ERROR.QTYGV') : '',
                }
            }
        })
        return newError
    }
    const onCreateGV = async () => {
        setLoading(true)

        const newError = onCheckErrorSubmit()
        if (Object.values(newError).some(value => value !== "")) {
            setErrorMessage(newError)
            setLoading(false)
            return
        }

        const newData = dataVoucher
        console.log(newData, 'test')
        await newData.voucherItems.forEach((item: any) => {
            delete item.name;
            delete item.price
            delete item.voucherItemId
            delete item.itemName
            delete item.itemTypeName
            delete item.itemTypeId
            delete item.itemPrice
        });
        await newData.voucherGroups.forEach((item: any) => {
            delete item.voucherGroupId
        })

        setLoading(true)
        await VoucherApi.createVoucher(newData).then((resp) => {
            setVoucherId(resp.data)
            setIsOpenSnack(true)
            setTypeSnack('success')
            setLabelSnack('บันทึกสำเร็จ')
            setTimeout(() => {
                history.push(routeName.voucher)
            }, 1500);
        }).catch((err) => {
            if (err?.response?.data?.message === 'IS_DUPLICATE_VOUCHER_PREFIX') {
                setIsDupicate(true)
            } else if (err?.response?.data?.message === 'IS_DUPLICATE_NAME') {
                setErrorMessage({
                    'VOUCHERNAME': err?.response?.data?.message === 'IS_DUPLICATE_NAME' ? t('VOUCHER.MESSAGE.DUPICATE_NAME') : ""
                })
            } else if (err?.response?.data?.message === 'IS_DUPLICATE_NAME_EN') {
                setErrorMessage({
                    'VOUCHERNAMEEN': err?.response?.data?.message === 'IS_DUPLICATE_NAME_EN' ? t('VOUCHER.MESSAGE.DUPICATE_NAMEEN') : ""
                })
            }

            setLoading(false)
        })
    }

    const handleOnCheckAll = () => {
        const hasSome = _.some(dataVoucherList, (i: any) => _.some(isChecks, (j: number) => Number(i.voucherListId) === Number(j)))
        const hasAll = _.every(dataVoucherList, (i: any) => _.some(isChecks, (j: number) => Number(i.voucherListId) === Number(j)))
        if (!_.isEmpty(isChecks) && !hasAll && hasSome) setIsCheckAll('indeterminate')
        else if (!_.isEmpty(isChecks) && hasAll) setIsCheckAll('checked')
        else setIsCheckAll('')
    }

    useEffect(() => {
        handleOnCheckAll()
    }, [isChecks, dataVoucherList])

    const onCheckAll = (event: any) => {
        const { checked } = event
        const id = _.map(_.filter(dataVoucherList, (item) => item.status !== 'USED'), 'voucherListId')
        if (checked) {
            if (isCheckAll === 'indeterminate') {
                setIsChecks([])
            } else {
                setIsChecks(Array.from(new Set([...isChecks, ...id])))
            }
        } else {
            setIsChecks(_.filter(isChecks, (i) => !_.includes(id, i)))
        }
    }

    const onCheck = (event: any) => {
        const { checked, value } = event
        if (checked) {
            setIsChecks([...isChecks, Number(value)])
        } else {
            setIsChecks(
                _.filter(isChecks, (i: number) => {
                    return i !== Number(value)
                })
            )
        }
    }

    const deleteTableList = async (id?: number) => {

        const handleError = (msg: string) => {
            const msgError = ['THIS_VOUCHER_IS_BEING_USED']
            if (msgError.includes(msg)) {
                setLabelSnack(t(`VOUCHER.ERROR.${msgError}`))
            } else {
                setLabelSnack(t('VOUCHER.ERROR.ERROR'))
            }
        }

        if (id) {
            await VoucherApi.deleteVoucherList([id], statusDelete).then(() => {
                setIsDelete(false)
                setIsOpenSnack(true)
                setTypeSnack('success')
                setLabelSnack(t('VOUCHER.MESSAGE.SAVE_SUCCESS'))
                preparationVoucherId(voucherId)
                preparationVoucherId(voucherId, page, true)
            }).catch((error) => {
                setIsDelete(false)
                setIsOpenSnack(true)
                setTypeSnack('error')
                const msg = error?.response?.data?.message
                handleError(msg)
            })
        } else {
            await VoucherApi.deleteVoucherList(isChecks, statusDelete).then(() => {
                setIsDelete(false)
                setIsOpenSnack(true)
                setTypeSnack('success')
                setLabelSnack(t('VOUCHER.MESSAGE.SAVE_SUCCESS'))
                preparationVoucherId(voucherId)
                preparationVoucherId(voucherId, page, true)
            }).catch((error) => {
                setIsDelete(false)
                setIsOpenSnack(true)
                setTypeSnack('error')
                setLabelSnack(t('VOUCHER.MESSAGE.ERROR'))
                const msg = error?.response?.data?.message
                handleError(msg)
            })
        }
        setName('')
        setIsCheckAll('')
        setIsChecks([])
    }

    const headCells = [
        {
            id: 'voucherSelect',
            disablePadding: false,
            label: 'CHECKBOX',
            indeterminate: isCheckAll === 'indeterminate' ? true : false,
            checked: isCheckAll === 'checked' && true,
            disableDropdown: true,
            values: [],
            onCheckAll: (event: any) => onCheckAll(event.target),
            width: '70px'
        },
        { id: 'no', disablePadding: false, label: '#', width: '60px' },
        { id: 'id', disablePadding: false, label: t('VOUCHER.FORM.TABLE_VOUCHER_LIST.ID') },
        { id: 'status', disablePadding: false, label: t('VOUCHER.FORM.TABLE_VOUCHER_LIST.STATUS'), align: 'center', width: '100px' },
        { id: 'litNumber', disablePadding: false, label: t('VOUCHER.FORM.TABLE_VOUCHER_LIST.LOTNUMBER'), align: 'center', width: '120px' },
        { id: 'startDate', disablePadding: false, label: t('VOUCHER.FORM.TABLE_VOUCHER_LIST.START_DATE'), align: 'center', width: '150px' },
        { id: 'endDate', disablePadding: false, label: t('VOUCHER.FORM.TABLE_VOUCHER_LIST.END_DATE'), align: 'center', width: '150px' },
        { id: 'Owner', disablePadding: false, label: t('VOUCHER.FORM.TABLE_VOUCHER_LIST.OWNER'), align: 'center', width: '200px' },
        { id: 'UpdatedAt', disablePadding: false, label: t('VOUCHER.FORM.TABLE_VOUCHER_LIST.UPDATED_BY'), align: 'center', width: '200px' },
        { id: 'action', disablePadding: false, label: 'Action', align: 'center', width: '80px' }
    ]

    const headCellsGV = [
        { id: 'no', disablePadding: false, label: t('VOUCHER.FORM.TABLE.NO'), width: '100px' },
        { id: 'voucherUsage', disablePadding: false, label: t('VOUCHER.FORM.TABLE.CATEGORY') },
        { id: 'type', disablePadding: false, label: t('VOUCHER.FORM.TABLE.TYPE') },
        { id: 'list', disablePadding: false, label: t('VOUCHER.FORM.TABLE.LIST') },
        { id: 'amount', disablePadding: false, label: t('VOUCHER.FORM.TABLE.AMOUNT') },
    ]

    const renderDataGV = (objData: any, index: number) => {
        const objRenderData = {
            key: voucherListId,
            id: voucherListId,
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: index + 1 },
                { option: 'TEXT', align: 'left', label: objData?.itemType === 'OPERATIVE' ? 'รายการหัตถการ' : objData?.itemType === 'MEDICINE' ? 'ยา' : 'สินค้าและผลิตภัณฑ์' },
                { option: 'TEXT', align: 'left', label: objData?.itemTypeName || '-' },
                { option: 'TEXT', align: 'left', label: objData?.itemName || '-' },
                { option: 'TEXT', align: 'left', label: objData?.qty || '-' },
            ]
        }

        return (
            <TableRowCommon {...objRenderData} onCheck={(e) => onCheck(e.target)} />
        )
    }

    const statusType = (status: string) => {
        switch (status) {
            case 'READY':
                return t('VOUCHER.FORM.STATUS_READY')
            case 'WAITING':
                return t('VOUCHER.FORM.STATUS_WAITING')
            case 'USED':
                return t('VOUCHER.FORM.STATUS_USED')
            case 'EXPIRED':
                return t('VOUCHER.FORM.STATUS_EXPIRED')
            default:
                return ''
        }
    }

    const handleCopy = (voucherCode: any, index: number) => {
        setCopy(index);
        navigator.clipboard.writeText(voucherCode);
        setTimeout(() => {
            setCopy(0);
        }, 2000);
    };

    const renderData = (objData: any, index: number, isExport?: boolean) => {
        index = page * pageLimit - pageLimit + index + 1
        const { voucherCode, status, lotNumber, startDate, endDate, ownerName, usedDate, updatedBy, updatedAt, voucherListId } = objData
        const rowCheck = _.some(isChecks, (i: number) => Number(i) === Number(voucherListId))
        const objRenderData = {
            key: voucherListId,
            id: voucherListId,
            obj: objData,
            rowSelect: rowCheck,
            columns: [
                { option: 'CHECKBOX', align: 'center', label: voucherListId, disabled: status === 'USED' },
                { option: 'TEXT', align: 'center', label: index },
                {
                    option: 'COMPONENT', align: 'left', component:
                        <Box
                            onClick={() => status === "READY" && navigator.clipboard.writeText(voucherCode)}
                            sx={{ cursor: 'pointer', position: 'relative' }}
                        >
                            <Box display="flex" flexDirection="row" alignItems="center" gap="8px" position="relative">
                                <Typography>{voucherCode}</Typography>
                                <Box>
                                    {copy === index &&
                                        <Box position="absolute" padding="10px" bgcolor="#5A5A5A" color="#FFFFFF" borderRadius="2px" top="-40px" fontSize="14px" fontWeight={400}>Copied</Box>
                                    }
                                    {status === 'READY' && <ContentCopyIcon onClick={() => handleCopy(voucherCode, index)} sx={{ color: '#808080', height: '22px', width: '20px' }} />}
                                </Box>
                            </Box>
                        </Box>
                },
                { option: 'COMPONENT', align: 'center', component: <Box fontSize="16px" fontWeight={400} color={status === 'READY' ? '#1CB99A' : status === 'WAITING' ? '#EDBA07' : status === 'USED' ? '#5A5A5A' : '#B00020'}>{statusType(status)}</Box> },
                { option: 'TEXT', align: 'center', label: lotNumber.toString().padStart(4, '0') || '' },
                { option: 'TEXT', align: 'center', label: dateToView(startDate) || '' },
                { option: 'TEXT', align: 'center', label: dateToView(endDate) || '' },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: ownerName || '-', updatedAt: usedDate !== null ? usedDate : '-' } },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy || '-', updatedAt: updatedAt ?? '10/12/2564 08.59' } },
                {
                    option: 'COMPONENT', align: 'center', component:
                        <IconDropdownButton disabled={status === 'USED'} options={[
                            {
                                label: 'ยกเลิกการผูก', onClick: () => {
                                    setVoucherListId(voucherListId)
                                    setName(voucherCode)
                                    setStatusDelete('UNLINK')
                                    setIsDelete(true)
                                }, icon: <LinkOffIcon sx={{ color: '#808080' }} />, disabled: !ownerName
                            },
                            {
                                label: 'ลบรายการ', onClick: () => {
                                    setVoucherListId(voucherListId)
                                    setName(voucherCode)
                                    setStatusDelete('DELETED')
                                    setIsDelete(true)
                                }, icon: <DeleteOutlineOutlinedIcon sx={{ color: '#808080' }} />, disabled: ownerName
                            }
                        ]} />
                }
            ]
        }


        return (
            <TableRowCommon {...objRenderData} onCheck={(e) => onCheck(e.target)} />
        )




    }

    const renderDataVoucher = (objData: any, index: number, isExport?: boolean) => {
        if (isExport) {
            index = index + 1
        } else {
            index = page * pageLimit - pageLimit + index + 1
        }

        const { voucherCode, status, lotNumber, startDate, endDate, ownerName, usedDate, updatedBy, updatedAt, voucherListId } = objData
        const rowCheck = _.some(isChecks, (i: number) => Number(i) === Number(voucherListId))
        const statusType = (status: string) => {
            switch (status) {
                case 'READY':
                    return 'พร้อมใช้งาน'
                case 'WAITING':
                    return 'รอใช้งาน'
                case 'USED':
                    return 'ใช้งานแล้ว'
                case 'EXPIRED':
                    return 'หมดอายุ'
                default:
                    return ''
            }
        }

        const objRenderData = {
            key: voucherListId,
            id: voucherListId,
            obj: objData,
            rowSelect: rowCheck,
            columns: [
                { option: 'TEXT', align: 'center', label: index },
                { option: 'TEXT', align: 'left', label: voucherCode || '' },
                { option: 'TEXT', align: 'center', label: statusType(status) },
                { option: 'TEXT', align: 'center', label: lotNumber.toString().padStart(4, 0) || '' },
                { option: 'TEXT', align: 'center', label: dateToView(startDate) || '' },
                { option: 'TEXT', align: 'center', label: dateToView(endDate) || '' },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: ownerName || '-', updatedAt: usedDate !== null ? usedDate : '-' } },
                // { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy || '-', updatedAt: updatedAt ?? '10/12/2564 08.59' } },
            ]
        }

        if (isExport) {
            return <TableRowExport {...objRenderData} />
        }
    }

    const handleLotNumber = (number: any) => {
        const dataNumber: any = number[number.length - 1]
        const data = dataNumber?.id + 1
        const result = data.toString().padStart(4, '0');
        return result
    }

    const onCheckErrorSubmitModal = () => {
        const newError = {
            "VOUCHERTOTAL": !voucherTotal ? t('VOUCHER.FORM.MODAL.ERROR_TITLE') : voucherTotal > 2000 ? t('VOUCHER.FORM.MODAL.ERROR_MAX_LENGTH') : '',
            "DATASTARTDATE": !dataStartDate ? t('VOUCHER.FORM.MODAL.ERROR_STARTDATE') : '',
            "DATAENDDATE": !dataEndDate ? t('VOUCHER.FORM.MODAL.ERROR_ENDDATE') : '',

        }
        return newError
    }

    const onCreateVoucherModal = async () => {

        const newError = onCheckErrorSubmitModal()
        if (Object.values(newError).some(value => value !== "")) {
            setErrorMessage(newError)
            return
        }
        const body = {
            qty: voucherTotal,
            startDate: dataStartDate,
            endDate: dataEndDate,
            branchId: branchIdCurrent
        }
        await VoucherApi.createVoucherModal(voucherId, body).then((resp) => {
            setIsOpenSnack(true)
            setTypeSnack('success')
            setLabelSnack('บันทึกสำเร็จ')
            setIsOpenPageModal(false)
            preparationVoucherId(voucherId)
            preparationVoucherId(voucherId, page, true)

        }).catch(() => {
            setIsOpenSnack(true)
            setTypeSnack('error')
            setLabelSnack('เกิดข้อผิดพลาด')
            setLoading(false)
            setIsOpenPageModal(false)
        })
        setVoucherTotal(0)
    }

    const handleTotalPrice = () => {
        return dataVoucher?.voucherItems.reduce((acc: number, item: any) => {
            if (item.price && item.qty) {
                return acc + (item.price * item.qty);
            }
            return acc
        }, 0)?.toLocaleString()

    }

    return (
        <Box paddingX="24px" paddingY="32px">
            <AlertModal
                isOpen={isDupicate}
                title={t('VOUCHER.FORM.PREFIX_DUPICATE_TITLE')}
                message={t('VOUCHER.FORM.PREFIX_DUPICATE_MESSAGE')}
                type="warning"
                onSubmit={{ onClick: () => null }}
                onCancel={{ onClick: () => setIsDupicate(false), title: t('VOUCHER.FORM.PREFIX_DUPICATE_CANCEL') }}
                onCloseSubmit
                handleClose={() => setIsDupicate(false)}
            />
            <SnackbarComponent
                onOpen={isOpenSnack}
                onClose={() => setIsOpenSnack(false)}
                label={labelSnack}
                type={typeSnack}
                transform={`translateX(calc(100vw - 100% - 32px)) translateY(calc(-100vh + 100% + ${window.innerHeight - 50}px))`}
                autoHideTime={3000}
            />
            <Loading show={loading} />
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" gap='16px'>
                        <Typography fontSize="14px" fontWeight={400} sx={{ cursor: 'pointer' }} onClick={() => history.push(`${routeName.voucher}`)}>{t('VOUCHER.FORM.MANAGE_TITLE')}</Typography>
                        <Typography fontSize="14px" fontWeight={400}>{'>'}</Typography>
                        <Typography fontSize="14px" fontWeight={400}>{voucherId !== 0 ? t('VOUCHER.FORM.TITLE_UPDATE') : t('VOUCHER.FORM.TITLE_CREATE')}</Typography>
                    </Box>
                    <Typography fontSize="24px" fontWeight={500}>{voucherId !== 0 ? t('VOUCHER.FORM.TITLE_UPDATE') : t('VOUCHER.FORM.TITLE_CREATE')}</Typography>
                </Box>
                <Box display={voucherId !== 0 ? "none" : "flex"} flexDirection="row" alignItems="center" gap="16px">
                    <MainButton
                        title={t('VOUCHER.FORM.BUTTON_CANCEL')}
                        type="secondary"
                        variant="outlined"
                        onClick={() => history.push(routeName.voucher)}
                        style={{ width: '120px' }}
                    />
                    <MainButton
                        title={t('VOUCHER.FORM.BUTTON_SAVE')}
                        type="primary"
                        variant="contained"
                        onClick={() => {
                            onCreateGV()
                        }}
                        style={{ width: '120px' }}
                    />
                </Box>
            </Box>
            <Box marginTop="24px" boxShadow="0px 0px 12px 0px #CCCCCC;" borderRadius="8px" paddingX="32px" paddingY="24px" >
                <Grid container spacing="16px">
                    <Grid item xs={12} md={4}>
                        <TextInput
                            required
                            label={t('VOUCHER.FORM.VOUCHER_NAME')}
                            value={dataVoucher.voucherName}
                            onChange={(e) => {
                                setDataVoucher({ ...dataVoucher, voucherName: e.target.value })
                                setErrorMessage({ ...errorMessage, 'VOUCHERNAME': '' })
                            }}
                            fullWidth
                            helperText={errorMessage.VOUCHERNAME}
                            inputProps={{ value: dataVoucher.voucherName }}
                            disabled={voucherId !== 0}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextInput
                            label={t('VOUCHER.FORM.VOUCHER_NAMEEN')}
                            value={dataVoucher.voucherNameEn}
                            onChange={(e) => setDataVoucher({ ...dataVoucher, voucherNameEn: e.target.value })}
                            fullWidth
                            inputProps={{ value: dataVoucher.voucherNameEn }}
                            disabled={voucherId !== 0}
                            helperText={errorMessage.VOUCHERNAMEEN}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SelectInput
                            required
                            label={t('VOUCHER.FORM.VOUCHER_TYPE')}
                            onChange={(e) => {
                                setDataVoucher({ ...dataVoucher, voucherTypeId: e.target.value })
                                setErrorMessage({ ...errorMessage, 'VOUCHERTYPEID': '' })
                            }}
                            options={voucherType}
                            value={dataVoucher.voucherTypeId}
                            helperText={errorMessage.VOUCHERTYPEID}
                            disabled={voucherId !== 0}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxButton checked={listProduct} onChange={(e) => {
                            if (!e.target.checked) {
                                setSubValue("ALL")
                                setDataVoucher({ ...dataVoucher, voucherUsage: "ALL", voucherItems: [], voucherPriceType: 'DISCOUNT' })
                            } else {
                                setSubValue("SUB")
                                setDataVoucher({ ...dataVoucher, voucherUsage: "OPERATIVEDF", voucherItems: [...dataVoucher.voucherItems, {}] })
                            }
                            setListProduct(e.target.checked)
                        }} label={t('VOUCHER.FORM.BIND_OP_MED_PD')} disabled={voucherId !== 0} />
                        {listProduct &&
                            <Box>
                                <Typography marginTop="32px" marginBottom="16px" fontSize="14px" fontWeight={500}>{dataVoucher.voucherItems?.length > 1 ? t('VOUCHER.FORM.BIND_OP_MED_PD_DETAIL_ALL') : t('VOUCHER.FORM.BIND_OP_MED_PD_DETAIL')}</Typography>
                                <Box marginTop="16px" overflow="scroll">
                                    {voucherId === 0 ?
                                        <Table sx={{ border: `1px solid #E5E5E5`, color: 'white', minWidth: 800, borderRadius: '8px' }}>
                                            <TableHead sx={{ backgroundColor: '#2F4269', color: 'white', }}>
                                                <TableRow>
                                                    <TableCell align="center" sx={{ color: 'white', fontSize: "18px", fontWeight: '600px', width: '100px' }}>{t('VOUCHER.FORM.TABLE.NO')}</TableCell>
                                                    <TableCell sx={{ color: 'white', fontSize: "18px", fontWeight: '600px', minWidth: '50px', maxWidth: '400px' }}>{t('VOUCHER.FORM.TABLE.CATEGORY')}</TableCell>
                                                    <TableCell sx={{ color: 'white', fontSize: "18px", fontWeight: '600px', minWidth: '50px', maxWidth: '400px' }}>{t('VOUCHER.FORM.TABLE.TYPE')}</TableCell>
                                                    <TableCell sx={{ color: 'white', fontSize: "18px", fontWeight: '600px', minWidth: '50px', maxWidth: '400px' }}>{t('VOUCHER.FORM.TABLE.LIST')}</TableCell>
                                                    <TableCell sx={{ color: 'white', fontSize: "18px", fontWeight: '600px', width: '200px' }}>{t('VOUCHER.FORM.TABLE.AMOUNT')}</TableCell>
                                                    <TableCell align="center" sx={{ color: 'white', fontSize: "18px", fontWeight: '600px', width: '100px' }}>{'Action'}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={12}>
                                                        <Box onClick={() => {
                                                            const checked = dataVoucher?.voucherItems[dataVoucher?.voucherItems?.length - 1]?.qty
                                                            if (checked || dataVoucher?.voucherItems.length === 0) {
                                                                if (dataVoucher?.voucherItems.length > 0) {
                                                                    setSubValue("ALL")
                                                                    setDataVoucher({ ...dataVoucher, voucherItems: [...dataVoucher.voucherItems, {}], voucherUsage: 'ALL' })
                                                                } else {
                                                                    setDataVoucher({ ...dataVoucher, voucherItems: [...dataVoucher.voucherItems, {}] })

                                                                }
                                                            }
                                                        }} sx={{ cursor: dataVoucher?.voucherItems[dataVoucher?.voucherItems?.length - 1]?.qty || dataVoucher?.voucherItems.length === 0 ? 'pointer' : 'default', color: dataVoucher?.voucherItems[dataVoucher?.voucherItems?.length - 1]?.qty || dataVoucher?.voucherItems.length === 0 ? '#1CB99A' : '#C5C5C5' }}>{t('VOUCHER.FORM.ADD_ROW')}</Box>
                                                    </TableCell>
                                                </TableRow>
                                                {dataVoucher.voucherItems.map((data: any, index: number) => (
                                                    <TableRow key={`row-${index}`} sx={{ height: '100px' }}>
                                                        <TableCell style={{ verticalAlign: 'top' }} align="center" ><Typography marginTop="10px">{index + 1}</Typography></TableCell>
                                                        <TableCell style={{ verticalAlign: 'top' }}>
                                                            <SelectInput
                                                                required
                                                                label={t('VOUCHER.FORM.TABLE.CATEGORY')}
                                                                width={{ xs: '200px', md: '200px', lg: '99%', xl: '%' }}
                                                                onChange={(e) => {
                                                                    const updateItem = [...dataVoucher.voucherItems]
                                                                    updateItem[index] = { ...updateItem[index], itemType: e.target.value, itemTypeId: null, itemTypeName: null, itemId: null, itemName: null }
                                                                    if (dataVoucher?.voucherItems.length === 1 && updateItem[0]?.itemType !== 'OPERATIVE') {
                                                                        setDataVoucher({ ...dataVoucher, voucherItems: updateItem, voucherUsage: updateItem.length > 1 ? "ALL" : "PRODUCT" })
                                                                    } else {
                                                                        setDataVoucher({ ...dataVoucher, voucherItems: updateItem, voucherUsage: updateItem.length > 1 ? "ALL" : "OPERATIVEDF" })
                                                                    }
                                                                }}
                                                                value={data.itemType}
                                                                options={[
                                                                    { name: 'รายการหัตถการ', value: 'OPERATIVE' },
                                                                    { name: 'ยา', value: 'MEDICINE' },
                                                                    { name: 'สินค้าและผลิตภัณฑ์', value: 'PRODUCT' },
                                                                ]}
                                                                helperText={(errorMessage.ITEMTYPE && !data.itemType) ? errorMessage.ITEMTYPE || '' : ''}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ verticalAlign: 'top' }}>
                                                            <AutocompleteSelect
                                                                height={40}
                                                                labelId="1"
                                                                inputLabel={t('VOUCHER.FORM.TABLE.TYPE')}
                                                                noOptionsText='ไม่พบข้อมูล'
                                                                getOptionLabel={(option) => option?.itemTypeName || ''}
                                                                options={optionsType || []}
                                                                renderOption={(props, option) => {
                                                                    return (
                                                                        <Box {...props} >
                                                                            {option?.itemTypeName}
                                                                        </Box>
                                                                    )
                                                                }}
                                                                onInputChange={() => preparationOptionType(data?.itemType)}
                                                                onOpen={() => preparationOptionType(data.itemType)}
                                                                onchange={(_e, value) => {
                                                                    const updateItem = [...dataVoucher.voucherItems]
                                                                    updateItem[index] = { ...updateItem[index], itemTypeId: value.itemTypeId, itemTypeName: value.itemTypeName }
                                                                    setDataVoucher({ ...dataVoucher, voucherItems: updateItem })
                                                                }}
                                                                value={data}
                                                                disabled={!data.itemType}
                                                                disableClearable
                                                                required
                                                                errMessage={(errorMessage.ITEMID && !data.itemTypeName) ? 'กรุณากรอก ประเภท' : ''}
                                                            />

                                                        </TableCell>
                                                        <TableCell style={{ verticalAlign: 'top' }}>
                                                            <AutocompleteSelect
                                                                height={40}
                                                                labelId="1"
                                                                inputLabel={t('VOUCHER.FORM.TABLE.LIST')}
                                                                noOptionsText='ไม่พบข้อมูล'
                                                                getOptionLabel={(option) => option?.itemName || ''}
                                                                options={listType || []}
                                                                renderOption={(props, option) => {
                                                                    return (
                                                                        <Box {...props} >
                                                                            {option?.itemName}
                                                                        </Box>
                                                                    )
                                                                }}
                                                                onInputChange={(e, value) => preparationListType(data)}
                                                                onOpen={() => preparationListType(data)}
                                                                onchange={(e, value) => {
                                                                    const updateItem = [...dataVoucher.voucherItems]
                                                                    updateItem[index] = { ...updateItem[index], ...value }
                                                                    setDataVoucher({ ...dataVoucher, voucherItems: updateItem })
                                                                }}
                                                                value={data}
                                                                disabled={!data.itemTypeId}
                                                                disableClearable
                                                                required
                                                                errMessage={(errorMessage.ITEMID && !data.itemId) ? errorMessage.ITEMID : ''}
                                                            />

                                                        </TableCell>
                                                        <TableCell style={{ verticalAlign: 'top' }}>
                                                            <TextInput
                                                                required
                                                                label={t('VOUCHER.FORM.TABLE.AMOUNT')}
                                                                numberOnly
                                                                onChange={(e) => {
                                                                    const updateItem = [...dataVoucher.voucherItems]
                                                                    updateItem[index] = { ...updateItem[index], qty: e.target.value }
                                                                    setDataVoucher({ ...dataVoucher, voucherItems: updateItem })
                                                                }}
                                                                fullWidth
                                                                value={data.qty}
                                                                disabled={!data?.itemId}
                                                                helperText={(errorMessage.QTYGV && !data.qty) ? errorMessage.QTYGV : ''}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ verticalAlign: 'top' }} align="center">
                                                            <IconButton
                                                                className={'btn-delete'}
                                                                disableFocusRipple
                                                                disableRipple
                                                                disableTouchRipple
                                                                aria-label="delete"
                                                                onClick={() => {
                                                                    if (dataVoucher?.voucherItems?.length < 3) {
                                                                        if (dataVoucher?.voucherItems?.length === 1) {
                                                                            setListProduct(false)
                                                                            setSubValue('ALL')
                                                                            setDataVoucher({ ...dataVoucher, voucherItems: dataVoucher?.voucherItems?.filter((_item: any, num: number) => num !== index), voucherUsage: 'ALL' })
                                                                        } else {
                                                                            setSubValue('SUB')
                                                                            setDataVoucher({ ...dataVoucher, voucherItems: dataVoucher?.voucherItems?.filter((_item: any, num: number) => num !== index), voucherUsage: dataVoucher?.voucherItems[0]?.itemType !== "OPERATIVE" ? "PRODUCT" : 'OPERATIVEDF' })
                                                                        }
                                                                    } else {
                                                                        setSubValue("ALL")
                                                                        setDataVoucher({ ...dataVoucher, voucherItems: dataVoucher?.voucherItems?.filter((_item: any, num: number) => num !== index), voucherUsage: 'ALL' })
                                                                    }
                                                                }}
                                                            >
                                                                <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        : <TableCustom
                                            page={1}
                                            pageLimit={99}
                                            sortType={''}
                                            sortBy={''}
                                            onSort={() => console.log()}
                                            setPageLimit={() => console.log()}
                                            setPage={() => console.log()}
                                            rowCount={1}
                                            hidePagination
                                            headCells={headCellsGV}
                                            rowsData={dataVoucher.voucherItems.map((item: any, index: number) => {
                                                return renderDataGV(item, index)
                                            })}

                                        />
                                    }
                                </Box>
                                <Box width="100%" bgcolor="#F7F8F8" height="54px" paddingX="32px" marginY="16px" display="flex" flexDirection="row" gap='100px' justifyContent="end" alignItems="center">
                                    <Typography fontSize="18px" fontWeight={400}>{t('VOUCHER.FORM.TOTALPRICE')}</Typography>
                                    <Typography fontSize="18px" fontWeight={400}>{handleTotalPrice()} บาท</Typography>
                                </Box>
                            </Box>
                        }


                    </Grid>
                    <Grid item xs={4}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography>{t('VOUCHER.FORM.PRICE_TYPE_COST')}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <RadioButton
                                    name="radio-buttons-group"
                                    onChange={(e) => {
                                        setDataVoucher({ ...dataVoucher, voucherPriceType: e.target.value, voucherPrice: 0 })
                                    }}
                                    sx={{ gap: '16px' }}
                                    // defaultValue={dataVoucher.voucherPriceType}
                                    options={[
                                        { label: t('VOUCHER.FORM.PRICE_TYPE_DISCOUNT'), value: 'DISCOUNT', disabled: voucherId !== 0 },
                                        { label: t('VOUCHER.FORM.PRICE_TYPE_SALEPRICE'), value: 'SALEPRICE', disabled: !listProduct || voucherId !== 0 }
                                    ]}
                                    value={dataVoucher.voucherPriceType}
                                    disabled={voucherId !== 0}

                                />

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Box display="flex" flexDirection="row" gap="8px">
                            <TextInput required label={t('VOUCHER.FORM.COST')} numberOnly onChange={(e) => {
                                const value = e.target.value
                                const newValue = (dataVoucher?.voucherPriceUnit === "PERCENT" && Number(value) > 100) ? 100 : value
                                setDataVoucher({ ...dataVoucher, voucherPrice: newValue })
                                setErrorMessage({ ...errorMessage, 'DISCOUNT': '' })
                            }} fullWidth disabled={dataVoucher.voucherPriceType !== 'DISCOUNT' || voucherId !== 0} helperText={errorMessage.DISCOUNT} inputProps={{ value: dataVoucher.voucherPriceType === 'DISCOUNT' && dataVoucher.voucherPrice || '' }} />
                            <SelectInput
                                required
                                label="หน่วย"
                                value={dataVoucher.voucherPriceUnit}
                                onChange={(e) => setDataVoucher({ ...dataVoucher, voucherPriceUnit: e.target.value, voucherPrice: 0 })}
                                options={[
                                    { name: t('VOUCHER.FORM.BAHT'), value: 'BAHT' },
                                    { name: '%', value: 'PERCENT' }
                                ]}
                                disabled={dataVoucher.voucherPriceType !== 'DISCOUNT' || voucherId !== 0}
                            />
                        </Box>
                        <Grid container spacing="8px" alignItems="center" marginTop="8px">
                            <Grid item xs={6}>
                                <Box display="flex" flexDirection="row" gap="8px">
                                    <TextInput required label={t('VOUCHER.FORM.AMOUNT')} numberOnly onChange={(e) => setDataVoucher({ ...dataVoucher, voucherPrice: e.target.value })} fullWidth disabled={dataVoucher.voucherPriceType !== 'SALEPRICE' || voucherId !== 0} helperText={errorMessage.SALEPRICE} inputProps={{ value: dataVoucher.voucherPriceType === 'SALEPRICE' && dataVoucher.voucherPrice || '' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography alignItems="ceneter" fontSize="16px" fontWeight={400}>{t('VOUCHER.FORM.BAHT')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography fontSize="16px" fontWeight={400}>{t('VOUCHER.FORM.USAGE')}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <RadioButton
                                    name="voucherUsage"
                                    onChange={(e) => {
                                        if (e.target.value === 'ALL') {
                                            setSubValue(e.target.value)
                                            setDataVoucher({ ...dataVoucher, voucherUsage: e.target.value })
                                        } else {
                                            setSubValue('SUB')
                                            setDataVoucher({ ...dataVoucher, voucherUsage: 'OPERATIVEDF' })
                                        }
                                    }}

                                    sx={{ gap: '16px' }}
                                    value={subValue}
                                    defaultValue={subValue}
                                    options={[
                                        { label: t('VOUCHER.FORM.USAGE_ALL'), value: 'ALL', disabled: subValue === 'SUB' && dataVoucher?.voucherItems.length <= 1 || voucherId !== 0, tooltip: t('VOUCHER.FORM.USAGE_ALL_HOVER') },
                                        { label: t('VOUCHER.FORM.USAGE_LIST'), value: 'SUB', disabled: subValue === 'ALL' || voucherId !== 0, tooltip: t('VOUCHER.FORM.USAGE_LIST_HOVER') }
                                    ]}
                                    disabled={voucherId !== 0}
                                    toolTip
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                        <Grid container>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}>
                                <Box marginLeft="24px">
                                    <RadioButton
                                        name="subVoucherUsage"
                                        onChange={(e) => setDataVoucher({ ...dataVoucher, voucherUsage: e.target.value })}
                                        sx={{ gap: '16px' }}
                                        defaultValue={subValue === 'SUB' ? 'OPERATIVEDF' : ''}
                                        value={dataVoucher.voucherUsage}
                                        options={[
                                            { label: t('VOUCHER.FORM.USAGE_LIST_DF'), value: 'OPERATIVEDF', disabled: !listProduct || subValue === 'ALL' || dataVoucher?.voucherUsage === 'PRODUCT' || voucherId !== 0 },
                                            { label: t('VOUCHER.FORM.USAGE_LIST_CF'), value: 'OPERATIVECF', disabled: !listProduct || subValue === 'ALL' || dataVoucher?.voucherUsage === 'PRODUCT' || voucherId !== 0 },
                                            { label: t('VOUCHER.FORM.USAGE_LIST_DF_CF'), value: 'OPERATIVE', disabled: !listProduct || subValue === 'ALL' || dataVoucher?.voucherUsage === 'PRODUCT' || voucherId !== 0 },
                                            { label: t('VOUCHER.FORM.USAGE_LIST_PD'), value: 'PRODUCT', disabled: !listProduct || subValue === 'ALL' || dataVoucher?.voucherUsage !== 'PRODUCT' || voucherId !== 0 },
                                        ]}
                                        disabled={voucherId !== 0}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                        <Grid container alignItems="center">
                            <Grid item xs={6}>
                                <Typography fontSize="16px" fontWeight={400}>{t('VOUCHER.FORM.MINIMUM')}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing="8px" alignItems="center">
                                    <Grid item xs={8}>
                                        <Box display="flex" flexDirection="row" alignItems="center" sx={{ '.MuiFormHelperText-root .MuiBox-root': { whiteSpace: 'nowrap' } }}>
                                            <TextInput
                                                required
                                                label={t('VOUCHER.FORM.MINIMUM_COST')}
                                                numberOnly
                                                value={dataVoucher.minimum || 0}
                                                onChange={(e) => {
                                                    setDataVoucher({ ...dataVoucher, minimum: e.target.value })
                                                    setErrorMessage({ ...errorMessage, "MINIMUM": '' })
                                                }}
                                                fullWidth
                                                helperText={errorMessage.MINIMUM}
                                                inputProps={{ value: dataVoucher.minimum }}
                                                disabled={voucherId !== 0}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography fontSize="16px" fontWeight={400}>{t('VOUCHER.FORM.BAHT')}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                        <TextInput required label={t('VOUCHER.FORM.INITIAL_GIFT_VOUCHER_QUANTITY')} value={dataVoucher.qty} numberOnly onChange={(e) => {
                            setDataVoucher({ ...dataVoucher, qty: e.target.value })
                            setErrorMessage({ ...errorMessage, "QTY": '' })
                        }} fullWidth helperText={errorMessage.QTY} inputProps={{ value: dataVoucher.qty }} disabled={voucherId !== 0} />
                    </Grid>
                    <Grid item xs={4}>
                        <DateInput
                            required
                            label={t('VOUCHER.FORM.DATE_START')}
                            onChange={(e) => {
                                setDataVoucher({ ...dataVoucher, startDate: format(e, 'yyyy-MM-dd'), endDate: '' })
                                setErrorMessage({ ...errorMessage, "STARTDATE": '' })
                            }}
                            value={dataVoucher.startDate}
                            fullWidth
                            onClear={(e) => {
                                e.stopPropagation()
                                setDataVoucher({ ...dataVoucher, startDate: '' })
                            }}
                            helperText={errorMessage.STARTDATE}
                            disabled={voucherId !== 0}
                            disabledDatePast
                            language="TH"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateInput
                            required
                            label={t('VOUCHER.FORM.DATE_END')}
                            onChange={(e) => {
                                setDataVoucher({ ...dataVoucher, endDate: format(e, 'yyyy-MM-dd') })
                                setErrorMessage({ ...errorMessage, "ENDDATE": '' })
                            }}
                            value={dataVoucher.endDate}
                            fullWidth
                            onClear={(e) => {
                                e.stopPropagation()
                                setDataVoucher({ ...dataVoucher, endDate: '' })
                            }}
                            helperText={errorMessage.ENDDATE}
                            disabled={voucherId !== 0 || !dataVoucher.startDate}
                            startDate={dataVoucher?.startDate}
                            language="TH"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextAreaInput label={t('VOUCHER.FORM.DETAIL')} value={dataVoucher.detail} onChange={(e) => setDataVoucher({ ...dataVoucher, detail: e.target.value })} fullWidth inputProps={{ value: dataVoucher.detail }} disabled={voucherId !== 0} />
                    </Grid>
                    <Box border="1px solid" borderColor="#E5E5E5" width="100%" marginLeft="16px" marginTop="16px" />
                    <Grid item xs={12}>
                        <Typography fontSize="18px" fontWeight={500}>{t('VOUCHER.FORM.SETTING.TITLE')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Box gap="16px">
                            <CheckboxButton onChange={(e) => {
                                setIsPrefixGV(e.target.checked)
                                setDataVoucher({ ...dataVoucher, voucherPrefix: '' })

                            }} label={t('VOUCHER.FORM.SETTING.PREFIX_ID')} disabled={voucherId !== 0} checked={isPrefixGV} />
                            <Tooltip title={t('VOUCHER.FORM.SETTING.PREFIX_ID_HOVER')} placement="right">
                                <InfoIcon sx={{ width: '24px', height: '24px', color: '#A8A8A8' }} />
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        {isPrefixGV && <TextInput required label={t('VOUCHER.FORM.SETTING.PREFIX_ID_INPUT')} numberOrText onChange={(e) => setDataVoucher({ ...dataVoucher, voucherPrefix: e.target.value.toUpperCase() })} fullWidth helperText={errorMessage.VOUCHERPREFIX} maxLength={5} disabled={voucherId !== 0} inputProps={{ value: dataVoucher.voucherPrefix }} />}
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                        <CheckboxButton onChange={(e) => {
                            setIsPatient(e.target.checked)
                            setDataVoucher({ ...dataVoucher, voucherGroups: [], voucherGroup: "ALL" })
                        }} label={t('VOUCHER.FORM.SETTING.PATIENT')} disabled={voucherId !== 0} checked={isPatient} />
                    </Grid>
                    <Grid item xs={4}>
                        {isPatient &&
                            <Box display="flex" flexDirection="row" gap="8px">
                                <SelectInput required label={t('VOUCHER.FORM.SETTING.GROUP_PATIENT')} onChange={(e) => {
                                    setDataVoucher({ ...dataVoucher, voucherGroups: [] })
                                    setSubGroup([])
                                    setGroupPatient(e.target.value)
                                    preparationPatient(e.target.value)
                                }} options={[{ name: 'Ranking Member', value: 'RANK' }, { name: t('VOUCHER.FORM.SETTING.TYPE_PATIENT'), value: 'PATIENT' }]} helperText={errorMessage.GROUPTYPE} disabled={voucherId !== 0} value={groupPatient} />
                                <SelectCheckBoxInput
                                    required
                                    label={t('VOUCHER.FORM.SETTING.SUB_GROUP')}
                                    onChange={(e) => {
                                        setSubGroup(e)
                                        setDataVoucher({ ...dataVoucher, voucherGroups: _.map(e, (item) => ({ groupType: groupPatient, groupTypeId: item })), voucherGroup: groupPatient })
                                    }
                                    }
                                    options={optionSubGroup}
                                    selected={subGroup}
                                    disabled={voucherId !== 0}
                                    helperText={errorMessage.GROUPTYPEID}
                                    tranformOption
                                />
                            </Box>
                        }
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={12}>
                        <CheckboxButton onChange={(e) => {
                            if (e.target.checked) {
                                setDataVoucher({ ...dataVoucher, connectPatient: "1" })
                            } else {
                                setDataVoucher({ ...dataVoucher, connectPatient: "0" })
                            }
                        }} label={t('VOUCHER.FORM.SETTING.USE_VOUCHER_DONT_PATIENT')} disabled={voucherId !== 0} checked={dataVoucher.connectPatient === "1"} />
                    </Grid>
                </Grid>
            </Box>

            <PageModal
                isOpen={isOpenPageModal}
                title={t('VOUCHER.FORM.MODAL.TITLE')}
                size="XS"
                onSubmit={{ onClick: onCreateVoucherModal }}
                onCancel={{
                    onClick: () => {
                        setDataStartDate(dataVoucher?.startDate)
                        setDataEndDate(dataVoucher?.endDate)
                        setErrorMessage({})
                        setIsOpenPageModal(false)
                    }
                }}
                handleClose={() => {
                    setDataStartDate(dataVoucher?.startDate)
                    setDataEndDate(dataVoucher?.endDate)
                    setErrorMessage({})
                    setIsOpenPageModal(false)
                }}
            >
                <Box display="flex" flexDirection="column" gap="16px" >
                    <Typography fontSize="16px" fontWeight={400} >{t('VOUCHER.FORM.MODAL.LOTNUMBER')} {handleLotNumber(optionLotNumber)}</Typography>
                    <TextInput required label={t('VOUCHER.FORM.MODAL.VOUCHER_AMOUNT')} value={voucherTotal} onChange={(e: any) => {
                        setVoucherTotal(e.target.value?.replace(/[^0-9]/g, ''))
                        setErrorMessage({ ...errorMessage, 'VOUCHERTOTAL': '' })
                    }} fullWidth helperText={errorMessage.VOUCHERTOTAL} />
                    <DateInput
                        required
                        label={t('VOUCHER.FORM.MODAL.START_DATE')}
                        onChange={(e) => {
                            setDataStartDate(format(e, 'yyyy-MM-dd'))
                            setDataEndDate('')
                            setErrorMessage({ ...errorMessage, 'DATASTARTDATE': '' })
                        }}
                        onClear={(e) => {
                            e.stopPropagation()
                            setDataStartDate('')
                        }}
                        value={dataStartDate}
                        fullWidth
                        disabledDatePast
                        helperText={errorMessage.DATASTARTDATE}
                        language="TH"
                    />
                    <DateInput
                        required
                        label={t('VOUCHER.FORM.MODAL.END_DATE')}
                        onChange={(e) => {
                            setDataEndDate(format(e, 'yyyy-MM-dd'))
                            setErrorMessage({ ...errorMessage, 'DATASENDDATE': '' })
                        }}
                        onClear={(e) => {
                            e.stopPropagation()
                            setDataEndDate('')
                        }}
                        value={dataEndDate}
                        fullWidth
                        startDate={dataStartDate}
                        helperText={errorMessage.DATAENDDATE}
                        language="TH"
                    />
                </Box>
            </PageModal>

            <AlertModal
                isOpen={isDelete}
                title={t(`VOUCHER.FORM.MESSAGE.${statusDelete === 'UNLINK' ? 'UNLINK_TITLE' : 'DELETE_TITLE'}`)}
                message={t(`VOUCHER.FORM.MESSAGE.${statusDelete === 'UNLINK' ? 'UNLINK_MESSAGE' : 'DELETE_MESSAGE'}`, { name: name ? name : `(${isChecks.length} ${t('VOUCHER.FORM.MESSAGE.LIST')})` })}
                type={statusDelete === 'UNLINK' ? 'warning' : 'error'}
                onSubmit={{ onClick: () => name ? deleteTableList(voucherListId) : deleteTableList(), title: t('VOUCHER.FORM.MESSAGE.BUTTON_SAVE_DELETE') }}
                onCancel={{
                    onClick: () => {
                        setName('')
                        setIsDelete(false)
                        setErrorMessage({})
                    }, title: t('VOUCHER.FORM.MESSAGE.BUTTON_CANCEL')
                }}
                handleClose={() => {
                    setName('')
                    setIsDelete(false)
                    setErrorMessage({})
                }}
            />

            {voucherId !== 0 && location.pathname === "/voucher/update" &&
                <>
                    <Box marginTop="24px" boxShadow="0px 0px 12px 0px #CCCCCC;" borderRadius="8px" paddingX="32px" paddingY="24px">
                        <Box display="flex" justifyContent="space-between" alignItems="center" >
                            <Typography fontSize="24px" fontWeight={500}>{t('VOUCHER.FORM.LIST_VOUCHER_ID')}</Typography>
                            <Box display="flex" flexDirection="row" gap="16px">
                                <ButtonExport
                                    headCells={headCells}
                                    portrait={true}
                                    filter={''}
                                    fileName={'รายการรหัส Gift Voucher'}
                                    rowsData={dataVoucherListExport.map((item: any, index: number) => {
                                        return renderDataVoucher(item, index, true)
                                    })}
                                    marginPrint={'0.5cm'}
                                    componentExportRef={componentExportRef}
                                />
                                <MainButton
                                    onClick={() => setIsOpenPageModal(true)}
                                    title={t('VOUCHER.FORM.ADD_UNIT_VOUCHER')}
                                    type="primary"
                                    variant="outlined"
                                    startIcon={<AddCircleIcon />}
                                    style={{ height: '40px' }}
                                />
                            </Box>
                        </Box>

                        <Grid container>
                            <Grid item xs={1}>
                                <Typography color="#29302E" fontSize="16px" fontWeight={500}>{t('VOUCHER.FORM.STATUS')}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Box display="flex" flexDirection="row" alignItems="center" gap="24px">
                                    <Typography color="#EDBA07" fontSize="16px" fontWeight={400}>{t('VOUCHER.FORM.STATUS_WAITING')}</Typography>
                                    <Typography fontSize="16px" fontWeight={400}>{countWaiting || 0}/{totalCount}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box display="flex" flexDirection="row" alignItems="center" gap="24px">
                                    <Typography color="#1CB99A" fontSize="16px" fontWeight={400}>{t('VOUCHER.FORM.STATUS_READY')}</Typography>
                                    <Typography fontSize="16px" fontWeight={400}>{countReady || 0}/{totalCount}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box display="flex" flexDirection="row" alignItems="center" gap="24px">
                                    <Typography color="#5A5A5A" fontSize="16px" fontWeight={400}>{t('VOUCHER.FORM.STATUS_USED')}</Typography>
                                    <Typography fontSize="16px" fontWeight={400}>{countUsed || 0}/{totalCount}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box display="flex" flexDirection="row" alignItems="center" gap="24px">
                                    <Typography color="#B00020" fontSize="16px" fontWeight={400}>{t('VOUCHER.FORM.STATUS_EXPIRED')}</Typography>
                                    <Typography fontSize="16px" fontWeight={400}>{countExpired || 0}/{totalCount}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3}></Grid>
                        </Grid>

                        <Grid container spacing="16px" marginTop="0px">
                            <Grid item xs={4}>
                                <Grid container spacing="16px">
                                    <Grid item xs={6}>
                                        <TextInput label={t('VOUCHER.FORM.SEARCH')} onChange={(e) => setSearch(e.target.value)} fullWidth value={search} inputProps={{ endAdornment: search && <InputAdornment position="end"><Box sx={{ cursor: 'pointer' }} onClick={() => setSearch('')}><ClearIcon /></Box></InputAdornment> }} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SelectCheckBoxInput
                                            label={t('VOUCHER.FORM.STATUS_FITTER.STATUS')}
                                            onChange={(e) => {
                                                setStatus(e)
                                                const result = e.join(',')
                                                setResultStatus(result)
                                            }}
                                            options={[
                                                { name: t('VOUCHER.FORM.STATUS_FITTER.WAITING'), id: 'WAITING', },
                                                { name: t('VOUCHER.FORM.STATUS_FITTER.READY'), id: 'READY', },
                                                { name: t('VOUCHER.FORM.STATUS_FITTER.USED'), id: 'USED', },
                                                { name: t('VOUCHER.FORM.STATUS_FITTER.EXPIRED'), id: 'EXPIRED' }
                                            ]}
                                            selected={status || []}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={4} >
                                <Grid container spacing="16px">
                                    <Grid item xs={6}>
                                        <SelectCheckBoxInput
                                            label={t('VOUCHER.FORM.LOTNUMBER')}
                                            onChange={(e) => {
                                                setLotNumber(e)
                                                const result = e.join(',')
                                                setResultLotNumber(result)
                                            }}
                                            options={optionLotNumber}
                                            selected={lotNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DateRangeInput
                                            inputHeight={40}
                                            value={rangeDate || ['', '']}
                                            onchange={(val: any) => {
                                                if (_.isEmpty(val)) setRangeDate(['', ''])
                                                else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                                            }}
                                            label={''}
                                            allowClear
                                            onClear={() => setRangeDate(['', ''])}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Box display="flex" justifyContent="space-between" >
                                    <MainButton onClick={() => {
                                        setPage(1)
                                        preparationVoucherId(voucherId, 1)
                                        preparationVoucherId(voucherId, 1, true)
                                    }} title={t('VOUCHER.FORM.SEARCH_VOUCHER_LIST')} type="primary" variant="contained" />
                                    <SelectPrimaryInput buttonLabel="จัดการหลายรายการ" disabled={isChecks.length === 0} options={[
                                        {
                                            label: 'ยกเลิกการผูก', onClick: () => {
                                                setStatusDelete('UNLINK')
                                                setIsDelete(true)
                                            }, icon: <LinkOffIcon sx={{ color: '#808080' }} />
                                        },
                                        {
                                            label: 'ลบรายการ', onClick: () => {
                                                setStatusDelete('DELETED')
                                                setIsDelete(true)
                                            }, icon: <DeleteOutlineOutlinedIcon sx={{ color: '#808080' }} />
                                        }
                                    ]} />
                                    {/* <MainButton onClick={() => setIsDelete(true)} title={t('VOUCHER.FORM.BUTTON_DELETE_VOUCHER_ALL')} type="danger" variant="outlined" startIcon={<DeleteOutlineIcon />} disabled={isChecks.length === 0} /> */}
                                </Box>
                            </Grid>
                        </Grid>

                        <Box marginTop="16px" >
                            <TableCustom
                                headCells={headCells}
                                onSort={(sortByVal: string, sortTypeVal: string) => {
                                    setSortType(sortTypeVal)
                                    setSortBy(sortByVal)
                                }}
                                page={page}
                                pageLimit={pageLimit}
                                rowsData={dataVoucherList.map((item: any, index: number) => {
                                    return renderData(item, index)
                                })}
                                setPage={(e) => setPage(e)}
                                setPageLimit={(e) => setPageLimit(e)}
                                sortBy={sortBy}
                                sortType={sortType}
                                rowCount={rowCount}

                            />
                        </Box>
                    </Box>
                    <Box className={`print-show`} ref={componentExportRef}>
                        <Box fontSize={'10px'} fontWeight={'600'}>{'รายการรหัส Gift Voucher'}</Box>
                        <Box fontSize={'10px'} display="flex" flexDirection="row" alignItems="center" gap="8px">
                            <Typography fontSize="10px" fontWeight="700px">ชื่อ Gift Voucher:</Typography>
                            <Typography fontSize="10px" fontWeight="400px">{dataVoucher?.voucherName}</Typography>
                        </Box>
                        <Box fontSize={'10px'} fontWeight={'500'} display={'flex'} gap={'8px'} marginBottom={'8px'}>
                            <span>
                                {'ค้นหา'}: {search || '-'}
                            </span>
                            <span>
                                {'สถานะ'}: {_.map(status, (item: string) => statusType(item))?.join(", ") || '-'}
                            </span>
                            <span>
                                {'วันที่เริ่มต้น'}: {moment(dataVoucher.startDate).format('DD/MM/YYYY') || '-'}
                            </span>
                            <span>
                                {'วันที่สิ้นสุด'}: {moment(dataVoucher.endDate).format('DD/MM/YYYY') || '-'}
                            </span>
                        </Box>
                        <Box
                            width={'100%'}
                            marginBottom={'8px'}
                            sx={{
                                '.no-link': { display: 'flex', justifyContent: 'center', gap: '4px' },
                                'th,td': {
                                    fontSize: '8px',
                                    margin: '0',
                                    padding: '1px',
                                    borderBottom: '1px solid black',
                                    borderTop: '1px solid black',
                                    textAlign: 'center',
                                    ':last-child': {
                                        borderRight: 0,
                                    }
                                }
                            }}
                        >
                            <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                                <thead>
                                    {_.map(headCells.filter((headCell: any) => headCell.id !== 'action' && headCell.id !== 'voucherSelect' && headCell.id !== 'UpdatedAt'), (val: any, index: number) => {
                                        return (
                                            <th
                                                style={{
                                                    textAlign: val.align ? val.align : 'left',
                                                    fontSize: 8,
                                                    fontWeight: 500
                                                }}>
                                                {val.label || (val.labelKey)}
                                            </th>
                                        )
                                    })}
                                </thead>
                                <tbody>
                                    {dataVoucherListExport?.length ? dataVoucherListExport?.map((item: any, index: number) => {
                                        return renderDataVoucher(item, index, true)
                                    }) : []}
                                    {!dataVoucherListExport?.length && (
                                        <td colSpan={headCells.length}>
                                            {'ไม่พบข้อมูล'}
                                        </td>
                                    )}
                                </tbody>
                            </table>
                        </Box>
                    </Box>
                </>
            }
        </Box>
    )
}


export default FormVoucher
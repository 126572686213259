import _ from "lodash"
import { Box, FormControlLabel, Radio, RadioGroup, Tooltip } from "@mui/material"
import InfoIcon from '@mui/icons-material/Info'
interface RadioButtonProps {
    onChange: (e: any) => void
    name: string
    options: Array<{ label: string, value: string, disabled?: boolean, tooltip?: string }>
    sx?: any
    row?: boolean
    disabled?: boolean
    defaultValue?: string
    toolTip?: boolean
    value?: any
}
export default function RadioButton(props: RadioButtonProps) {

    ////-------------------
    ////    HANDLE
    ////-------------------
    const sx = {
        '& .MuiSvgIcon-root': {
            fontSize: 20,
        },
        '&.Mui-checked': {
            color: '#1CB99A',
        },
        '&.Mui-disabled': {
            color: '#C5C5C5'
        },
        '&:active': {
            color: '#1CB99A'
        }
    }

    return (
        <RadioGroup
            defaultValue={props.defaultValue}
            onChange={props.onChange}
            row={props.row}
            sx={props.sx}
            name={props.name}
            value={props.value}
        >
            {_.map(props.options, (item: { label: string, value: string, disabled?: boolean, tooltip?: string }, index: number) =>
                <Box display="flex" alignItems="center">
                    <FormControlLabel
                        disabled={item.disabled}
                        key={index}
                        value={item.value}
                        control={<Radio sx={sx} disabled={item.disabled} />} label={item.label}
                    />
                    {props.toolTip &&
                        <Tooltip title={item.tooltip} placement="right">
                            <InfoIcon sx={{ width: '24px', height: '24px', color: '#A8A8A8', mr: '8px' }} />
                        </Tooltip>}
                </Box>

            )}
        </RadioGroup>

    )
}
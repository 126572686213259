import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useRef, useState } from "react";
import _ from "lodash";


interface MenuOption {
    label: string;
    icon?: any;
    onClick: () => void;
    disabled?: boolean
}

interface IconDropdownButtonProps {
    options: MenuOption[];
    disabled?: boolean
}

export default function IconDropdownButton(props: IconDropdownButtonProps) {
    ////-------------------
    ////    REF
    ////-------------------
    const ref = useRef<HTMLDivElement | null>(null)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    return (
        <Box>
            <Button
                variant="outlined"
                onClick={(event) => !props.disabled && setAnchorEl(event.currentTarget)}
                disabled={props.disabled}
                sx={{
                    backgroundColor: props.disabled ? '#E0E0E0' : 'white',
                    borderColor: props.disabled ? '#E0E0E0' : '#1CB99A',
                    '&:hover': { borderColor: props.disabled ? '#E0E0E0' : '#1CB99A' },
                    maxWidth: '32px',
                    minWidth: '32px',
                    height: '32px'
                }}
            >
                <MoreHorizIcon sx={{ color: props.disabled ? '#808080' : '#1CB99A' }} />
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right', // เปลี่ยนจาก 'right' เป็น 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right', // เปลี่ยนจาก 'right' เป็น 'left'
                }}
            >
                {props.options.map((option, index) => (
                    <MenuItem
                        key={index}
                        disabled={option.disabled}
                        onClick={() => {
                            option.onClick();
                            setAnchorEl(null);
                        }}
                    >
                        {option.icon}
                        <Typography fontSize="16px" fontWeight={400} sx={{ marginLeft: '8px', color: '#231F20' }}>{option.label}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )
}
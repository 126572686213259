import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { Box, ClickAwayListener, FormControlLabel, Grid, MenuItem, RadioGroup, Typography } from '@mui/material'
import { routeName } from 'routes/routes-name'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { getBranch, getClinicInfo, numberFormat, toBuddhistYear } from 'utils/app.utils'
import ReportApi, { FindAllReportInterface } from 'api/report.api'
import ClinicApi, { BranchInterface, BillInterface } from 'api/master/clinic.api'
import LabGroupsApi from 'api/setting/default-labs/lab-group.api'
import LabCompanyApi from 'api/setting/default-labs/lab-company.api'
import DoctorScheduleApi from 'api/counter/doctor-schedule.api'

/** COMPONENT */
import Loading from 'component/Loading'
import FucntionPrint from 'component/Print/print'
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import RemunerationClinicFeeList from './RemunerationClinicFeeList'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { CustomFilterDoctor, ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import { SpanClearFilter } from 'features/report/useStyled'
import { colors } from 'constants/theme'
import { ReportFormulaDF, optionFormula } from '../report-option-df'
import ButtonCustom from 'component/Button/ButtonCustom'
import { KEY_STORAGE } from 'constants/common'
import InputRadio from 'component/Input/InputRadio'
import { dateTimeToView } from 'utils/date.utils'
import RadioButton from 'new-components/buttons/radio-button'
import SelectInput from 'new-components/inputs/select-input'
import SelectCheckBoxInput from 'new-components/inputs/select_checkbox_input'
import RemunerationLabReport from './remuneration-lab-report'
import { UseSnCode } from 'utils/useSnCode'
import RemunerationDentistFee from './remuneration-dentist-fee'
import RemunerationTotalIncomeSummary from './remuneration-total-income-summary'
import RemunerationTotalDoctor from './remuneration-total-doctor'
import DropdownButton from 'new-components/buttons/dropdown-button'
import MainButton from 'new-components/buttons/main-button'

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

interface DentistInterface {
  dentistId: number
  dentistFullname: string
}

export default function RemunerationClinicFee() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const componentRefExcel = useRef<HTMLDivElement>(null)
  const momentNowRange = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  const momentDate = () => momentNowRange

  const [selectedOptionPrice, setSelectedOptionPrice] = useState<any>(localStorage.getItem(KEY_STORAGE.REPORT_CONDITION) || 'TREATMENT')
  const [branchId, setBranchId] = useState<any>(getBranch())
  const [branches, setBranches] = useState<any[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [sortBy, setSortBy] = useState<string>('createAt')
  const [sortType, setSortType] = useState<string>('ASC')
  const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
  const [receiptPrefix, setReceiptPrefix] = useState<any[]>([])
  const [prefix, setPrefix] = useState('ALL')

  const [doctorsMultiple, setDoctorsMultiple] = useState<DentistInterface[]>([])
  const [doctors, setDoctors] = useState<any>([])
  const [selectDoctors, setSelectDoctors] = useState<any[]>([])
  const [orderedSelectedDoctors, setOrderedSelectedDoctors] = useState<any[]>([])
  const [openFilterDoctors, setOpenFilterDoctors] = useState(false)

  const [labGroup, setLabGroup] = useState<any[]>([])
  const [labCompany, setLabCompany] = useState<any[]>([])
  const [labGroupId, setLabGroupId] = useState<number>(0)
  const [labCompanyId, setLabCompanyId] = useState<number>(0)

  const [optionDf, setOptionDf] = useState<'0' | '1' | '2' | '3'>(getClinicInfo().defaultDfCon || '1')
  const [remunerationClinicFeeData, getRemunerationClinicFeeData] = useState<any>(null)
  const [remunerationClinicFeePage, setRemunerationClinicFeePage] = useState<number>(1)
  const [remunerationClinicFeeTypePage, setRemunerationClinicFeeTypePage] = useState<number>(1)
  const [remunerationClinicFeeLimitPage, setRemunerationClinicFeeLimitPage] = useState<number>(10)

  const [checkDF, setCheckDF] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)

  const [popFormula, setPopFormula] = useState<boolean>(false)
  const [imageFormula, setImageFormula] = useState<string>('')

  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }
    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }

    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'REMUNERATION_CLINIC_FEE') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })

    setOptionDf(getClinicInfo().defaultDfCon)
    // if (_.isEmpty(branches)) {
    //   ClinicApi.findAllBranches()
    //     .then(({ data }) => setBranches(_.map(data, (item) => ({ value: item.branchId, name: item.branchName || item.branchNameEn }))))
    //     .catch((e) => {
    //       return
    //     })
    // }
    if (_.isEmpty(branches)) {
      ClinicApi.findAllBranches()
        .then(({ data }) => setBranches(data))
        .catch((e) => {
          return
        })
    }

    // if (_.isEmpty(receiptPrefix)) {
    //   ClinicApi.allBillPrefix()
    //     .then(({ data }) => {
    //       const filteredData = data.map((item: any) => ({ value: item.billPrefixId, name: item.prefix }))
    //       setReceiptPrefix(filteredData)
    //     })
    //     .catch((e) => {
    //       return
    //     })
    // }
    if (_.isEmpty(receiptPrefix)) {
      ClinicApi.allBillPrefix()
        .then((resp) => setReceiptPrefix(resp.data))
        .catch((e) => {
          return
        })
    }

    // if (_.isEmpty(doctors)) {
    //   ClinicApi.findAllDoctors()
    //     .then((resp) => {
    //       setDoctors(_.map(resp.data, (item) => ({ id: item.userId, name: item.fullname })))
    //       const dentists: DentistInterface[] = resp?.data?.map((d: any) => {
    //         return {
    //           dentistId: d.userId,
    //           dentistFullname: d.fullname
    //         }
    //       })
    //       setDoctorsMultiple(dentists)
    //     })
    //     .catch((e) => {
    //       return
    //     })
    // }
    if (_.isEmpty(doctors)) {
      ClinicApi.findAllDoctors()
        .then((resp) => {
          setDoctors(resp.data)
          const dentists: DentistInterface[] = resp?.data?.map((d: any) => {
            return {
              dentistId: d.userId,
              dentistFullname: d.fullname
            }
          })
          setDoctorsMultiple(dentists)
        })
        .catch((e) => {
          return
        })
    }

    // if (_.isEmpty(labGroup)) {
    //   LabGroupsApi.findAll({ page: 1, pageLimit: 100, status: 'ACTIVE' })
    //     .then(({ data }) => {
    //       setLabGroup(_.map(data, (item) => ({ value: item.labGroupId, name: item.labGroupName })))
    //     })
    //     .catch((e) => {
    //       return
    //     })
    // }
    if (_.isEmpty(labGroup)) {
      LabGroupsApi.findAll({ page: 1, pageLimit: 100, status: 'ACTIVE' })
        .then(({ data: dataLabGroup }) => {
          setLabGroup(dataLabGroup)
        })
        .catch((e) => {
          return
        })
    }

    // if (_.isEmpty(labCompany)) {
    //   LabCompanyApi.findAll({ page: 1, pageLimit: 100, status: 'ACTIVE' })
    //     .then(({ data }) => {
    //       setLabCompany(_.map(data, (item) => ({ value: item.labCompanyId, name: item.labCompanyName })))
    //     })
    //     .catch((e) => {
    //       return
    //     })
    // }
    if (_.isEmpty(labCompany)) {
      LabCompanyApi.findAll({ page: 1, pageLimit: 100, status: 'ACTIVE' })
        .then(({ data: dataLabCompany }) => {
          setLabCompany(dataLabCompany)
        })
        .catch((e) => {
          return
        })
    }

    DoctorScheduleApi.findAllDoctor({ branchId: branchId }).then(({ data: dataAllDoctor }) => {
      const dentists: DentistInterface[] = dataAllDoctor.map((d: any) => {
        return {
          dentistId: d.userId,
          dentistFullname: d.fullname
        }
      })
      // setDoctorsMultiple(dentists)
      // setSelectDoctors([...dentists.map((d: DentistInterface) => d.dentistId)])
      setSelectDoctors(['ALL', ...dentists.map((d: DentistInterface) => d.dentistId)])

    })
    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [branchId])

  useEffect(() => {
    fetchRemunerationClinicFee()
  }, [sortBy, sortType, remunerationClinicFeeTypePage, optionDf, selectedOptionPrice])


  const fetchRemunerationClinicFee = () => {
    {
      let condition: FindAllReportInterface = {
        page: remunerationClinicFeePage,
        pageLimit: remunerationClinicFeeLimitPage,
        branchId: branchId,
        dateStart: rangeDate[0],
        dateEnd: rangeDate[1],
        optionPrice: selectedOptionPrice

      }
      condition = { ...condition, labCompanyId: labCompanyId }
      condition = { ...condition, labGroupId: labGroupId }
      if (selectDoctors.length !== 0) {
        const doctor = _.filter(selectDoctors, (v: any) => v > 0).join(',')
        if (doctor) condition = { ...condition, doctorId: _.filter(selectDoctors, (v: any) => v > 0).join(',') }
      }
      if (prefix) condition = { ...condition, prefix: prefix === 'ALL' ? '' : prefix }
      if (sortType) condition = { ...condition, sortType: sortType }
      if (sortBy) condition = { ...condition, sortBy: sortBy }
      if (optionDf) condition = { ...condition, optionDf: Number(optionDf) }
      if (rangeDate) {
        if ((rangeDate[0] !== null || rangeDate[0] !== '') && (rangeDate[1] !== null || rangeDate[1] !== '')) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
      }
      ReportApi.reportRemittanceClinicFee(condition)
        .then(({ headers, data }) => {
          getRemunerationClinicFeeData(data)
          setRowCount(headers['x-total'])
        })
        .catch((e) => setLoading(false))
        .finally(() => setLoading(false))

    }
  }

  const setDoc = (data: any) => {
    const sortedSelectData = data.sort((a: any, b: any) => {
      const idA = a.dentistId
      const idB = b.dentistId
      return selectDoctors.indexOf(idA) - selectDoctors.indexOf(idB)
    })
    return sortedSelectData
  }
  const filter = [
    branchId === 0 ? `${t(`REPORT.FILTER.ALL`)}` : `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`,
    rangeDate[0],
    rangeDate[1],
    prefix === 'ALL' ? `${t(`REPORT.FILTER.ALL`)}` : prefix,
    _.isEmpty(selectDoctors)
      ? ` ${t('REPORT.FILTER.NO_SELECT')}`
      : selectDoctors.filter((i) => typeof i === 'number').length === doctors.length
        ? `${t('REPORT.FILTER.ALL')}`
        : `${setDoc(doctors.filter((d: any) => selectDoctors.includes(d.userId)))
          ?.map((d: any) => d.fullname)
          .slice(0, 3) // เเสดงเเค่ 3 คนเเรก
          .join(', ')}`,
    labGroupId ? `${_.get(_.find(labGroup, { labGroupId: labGroupId }), 'labGroupName' || 'labGroupNameEn')} ` : `${t(`REPORT.FILTER.ALL`)}`,
    labCompanyId ? `${_.get(_.find(labCompany, { labCompanyId: labCompanyId }), 'labCompanyName' || 'labCompanyNameEn')} ` : `${t(`REPORT.FILTER.ALL`)}`,
    optionDf === '0' ? t('REPORT.FILTER.NO_CAL_DF') : t(`REPORT.FILTER.DF${optionDf}`)
  ]

  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRefExcel.current,
    filename: `${t(`REPORT.REPORT_NAME.REMUNERATION_CLINIC_FEE`)} (${rangeDate[0]} - ${rangeDate[1]})`,
    sheetName: `${t(`REPORT.REPORT_NAME.REMUNERATION_CLINIC_FEE`)}`
  })

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleFilterDoctors = (value: any) => {
    if ((value === 'ALL' && _.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)) || value === 'CLEAR') setSelectDoctors([''])
    else if (value === 'ALL') setSelectDoctors([..._.map(doctors, (d: any) => d.userId)])
    else if (_.includes(selectDoctors, value)) setSelectDoctors(_.filter(selectDoctors, (d) => d !== value))
    else setSelectDoctors([...selectDoctors, value])
  }
  // useEffect(() => {
  //   fetchRemunerationClinicFee()
  // }, [fetchRemunerationClinicFee])

  useEffect(() => {
    if (imageFormula === '') {
      setPopFormula(false)
    } else {
      setPopFormula(true)
    }
  }, [imageFormula])

  const renderTotal = () => {
    let sumAmountCash = 0
    let sumAmountTransfer = 0
    let sumAmountDebit = 0
    let sumAmountCredit = 0
    let sumAmountTrementRight = 0
    let sumAmountAdvance = 0
    let sumLab = 0
    let sumAmountPaid = 0
    let sumDf = 0
    let dfNet = 0
    let dfLabFee = 0
    _.map(remunerationClinicFeeData?.rows, (salary, indexSalary: number) => {
      sumAmountPaid += salary?.table.map((amountPaidData: any) => amountPaidData.amountPaid).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
      sumAmountCash += salary?.table.map((amountCashData: any) => amountCashData.amountCash).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
      sumAmountTransfer += salary?.table.map((amountTransferData: any) => amountTransferData.amountTransfer).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
      sumAmountDebit += salary?.table.map((amountDebitData: any) => amountDebitData.amountDebit).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
      sumAmountCredit += salary?.table.map((amountCreditData: any) => amountCreditData.amountCredit).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
      sumAmountTrementRight += salary?.table.map((amountTreatmentRightData: any) => amountTreatmentRightData.amountTrementRight).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
      sumAmountAdvance += salary?.table.map((amountAdvanceData: any) => amountAdvanceData.amountAdvance).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
    })
    _.map(remunerationClinicFeeData?.rowLab, (lab, indexLab: number) => {
      sumLab += lab?.table.map((sumLabData: any) => sumLabData.labFee).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
    })

    if (remunerationClinicFeeData?.rowDf !== undefined) {
      if (remunerationClinicFeeData?.rowDf.length > 0) {
        sumDf += remunerationClinicFeeData?.rowDf ? remunerationClinicFeeData?.rowDf.map((rowDfData: any) => rowDfData.dfLostCreditAndTreatment).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0)) : 0
        dfNet += remunerationClinicFeeData?.rowDf ? remunerationClinicFeeData?.rowDf.map((rowDfData: any) => rowDfData.dfNet).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0)) : 0
        dfLabFee += remunerationClinicFeeData?.rowDf ? remunerationClinicFeeData?.rowDf.map((rowDfData: any) => rowDfData.dfLabFee).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0)) : 0
      }
    }
    const netClinic = sumAmountPaid - dfNet < 0 ? 0 : sumAmountPaid - dfNet
    return (
      <tr style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(sumAmountPaid || 0)}</td>
        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(netClinic || 0)}</td>
        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(dfNet || 0)}</td>
        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(dfNet + dfLabFee || 0)}</td>
        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(dfLabFee || 0)}</td>
        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top', color: '#007BFF' }}>{numberFormat(sumAmountCash || 0)}</td>
        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top', color: '#007BFF' }}>{numberFormat(sumAmountTransfer || 0)}</td>
        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top', color: '#007BFF' }}>{numberFormat(sumAmountDebit || 0)}</td>
        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top', color: '#007BFF' }}>{numberFormat(sumAmountCredit || 0)}</td>
        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top', color: '#007BFF' }}>{numberFormat(sumAmountTrementRight || 0)}</td>
        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top', color: '#007BFF' }}>{numberFormat(sumAmountAdvance || 0)}</td>
      </tr>
    )
  }

  const renderSalaryTable = (data: any) => {
    const items = _.chain(data)
      .map((i: any) => {
        i.dateFormat = toBuddhistYear(moment(i.date), 'DD/MM/YYYY')
        return i
      })
      .groupBy('dateFormat')
      .map((value: any, key: string) => ({ date: key, data: value }))
      .value()
    return items
  }

  return (
    <Box>
      <HeaderCard text={t(`REPORT.REPORT_NAME.REMUNERATION_CLINIC_FEE`)} />
      <Box padding="16px">
        <RadioButton
          name="row-radio-buttons-group"
          onChange={(e) => {
            localStorage.setItem(KEY_STORAGE.REPORT_CONDITION, e.target.value.toString())
            setSelectedOptionPrice(e.target.value)
          }}
          row
          options={[{ label: t('REPORT.FILTER.PRICE_OF_TREATMENT'), value: 'TREATMENT' }, { label: t('REPORT.FILTER.ACTUAL_PRICE_PAID'), value: 'PAID' }]}
          value={selectedOptionPrice} />
        <Grid container spacing="8px">
          <Grid item xs={12} sm={6} md={3}>
            {/* <SelectInput
              onChange={(e) => {
                setBranchId(e.target.value)
                setRemunerationClinicFeePage(1)
              }}
              options={branches}
              value={branchId}
              label={t('REPORT.FILTER.BRANCH')}
            /> */}
            <FilterSelect
              onchange={(e) => {
                setBranchId(e.target.value)
                setRemunerationClinicFeePage(1)
              }}
              renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
              label={''}
              selectId="select-branch"
              labelId="label-branch"
              value={branchId}
              options={_.map(branches, (b: BranchInterface) => {
                return (
                  <MenuItem key={b.branchId} value={b.branchId}>
                    {b.branchName || b.branchNameEn}
                  </MenuItem>
                )
              })}
              formControlStyle={{ maxWidth: 'unset !important' }}
              classesOption="style-select-doctors"
              style={{ height: 40 }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InputSecondNewRangePicker
              inputHeight={40}
              value={rangeDate || emptyRangePicker}
              onchange={(val: any) => {
                if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                setRemunerationClinicFeePage(1)
                setRemunerationClinicFeeTypePage(1)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {/* <SelectInput
              onChange={(e) => {
                setPrefix(e.target.value)
                setRemunerationClinicFeePage(1)
                setRemunerationClinicFeeTypePage(1)
              }}
              value={prefix}
              options={[{ value: 'ALL', name: t('REPORT.FILTER.ALL') }, ...receiptPrefix]}
              label={t('REPORT.FILTER.RECEIPT_TYPE')}
            /> */}
            <FilterSelect
              onchange={(e) => {
                setPrefix(e.target.value)
                setRemunerationClinicFeePage(1)
                setRemunerationClinicFeeTypePage(1)
              }}
              renderValue={() => `${t('REPORT.FILTER.RECEIPT_TYPE')}: ${prefix === 'ALL' ? t(`REPORT.FILTER.ALL`) : prefix}`}
              label={''}
              selectId="select-prefix"
              labelId="label-prefix"
              value={prefix}
              options={[
                <MenuItem key="0" value="ALL">
                  {t('REPORT.FILTER.ALL')}
                </MenuItem>,
                _.map(receiptPrefix, (data: BillInterface) => {
                  return (
                    <MenuItem key={data.billPrefixId} value={data.prefix}>
                      {data.prefix}
                    </MenuItem>
                  )
                })
              ]}
              formControlStyle={{ maxWidth: 'unset !important' }}
              classesOption="style-select-doctors"
              style={{ height: 40 }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {/* <SelectCheckBoxInput
              onChange={(e) => {
                setSelectDoctors(e)
              }}
              options={doctors}
              selected={selectDoctors}
              label={t('REPORT.FILTER.DOCTOR')}
            /> */}
            <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
              <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
                <AutocompleteSelect
                  open={openFilterDoctors}
                  multiple
                  labelId="label-doctors"
                  options={['ALL', ...doctors, 'CLEAR']}
                  getOptionLabel={(option) => _.find(doctors, { userId: option.userId })?.fullname || '-'}
                  renderOption={(props, option) => {
                    if (option === 'ALL') {
                      return (
                        <ListFilterDoctors {...props} key={option} component="li" value={option}>
                          <InputCheckbox
                            label={t('REPORT.REPORT_GROUP.ALL')}
                            checked={_.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)}
                            indeterminate={_.size(selectDoctors) > 1 && _.size(selectDoctors.filter((item) => typeof item === 'number')) !== _.size(doctors)}
                            className={'pe-none'}
                          />
                        </ListFilterDoctors>
                      )
                    } else if (option === 'CLEAR') {
                      return (
                        <Box {...props} key={option} component="li" value={option} sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
                          <SpanClearFilter className="pe-none">{t('REPORT.FILTER.CLEAR_FILTER')}</SpanClearFilter>
                        </Box>
                      )
                    } else {
                      return (
                        <ListFilterDoctors {...props} key={option.userId} component="li" value={option.userId}>
                          <InputCheckbox label={option.fullname} checked={_.includes(selectDoctors, option.userId)} className={'pe-none'} />
                        </ListFilterDoctors>
                      )
                    }
                  }}
                  renderTags={(tagValue, getTagProps) => {
                    return (
                      <>
                        <span className="pr-1">{t('USER_DOCTOR')}:</span>
                        {!openFilterDoctors &&
                          ((_.size(tagValue.filter((item: any) => typeof item === 'number')) === _.size(doctors) && <span>{t('REPORT.REPORT_GROUP.ALL')}</span>) || (
                            <Box className="text-ellipsis">
                              {_.map(tagValue, (option: any, index: number) => {
                                return (
                                  <span>
                                    {_.find(doctors, { userId: option })?.fullname}
                                    {index !== _.findLastIndex(tagValue) && option && `,`}
                                  </span>
                                )
                              })}
                            </Box>
                          ))}
                      </>
                    )
                  }}
                  onchange={(e, value) => handleFilterDoctors(e?.target?.value || e?.target?.attributes?.value?.value)}
                  noOptionsText={t('NOT_FOUND')}
                  filterOptions={(option) => 'ALL' + option.fullname + 'CLEAR'}
                  value={selectDoctors}
                  height={40}
                  disableClearable
                />
              </CustomFilterDoctor>
            </ClickAwayListener>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {/* <SelectInput
              onChange={(e) => {
                setLabGroupId(e.target.value)
              }}
              value={labGroupId}
              options={[{ value: 0, name: t('REPORT.FILTER.ALL') }, ...labGroup]}
              label={t('REPORT.FILTER.LAB_GROUP')}
            /> */}
            <AutocompleteSelect
              key={'lab-group'}
              labelId="lab-group"
              inputLabel={t('REPORT.FILTER.LAB_GROUP')}
              noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
              options={labGroup}
              getOptionLabel={(option: any) => option.labGroupName}
              renderOption={(props, option: any) => (
                <Box key={option} component="li" {...props}>
                  {option.labGroupName}
                </Box>
              )}
              onchange={(e, value: any) => {
                if (value) {
                  setLabGroupId(value.labGroupId)
                }
              }}
              value={_.find(labGroup, (val: any) => Number(val.labGroupId) === Number(labGroupId)) || null}
              disableClearable
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {/* <SelectInput
              onChange={(e) => {
                setLabCompanyId(e.target.value)
              }}
              value={labCompanyId}
              options={[{ value: 0, name: t('REPORT.FILTER.ALL') }, ...labCompany]}
              label={t('REPORT.FILTER.LAB_COMPANY')}
            /> */}
            <AutocompleteSelect
              key={'lab-company'}
              labelId="lab-company"
              inputLabel={t('REPORT.FILTER.LAB_COMPANY')}
              noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
              options={labCompany}
              getOptionLabel={(option: any) => option.labCompanyName}
              renderOption={(props, option: any) => (
                <Box key={option} component="li" {...props}>
                  {option.labCompanyName}
                </Box>
              )}
              onchange={(e, value: any) => {
                if (value) {
                  setLabCompanyId(value.labCompanyId)
                }
              }}
              value={_.find(labCompany, (val: any) => Number(val.labCompanyId) === Number(labCompanyId)) || null}
              disableClearable
            />
          </Grid>

          <Grid item container justifyContent="space-between" alignItems="center" md={6}>
            <MainButton onClick={() => fetchRemunerationClinicFee()} title={t('INPUT.SEARCH')} type='primary' variant='contained' style={{ width: '120px' }}></MainButton>
            <DropdownButton title='พิมพ์เอกสาร' onChange={(e) => {
              if (e === 'Excel') onDownload()
              if (e === 'PDF') {
                const style = document.createElement("style");
                style.innerHTML = `
                      @media print {
                        @page { size: landscape }
                      }
                    `;
                document.head.appendChild(style);
                window.print();
                document.head.removeChild(style);

              }
            }} options={[{ value: 'Excel', name: ' Excel' }, { value: 'PDF', name: ' PDF' }]} />
          </Grid>
        </Grid>
        <RemunerationTotalDoctor
          startDate={rangeDate[0]}
          endDate={rangeDate[1]}
          data={remunerationClinicFeeData}
          page={remunerationClinicFeePage}
          pageLimit={remunerationClinicFeeLimitPage}
          doctors={doctorsMultiple}
          snType={snType}
        />
        <Box borderBottom={1} width="100%" borderColor="#E5E5E5" paddingBottom="16px" />
        <RemunerationLabReport
          startDate={rangeDate[0]}
          endDate={rangeDate[1]}
          data={remunerationClinicFeeData}
          page={remunerationClinicFeePage}
          pageLimit={remunerationClinicFeeLimitPage}
          snType={snType}
        />
        <Box borderBottom={1} width="100%" borderColor="#E5E5E5" />
        <RemunerationDentistFee
          startDate={rangeDate[0]}
          endDate={rangeDate[1]}
          data={remunerationClinicFeeData}
          page={remunerationClinicFeePage}
          pageLimit={remunerationClinicFeeLimitPage}
          snType={snType}
          doctors={doctorsMultiple}
        />

        <RemunerationTotalIncomeSummary
          startDate={rangeDate[0]}
          endDate={rangeDate[1]}
          data={remunerationClinicFeeData}
          page={remunerationClinicFeePage}
          pageLimit={remunerationClinicFeeLimitPage}
          snType={snType}
        />
      </Box>

      <Box
        id="print-table-container"
        ref={componentRef}
      >
        <Typography fontSize="14px" fontWeight={400}>รายงานค่าตอบแทนทันตแพทย์หัก Clinic Fee</Typography>
        <Box gap="16px" display="flex" flexDirection="row" marginTop="16px">
          <Box display="flex" flexDirection="row" marginBottom="16px">
            <Typography fontSize="9.5px" fontWeight={700}>{t('REPORT.FILTER.BRANCH')}:&nbsp;</Typography>
            {/* <Typography fontSize="9.5px">{_.find(branches, (item) => item.value === branchId)?.name}</Typography> */}
            <Typography fontSize="9.5px">{_.find(branches, (item) => item.branchId === branchId)?.branchName}</Typography>
          </Box>
          {/* <Box display="flex" flexDirection="row" fontSize="9.5px">
            <Typography fontSize="9.5px" fontWeight={700}>{t('REPORT.FILTER.RECEIPT_STATUS')}: </Typography>
          </Box> */}
          <Box display="flex" flexDirection="row" fontSize="9.5px">
            <Typography fontSize="9.5px" fontWeight={700}>{t('REPORT.FILTER.RECEIPT_TYPE')}:&nbsp;</Typography>
            <Typography fontSize="9.5px">{prefix === 'ALL' ? t(`REPORT.FILTER.ALL`) : prefix}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" fontSize="9.5px">
            <Typography fontSize="9.5px" fontWeight={700}>{t('REPORT.FILTER.START')}&nbsp;</Typography>
            <Typography fontSize="9.5px">{rangeDate[0] ? toBuddhistYear(moment(rangeDate[0]), 'DD/MM/YYYY') : '-'}</Typography>
            <Typography fontSize="9.5px" fontWeight={700}>&nbsp;{t('REPORT.FILTER.DATE_END')}&nbsp;</Typography>
            <Typography fontSize="9.5px">{rangeDate[1] ? toBuddhistYear(moment(rangeDate[1]), 'DD/MM/YYYY') : '-'}</Typography>
          </Box>
        </Box>
        {remunerationClinicFeeData?.rows.map((data: any, index: number) => (
          <Box key={index}>
            <table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
              <thead>
                <tr style={{ textAlign: 'center', fontSize: '9.5px', fontWeight: 700 }}>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.#NO')}</th>
                  <th style={{ borderBottom: '1px solid black' }} >{t('REPORT.TABLE.REMUNERATION.BILL_ID')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_CN_NUMBER')}</th>
                  {snType && <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_SN_CODE')}</th>}
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_NAME')}</th>
                  <th style={{ borderBottom: '1px solid black', textAlign: 'left' }}>{t('REPORT.TABLE.REMUNERATION.ITEM')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PAYMENT')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.CASH')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.TRANSFER')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.DEBIT')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.CREDIT')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.ADVANCE')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.NAME_CLINICFEE')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PRICE_CLINICFEE')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.DF_PERCEN')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.DF')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.CREDIT_PERCEN')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.CREDIT_DF')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT_DF')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.NET_DF')}</th>
                </tr>
              </thead>
              <tbody>

                {renderSalaryTable(data?.table).map((table: any, index: number) => (
                  <>
                    <tr>
                      <td colSpan={snType ? 21 : 20} >
                        <div style={{ margin: 0, lineHeight: 1 }}>
                          <span style={{ fontSize: '9.5px', fontWeight: 400, margin: 0 }}>{t('REPORT.FILTER.DOCTOR')}</span>
                          <span style={{ fontSize: '9.5px', fontWeight: 400, margin: 0 }}>
                            {_.get(_.find(doctorsMultiple, { dentistId: Number(data.doctorId) }), 'dentistFullname')}
                          </span>
                        </div>
                        <div style={{ lineHeight: 0.5, marginBottom: 4 }}>
                          <span className="mr-2" style={{ fontSize: '9.5px', fontWeight: 500, margin: 0 }}>{t('MOMENT_AT')}</span>
                          <span style={{ fontSize: '9.5px', fontWeight: 500, margin: 0 }}>{table?.date}</span>
                          <span style={{ fontSize: '9.5px', fontWeight: 500 }}>{`ค่าประกันลงเวร ${numberFormat(table?.data?.[0]?.handInsurance || 0, 0, 0)} บาท`}</span>
                        </div>

                      </td>
                    </tr>
                    {_.map(table.data, (data, index) => (
                      <tr key={index}>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'center', verticalAlign: 'top' }}>{remunerationClinicFeePage * remunerationClinicFeeLimitPage - remunerationClinicFeeLimitPage + index + 1}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'center', verticalAlign: 'top' }}>{data?.billNo || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'center', verticalAlign: 'top' }}>{data?.branchCnNumber || '-'}</td>
                        {snType && <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'center', verticalAlign: 'top' }}>{data?.snCode || '-'}</td>}
                        <td style={{ fontSize: '9.5px', fontWeight: 400, width: '120px', verticalAlign: 'top' }}>{data?.patientFullname || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, verticalAlign: 'top' }}>{data?.list || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountPaid || 0)}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountCash || 0)}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data.amountTransfer) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountDebit) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountCredit) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountTrementRight) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountAdvance) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, verticalAlign: 'top' }}>{data?.clinicFeeName || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.clinicFeePrice) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{data?.dfPercentOriginal || ""}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfPaid) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.creditCost > 0 ? data?.creditCardFee : 0, 2) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.creditCost || 0, 3, 3)}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfLostTreatment) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfNet) || 0}</td>
                      </tr>
                    ))}
                    <tr style={{ border: '1px solid black' }}>
                      <td colSpan={5} style={{ fontSize: '9.5px', fontWeight: 500, borderRight: '1px solid black' }} align='right'>รวม</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'amountPaid'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'amountCash'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'amountTransfer'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'amountDebit'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'amountCredit'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'amountTrementRight'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'amountAdvance'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}></td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'clinicFeePrice'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}></td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'dfPaid'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, (o: any) => o.creditCost > 0 ? o.creditCardFee : 0), 2)}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'creditCost'), 3, 3)}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'dfLostTreatment'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'dfNet'))}</td>
                    </tr>
                  </>
                ))}

              </tbody>
            </table>
          </Box>
        ))}

        <span style={{ fontSize: '12px', fontWeight: 500 }}>Lab Report</span>
        <table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ fontSize: '9.5px', fontWeight: 700 }}>
              <th style={{ borderBottom: '1px solid black', textAlign: 'center' }}>{t('REPORT.TABLE.REMUNERATION.#NO')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.DOCTOR')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_CN_NUMBER')}</th>
              {snType && <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_SN_CODE')}</th>}
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_NAME')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.DESCRIPTION')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.LAB_NAME')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.JOB')}</th>
              <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('LAB Fee')}</th>
              <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('DF LAB Fee')}</th>
            </tr>
          </thead>
          <tbody>
            {remunerationClinicFeeData?.rowLab.length === 0 ?
              <tr>
                <td colSpan={10} style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'center' }}>{t('NOT_FOUND')}</td>
              </tr>
              : <>
                {remunerationClinicFeeData?.rowLab.map((item: any, index: number) => (
                  <>
                    {item?.table.map((data: any, i: number) => (
                      <tr key={i}>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'center', verticalAlign: 'top' }}>{remunerationClinicFeePage * remunerationClinicFeeLimitPage - remunerationClinicFeeLimitPage + index + 1}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, verticalAlign: 'top' }}>{data?.dentist || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'center', verticalAlign: 'top' }}>{data?.branchCnNumber || '-'}</td>
                        {snType && <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'center', verticalAlign: 'top' }}>{data?.snCode || '-'}</td>}
                        <td style={{ fontSize: '9.5px', fontWeight: 400, width: '120px', verticalAlign: 'top' }}>{data?.patientFullname || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, verticalAlign: 'top' }}>{data?.remark || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, verticalAlign: 'top' }}>{data?.lab || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, verticalAlign: 'top' }}>{data?.operativeName || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.labFee || 0)}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfLabFee || 0)}</td>
                      </tr>
                    ))}
                    <tr style={{ border: '1px solid black' }}>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top', borderRight: '1px solid black' }}>{'รวม'}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(item?.table, 'labFee'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(item?.table, 'dfLabFee'))}</td>
                    </tr>
                  </>
                ))}
                {/* {remunerationClinicFeeData?.rowLab[0]?.table.map((data: any, index: number) => (
                  <tr key={index}>
                    <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'center', verticalAlign: 'top' }}>{remunerationClinicFeePage * remunerationClinicFeeLimitPage - remunerationClinicFeeLimitPage + index + 1}</td>
                    <td style={{ fontSize: '9.5px', fontWeight: 400, verticalAlign: 'top' }}>{data?.dentist || '-'}</td>
                    <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'center', verticalAlign: 'top' }}>{data?.branchCnNumber || '-'}</td>
                    {snType && <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'center', verticalAlign: 'top' }}>{data?.snCode || '-'}</td>}
                    <td style={{ fontSize: '9.5px', fontWeight: 400, width: '120px', verticalAlign: 'top' }}>{data?.patientFullname || '-'}</td>
                    <td style={{ fontSize: '9.5px', fontWeight: 400, verticalAlign: 'top' }}>{data?.remark || '-'}</td>
                    <td style={{ fontSize: '9.5px', fontWeight: 400, verticalAlign: 'top' }}>{data?.lab || '-'}</td>
                    <td style={{ fontSize: '9.5px', fontWeight: 400, verticalAlign: 'top' }}>{data?.operativeName || '-'}</td>
                    <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.labFee || 0)}</td>
                    <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfLabFee || 0)}</td>
                  </tr>
                ))}
                <tr style={{ border: '1px solid black' }}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top', borderRight: '1px solid black' }}>{'รวม'}</td>
                  <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowLab[0]?.table, 'labFee'))}</td>
                  <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowLab[0]?.table, 'dfLabFee'))}</td>
                </tr> */}
              </>
            }

          </tbody>
        </table>

        <span style={{ fontSize: '12px', fontWeight: 500 }}>Dentist Fee</span>
        <table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ fontSize: '9.5px', fontWeight: 700 }}>
              <th style={{ borderBottom: '1px solid black', textAlign: 'center' }}>{t('REPORT.TABLE.REMUNERATION.#NO')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.DOCTOR')}</th>
              <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.NET_PRICE')}</th>
              <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('ค่าประกันมือ')}</th>
              <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.SUM_PAID')}</th>
              <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.DF')}</th>
              <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.CREDIT_DF')}</th>
              <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT_DF')}</th>
              <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.SUM_DF')}</th>
              <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('DF LAB')}</th>
              <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('Net DF')}</th>
            </tr>
          </thead>
          <tbody>
            {remunerationClinicFeeData?.rowDf.map((data: any, index: number) => (
              <tr key={index}>
                <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'center', verticalAlign: 'top' }}>{remunerationClinicFeePage * remunerationClinicFeeLimitPage - remunerationClinicFeeLimitPage + index + 1}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 400, verticalAlign: 'top' }}>{_.get(_.find(doctorsMultiple, { dentistId: Number(data?.doctorId) }), 'dentistFullname') || '-'}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.totalAmount || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.handInsurance || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountPaid || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountDf || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfCredit || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfLostTreatment || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfLostCreditAndTreatment || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfLabFee || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfNet || 0)}</td>
              </tr>
            ))}
            <tr style={{ border: '1px solid black' }}>
              <td></td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top', borderRight: '1px solid black' }}>{'รวม'}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'totalAmount'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'handInsurance'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'amountPaid'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'amountDf'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'dfCredit'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'dfLostTreatment'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'dfLostCreditAndTreatment'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'dfLabFee'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'dfNet'))}</td>
            </tr>
          </tbody>
        </table>

        <Box className='table-2'>
          <span style={{ fontSize: '12px', fontWeight: 500 }}>Total Income Summary</span>
          <table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ fontSize: '9.5px', fontWeight: 700 }}>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.INCOME')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.NET_CLINIC')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.DF_NET')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.DENTIST_FREE')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.DF_LAB')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.CASH')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.TRANSFER')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.DEBIT')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.CREDIT')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.ADVANCE')}</th>
              </tr>
            </thead>
            <tbody>
              {renderTotal()}
            </tbody>
          </table>
        </Box>
      </Box>

      <Box
        sx={{ visibility: 'hidden', position: 'absolute', top: 0, left: 0 }}
        ref={componentRefExcel}
      >
        <Typography fontSize="14px" fontWeight={400}>รายงานค่าตอบแทนทันตแพทย์หัก Clinic Fee</Typography>

        <table>
          <tr>
            <td style={{ fontSize: 10, fontWeight: 800 }}>{t('REPORT.FILTER.BRANCH')} :</td>
            {/* <td style={{ fontSize: 10, fontWeight: 800 }}>{_.find(branches, (item) => item.value === branchId)?.name}</td> */}
            <td style={{ fontSize: 10, fontWeight: 800 }}>{_.find(branches, (item) => item.branchId === branchId)?.branchName}</td>
            <td style={{ fontSize: 10, fontWeight: 800 }}>{t('REPORT.FILTER.RECEIPT_TYPE')} :</td>
            <td style={{ fontSize: 10, fontWeight: 800 }}>{prefix === 'ALL' ? t(`REPORT.FILTER.ALL`) : prefix}</td>
            <td style={{ fontSize: 10, fontWeight: 800 }}>{t('REPORT.FILTER.DATE_START')} :</td>
            <td style={{ fontSize: 10, fontWeight: 800 }}>{rangeDate[0] ? toBuddhistYear(moment(rangeDate[0]), 'DD/MM/YYYY') : '-'}</td>
            <td style={{ fontSize: 10, fontWeight: 800 }}>{t('REPORT.FILTER.DATE_END')} :</td>
            <td style={{ fontSize: 10, fontWeight: 800 }}>{rangeDate[1] ? toBuddhistYear(moment(rangeDate[1]), 'DD/MM/YYYY') : '-'}</td>
          </tr>
        </table>
        <br />
        {remunerationClinicFeeData?.rows.map((data: any, index: number) => (
          <Box key={index}>
            <Box display="flex" flexDirection='row' alignItems="center" gap="8px" marginTop="16px" fontSize="10px">
              <Typography fontSize="10px">{t('REPORT.FILTER.DOCTOR')}</Typography>
              {_.get(_.find(doctorsMultiple, { dentistId: Number(data.doctorId) }), 'dentistFullname')}
              {data.doctor}
            </Box>

            <table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
              <thead>
                <tr style={{ textAlign: 'center', fontSize: '9.5px', fontWeight: 700 }}>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.#NO')}</th>
                  <th style={{ borderBottom: '1px solid black' }} >{t('REPORT.TABLE.REMUNERATION.BILL_ID')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_CN_NUMBER')}</th>
                  {snType && <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_SN_CODE')}</th>}
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_NAME')}</th>
                  <th style={{ borderBottom: '1px solid black', textAlign: 'left' }}>{t('REPORT.TABLE.REMUNERATION.ITEM')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PAYMENT')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.CASH')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.TRANSFER')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.DEBIT')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.CREDIT')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.ADVANCE')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.NAME_CLINICFEE')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PRICE_CLINICFEE')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.DF_PERCEN')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.DF')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.CREDIT_PERCEN')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.CREDIT_DF')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT_DF')}</th>
                  <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.NET_DF')}</th>
                </tr>
              </thead>
              <tbody>

                {renderSalaryTable(data?.table).map((table: any, index: number) => (
                  <>
                    <tr>
                      <td colSpan={snType ? 21 : 20} >
                        <span className="mr-2" style={{ fontSize: '9.5px', fontWeight: 500 }}>{t('MOMENT_AT')}</span>
                        <span style={{ fontSize: '9.5px', fontWeight: 500 }}>{toBuddhistYear(moment(table?.date), 'DD/MM/YYYY')}</span>
                        <span style={{ fontSize: '9.5px', fontWeight: 500 }}>{`ค่าประกันลงเวร ${numberFormat(table?.data?.[0]?.handInsurance || 0, 0, 0)} บาท`}</span>
                      </td>
                    </tr>
                    {_.map(table.data, (data, index) => (
                      <tr key={index}>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'center', verticalAlign: 'top' }}>{remunerationClinicFeePage * remunerationClinicFeeLimitPage - remunerationClinicFeeLimitPage + index + 1}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'center', verticalAlign: 'top' }}>{data?.billNo || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'center', verticalAlign: 'top' }}>{data?.branchCnNumber || '-'}</td>
                        {snType && <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'center', verticalAlign: 'top' }}>{data?.snCode || '-'}</td>}
                        <td style={{ fontSize: '9.5px', fontWeight: 500, width: '120px', verticalAlign: 'top' }}>{data?.patientFullname || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, verticalAlign: 'top' }}>{data?.list || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountPaid || 0)}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountCash || 0)}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data.amountTransfer) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountDebit) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountCredit) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountTrementRight) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountAdvance) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, verticalAlign: 'top' }}>{data?.clinicFeeName || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.clinicFeePrice) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{data?.dfPercentOriginal || ''}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfPaid) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.creditCost > 0 ? data?.creditCardFee : 0, 2) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.creditCost || 0, 3, 3)}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfLostTreatment) || 0}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfNet) || 0}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={5} style={{ fontSize: '9.5px', fontWeight: 500 }} align='right'>รวม</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'amountPaid'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'amountCash'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'amountTransfer'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'amountDebit'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'amountCredit'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'amountTrementRight'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'amountAdvance'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}></td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'clinicFeePrice'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}></td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'dfPaid'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, (o: any) => o.creditCost > 0 ? o.creditCardFee : 0), 2)}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'creditCost'), 3, 3)}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'dfLostTreatment'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'right', verticalAlign: 'top' }}>{numberFormat(_.sumBy(table?.data, 'dfNet'))}</td>
                    </tr>
                  </>
                ))}

              </tbody>
            </table>
          </Box>
        ))}
        <br />

        <span style={{ fontSize: '10px' }}>Lab Report</span>
        <table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ fontSize: '9.5px', fontWeight: 700 }}>
              <th style={{ borderBottom: '1px solid black', textAlign: 'center' }}>{t('REPORT.TABLE.REMUNERATION.#NO')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.DOCTOR')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_CN_NUMBER')}</th>
              {snType && <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_SN_CODE')}</th>}
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_NAME')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.DESCRIPTION')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.LAB_NAME')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.JOB')}</th>
              <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('LAB Fee')}</th>
              <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('DF LAB Fee')}</th>
            </tr>
          </thead>
          <tbody>
            {remunerationClinicFeeData?.rowLab.length === 0 ?
              <tr>
                <td colSpan={10} style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'center' }}>{t('NOT_FOUND')}</td>
              </tr>
              : <>
                {remunerationClinicFeeData?.rowLab.map((item: any, index: number) => (
                  <>
                    {item?.table.map((data: any, i: number) => (
                      <tr key={i}>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'center', verticalAlign: 'top' }}>{remunerationClinicFeePage * remunerationClinicFeeLimitPage - remunerationClinicFeeLimitPage + index + 1}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, verticalAlign: 'top' }}>{data?.dentist || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'center', verticalAlign: 'top' }}>{data?.branchCnNumber || '-'}</td>
                        {snType && <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'center', verticalAlign: 'top' }}>{data?.snCode || '-'}</td>}
                        <td style={{ fontSize: '9.5px', fontWeight: 400, width: '120px', verticalAlign: 'top' }}>{data?.patientFullname || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, verticalAlign: 'top' }}>{data?.remark || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, verticalAlign: 'top' }}>{data?.lab || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, verticalAlign: 'top' }}>{data?.operativeName || '-'}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.labFee || 0)}</td>
                        <td style={{ fontSize: '9.5px', fontWeight: 400, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfLabFee || 0)}</td>
                      </tr>
                    ))}
                    <tr style={{ border: '1px solid black' }}>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top', borderRight: '1px solid black' }}>{'รวม'}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(item?.table, 'labFee'))}</td>
                      <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(item?.table, 'dfLabFee'))}</td>
                    </tr>
                  </>
                ))}
              </>}
          </tbody>
        </table>
        <br />

        <span style={{ fontSize: '10px' }}>Dentist Fee</span>
        <table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ fontSize: '9.5px', fontWeight: 700 }}>
              <th style={{ borderBottom: '1px solid black', textAlign: 'center' }}>{t('REPORT.TABLE.REMUNERATION.#NO')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.DOCTOR')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.NET_PRICE')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('ค่าประกันมือ')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.SUM_PAID')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.DF')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.CREDIT_DF')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT_DF')}</th>
              <th style={{ borderBottom: '1px solid black' }}>{t('REPORT.TABLE.REMUNERATION.SUM_DF')}</th>
              <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('DF LAB')}</th>
              <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('Net DF')}</th>
            </tr>
          </thead>
          <tbody>
            {remunerationClinicFeeData?.rowDf.map((data: any, index: number) => (
              <tr key={index}>
                <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'center', verticalAlign: 'top' }}>{remunerationClinicFeePage * remunerationClinicFeeLimitPage - remunerationClinicFeeLimitPage + index + 1}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 500, verticalAlign: 'top' }}>{_.get(_.find(doctorsMultiple, { dentistId: Number(data?.doctorId) }), 'dentistFullname') || '-'}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.totalAmount || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.handInsurance || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountPaid || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.amountDf || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfCredit || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfLostTreatment || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfLostCreditAndTreatment || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfLabFee || 0)}</td>
                <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(data?.dfNet || 0)}</td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{'รวม'}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'totalAmount'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'handInsurance'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'amountPaid'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'amountDf'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'dfCredit'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'dfLostTreatment'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'dfLostCreditAndTreatment'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'dfLabFee'))}</td>
              <td style={{ fontSize: '9.5px', fontWeight: 500, textAlign: 'end', verticalAlign: 'top' }}>{numberFormat(_.sumBy(remunerationClinicFeeData?.rowDf, 'dfNet'))}</td>
            </tr>
          </tbody>
        </table>
        <br />

        <Box className='table-2'>
          <span style={{ fontSize: '10px' }}>Total Income Summary</span>
          <table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ fontSize: '9.5px', fontWeight: 700 }}>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.INCOME')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.NET_CLINIC')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.DF_NET')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.DENTIST_FREE')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.DF_LAB')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.CASH')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.TRANSFER')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.DEBIT')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.CREDIT')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT')}</th>
                <th style={{ borderBottom: '1px solid black', textAlign: 'end' }}>{t('REPORT.TABLE.REMUNERATION.ADVANCE')}</th>
              </tr>
            </thead>
            <tbody>
              {renderTotal()}
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
    // <div>
    //   <Card className={'border-0 h-100'}>
    //     <HeaderCard text={t(`REPORT.REPORT_NAME.REMUNERATION_CLINIC_FEE`)} />
    //     <Card.Body>
    //       <Card.Title className={'mb-0'}>
    //         <Row className="mx-0">
    //           <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
    //             <FilterSelect
    //               onchange={(e) => {
    //                 setBranchId(e.target.value)
    //                 setRemunerationClinicFeePage(1)
    //               }}
    //               renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
    //               label={''}
    //               selectId="select-branch"
    //               labelId="label-branch"
    //               value={branchId}
    //               options={_.map(branches, (b: BranchInterface) => {
    //                 return (
    //                   <MenuItem key={b.branchId} value={b.branchId}>
    //                     {b.branchName || b.branchNameEn}
    //                   </MenuItem>
    //                 )
    //               })}
    //               formControlStyle={{ maxWidth: 'unset !important' }}
    //               classesOption="style-select-doctors"
    //             />
    //           </Col>
    //           <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
    //             <FilterSelect
    //               onchange={(e) => {
    //                 setPrefix(e.target.value)
    //                 setRemunerationClinicFeePage(1)
    //                 setRemunerationClinicFeeTypePage(1)
    //               }}
    //               renderValue={() => `${t('REPORT.FILTER.RECEIPT_TYPE')}: ${prefix === 'ALL' ? t(`REPORT.FILTER.ALL`) : prefix}`}
    //               label={''}
    //               selectId="select-prefix"
    //               labelId="label-prefix"
    //               value={prefix}
    //               options={[
    //                 <MenuItem key="0" value="ALL">
    //                   {t('REPORT.FILTER.ALL')}
    //                 </MenuItem>,
    //                 _.map(receiptPrefix, (data: BillInterface) => {
    //                   return (
    //                     <MenuItem key={data.billPrefixId} value={data.prefix}>
    //                       {data.prefix}
    //                     </MenuItem>
    //                   )
    //                 })
    //               ]}
    //               formControlStyle={{ maxWidth: 'unset !important' }}
    //               classesOption="style-select-doctors"
    //             />
    //           </Col>

    //           <Col
    //             sm={6}
    //             md={4}
    //             xl={3}
    //             className={'pt-2 px-1'}
    //             onClick={() => {
    //               setCheckDF(!checkDF)
    //               setRemunerationClinicFeePage(1)
    //             }}
    //           >
    //             <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
    //               <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
    //                 <AutocompleteSelect
    //                   open={openFilterDoctors}
    //                   multiple
    //                   labelId="label-doctors"
    //                   options={['ALL', ...doctors, 'CLEAR']}
    //                   getOptionLabel={(option) => _.find(doctors, { userId: option.userId })?.fullname || '-'}
    //                   renderOption={(props, option) => {
    //                     if (option === 'ALL') {
    //                       return (
    //                         <ListFilterDoctors {...props} key={option} component="li" value={option}>
    //                           <InputCheckbox
    //                             label={t('REPORT.REPORT_GROUP.ALL')}
    //                             checked={_.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)}
    //                             indeterminate={_.size(selectDoctors) > 1 && _.size(selectDoctors.filter((item) => typeof item === 'number')) !== _.size(doctors)}
    //                             className={'pe-none'}
    //                           />
    //                         </ListFilterDoctors>
    //                       )
    //                     } else if (option === 'CLEAR') {
    //                       return (
    //                         <Box {...props} key={option} component="li" value={option} sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
    //                           <SpanClearFilter className="pe-none">{t('REPORT.FILTER.CLEAR_FILTER')}</SpanClearFilter>
    //                         </Box>
    //                       )
    //                     } else {
    //                       return (
    //                         <ListFilterDoctors {...props} key={option.userId} component="li" value={option.userId}>
    //                           <InputCheckbox label={option.fullname} checked={_.includes(selectDoctors, option.userId)} className={'pe-none'} />
    //                         </ListFilterDoctors>
    //                       )
    //                     }
    //                   }}
    //                   renderTags={(tagValue, getTagProps) => {
    //                     return (
    //                       <>
    //                         <span className="pr-1">{t('USER_DOCTOR')}:</span>
    //                         {!openFilterDoctors &&
    //                           ((_.size(tagValue.filter((item: any) => typeof item === 'number')) === _.size(doctors) && <span>{t('REPORT.REPORT_GROUP.ALL')}</span>) || (
    //                             <Box className="text-ellipsis">
    //                               {_.map(tagValue, (option: any, index: number) => {
    //                                 return (
    //                                   <span>
    //                                     {_.find(doctors, { userId: option })?.fullname}
    //                                     {index !== _.findLastIndex(tagValue) && option && `,`}
    //                                   </span>
    //                                 )
    //                               })}
    //                             </Box>
    //                           ))}
    //                       </>
    //                     )
    //                   }}
    //                   onchange={(e, value) => handleFilterDoctors(e?.target?.value || e?.target?.attributes?.value?.value)}
    //                   noOptionsText={t('NOT_FOUND')}
    //                   filterOptions={(option) => 'ALL' + option.fullname + 'CLEAR'}
    //                   value={selectDoctors}
    //                   height={32}
    //                   disableClearable
    //                 />
    //               </CustomFilterDoctor>
    //             </ClickAwayListener>
    //           </Col>
    //         </Row>

    //         <Row className="mx-0">
    //           <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
    //             <UseStyled.HeightAutocompleteSelect>
    //               <AutocompleteSelect
    //                 key={'lab-group'}
    //                 labelId="lab-group"
    //                 inputLabel={t('REPORT.FILTER.LAB_GROUP')}
    //                 noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
    //                 options={labGroup}
    //                 getOptionLabel={(option: any) => option.labGroupName}
    //                 renderOption={(props, option: any) => (
    //                   <Box key={option} component="li" {...props}>
    //                     {option.labGroupName}
    //                   </Box>
    //                 )}
    //                 onchange={(e, value: any) => {
    //                   if (value) {
    //                     setLabGroupId(value.labGroupId)
    //                   }
    //                 }}
    //                 value={_.find(labGroup, (val: any) => Number(val.labGroupId) === Number(labGroupId)) || null}
    //                 disableClearable
    //               />
    //             </UseStyled.HeightAutocompleteSelect>
    //           </Col>

    //           <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
    //             <UseStyled.HeightAutocompleteSelect>
    //               <AutocompleteSelect
    //                 key={'lab-company'}
    //                 labelId="lab-company"
    //                 inputLabel={t('REPORT.FILTER.LAB_COMPANY')}
    //                 noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
    //                 options={labCompany}
    //                 getOptionLabel={(option: any) => option.labCompanyName}
    //                 renderOption={(props, option: any) => (
    //                   <Box key={option} component="li" {...props}>
    //                     {option.labCompanyName}
    //                   </Box>
    //                 )}
    //                 onchange={(e, value: any) => {
    //                   if (value) {
    //                     setLabCompanyId(value.labCompanyId)
    //                   }
    //                 }}
    //                 value={_.find(labCompany, (val: any) => Number(val.labCompanyId) === Number(labCompanyId)) || null}
    //                 disableClearable
    //               />
    //             </UseStyled.HeightAutocompleteSelect>
    //           </Col>

    //           {/* <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
    //             <FilterSelect
    //               onchange={(e) => {
    //                 setOptionDf(e.target.value)
    //                 setRemunerationClinicFeePage(1)
    //               }}
    //               renderValue={() => `${t('REPORT.FILTER.OPTION_DF')}: ${optionDf === '0' ? t('REPORT.FILTER.NO_CAL_DF') : t(`REPORT.FILTER.DF${optionDf}`)}`}
    //               label={''}
    //               selectId="select-optionDf"
    //               labelId="label-optionDf"
    //               value={optionDf}
    //               options={[
    //                 <MenuItem key="0" value="0">
    //                   {t('REPORT.FILTER.NO_CAL_DF')}
    //                 </MenuItem>,
    //                 new Array(3).fill(null).map((item: any, index: number) => {
    //                   return (
    //                     <MenuItem key={index + 1} value={`${index + 1}`}>
    //                       <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    //                         <Box>{t(`REPORT.FILTER.DF_${index + 1}`)}</Box>
    //                         <Box>
    //                           <ButtonCustom
    //                             textButton={t('REPORT.FORMULA')}
    //                             onClick={(event) => {
    //                               event.stopPropagation()
    //                               setImageFormula(optionFormula[`DF_${index + 1}`] || '')
    //                             }}
    //                           />
    //                         </Box>
    //                       </Box>
    //                     </MenuItem>
    //                   )
    //                 })
    //               ]}
    //               formControlStyle={{ maxWidth: 'unset !important' }}
    //               classesOption="style-select-doctors"
    //             />
    //           </Col> */}

    //           <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
    //             <UseStyled.DropdownDocument>
    //               <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
    //                 {t('REGISTER.BUTTON.PRINT_DOCUMENT')}
    //               </Dropdown.Toggle>
    //               <Dropdown.Menu>
    //                 <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
    //                 <FucntionPrint
    //                   content={() => componentRef.current}
    //                   removeIfram
    //                   trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
    //                   style={`@page { size: 1122.519685px 800.7007874px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
    //                 />
    //               </Dropdown.Menu>
    //             </UseStyled.DropdownDocument>
    //           </Col>
    //         </Row>
    //       </Card.Title>
    //       <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
    //         <RemunerationClinicFeeList
    //           page={remunerationClinicFeePage}
    //           pageLimit={remunerationClinicFeeLimitPage}
    //           rowCount={rowCount}
    //           sortBy={sortBy}
    //           sortType={sortType}
    //           data={remunerationClinicFeeData}
    //           doctors={doctorsMultiple}
    //           onRequestSort={onRequestSort}
    //           handleChangeRowsPerPage={handleChangeRowsPerPage}
    //           handleChangePage={handleChangePage}
    //           filter={filter}
    //           filterDateStart={rangeDate[0]}
    //           filterDateEnd={rangeDate[1]}
    //           componentRef={componentRef}
    //         />
    //       </Box>
    //     </Card.Body>
    //   </Card>
    //   <Loading show={loading} type="fullLoading" />
    //   {popFormula && <ReportFormulaDF img={imageFormula} setImg={setImageFormula} />}
    // </div>
  )
}

import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const OpNumberPrefixReceipt = () => {
    const { t } = useTranslation()
    const optionNumberPrefixReceipt = [
        {
            name: t('BRANCH.NUMBER_PREFIX_RECEIPT.CN.TITLE'), value: "CN",
            component: <NumberPrefixReceipt value='CN' />,
        },
        {
            name: t('BRANCH.NUMBER_PREFIX_RECEIPT.PT.TITLE'), value: "PT",
            component: <NumberPrefixReceipt value='PT' />,
        },
        {
            name: t('BRANCH.NUMBER_PREFIX_RECEIPT.SN.TITLE'), value: "SN",
            component: <NumberPrefixReceipt value='SN' />,
        }
    ]
    return (optionNumberPrefixReceipt)
}

export default OpNumberPrefixReceipt

interface NumberPrefixReceiptProps {
    value: 'CN' | 'PT' | 'SN'
}
const NumberPrefixReceipt = (props: NumberPrefixReceiptProps) => {
    const { value } = props
    const { t } = useTranslation()
    return (
        <Box display='flex' flexDirection='column' gap='16px' height='100%'>
            <Box marginBottom='16px'>
                <Typography fontSize={'20px'} fontWeight={'500'} marginBottom='8px'>
                    {t('BRANCH.NUMBER_PREFIX_RECEIPT.TITLE')}
                </Typography>
            </Box>

            <Box>
                <Typography fontSize={'20px'} fontWeight={'500'} marginBottom='8px'>
                    {t(`BRANCH.NUMBER_PREFIX_RECEIPT.${value}.TITLE`)}
                </Typography>
                <Typography fontWeight={'400'} paddingLeft='20px' color='#5A5A5A'>
                    {t(`BRANCH.NUMBER_PREFIX_RECEIPT.${value}.TITLE_SUB`)}
                </Typography>
            </Box>

            <Box>
                <Typography fontSize={'20px'} fontWeight={'500'} marginBottom='8px'>
                    {t(`BRANCH.NUMBER_PREFIX_RECEIPT.EXAMPLE`)}
                </Typography>
                <Box marginTop="8px" padding="16px" bgcolor={'#ffffff'}>
                    <Typography fontWeight={'400'} paddingLeft='20px' color='#5A5A5A'>
                        {t(`BRANCH.NUMBER_PREFIX_RECEIPT.${value}.EXAMPLE`)}
                    </Typography>
                </Box>

            </Box>


        </Box>
    )
}
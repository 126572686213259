import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import THERMAL_FORMAT_1 from 'assets/branch/receipt/THERMAL_FORMAT_1.png'
import THERMAL_FORMAT_2 from 'assets/branch/receipt/THERMAL_FORMAT_2.png'
import THERMAL_FORMAT_3 from 'assets/branch/receipt/THERMAL_FORMAT_3.png'

const OpFormatThermal = () => {
  const { t } = useTranslation()

  const optionFormatThermal = [
    {
      name: t('BRANCH.TAB.FORMAT_1'), value: "FORMAT_1",
      component: <FormatThermalBox textFormat={t('BRANCH.TAB.FORMAT_1')} images={[THERMAL_FORMAT_1]} />,
    },
    {
      name: t('BRANCH.TAB.FORMAT_2'), value: "FORMAT_2",
      component: <FormatThermalBox textFormat={t('BRANCH.TAB.FORMAT_2')} images={[THERMAL_FORMAT_2]} />,
    },
    {
      name: t('BRANCH.TAB.FORMAT_3'), value: "FORMAT_3",
      component: <FormatThermalBox textFormat={t('BRANCH.TAB.FORMAT_3')} images={[THERMAL_FORMAT_3]} />,
    },
  ]

  return optionFormatThermal
}

export default OpFormatThermal

interface FormatThermalBoxProps {
  textFormat: string
  images: string[]
}

const FormatThermalBox = (props: FormatThermalBoxProps) => {
  const { textFormat, images } = props
  const { t } = useTranslation()
  return (
    <Box display='flex' flexDirection='column' gap='16px' height='100%'>
      <Box >
        <Typography fontSize={'20px'} fontWeight={'500'}>
          {t('BRANCH.TAB.FORMAT_BILL')}
        </Typography>
      </Box>

      <Box >
        <Box marginBottom='16px'>
          <Typography fontWeight={'500'} marginBottom='8px'>
            {textFormat}
          </Typography>
        </Box>

        <Box display='flex' alignItems={'center'} gap={'16px'} flexDirection={'column'} >
          {images.map((item, index) => (
            <Box key={index} textAlign='center' sx={{ img: { width: '60%' } }}>
              <img src={item} alt="" />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
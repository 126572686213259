import { Box, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import A5_ORI from 'assets/branch/receipt/A5_ORI.png'
import A5_COPY from 'assets/branch/receipt/A5_COPY.png'
import A5_EN_ORI from 'assets/branch/receipt/A5_EN_ORI.png'
import A5_EN_COPY from 'assets/branch/receipt/A5_EN_COPY.png'
import A5_LANDSCAPE_ORI from 'assets/branch/receipt/A5_LANDSCAPE_ORI.png'
import A5_LANDSCAPE_COPY from 'assets/branch/receipt/A5_LANDSCAPE_COPY.png'
import A5_LANDSCAPE_ORI_DF from 'assets/branch/receipt/A5_LANDSCAPE_ORI_DF.png'
import A5_LANDSCAPE_COPY_DF from 'assets/branch/receipt/A5_LANDSCAPE_COPY_DF.png'
import A5_LANDSCAPE_ORI_FIX_DF from 'assets/branch/receipt/A5_LANDSCAPE_ORI_FIX_DF.png'
import A5_LANDSCAPE_COPY_FIX_DF from 'assets/branch/receipt/A5_LANDSCAPE_COPY_FIX_DF.png'
import A4_ORI from 'assets/branch/receipt/A4_ORI.png'
import A4_COPY from 'assets/branch/receipt/A4_COPY.png'
import THERMAL from 'assets/branch/receipt/THERMAL_FORMAT_1.png'

import THERMAL_FORMAT_1 from 'assets/branch/receipt/THERMAL_FORMAT_1.png'
import THERMAL_FORMAT_2 from 'assets/branch/receipt/THERMAL_FORMAT_2.png'
import THERMAL_FORMAT_3 from 'assets/branch/receipt/THERMAL_FORMAT_3.png'

type OpReceiptDefaultProps = {
    thermalFormat: string
}

const OpReceiptDefault = (props: OpReceiptDefaultProps) => {
    const { t } = useTranslation()
    const { thermalFormat } = props

    const renderImageThermal = useCallback(() => {
        if (thermalFormat === "FORMAT_2") {
            return [THERMAL_FORMAT_2]
        } else if (thermalFormat === "FORMAT_3") {
            return [THERMAL_FORMAT_3]
        } else {
            return [THERMAL_FORMAT_1]
        }
    }, [thermalFormat])

    const optionDefaultReceipt = [
        {
            name: t('BRANCH.TAB.BILL_A5'), value: "A5",
            component: <ReceiptBox title={t('BRANCH.TAB.BILL_A5')} images={[A5_ORI, A5_COPY]} />,
        },
        {
            name: t('PAYMENT_QUEUE.BILL_A5_ORI'), value: "A5_ORI",
            component: <ReceiptBox title={t('PAYMENT_QUEUE.BILL_A5_ORI')} images={[A5_ORI]} />
        },
        {
            name: t('PAYMENT_QUEUE.BILL_A5_COPY'), value: "A5_COPY",
            component: <ReceiptBox title={t('PAYMENT_QUEUE.BILL_A5_COPY')} images={[A5_COPY]} />
        },
        {
            name: t('PAYMENT_QUEUE.BILL_A5_EN'), value: "A5_EN",
            component: <ReceiptBox title={t('PAYMENT_QUEUE.BILL_A5_EN')} images={[A5_EN_ORI, A5_EN_COPY]} />,
        },
        {
            name: t('PAYMENT_QUEUE.BILL_A5_EN_ORI'), value: "A5_ORI_EN",
            component: <ReceiptBox title={t('PAYMENT_QUEUE.BILL_A5_EN_ORI')} images={[A5_EN_ORI]} />
        },
        {
            name: t('PAYMENT_QUEUE.BILL_A5_EN_COPY'), value: "A5_COPY_EN",
            component: <ReceiptBox title={t('PAYMENT_QUEUE.BILL_A5_EN_COPY')} images={[A5_EN_COPY]} />
        },
        {
            name: t('PAYMENT_QUEUE.BILL_A5_LANDSCAPE'), value: "A5_LANDSCAPE_NORMAL",
            component: <ReceiptBox title={t('PAYMENT_QUEUE.BILL_A5_LANDSCAPE')} images={[A5_LANDSCAPE_ORI, A5_LANDSCAPE_COPY]} />,
        },
        {
            name: t('PAYMENT_QUEUE.BILL_A5_LANDSCAPE_ORI'), value: "A5_LANDSCAPE_ORI_NORMAL",
            component: <ReceiptBox title={t('PAYMENT_QUEUE.BILL_A5_LANDSCAPE_ORI')} images={[A5_LANDSCAPE_ORI]} />
        },
        {
            name: t('PAYMENT_QUEUE.BILL_A5_LANDSCAPE_COPY'), value: "A5_LANDSCAPE_COPY_NORMAL",
            component: <ReceiptBox title={t('PAYMENT_QUEUE.BILL_A5_LANDSCAPE_COPY')} images={[A5_LANDSCAPE_COPY]} />
        },
        {
            name: t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_DF'), value: "A5_LANDSCAPE",
            component:
                <ReceiptBox
                    title={t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_DF')}
                    detail={t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_DF_DETAIL')}
                    images={[A5_LANDSCAPE_ORI_DF, A5_LANDSCAPE_COPY_DF]}
                />,
        },
        {
            name: t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_ORI_DF'), value: "A5_LANDSCAPE_ORI",
            component:
                <ReceiptBox
                    title={t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_ORI_DF')}
                    detail={t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_DF_DETAIL')}
                    images={[A5_LANDSCAPE_ORI_DF]}
                />
        },
        {
            name: t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_COPY_DF'), value: "A5_LANDSCAPE_COPY",
            component:
                <ReceiptBox
                    title={t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_COPY_DF')}
                    detail={t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_DF_DETAIL')}
                    images={[A5_LANDSCAPE_COPY_DF]}
                />
        },
        {
            name: t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_FIX_DF'), value: "A5_LANDSCAPE_DF",
            component:
                <ReceiptBox
                    title={t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_FIX_DF')}
                    detail={t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_FIX_DF_DETAIL')}
                    images={[A5_LANDSCAPE_ORI_FIX_DF, A5_LANDSCAPE_COPY_FIX_DF]}
                />,
        },
        {
            name: t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_ORI_FIX_DF'), value: "A5_LANDSCAPE_DF_ORI",
            component:
                <ReceiptBox
                    title={t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_ORI_FIX_DF')}
                    detail={t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_FIX_DF_DETAIL')}
                    images={[A5_LANDSCAPE_ORI_FIX_DF]}
                />
        },
        {
            name: t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_COPY_FIX_DF'), value: "A5_LANDSCAPE_DF_COPY",
            component:
                <ReceiptBox
                    title={t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_COPY_FIX_DF')}
                    detail={t('BRANCH.RECEIPT_DEFAULT.A5_LANDSCAPE_FIX_DF_DETAIL')}
                    images={[A5_LANDSCAPE_COPY_FIX_DF]}
                />
        },
        {
            name: t('BRANCH.TAB.BILL_A4'), value: "A4",
            component: <ReceiptBox title={t('BRANCH.TAB.BILL_A4')} images={[A4_ORI, A4_COPY]} />,
        },
        {
            name: t('PAYMENT_QUEUE.BILL_A4_ORI'), value: "A4_ORI",
            component: <ReceiptBox title={t('PAYMENT_QUEUE.BILL_A4_ORI')} images={[A4_ORI]} />
        },
        {
            name: t('PAYMENT_QUEUE.BILL_A4_COPY'), value: "A4_COPY",
            component: <ReceiptBox title={t('PAYMENT_QUEUE.BILL_A4_COPY')} images={[A4_COPY]} />
        },
        {
            name: t('PAYMENT_QUEUE.BILL_THERMAL'), value: "THERMAL",
            component: <ReceiptBox title={t('PAYMENT_QUEUE.BILL_THERMAL')} images={renderImageThermal()} widthImage={'60%'} />
        },
    ]

    return optionDefaultReceipt
}

export default OpReceiptDefault

interface ReceiptBoxProps {
    title: string
    detail?: string
    images: string[]
    widthImage?: string
}

const ReceiptBox = (props: ReceiptBoxProps) => {
    const { title, images, detail, widthImage } = props
    const { t } = useTranslation()
    return (
        <Box display='flex' flexDirection='column' gap='16px' height='100%'>
            <Box >
                <Typography fontSize={'20px'} fontWeight={'500'} marginBottom='8px'>
                    {t('BRANCH.RECEIPT_DEFAULT.TITLE')}
                </Typography>
                <Typography fontWeight={'400'} paddingLeft='20px' color='#5A5A5A'>
                    {t('BRANCH.RECEIPT_DEFAULT.TITLE_SUB')}
                </Typography>
            </Box>

            <Box>
                <Box marginBottom='16px'>
                    <Typography fontWeight={'500'} marginBottom='8px'>
                        {title}
                    </Typography>
                    {
                        detail && (
                            <Typography fontWeight={'400'} paddingLeft='20px' color='#5A5A5A'>
                                {detail}
                            </Typography>
                        )
                    }
                </Box>

                <Box display='flex' alignItems={'center'} gap={'16px'} flexDirection={'column'} >
                    {images.map((item, index) => (
                        <Box key={index} textAlign='center' sx={{ img: { width: widthImage || '90%' } }}>
                            <img src={item} alt="" />
                        </Box>
                    ))}
                </Box>
            </Box>

        </Box>
    )
}
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const OpAddressReciept = () => {
    const { t } = useTranslation()
    const optionAddressReciept = [
        {
            name: t('BRANCH.ADDRESS_RECEIPT.OP_MAIN'), value: false,
            component: <AddressReciept address='MAIN' />,
        },
        {
            name: t('BRANCH.ADDRESS_RECEIPT.OP_BRANCH'), value: true,
            component: <AddressReciept address='BRANCH' />,
        }
    ]
    return (optionAddressReciept)
}

export default OpAddressReciept

interface AddressRecieptProps {
    address: 'MAIN' | 'BRANCH'
}
const AddressReciept = (props: AddressRecieptProps) => {
    const { address } = props
    const { t } = useTranslation()
    return (
        <Box display='flex' flexDirection='column' gap='16px' height='100%'>
            <Box marginBottom='16px'>
                <Typography fontSize={'20px'} fontWeight={'500'} marginBottom='8px'>
                    {t('BRANCH.ADDRESS_RECEIPT.TITLE')}
                </Typography>
                <Typography fontWeight={'400'} paddingLeft='20px' color='#5A5A5A'>
                    {t('BRANCH.ADDRESS_RECEIPT.TITLE_SUB')}
                </Typography>
            </Box>

            <Box>
                <Typography fontSize={'20px'} fontWeight={'500'} marginBottom='8px'>
                    {t(`BRANCH.ADDRESS_RECEIPT.${address}.TITLE`)}
                </Typography>
                <Typography fontWeight={'400'} paddingLeft='20px' color='#5A5A5A'>
                    {t(`BRANCH.ADDRESS_RECEIPT.${address}.TITLE_SUB`)}
                </Typography>
            </Box>

            <Box>
                <Typography fontSize={'20px'} fontWeight={'500'} marginBottom='8px'>
                    {t(`BRANCH.ADDRESS_RECEIPT.EXAMPLE`)}
                </Typography>
                <Box marginTop="8px" padding="16px" bgcolor={'#ffffff'}>
                    <Typography fontWeight={'400'} paddingLeft='20px' color='#5A5A5A'>
                        {t(`BRANCH.ADDRESS_RECEIPT.${address}.EXAMPLE`)}
                    </Typography>
                </Box>

            </Box>


        </Box>
    )
}
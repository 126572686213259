import { Grid, Box, Typography, Divider, Collapse, IconButton } from '@mui/material'
import * as UseStyles from 'features/setting/branch-service-point/branch/style'

import React, { ReactNode, useEffect, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import UploadImage from 'component/Upload/UploadImage'
import InputTextarea from 'component/Input/InputTextarea'

import { colors } from 'constants/theme'

import SelectInput from 'new-components/inputs/select-input'

import { BranchSelectAddressInterface } from './form-branch'
import OpReceiptDefault from './preview-receipt-sticker/receipt-default'
import OpAddressReciept from './preview-receipt-sticker/address-reciept'
import OpFormatThermal from './preview-receipt-sticker/format-thermal'
import OpRemarkAppointmentReceipt from './preview-receipt-sticker/remark-appointment-receipt'
import OpPracticeReceipt from './preview-receipt-sticker/practice-reciept'
import OpNumberPrefixReceipt from './preview-receipt-sticker/number-prefix-receipt'
import OpStickerMed from './preview-receipt-sticker/sticker-med'


type FormBranchRecieveStickerProps = {
    addressRecieptSameBranch: boolean
    setAddressRecieptSameBranch: (value: boolean) => void
    clearErrorMessage: () => void
    countryOptions: any
    receiptCountry: any
    setRecieptCountry: (value: any) => void
    receiptSelectAddress: BranchSelectAddressInterface | undefined
    setRecieptSelectAddress: (value: BranchSelectAddressInterface) => void
    errorMessage: any
    receiptAddress: string
    setRecieptAddress: (value: string) => void
    receiptAddressEn: string
    setRecieptAddressEn: (value: string) => void
    showCostEstimateReceipt: string
    setShowCostEstimateReceipt: (value: string) => void
    defaultReceipt: string
    setDefaultReceipt: (value: string) => void
    defaultReceiptRemarkStatus: string
    setDefaultReceiptRemarkStatus: (value: string) => void
    defaultReceiptPracticeStatus: string
    setDefaultReceiptPracticeStatus: (value: string) => void
    thermalFormat: string
    setThermalFormat: (value: string) => void
    thermalRemark: string
    setThermalRemark: (value: string) => void
    receiptNumberPrefix: string
    setReceiptNumberPrefix: (value: string) => void
    snType: boolean
    imgQRData: string
    setImgQRData: (value: string) => void
    setQRCodeReceipt: (value: any) => void
    qrCodeReceiptDetail: string
    setQRCodeReceiptDetail: (value: string) => void
    stickerFormat: string
    setStickerFormat: (value: string) => void
}

const FormBranchReceiptSticker = (props: FormBranchRecieveStickerProps) => {
    const { t } = useTranslation()

    const { addressRecieptSameBranch, setAddressRecieptSameBranch, clearErrorMessage, countryOptions, errorMessage,
        receiptCountry, setRecieptCountry, receiptSelectAddress, setRecieptSelectAddress,
        receiptAddress, setRecieptAddress, receiptAddressEn, setRecieptAddressEn,
        showCostEstimateReceipt, setShowCostEstimateReceipt, defaultReceipt, setDefaultReceipt,
        defaultReceiptRemarkStatus, setDefaultReceiptRemarkStatus, defaultReceiptPracticeStatus, setDefaultReceiptPracticeStatus,
        thermalFormat, setThermalFormat, thermalRemark, setThermalRemark, receiptNumberPrefix, setReceiptNumberPrefix, snType,
        imgQRData, setImgQRData, setQRCodeReceipt, qrCodeReceiptDetail, setQRCodeReceiptDetail, stickerFormat, setStickerFormat
    } = props

    const [openSelectInput, setOpenSelectInput] = useState(false)
    const [previewComponent, setPreviewComponent] = useState<ReactNode>(<></>)

    const objImage = { fileId: null, base64: '', fileExtension: '', fileSize: '' }

    const optionAddressReciept = OpAddressReciept()
    const optionDefaultReceipt = OpReceiptDefault({ thermalFormat })
    const optionFormatThermal = OpFormatThermal()
    const optionRemarkAppointmentReceipt = OpRemarkAppointmentReceipt()
    const optionPracticeReceipt = OpPracticeReceipt()
    const optionNumberPrefixReceipt = OpNumberPrefixReceipt()
    const optionStickerMed = OpStickerMed()

    const handleChangCheckAddress = (event: any) => {
        setAddressRecieptSameBranch(!addressRecieptSameBranch)
    }

    const uploadQRCode = (files: any) => {
        if (files[0]) {
            const file = files[0]
            const fileName = file.name.split('.')
            const detailImage = {
                fileId: null,
                base64: '',
                fileExtension: fileName[fileName.length - 1],
                fileSize: file.size.toString()
            }
            const reader: any = new FileReader()
            reader.addEventListener('load', () => {
                const base64 = reader.result.split(',')
                setImgQRData(reader.result)
                detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
            })
            reader.readAsDataURL(file)
            setQRCodeReceipt(detailImage)
        }
    }

    const handleChangePreviewComponent = (op: any[], data: any) => {
        const newComponent = op.find((item) => item.value === data)?.component || <>Unknow</>
        setPreviewComponent(newComponent)
        setOpenSelectInput(false)
    }

    const handlePreviewInfo = (pre: string) => {
        if (openSelectInput) {
            setOpenSelectInput(false)
            return
        }

        switch (pre) {
            case 'addressReciept':
                handleChangePreviewComponent(optionAddressReciept, addressRecieptSameBranch)
                break;
            case 'recieptDefault':
                handleChangePreviewComponent(optionDefaultReceipt, defaultReceipt)
                break;
            case 'formatThermal':
                handleChangePreviewComponent(optionFormatThermal, thermalFormat)
                break;
            case 'remarkAppointmentReceipt':
                handleChangePreviewComponent(optionRemarkAppointmentReceipt, defaultReceiptRemarkStatus)
                break;
            case 'practiceReceipt':
                handleChangePreviewComponent(optionPracticeReceipt, defaultReceiptPracticeStatus)
                break;
            case 'receiptNumberPrefix':
                handleChangePreviewComponent(optionNumberPrefixReceipt, receiptNumberPrefix)
                break;
            case 'stickerMed':
                handleChangePreviewComponent(optionStickerMed, stickerFormat)
                break;
            default:
                handleChangePreviewComponent(optionAddressReciept, addressRecieptSameBranch)
        }

    }

    useEffect(() => {
        handlePreviewInfo('addressReciept')
    }, [])

    return (
        <>
            <Grid container className="mt-3" display='flex'>
                <Grid container item lg={6} xs={12}
                    maxWidth={{ lg: '670px !important', xs: '100%' }}
                    width={{ lg: '50%', xs: '100%' }}
                    paddingRight={{ lg: '12px', xs: '0' }}
                    height={'fit-content'}
                >

                    <Grid container item padding='8px 0' onClick={() => handlePreviewInfo('addressReciept')}>
                        <Grid item sm={6} alignContent='center'>
                            {t('BRANCH.ADDRESS_RECEIPT.TITLE_SUB')}
                        </Grid>
                        <Grid item sm={6}>
                            <SelectInput
                                onOpen={() => setOpenSelectInput(true)}
                                label=''
                                onChange={(e) => {
                                    const data = e.target.value
                                    setAddressRecieptSameBranch(data)
                                    handleChangePreviewComponent(optionAddressReciept, data)
                                }}
                                options={optionAddressReciept}
                                value={addressRecieptSameBranch.toString()}
                            />
                        </Grid>
                    </Grid>

                    <Divider className="mx-0 my-3 w-100" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

                    <Grid container item padding='8px 0' onClick={() => handlePreviewInfo('recieptDefault')}>
                        <Grid item sm={6} alignContent='center'>
                            {t('BRANCH.RECEIPT_DEFAULT.TITLE')}
                        </Grid>
                        <Grid item sm={6}>
                            <SelectInput
                                onOpen={() => setOpenSelectInput(true)}
                                label=''
                                onChange={(e) => {
                                    const data = e.target.value
                                    setDefaultReceipt(data)
                                    handleChangePreviewComponent(optionDefaultReceipt, data)
                                }}
                                options={optionDefaultReceipt}
                                value={defaultReceipt}
                            />
                        </Grid>
                    </Grid>

                    <Divider className="mx-0 my-3 w-100" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

                    <Grid container item padding='8px 0' onClick={() => !(defaultReceipt !== "THERMAL") && handlePreviewInfo('formatThermal')}>
                        <Grid item sm={6} alignContent='center'>
                            {t('BRANCH.TAB.FORMAT_BILL')}
                        </Grid>
                        <Grid item sm={6}>
                            <SelectInput
                                onOpen={() => setOpenSelectInput(true)}
                                label=''
                                onChange={(e) => {
                                    const data = e.target.value
                                    setThermalFormat(data)
                                    handleChangePreviewComponent(optionFormatThermal, data)
                                }}
                                options={optionFormatThermal}
                                value={thermalFormat}
                                disabled={defaultReceipt !== "THERMAL"}
                            />
                        </Grid>
                        <Grid xs={12} marginTop='24px'>
                            <InputTextarea
                                disabled={defaultReceipt !== "THERMAL"}
                                onchange={(event) => {
                                    const inputValue = event.target.value;
                                    if (inputValue.length <= 100) {
                                        setThermalRemark(event.target.value)
                                    }
                                }}
                                value={thermalRemark}
                                label={t('BRANCH.INPUT.THERMAL_REMARK')}
                                inputProps={{
                                    style: {
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-start',
                                        fontSize: "12px"
                                    },
                                    startAdornment: (
                                        <div style={{ position: "absolute", top: "-18px" }}>
                                            <div>{`${thermalRemark?.length || 0}/100`}</div>
                                        </div>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Divider className="mx-0 my-3 w-100" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

                    <Grid container item padding='8px 0' onClick={() => !(defaultReceipt !== "THERMAL") && handlePreviewInfo('formatThermal')}>
                        <Grid item xs={12} alignContent='center' marginBottom='16px'>
                            {t('BRANCH.INPUT.QRCODE_TITLE')}
                        </Grid>
                        <Grid container item xs display='flex' gap='16px' flexWrap='nowrap'>

                            <Box>
                                <UseStyles.UploadReceiptImageBranch className="w-100" width={200} height={200}>
                                    <UploadImage
                                        setValue={uploadQRCode}
                                        pathFile={imgQRData}
                                        canDelete
                                        onDelete={() => {
                                            setQRCodeReceipt(objImage)
                                            setImgQRData("")
                                        }}
                                    />
                                </UseStyles.UploadReceiptImageBranch>
                            </Box>
                            <Box width='100%'>
                                <InputTextarea
                                    label={t('BRANCH.INPUT.QRCODE_DETAIL')}
                                    onchange={(e: any) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= 100) {
                                            setQRCodeReceiptDetail(e.target.value)
                                        }
                                    }}
                                    value={qrCodeReceiptDetail}
                                    inputProps={{
                                        style: {
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-start',
                                            fontSize: "12px"
                                        },
                                        startAdornment: (
                                            <Box sx={{ position: "absolute", bottom: "2px" }}>
                                                <Box sx={{ fontSize: '12px', color: '#808080' }}>{`${qrCodeReceiptDetail?.length || 0}/100`}</Box>
                                            </Box>
                                        ),
                                    }} />
                            </Box>
                        </Grid>
                    </Grid>

                    <Divider className="mx-0 my-3 w-100" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

                    <Grid container item padding='8px 0' onClick={() => handlePreviewInfo('remarkAppointmentReceipt')}>
                        <Grid item sm={6} alignContent='center'>
                            {t('BRANCH.REMARK_APPOINTMENT_RECEIPT.TITLE')}
                        </Grid>
                        <Grid item sm={6}>
                            <SelectInput
                                onOpen={() => setOpenSelectInput(true)}
                                label=''
                                onChange={(e) => {
                                    const data = e.target.value
                                    setDefaultReceiptRemarkStatus(data)
                                    handleChangePreviewComponent(optionRemarkAppointmentReceipt, data)
                                }}
                                options={optionRemarkAppointmentReceipt}
                                value={defaultReceiptRemarkStatus.toString()}
                            />
                        </Grid>
                    </Grid>

                    <Divider className="mx-0 my-3 w-100" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

                    <Grid container item padding='8px 0' onClick={() => handlePreviewInfo('practiceReceipt')}>
                        <Grid item sm={6} alignContent='center'>
                            {t('BRANCH.PRACTICE_RECEIPT.TITLE')}
                        </Grid>
                        <Grid item sm={6}>
                            <SelectInput
                                onOpen={() => setOpenSelectInput(true)}
                                label=''
                                onChange={(e) => {
                                    const data = e.target.value
                                    setDefaultReceiptPracticeStatus(data)
                                    handleChangePreviewComponent(optionPracticeReceipt, data)
                                }}
                                options={optionPracticeReceipt}
                                value={defaultReceiptPracticeStatus.toString()}
                            />
                        </Grid>
                    </Grid>

                    <Divider className="mx-0 my-3 w-100" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

                    <Grid container item padding='8px 0' onClick={() => handlePreviewInfo('receiptNumberPrefix')}>
                        <Grid item sm={6} alignContent='center'>
                            {t('BRANCH.NUMBER_PREFIX_RECEIPT.TITLE')}
                        </Grid>
                        <Grid item sm={6}>
                            <SelectInput
                                onOpen={() => setOpenSelectInput(true)}
                                label=''
                                onChange={(e) => {
                                    const data = e.target.value
                                    setReceiptNumberPrefix(data)
                                    handleChangePreviewComponent(optionNumberPrefixReceipt, data)
                                }}
                                options={optionNumberPrefixReceipt}
                                value={receiptNumberPrefix.toString()}
                            />
                        </Grid>
                    </Grid>

                    <Divider className="mx-0 my-3 w-100" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

                    <Grid container item padding='8px 0' onClick={() => handlePreviewInfo('stickerMed')}>
                        <Grid item sm={6} alignContent='center'>
                            {t('BRANCH.TAB.SENTTING.STICKER_MEDICINE')}
                        </Grid>
                        <Grid item sm={6}>
                            <SelectInput
                                onOpen={() => setOpenSelectInput(true)}
                                label=''
                                onChange={(e) => {
                                    const data = e.target.value
                                    setStickerFormat(data)
                                    handleChangePreviewComponent(optionStickerMed, data)
                                }}
                                options={optionStickerMed}
                                value={stickerFormat.toString()}
                            />
                        </Grid>
                    </Grid>

                    <Divider className="mx-0 my-3 w-100" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

                </Grid>
                <Grid lg xs={0} >
                    <Box width='100%' height='100%' padding='16px' bgcolor={'#F7F8F8'}>
                        <FormBranchReceiptStickerPreview component={previewComponent} />
                    </Box>
                </Grid>
            </Grid >
        </>
    )
}

export default FormBranchReceiptSticker

interface FormBranchReceiptStickerPreviewProps {
    component: ReactNode
}
const FormBranchReceiptStickerPreview = (props: FormBranchReceiptStickerPreviewProps) => {
    const { component } = props
    return (
        <>
            {component}
        </>
    )
}
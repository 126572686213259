import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ExportProps } from 'features/report/report-constants'
import TableRowExport from 'component/Pdf/TableRowExport'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { Box, MenuItem, Divider, Typography, Grid } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import { UseSnCode } from 'utils/useSnCode'
/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import ClinicApi, { BranchInterface, BillInterface } from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import Loading from 'component/Loading'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import FucntionPrint from 'component/Print/print'
import MainButton from 'new-components/buttons/main-button'
import SelectCheckBoxInput from 'new-components/inputs/select_checkbox_input'

/** REPORT */
import IncomeList, { SummaryList, SummaryPayment, SummaryPD, SummaryTx } from './IncomeList'

/** CONSTANTS */
import { getBranch, numberFormat, toBuddhistYear } from 'utils/app.utils'
import { colors } from 'constants/theme'
import { dateTimeToView, dateToView } from 'utils/date.utils'
import { routeName } from 'routes/routes-name'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import ProductsApi from 'api/warehouses/product.api'
import ProductTypesApi from 'api/setting/default-products/product-type.api'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'

export default function IncomeOperativeReport() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const componentRefPDF = useRef<HTMLDivElement>(null)
  const momentNowRange = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]

  const [loading, setLoading] = useState<boolean>(true)
  const [branches, setBranches] = useState<any[]>([])


  const [filter, setFilter] = useState<{ loading: boolean, branchId: any, page: number, pageLimit: number, sortBy: string, sortType: string, rangeDate: string[], productTypeId: number, productId: number }>({ loading: true, branchId: getBranch(), page: 1, pageLimit: 10, sortBy: 'createAt', sortType: 'ASC', rangeDate: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')], productTypeId: 0, productId: 0 });


  const [incomeData, setIncomeData] = useState<any[]>([])
  const [incomeSummeryData, setIncomeSummeryData] = useState<any[]>([])
  const [incomeDataProduct, setIncomeDataProduct] = useState<any[]>([])
  const [incomeDataTX, setIncomeDataTX] = useState<any[]>([])
  const [incomeExport, setIncomeExportData] = useState<any[]>([])
  const [incomeRowCount, setIncomeRowCount] = useState<number>(0)


  const [productType, setProductType] = useState([])
  const [productTypeSelected, setProductTypeSelected] = useState({ productTypeId: 0, productTypeName: t('REPORT.FILTER.ALL') })

  const [product, setProduct] = useState([])
  const [productSelected, setProductSelected] = useState({ productId: 0, productName: t('REPORT.FILTER.ALL') })

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }
    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'INCOME_SIMPLE_DAILY_OPERATIVE') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })

    /** MASTER DATA */
    ClinicApi.findAllBranches()
      .then(({ data }) => setBranches(data))
      .catch((e) => {
        return
      })
    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [])

  /** TABLE 1 */
  const fetchIncome = useCallback(() => {
    let condition: FindAllReportInterface = {
      page: filter.page,
      pageLimit: filter.pageLimit,
      branchId: filter.branchId,
    }
    if (filter.sortType) condition = { ...condition, sortType: filter.sortType }
    if (filter.sortBy) condition = { ...condition, sortBy: filter.sortBy }

    if (filter.productTypeId > 0) condition = { ...condition, productTypeId: filter.productTypeId }
    if (filter.productId > 0) condition = { ...condition, productId: filter.productId }

    if (filter.rangeDate[0] !== null && filter.rangeDate[1] !== null) condition = { ...condition, dateStart: filter.rangeDate[0], dateEnd: filter.rangeDate[1] }

    if (filter.loading) ReportApi.reportIncomeSimpleDailyOperative(condition)
      .then(({ headers, data }) => {
        setIncomeData(data)
        setIncomeRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [filter.loading])

  /** TABLE PRODUCT */

  const fetchIncomeProduct = useCallback(() => {
    let condition: FindAllReportInterface = {
      page: filter.page,
      pageLimit: filter.pageLimit,
      branchId: filter.branchId,
    }
    if (filter.sortType) condition = { ...condition, sortType: filter.sortType }
    if (filter.sortBy) condition = { ...condition, sortBy: filter.sortBy }

    if (filter.productTypeId > 0) condition = { ...condition, productTypeId: filter.productTypeId }
    if (filter.productId > 0) condition = { ...condition, productId: filter.productId }

    if (filter.rangeDate[0] !== null && filter.rangeDate[1] !== null) condition = { ...condition, dateStart: filter.rangeDate[0], dateEnd: filter.rangeDate[1] }

    if (filter.loading) ReportApi.reportIncomeSimpleDailySummery(condition)
      .then(({ headers, data }) => {
        const d = _.groupBy(data, 'refItemType')
        setIncomeDataProduct(d['ORDER_PRODUCT'])
        setIncomeDataTX(d['ORDER_TREATMENT'])
        setIncomeSummeryData(data)
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [filter.loading])


  const onChangeIncomeSort = (sortByVal: string, sortTypeVal: any) => {
    setFilter((val) => ({ ...val, sortType: sortTypeVal, sortBy: sortByVal }))
  }

  const onChangeIncomeRowPerPage = (limit: number) => {
    setFilter((val) => ({ ...val, pageLimit: limit }))

  }

  const onChangeIncomePage = (page: number) => {
    setFilter((val) => ({ ...val, page: page }))
  }

  const loadIncomeExport = useCallback(async () => {
    let condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 10000,
      branchId: filter.branchId,
      export: 1
    }

    if (filter.rangeDate[0] !== null && filter.rangeDate[1] !== null) condition = { ...condition, dateStart: filter.rangeDate[0], dateEnd: filter.rangeDate[1] }
    if (filter.productTypeId > 0) condition = { ...condition, productTypeId: filter.productTypeId }
    if (filter.productId > 0) condition = { ...condition, productId: filter.productId }

    if (filter.loading) ReportApi.reportIncomeSimpleDailyOperative(condition)
      .then(({ data }) => setIncomeExportData(data))
      .catch((e) => setFilter((val) => ({ ...val, loading: false })))
      .finally(() => setFilter((val) => ({ ...val, loading: false })))
  }, [filter.loading])

  const loadProductType = useCallback((search = '') => {
    ProductTypesApi.findAll({ status: 'ACTIVE', pageLimit: 50, search: search })
      .then(({ data }) => setProductType(data))
      .catch((e) => {
        return
      })
  }, [])

  useEffect(() => {
    ProductsApi.findByType({ status: 'ACTIVE', pageLimit: 50, itemTypeId: productTypeSelected.productTypeId })
      .then(({ data }) => setProduct(data))
      .catch((e) => {
        return
      })
  }, [productTypeSelected, productSelected])


  /** UseEffect */
  useEffect(() => {
    fetchIncome()
  }, [fetchIncome])

  useEffect(() => {
    fetchIncomeProduct()
  }, [fetchIncomeProduct])


  useEffect(() => {
    loadIncomeExport()
  }, [loadIncomeExport,])

  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.INCOME_SIMPLE_DAILY_OPERATIVE`)} ${filter.rangeDate ? `${filter.rangeDate[0]} - ${filter.rangeDate[1]}` : ''}`,
    sheetName: `${t(`REPORT.REPORT_NAME.INCOME_SIMPLE_DAILY_OPERATIVE`)}`
  })

  const setHeaderSearch = [
    {
      name: `${t('REPORT.FILTER.BRANCH')}`,
      style: { width: '1%', whiteSpace: 'nowrap' }
    },
    {
      name: `${_.get(_.find(branches, { branchId: filter.branchId }), 'branchName' || 'branchNameEn')}`,
      style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '10px' }
    },
    {
      name: `${t('REPORT.FILTER.RECEIPT_STATUS')}`,
      style: { width: '1%', whiteSpace: 'nowrap' }
    },

    {
      name: `${t('REPORT.FILTER.RECEIPT_TYPE')}`,
      style: { width: '1%', whiteSpace: 'nowrap' }
    },

    {
      name: `${t('REPORT.FILTER.START')}`,
      style: { width: '1%', whiteSpace: 'nowrap' }
    },
    {
      name: `${filter.rangeDate[0] ? toBuddhistYear(moment(filter.rangeDate[0]), 'DD/MM/YYYY') : '-'}`,
      style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '10px' }
    },
    {
      name: `${t('REPORT.FILTER.DATE_END')}`,
      style: { width: '1%', whiteSpace: 'nowrap' }
    },
    {
      name: `${filter.rangeDate[1] ? toBuddhistYear(moment(filter.rangeDate[1]), 'DD/MM/YYYY') : '-'}`,
      style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '10px' }
    },
    {
      name: ``,
      style: {}
    }
  ]

  const onLoadData = () => {
    fetchIncome()
    fetchIncomeProduct()
    loadIncomeExport()
  }

  return (
    <Card className={'border-0 h-100'}>
      <HeaderCard text={t(`REPORT.REPORT_NAME.INCOME_SIMPLE_DAILY_OPERATIVE`)} />
      <Card.Body>
        <Card.Title className={'mb-0'}>
          <Row className="mx-0">
            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
              <FilterSelect
                onchange={(e) => setFilter((val) => ({ ...val, branchId: e.target.value, page: 1, pageLimit: 10 }))}
                renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: filter.branchId }), 'branchName' || 'branchNameEn')} `}
                label={''}
                selectId="select-branch"
                labelId="label-branch"
                value={filter.branchId}
                options={_.map(branches, (b: BranchInterface) => {
                  return (
                    <MenuItem key={b.branchId} value={b.branchId}>
                      {b.branchName || b.branchNameEn}
                    </MenuItem>
                  )
                })}
                formControlStyle={{ maxWidth: 'unset !important' }}
                classesOption="style-select-doctors"
              />
            </Col>

            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
              <InputSecondNewRangePicker
                inputHeight={32}
                value={filter.rangeDate || emptyRangePicker}
                onchange={(val: any) => {
                  if (_.isEmpty(val)) setFilter((val) => ({ ...val, rangeDate: emptyRangePicker, page: 1, }))
                  else setFilter((old) => ({ ...old, rangeDate: [moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')], page: 1, }))
                }}
                label={''}
                allowClear
                onClear={() => setFilter((val) => ({ ...val, rangeDate: emptyRangePicker, page: 1, }))}
              />
            </Col>

            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
              <Box position={'relative'} sx={{ '.MuiInputBase-root': { height: '32px' } }}>


                <AutocompleteSelect
                  key={'operativeType'}
                  labelId="select-operative-type"
                  inputLabel={t('ประเภทผลิตภัณฑ์')}
                  noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                  options={[{ productTypeId: 0, productTypeName: t('REPORT.FILTER.ALL') }, ...productType]}
                  getOptionLabel={(option: any) => option.productTypeName}
                  renderOption={(props, option: any) => (
                    <Box key={option} component="li" {...props}>
                      {option.productTypeName}
                    </Box>
                  )}
                  onchange={(e, value: any) => {
                    if (value) {
                      setFilter((val) => ({ ...val, productTypeId: value.productTypeId || '' }))
                      setProductTypeSelected({ productTypeId: value.productTypeId, productTypeName: value.productTypeName })
                      setProductSelected({ productId: 0, productName: t('REPORT.FILTER.ALL') })
                    } else {
                      setFilter((val) => ({ ...val, productTypeId: 0 }))
                      setProductTypeSelected({ productTypeId: 0, productTypeName: t('REPORT.FILTER.ALL') })
                    }
                  }}
                  inputProps={{
                    onChange: (e: any) => {
                      if (productType.length === 50) {
                        setFilter((val) => ({ ...val, productTypeId: 0 }))
                        setProductType([])
                        loadProductType(e.target.value)
                      }
                    }
                  }}
                  onOpen={(e: any) => {
                    setFilter((val) => ({ ...val, productTypeId: 0 }))
                    setProductTypeSelected({ productTypeId: 0, productTypeName: '' })
                    loadProductType()
                  }}
                  value={productTypeSelected}
                  disableClearable
                />
              </Box>
            </Col>
            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
              <Box position={'relative'} sx={{ '.MuiInputBase-root': { height: '32px' } }}>
                <AutocompleteSelect
                  key={'operative'}
                  labelId="select-operative"
                  inputLabel={t('รายการผลิตภัณฑ์')}
                  noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                  options={[{ productId: 0, productName: t('REPORT.FILTER.ALL') }, ...product]}
                  getOptionLabel={(option: any) => option.productName}
                  renderOption={(props, option: any) => (
                    <Box key={option} component="li" {...props}>
                      {option.productName}
                    </Box>
                  )}
                  onchange={(e, value: any) => {
                    if (value) {
                      setFilter((val) => ({ ...val, productId: value.productId }))
                      setProductSelected({ productId: value.productId, productName: value.productName })
                    } else {
                      setFilter((val) => ({ ...val, productId: 0 }))
                      setProductSelected({ productId: 0, productName: t('REPORT.FILTER.ALL') })
                    }
                  }}
                  inputProps={{
                    onChange: (e: any) => {
                      if (product.length === 50) {
                        setFilter((val) => ({ ...val, productId: 0 }))
                        setProduct([])
                      }
                    }
                  }}
                  onOpen={(e: any) => {
                    setFilter((val) => ({ ...val, productId: 0 }))
                    setProductSelected({ productId: 0, productName: t('REPORT.FILTER.ALL') })
                  }}
                  value={productSelected}
                  disableClearable
                />
              </Box>
              <Box maxWidth={'100px'} width={'100%'} sx={{ button: { width: '100%' } }}>
                <MainButton
                  title={t('INPUT.SEARCH')}
                  onClick={() => {
                    setFilter((val) => ({ ...val, page: 1, loading: true }))
                  }}
                  type={'primary'}
                  variant={'contained'}
                />
              </Box>
            </Col>


            <Col md={4} lg={3} xl={2} className={'d-flex justify-content-end ml-xl-auto pt-2 px-1 gap-2'}>

              <Box>
                <UseStyled.DropdownDocument>
                  <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                    {t('PRINT_DOCUMENT')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                    {/* <FucntionPrint
                      content={() => componentRefPDF.current}
                      removeIfram
                      trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                      style={`@page { size: 1122.519685px 793.7007874px ;  margin: 0.5cm;  } @media print { body { -webkit-print-color-adjust: exact; }`}
                    /> */}
                  </Dropdown.Menu>
                </UseStyled.DropdownDocument>
              </Box>

            </Col>
          </Row>
        </Card.Title>

        <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
          <IncomeList
            page={filter.page}
            pageLimit={filter.pageLimit}
            rowCount={incomeRowCount}
            sortBy={filter.sortBy}
            sortType={filter.sortType}
            data={incomeData}
            dataExport={incomeExport}
            onRequestSort={onChangeIncomeSort}
            handleChangeRowsPerPage={onChangeIncomeRowPerPage}
            handleChangePage={onChangeIncomePage}
          />
        </Box>
        <Grid container gridColumn={2} spacing={2}>
          <Grid item >
            <SummaryPayment data={incomeExport} />
          </Grid>
          <Grid item >
            <SummaryList data={incomeExport} />
          </Grid>
          <Grid item >
            <SummaryTx data={incomeExport} />
          </Grid>
          <Grid item >
            <SummaryPD data={incomeDataProduct} />
          </Grid>
        </Grid>
      </Card.Body>
      <Loading show={filter.loading} type="fullLoading" />

      <div className="print-show" ref={componentRef}>
        <ExportData
          headCells={t(`REPORT.REPORT_NAME.INCOME_SIMPLE_DAILY_OPERATIVE`)}
          componant={
            <>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(setHeaderSearch, (val: any) => {
                        return <td style={{ textAlign: 'left', fontSize: 10, fontWeight: 500, border: 'none', ...val.style }}>{t(val.name)}</td>
                      })}
                    </tr>
                  </thead>
                </table>
              </UseStyled.Styles>
              <br></br>
              <SummaryPayment data={incomeExport} />
              <SummaryList data={incomeExport} />
              <br></br>
              <SummaryTx data={incomeExport} />
              <SummaryPD data={incomeDataProduct} />
            </>
          }
        />
      </div>
    </Card>
  )
}

export function ExportData(props: ExportProps & { incomeData?: any }) {

  const { t } = useTranslation()

  const commonColFix = ['A', 'B', '%A', 'พิมพ์ปาก', 'C_%A', 'C_%B']
  const commonColOpFix = ['พิมพ์ปาก', 'รากปักใหม่', 'รากปักแก้', 'กระดูก', 'เมฆเบรน', 'ติดเครื่องมือ', 'ถอดเครื่องมือ', 'เปลี่ยนยาง']
  const colFix_1 = [
    {
      col: 'รากฟันเทียม',
      sub: [...commonColFix, 'รากปักใหม่', 'รากปักแก้', 'ที่ใช้', 'กระดูก', 'เมฆเบรน', 'หมายเหตุ']
    },
    {
      col: 'จัดฟัน',
      sub: [...commonColFix, 'ติดเครื่องมือ', 'ถอดเครื่องมือ', 'เปลี่ยนยาง']
    },
    {
      col: 'จัดฟันแบบใส',
      sub: [...commonColFix,]
    },
    {
      col: 'รีเทนเนอร์',
      sub: [...commonColFix,]
    }
  ]
  const colFix_2 = [
    { col: 'ใส่ฟัน', sub: [...commonColFix,] },
    {
      col: 'สีเนียร์', sub: [...commonColFix,]
    },
    { col: 'ฟอกสีฟัน', sub: [...commonColFix,] },
    { col: 'ฟอกสีฟัน_', sub: ['เคส', 'เคสkit'] }
  ]

  const row = [
    {
      "date": "2024-11-01",
      "operativeTypeName": "รากฟันเทียม",
      "operativeName": "พิมพ์ปากล่าง ด้วยวัสดุอัลจิเจต",
      "cTx": 1,
      "apt": 1,
      "aptDone": "0"
    },
    {
      "date": "2024-11-01",
      "operativeTypeName": "รากฟันเทียม",
      "operativeName": "พิมพ์ปากด้วยวัสดุซิลิโคน",
      "cTx": 1,
      "apt": 1,
      "aptDone": "0"
    },
    {
      "date": "2024-11-01",
      "operativeTypeName": "รากฟันเทียม",
      "operativeName": "ถ่ายภาพรังสีแบบ Periapical",
      "cTx": 1,
      "apt": 1,
      "aptDone": "0"
    },
    {
      "date": "2024-11-02",
      "operativeTypeName": "รากฟันเทียม",
      "operativeName": "ถ่ายภาพรังสีแบบ Bite-wing",
      "cTx": 1,
      "apt": 1,
      "aptDone": "0"
    },
    {
      "date": "2024-11-03",
      "operativeTypeName": "รากฟันเทียม",
      "operativeName": "ถ่ายภาพรังสีแบบ Panoramic",
      "cTx": 1,
      "apt": 1,
      "aptDone": "0"
    },
  ]

  const [dataRow, setDataRow] = useState<any>([])

  const handleChangeOpName = (text: string): string => {
    const index = commonColOpFix.findIndex((i: string) => text.includes(i))
    if (index < 0) {
      return text
    }
    return commonColOpFix[index]
  }

  useEffect(() => {
    const data: any = []
    row.forEach((i: any) => {

      const item = { ...i, operativeName: handleChangeOpName(i?.operativeName?.toString()) }
      if (data.every((info: any) => info?.date !== item.date)) {
        data.push({
          date: item.date,
          operativeType: [
            {
              operativeTypeName: item.operativeTypeName,
              operative: [
                {
                  operativeName: item.operativeName,
                  cTx: item.cTx,
                }
              ],
              apt: item.apt,
              aptDone: Number(item.aptDone || 0)
            }
          ]
        })
      } else {
        const indexDate = data.findIndex((info: any) => info?.date === item.date)
        const dataDate = data[indexDate]
        if (dataDate?.operativeType.every((info: any) => info?.operativeTypeName !== item.operativeTypeName)) {
          dataDate.operativeType.push({
            operativeTypeName: item.operativeTypeName,
            operative: [
              {
                operativeName: item.operativeName,
                cTx: item.cTx,
              }
            ],
            apt: item.apt,
            aptDone: item.aptDone
          })

        } else {
          const indexOpType = dataDate?.operativeType.findIndex((info: any) => info?.operativeTypeName === item.operativeTypeName)
          const dataOpType = dataDate?.operativeType[indexOpType]
          if (dataOpType?.operative.every((info: any) => info?.operativeName !== item.operativeName)) {
            dataOpType.operative.push({
              operativeName: item.operativeName,
              cTx: item.cTx,
            })
          } else {
            dataOpType.operative[indexOpType].cTx += item.cTx
          }
          dataOpType['apt'] = (dataOpType['apt'] || 0) + item.apt
          dataOpType['aptDone'] = (dataOpType['aptDone'] || 0) + Number(item?.aptDone || 0)
        }
      }
    })

    const colSort = [...colFix_1, ...colFix_2]
    const newTableData = data.map((item: any) => {
      let opType: any = {}
      colSort.forEach((col: any) => {
        const info = item?.operativeType?.find((i: any) => i?.operativeTypeName === col?.col)
        if (info) {
          const opTypeName = info.operativeTypeName
          const opApt = (info.apt || 0)
          const opAptDone = (info.aptDone || 0)
          const opC = info.operative.find((i: any) => i?.operativeName === 'พิมพ์ปาก')?.cTx || 0

          const prev: any = {}

          prev[`${opTypeName}_A`] = opApt
          prev[`${opTypeName}_B`] = opAptDone
          prev[`${opTypeName}_%B`] = `${numberFormat(!opAptDone ? 0 : (opApt / opAptDone) * 100)}%`
          prev[`${opTypeName}_พิมพ์ปาก`] = opC
          prev[`${opTypeName}_C_%A`] = `${numberFormat(!opC ? 0 : (opApt / opC) * 100)}%`
          prev[`${opTypeName}_C_%B`] = `${numberFormat(!opC ? 0 : (opAptDone / opC) * 100)}%`

          col.sub.forEach((sub: any) => {
            if (commonColFix.every((i: string) => i !== sub)) {
              if (sub === 'ที่ใช้') {
                const opC_1 = info.operative.find((i: any) => i?.operativeName === 'รากปักใหม่')?.cTx || 0
                const opC_2 = info.operative.find((i: any) => i?.operativeName === 'รากปักแก้')?.cTx || 0
                prev[`${opTypeName}_${sub}`] = opC_1 + opC_2
              } else if (sub === 'หมายเหตุ') {
                prev[`${opTypeName}_${sub}`] = '-'
              } else {
                const opC = info.operative.find((i: any) => i?.operativeName === sub)?.cTx || 0
                prev[`${opTypeName}_${sub}`] = opC
              }
            }
          })

          opType = { ...opType, ...prev }
        } else {
          const prev: any = {}
          col.sub.forEach((sub: any) => {
            if (sub.includes('%')) {
              prev[`${col.col}_${sub}`] = `${numberFormat(0)}%`
            } else {
              prev[`${col.col}_${sub}`] = 0
            }
          })
          opType = { ...opType, ...prev }
        }
      })

      return {
        date: item.date,
        ...opType
      }
    })
    setDataRow(newTableData)
  }, [])

  const headCellsFirst = [
    [
      { id: '0', disablePadding: false, labelKey: 'วันที่', align: 'center', class: 'text-nowrap', rowSpan: 2, style: { width: '70px' } },
      { id: '1', disablePadding: false, labelKey: 'รากฟันเทียม', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 12, },
      { id: '2', disablePadding: false, labelKey: 'จัดฟัน', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 9, },
      { id: '3', disablePadding: false, labelKey: 'จัดฟันแบบใส', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 6, },
      { id: '4', disablePadding: false, labelKey: 'รีเทนเนอร์', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 6, },
    ]
    ,
    [
      { id: '1_1', disablePadding: false, labelKey: 'A\nนัดปรึกษา', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1_2', disablePadding: false, labelKey: 'B\nมาตามนัด', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1_3', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1_4', disablePadding: false, labelKey: 'C\nพิมพ์ปาก', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1_5', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1-6', disablePadding: false, labelKey: '%B', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1_7', disablePadding: false, labelKey: 'จำนวน\nรากปักใหม่', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1_8', disablePadding: false, labelKey: 'จำนวน\nรากปักแก้', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1_9', disablePadding: false, labelKey: 'จำนวน\nที่ใช้', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1_10', disablePadding: false, labelKey: 'กระดูก', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1_11', disablePadding: false, labelKey: 'เมฆเบรน', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1_12', disablePadding: false, labelKey: 'หมาย\nเหตุ', align: 'center', class: 'text-nowrap', row: 1 },

      { id: '2_1', disablePadding: false, labelKey: 'A\nนัดปรึกษา', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '2_2', disablePadding: false, labelKey: 'B\nมาตามนัด', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '2_3', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '2_4', disablePadding: false, labelKey: 'C\nพิมพ์ปาก', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '2_5', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '2-6', disablePadding: false, labelKey: '%B', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '2_7', disablePadding: false, labelKey: 'ติดเครื่อง\nมือ(ครั้ง)', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '2_8', disablePadding: false, labelKey: 'ถอด\nเตรื่องมือ', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '2-9', disablePadding: false, labelKey: 'เปลี่ยน\nยาง', align: 'center', class: 'text-nowrap', row: 1 },

      { id: '3_1', disablePadding: false, labelKey: 'A\nนัดปรึกษา', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '3_2', disablePadding: false, labelKey: 'B\nมาตามนัด', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '3_3', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '3_4', disablePadding: false, labelKey: 'C\nพิมพ์ปาก', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '3_5', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '3-6', disablePadding: false, labelKey: '%B', align: 'center', class: 'text-nowrap', row: 1 },

      { id: '4_1', disablePadding: false, labelKey: 'A\nนัดปรึกษา', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '4_2', disablePadding: false, labelKey: 'B\nมาตามนัด', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '4_3', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '4_4', disablePadding: false, labelKey: 'C\nพิมพ์ปาก', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '4_5', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '4-6', disablePadding: false, labelKey: '%B', align: 'center', class: 'text-nowrap', row: 1 },
    ]
  ]

  const headCellsSec = [
    [
      { id: '0', disablePadding: false, labelKey: 'วันที่', align: 'center', class: 'text-nowrap', rowSpan: 2, style: { width: '70px' } },
      { id: '1', disablePadding: false, labelKey: 'ใส่ฟัน', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 6, },
      { id: '2', disablePadding: false, labelKey: 'สีเนียร์', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 6, },
      { id: '3', disablePadding: false, labelKey: 'ฟอกสีฟัน', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 6, },
      { id: '4', disablePadding: false, labelKey: 'ฟอกสีฟัน', align: 'center', class: 'text-wrap ', rowSpan: 1, colSpan: 2, },
    ]
    ,
    [
      { id: '1_1', disablePadding: false, labelKey: 'A\nนัดปรึกษา', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1_2', disablePadding: false, labelKey: 'B\nมาตามนัด', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1_3', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1_4', disablePadding: false, labelKey: 'C\nพิมพ์ปาก', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1_5', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '1-6', disablePadding: false, labelKey: '%B', align: 'center', class: 'text-nowrap', row: 1 },

      { id: '2_1', disablePadding: false, labelKey: 'A\nนัดปรึกษา', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '2_2', disablePadding: false, labelKey: 'B\nมาตามนัด', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '2_3', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '2_4', disablePadding: false, labelKey: 'C\nพิมพ์ปาก', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '2_5', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '2-6', disablePadding: false, labelKey: '%B', align: 'center', class: 'text-nowrap', row: 1 },

      { id: '3_1', disablePadding: false, labelKey: 'A\nนัดปรึกษา', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '3_2', disablePadding: false, labelKey: 'B\nมาตามนัด', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '3_3', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '3_4', disablePadding: false, labelKey: 'C\nพิมพ์ปาก', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '3_5', disablePadding: false, labelKey: '%A', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '3-6', disablePadding: false, labelKey: '%B', align: 'center', class: 'text-nowrap', row: 1 },

      { id: '4_1', disablePadding: false, labelKey: 'จำนวน\nเคส', align: 'center', class: 'text-nowrap', row: 1 },
      { id: '4_2', disablePadding: false, labelKey: 'เคส\nKit', align: 'center', class: 'text-nowrap', row: 1 },
    ]
  ]

  const renderDataFirst = (first?: boolean) => {
    const lenCol_1 = colFix_1.reduce((a: number, b: any) => a + b.sub.length, 0)
    const lenCol_2 = colFix_2.reduce((a: number, b: any) => a + b.sub.length, 0)
    const indexStart = first ? 0 : lenCol_1
    const indexEnd = first ? lenCol_1 : lenCol_1 + lenCol_2
    if (!dataRow || !dataRow.length) {
      return []
    }

    return [..._.map(dataRow, ({ date, ...info }: any, index: number) => {
      return (
        <tr key={index} style={{ fontSize: '10px' }}>
          <td style={{ textAlign: 'center' }}>{dateToView(date)}</td>
          {Object.values(info).splice(indexStart, indexEnd).map((i: any, num: number) => (<td key={num} style={{ textAlign: 'center' }} >{i}</td>))}
        </tr>
      )
    }),
    <tr key={props?.incomeData?.length} style={{ fontSize: '10px' }}>
      <td style={{ textAlign: 'right' }}>{t('รวม')}</td>
      {Array(first ? lenCol_1 : lenCol_2).fill(0).map((item: any, num: number) => (<td key={num} style={{ textAlign: 'center' }} >{'-'}</td>))}
    </tr>
    ]
  }

  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
      {/* <Box sx={{
        'td': {
          border: '1px ridge rgb(0, 0, 0) !important',
          padding: '1px 4px !important'
        },
      }}>
        <table className={'mb-3'}>
          <thead>
            {_.map(headCellsFirst, (info: any, index: number) => (
              <tr key={index}>
                {info.map((val: any, num: number) => {
                  return (
                    <td
                      key={num}
                      style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, whiteSpace: 'pre-line', ...val?.style }} rowSpan={val?.rowSpan} colSpan={val?.colSpan} > {val.labelKey}</td>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {renderDataFirst(true)}
          </tbody>
        </table>

        <table>
          <thead>
            {_.map(headCellsSec, (info: any, index: number) => (
              <tr key={index}>
                {info.map((val: any, num: number) => {
                  return (
                    <td
                      key={num}
                      style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, whiteSpace: 'pre-line', ...val?.style }} rowSpan={val?.rowSpan} colSpan={val?.colSpan} > {val.labelKey}</td>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {renderDataFirst()}
          </tbody>
        </table>
      </Box> */}

    </>
  )
}

export function ExportComponant({ incomeExport, incomeTypeExport, incomeOverdueExport }: any) {
  const { t } = useTranslation()
  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])
  // REPORT.TABLE.INCOME_DAY.PATIENT_CN_NUMBER
  const headCellIncome = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.BILL_ID', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.DATE_TIME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'APPLICATION.TABLE.CELL.CN_NUMBER', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'PATIENT_MANAGEMENT.TABLE.CELL.SN_CODE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PATIENT_NAME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PATIENT_AGE', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PAYMENT_STATUS', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PAYMENT_CHANNELS', align: 'left', class: 'text-wrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PAYMENT_PAY', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PAYMENT_OVERDUE', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.REMARK', align: 'center', class: 'text-wrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.CREATED_BY', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.REMARK_CANCEL', align: 'center', style: { width: '20px', whiteSpace: 'pre-wrap', wordWrap: 'break-word', important: true } },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.CANCEL_BY', align: 'center', class: 'text-nowrap' }
  ]
  if (!snType) {
    headCellIncome.splice(4, 1)
  }
  const headCellIncomeType = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.TYPE_OF_PAYMENT', align: 'left', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_INCOME', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_CANCEL', align: 'right', class: 'text-nowrap' }
  ]

  const headCellIncomeOverdue = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.TYPE_OF_PAYMENT', align: 'left', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_INCOME', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_CANCEL', align: 'right', class: 'text-nowrap' }
  ]

  const renderIncomeOverdueExport = (data: any, no: number) => {
    const num = no + 1
    return (
      <TableRowExport
        key={num.toString()}
        id={no.toString()}
        obj={data}
        columns={[
          { option: 'TEXT', align: 'left', label: data.paymentChannelName || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.paidNormal) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.paidCancel) || '-' }
        ]}
      />
    )
  }

  const renderIncomeTypeExport = (data: any, no: number) => {
    const num = no + 1
    const paymentChanel = data.paymentChannelName === 'Voucher' ? t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.VOUCHER') : data.paymentChannelName
    return (
      <>
        <TableRowExport
          key={num.toString()}
          id={no.toString()}
          obj={data}
          columns={[
            { option: 'TEXT', align: 'left', label: `${paymentChanel} ${data.cardTypeName !== null ? `(${data.cardTypeName})` : ``}` || '-' },
            { option: 'TEXT', align: 'right', label: numberFormat(data.paidNormal) || '-' },
            { option: 'TEXT', align: 'right', label: numberFormat(data.paidCancel) || '-' }
          ]}
        />
      </>
    )
  }

  const renderIncomeExport = (data: any, no: number) => {
    const num = no + 1
    const columns = [
      { option: 'TEXT', align: 'left', label: data.billNo, class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: dateTimeToView(data.paidAt), class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.cnNumber || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.branchCnNumber || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.snCode || '', class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.patientFullname, class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.age, class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: t(`REPORT.BILL_STATUS.${data.billStatus}`), class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.paymentChannel, class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.overdue), class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.remark, class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.createdBy, class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.cancelRemark, class: 'text-wrap' },
      { option: 'TEXT', align: 'center', label: data.cancelBy, class: 'text-nowrap' }
    ]
    if (!snType) {
      columns.splice(4, 1)
    }
    return (
      <>
        <TableRowExport key={num.toString()} id={no.toString()} obj={data} columns={columns} />
      </>
    )
  }

  return (
    <>
      <UseStyled.Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(headCellIncome, (val: any) => {
                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {_.map(incomeExport, (d: any, index: number) => {
              return renderIncomeExport(d, index)
            })}
          </tbody>
        </table>
      </UseStyled.Styles>
      <br></br>
      <UseStyled.Styles className="pt-5">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(headCellIncomeType, (val: any) => {
                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {[
              _.map(incomeTypeExport, (d: any, index: number) => {
                return renderIncomeTypeExport(d, index)
              })
            ]}
          </tbody>
        </table>
      </UseStyled.Styles>
      <br></br>
      <UseStyled.Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(headCellIncomeOverdue, (val: any) => {
                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {[
              _.map(incomeOverdueExport, (d: any, index: number) => {
                return renderIncomeOverdueExport(d, index)
              })
            ]}
          </tbody>
        </table>
      </UseStyled.Styles>
    </>
  )
}

import { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Dropdown } from 'react-bootstrap';
import { routeName } from 'routes/routes-name';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router'
import { Box, ClickAwayListener, FormControlLabel, MenuItem, RadioGroup } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/**API */
import { getBranch, toBuddhistYear } from 'utils/app.utils';
import ClinicApi, { BranchInterface } from 'api/master/clinic.api';
import ReportApi, { FindAllReportInterface } from 'api/report.api';

/**COMPONENT */
import LabOperativeList from './LabOperativeList';
import Loading from 'component/Loading';
import FucntionPrint from "component/Print/print";
import HeaderCard from 'component/CardCustom/HeaderCard';
import FilterSelect from 'component/Select/FilterSelect';
import { useDownloadExcel } from 'component/Excel/hooks/useExcel';
import InputSecondNewDatePicker, { PICKER_VIEWS } from 'component/Input/InputSecondNewDatePicker';

/**STYLE */
import * as UseStyled from 'features/report/useStyled';
import InputRadio from 'component/Input/InputRadio';
import ButtonCustom from 'component/Button/ButtonCustom';
import InputCheckbox from 'component/Input/InputCheckbox';
import DoctorScheduleApi from 'api/counter/doctor-schedule.api';

import { CustomFilterDoctor, ListFilterDoctors } from 'features/counter/appointment/head-appointment';
import AutocompleteSelect from 'component/Select/AutocompleteSelect';
import { colors } from 'constants/theme'
import { SpanClearFilter } from 'features/report/useStyled';

type Doctors = {
    userId: number
    firstname: string
    lastname: string
    fullname: string
    schedule: number | null
}

export interface DentistInterface {
    dentistId: number
    dentistFullname: string
}

export default function Df() {
    const { t } = useTranslation();
    const history = useHistory();
    const componentRef = useRef<HTMLDivElement>(null);
    const queryParams = new URLSearchParams(window.location.search)
    const userIdFromParam = Number(queryParams.get("doctorId"))


    const momentNow = moment().format('YYYY-MM-DD');
    const [branchId, setBranchId] = useState<any>(getBranch());
    const [branches, setBranches] = useState<any[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<string>('createAt');
    const [sortType, setSortType] = useState<string>('ASC');
    const [doctors, setDoctors] = useState<Doctors[]>([]);
    const [fetch, setFetch] = useState(false);
    const [dfData, getDfData] = useState<any[]>([]);
    const [date, setDate] = useState<string>(momentNow);
    const [page, setPage] = useState<number>(1);
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [display, setDisplay] = useState('day');
    const [loading, setLoading] = useState<boolean>(true);
    const [selectDoctors, setSelectDoctors] = useState<any[]>([])
    const [doctorSchedule, setDoctorSchedule] = useState<any[]>([])

    const [doctorsMultiple, setDoctorsMultiple] = useState<DentistInterface[]>([]);
    const [openFilterDoctors, setOpenFilterDoctors] = useState(false)


    const handleFilterDoctors = (value: any) => {
        if ((value === 'ALL' && _.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)) || value === 'CLEAR') setSelectDoctors([''])
        else if (value === 'ALL') setSelectDoctors([..._.map(doctors, (d: any) => d.userId)])
        else if (value === 'DOCTOR_SCHEDULE') {
            const checkSchedule = _.filter(selectDoctors, (d: any) => _.includes(doctorSchedule, d))
            if (_.size(checkSchedule) === _.size(doctorSchedule)) setSelectDoctors(_.filter(selectDoctors, (d: any) => !_.includes(doctorSchedule, d)))
            else setSelectDoctors([..._.filter(selectDoctors, (d: any) => !_.includes(doctorSchedule, d)), ..._.map(_.filter(doctors, (d) => d.userId === d.schedule), (d: any) => d.userId)])
        }
        else if (_.includes(selectDoctors, value)) setSelectDoctors(_.filter(selectDoctors, (d) => d !== value))
        else setSelectDoctors([...selectDoctors, value])
    }

    const getAllFilter = () => {
        return [{
            label: t('REPORT.FILTER.BRANCH'),
            value: _.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn'),
        },
        {
            label: display === 'day' ? t('REPORT.FILTER.DAY') : t('REPORT.FILTER.MONTH'),
            value: display === 'day' ? toBuddhistYear(moment(date), 'DD/MM/YYYY') : toBuddhistYear(moment(date), 'MMMM YYYY'),
        },
        {
            label: t('REPORT.FILTER.DOCTOR'),
            value: getFilterUser()
        }]
    }


    const getFilterUser = () => {
        if (_.includes(selectDoctors, 'ALL')) return t('REPORT.REPORT_GROUP.ALL')
        else {
            return ` ${doctorsMultiple
                .filter((d: DentistInterface) => selectDoctors.includes(d.dentistId))
                ?.map((d: DentistInterface) => d.dentistFullname)
                .join(', ')}`
        }
    }

    const verifyPermission = async () => {
        ClinicApi.findAllBranches()
            .then(({ data }) => setBranches(data))
            .catch((e) => { return })
        if (!userIdFromParam) {
            const condition: FindAllReportInterface = {
                page: 1, pageLimit: 200
            }
            ReportApi.reportList(condition)
                .then(({ data }) => {
                    const check = _.find(data, (val: any) => String(val.reportKey) === 'DF_LAB_OPERATIVE') ? true : false
                    if (!check) {
                        history.push(routeName.report)
                        return false
                    }
                })

        }
        return true
    }

    useEffect(() => {
        verifyPermission()
    }, [branchId])

    const fetchDF = useCallback(async () => {
        let condition: any = {
            page: page, pageLimit: pageLimit,
            branchId: branchId, date: date,
            display: display
        }

        if (doctors) {
            const doctor = _.filter(selectDoctors, (v: any) => v > 0).join(',')
            if (doctor) condition = { ...condition, doctorId: _.filter(selectDoctors, (v: any) => v > 0).join(',') }
        }
        if (userIdFromParam) condition = { ...condition, doctorId: userIdFromParam }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }


        ReportApi.reportDfLabOperative(condition)
            .then(({ headers, data }) => {
                getDfData(data)
                setRowCount(headers['x-total'])
            }).catch((e) => setLoading(false))
            .finally(() => {
                setLoading(false)
            })
        setFetch(false)

    }, [page, pageLimit, sortBy, sortType, fetch, display])



    const fetchDoctor = useCallback(async () => {
        ClinicApi.findAllDoctors(date, display)
            .then((resp) => {
                setDoctors([...resp.data])
                setDoctorSchedule(_.map(_.filter(resp.data, (d) => d.userId === d.schedule), (d: any) => d.userId))
                setSelectDoctors(['ALL', 'DOCTOR_SCHEDULE', ...resp.data.map((d: any) => d.userId)])
            })
            .catch((e) => {
                return
            })
    }, [branchId, date, display])


    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`REPORT.REPORT_NAME.DF_LAB_OPERATIVE`)} (${date})`,
        sheetName: `${t(`REPORT.REPORT_NAME.DF_LAB_OPERATIVE`)}`
    })

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

    const handleChangePage = (val: number) => setPage(val)


    useEffect(() => {
        fetchDoctor()
    }, [fetchDoctor])

    useEffect(() => {
        fetchDF()
    }, [fetchDF])

    return (
        <>
            <Card className={'border-0 h-100'}>
                <HeaderCard text={t(`REPORT.REPORT_NAME.DF_LAB_OPERATIVE`)} />
                <Card.Body>
                    <Card.Title className={'mb-0'}>
                        <RadioGroup className={'pt-2 px-1'} row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                            <FormControlLabel value='day' control={<InputRadio checked={display === 'day'} className="radio" onClick={(e: any) => setDisplay('day')} />} label={t('REPORT.MONTH.DAYLY')} />
                            <FormControlLabel value='month' control={<InputRadio checked={display === 'month'} onClick={(e: any) => setDisplay('month')} />} label={t('REPORT.MONTH.MONTHLY')} />
                        </RadioGroup>
                        <Row className='mx-0'>
                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        setBranchId(e.target.value)
                                        setPage(1)
                                    }}
                                    renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                                    label={''}
                                    selectId="select-branch"
                                    labelId="label-branch"
                                    value={branchId}
                                    options={
                                        _.map(branches, (b: BranchInterface, index) => {
                                            return (
                                                <MenuItem key={b.branchId} value={b.branchId}>
                                                    {b.branchName || b.branchNameEn}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>

                            {display === 'day' && <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <InputSecondNewDatePicker
                                    dateFormat={"DD/MM/YYYY"}
                                    placeholder={t('REPORT.FILTER.DATE')}
                                    label={''}
                                    inputHeight={32}
                                    value={date}
                                    onchange={(e: any) => {
                                        setDate(moment(e).format('YYYY-MM-DD'))
                                        setPage(1)
                                    }}
                                    views={PICKER_VIEWS.DAY || PICKER_VIEWS.MONTH || PICKER_VIEWS.YEAR}
                                />
                            </Col>
                            }

                            {display === 'month' && <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <InputSecondNewDatePicker
                                    dateFormat={`MMMM YYYY`}
                                    placeholder={t('REPORT.FILTER.DATE')}
                                    label={''}
                                    inputHeight={32}
                                    value={date}
                                    onchange={(e: any) => {
                                        setDate(moment(e).format('YYYY-MM-DD'))
                                        setPage(1)
                                    }}
                                    views={PICKER_VIEWS.MONTH}
                                />
                            </Col>
                            }
                            {!userIdFromParam && <Col xs={'12'} sm={6} xl={3} xxl={3} className={'pt-2 px-1'}>
                                <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
                                    <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
                                        <AutocompleteSelect
                                            open={openFilterDoctors}
                                            multiple
                                            labelId="label-doctors"
                                            options={['ALL', 'DOCTOR_SCHEDULE', ...doctors, 'CLEAR']}
                                            getOptionLabel={(option) => _.find(doctors, { userId: option.userId })?.fullname || '-'}
                                            renderOption={(props, option) => {
                                                if (option === 'ALL') {
                                                    return (
                                                        <ListFilterDoctors {...props} key={option} component="li" value={option}>
                                                            <InputCheckbox
                                                                label={t('REPORT.REPORT_GROUP.ALL')}
                                                                checked={_.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)}
                                                                indeterminate={_.size(selectDoctors) > 1 && _.size(selectDoctors.filter((item) => typeof item === 'number')) !== _.size(doctors)}
                                                                className={'pe-none'}
                                                            />
                                                        </ListFilterDoctors>
                                                    )
                                                } else if (option === 'DOCTOR_SCHEDULE') {
                                                    return (
                                                        <ListFilterDoctors {...props} key={option} component="li" value={option}>
                                                            <InputCheckbox
                                                                label={t('REPORT.FILTER.DOCTOR_SCHEDULE')}
                                                                checked={_.size(_.filter(selectDoctors, (d: any) => _.includes(doctorSchedule, d))) === _.size(doctorSchedule)}
                                                                indeterminate={_.size(_.filter(selectDoctors, (d: any) => _.includes(doctorSchedule, d))) > 1 && _.size(_.filter(selectDoctors, (d: any) => _.includes(doctorSchedule, d))) !== _.size(doctorSchedule)}
                                                                className={'pe-none'}
                                                            />
                                                        </ListFilterDoctors>
                                                    )
                                                } else if (option === 'CLEAR') {
                                                    return (
                                                        <Box {...props} key={option} component="li" value={option} sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
                                                            <SpanClearFilter className="pe-none">{t('REPORT.FILTER.CLEAR_FILTER')}</SpanClearFilter>
                                                        </Box>
                                                    )
                                                } else {
                                                    return (
                                                        <ListFilterDoctors {...props} key={option.userId} component="li" value={option.userId}>
                                                            <InputCheckbox label={option.fullname} checked={_.includes(selectDoctors, option.userId)} className={'pe-none'} />
                                                        </ListFilterDoctors>
                                                    )
                                                }
                                            }}
                                            renderTags={(tagValue, getTagProps) => {
                                                return (
                                                    <>
                                                        <span className="pr-1">{t('USER_DOCTOR')}:</span>
                                                        {!openFilterDoctors &&
                                                            ((
                                                                _.size(tagValue.filter((item: any) => typeof item === 'number')) === _.size(doctors) && <span>{t('REPORT.REPORT_GROUP.ALL')}</span>)
                                                                ||
                                                                (_.size(tagValue.filter((d: any) => _.includes(doctorSchedule, d))) === _.size(doctorSchedule) && <span>{t('REPORT.FILTER.DOCTOR_SCHEDULE')}</span>)

                                                                || (
                                                                    <Box className="text-ellipsis">
                                                                        {_.map(tagValue, (option: any, index: number) => {
                                                                            return (
                                                                                <span>
                                                                                    {_.find(doctors, { userId: option })?.fullname}
                                                                                    {index !== _.findLastIndex(tagValue) && option && `,`}
                                                                                </span>
                                                                            )
                                                                        })}
                                                                    </Box>
                                                                ))}
                                                    </>
                                                )
                                            }}
                                            onchange={(e, value) => handleFilterDoctors(e?.target?.value || e?.target?.attributes?.value?.value)}
                                            noOptionsText={t('NOT_FOUND')}
                                            filterOptions={(option) => 'ALL' + option.fullname + 'CLEAR'}
                                            value={selectDoctors}
                                            height={32}
                                            disableClearable
                                        />
                                    </CustomFilterDoctor>
                                </ClickAwayListener>
                            </Col>}
                            <Col md={1} xl={1} className={'pt-0'}>
                                <ButtonCustom onClick={() => {
                                    setFetch(true);
                                    setPage(1);
                                    setLoading(true);
                                }}
                                    textButton={t('INPUT.SEARCH')} style={{ height: '30px', marginTop: '5px' }} />
                            </Col>

                            <Col md={4} xl={2} className={'d-xl-flex justify-content-xl-end ml-xl-auto'}>
                                <UseStyled.DropdownDocument>
                                    <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                                        {t('PRINT_DOCUMENT')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={onDownload}>
                                            {t('Excel')}
                                        </Dropdown.Item>
                                        <FucntionPrint
                                            content={() => componentRef.current}

                                            removeIfram
                                            trigger={() => <Dropdown.Item >
                                                {t('PDF')}
                                            </Dropdown.Item>}
                                            style={`@page { size:  1122.519685px 793.7007874px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                                        />
                                    </Dropdown.Menu>
                                </UseStyled.DropdownDocument>
                            </Col>
                        </Row>
                    </Card.Title>
                    <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
                        <LabOperativeList
                            filterTable={getAllFilter()}
                            page={page}
                            pageLimit={pageLimit}
                            rowCount={rowCount}
                            sortBy={sortBy}
                            sortType={sortType}
                            data={dfData}
                            date={date}
                            display={display}
                            onRequestSort={onRequestSort}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleChangePage={handleChangePage}
                            componentRef={componentRef}
                        />
                    </Box>
                </Card.Body>
                <Loading show={loading} type='fullLoading' />
            </Card>
        </>
    )
}
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import none_show from 'assets/branch/receipt/none_show.png'
import remark_show from 'assets/branch/receipt/remark_shows.png'

const OpRemarkAppointmentReceipt = () => {
    const { t } = useTranslation()
    const optionRemarkAppointmentReceipt = [
        {
            name: t('BRANCH.REMARK_APPOINTMENT_RECEIPT.SHOW'), value: "1",
            component: <RemarkAppointmentReceiptBox title={t('BRANCH.REMARK_APPOINTMENT_RECEIPT.SHOW')} images={[remark_show]} />,
        },
        {
            name: t('BRANCH.REMARK_APPOINTMENT_RECEIPT.NONE_SHOW'), value: "0",
            component: <RemarkAppointmentReceiptBox title={t('BRANCH.REMARK_APPOINTMENT_RECEIPT.NONE_SHOW')} images={[none_show]} />,
        },
    ]
    return (optionRemarkAppointmentReceipt)
}

export default OpRemarkAppointmentReceipt

interface RemarkAppointmentReceiptBoxProps {
    title: string
    images: string[]
}

const RemarkAppointmentReceiptBox = (props: RemarkAppointmentReceiptBoxProps) => {
    const { title, images } = props

    const { t } = useTranslation()
    return (
        <Box display='flex' flexDirection='column' gap='16px' height='100%'>
            <Box >
                <Typography fontSize={'20px'} fontWeight={'500'} marginBottom='8px'>
                    {t('BRANCH.REMARK_APPOINTMENT_RECEIPT.TITLE')}
                </Typography>
            </Box>

            <Box>
                <Box marginBottom='16px'>
                    <Typography fontWeight={'500'} marginBottom='8px'>
                        {title}
                    </Typography>
                </Box>

                <Box display='flex' alignItems={'center'} gap={'16px'} flexDirection={'column'} >
                    {images.map((item, index) => (
                        <Box key={index} textAlign='center' sx={{ img: { width: '80%' } }}>
                            <img src={item} alt="" />
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import _ from "lodash";
import HelperText from "component/Input/HelperText";
import { useState } from "react";

interface SelectInputProps {
    onChange: (e: any) => void
    options: any
    value?: string | string[] | number
    disabled?: boolean
    sxMenuItem?: any
    label?: string
    helperText?: string
    width?: any
    dropdown?: boolean
    disabledMenuItem?: boolean
    defaultValue?: string
    required?: boolean
    loading?: boolean
    onOpen?: () => void
}

export default function SelectInput(props: SelectInputProps) {

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <FormControl sx={{ width: props.width || '100%' }} size="small" disabled={props.disabled}>
            <InputLabel id="demo-select-small-label" sx={{
                color: props.helperText ? '#B00020' : '#808080',
                '&.Mui-focused': {
                    color: props.helperText ? '#B00020' : '#1CB99A'
                },
            }}>
                {props.label} {props.required && <span style={{ color: (props.value || isFocused) ? 'red' : 'gray' }}>*</span>}
            </InputLabel>
            <Select
                error={props.helperText !== '' && props.helperText !== undefined}
                onChange={props.onChange}
                defaultValue={props.defaultValue}
                value={props.value}
                multiple={props.dropdown}
                onOpen={props.onOpen}
                onFocus={handleFocus}
                onBlur={handleBlur}
                inputProps={{ value: props.value }}
                sx={{
                    backgroundColor: props.disabled ? '#F0F0F0' : '#FFFFFF',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1CB99A',
                        borderWidth: '1px'
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#B00020',
                    },
                    '&.Mui-disabled': {
                        borderColor: '#C5C5C5',
                    },
                }}>
                {props.loading ? <MenuItem disabled>กำลังโหลด...</MenuItem> : props.dropdown ? props.options : _.map(props.options, (item, index) => <MenuItem sx={
                    {
                        "&.Mui-focusVisible": {
                            backgroundColor: '#FFFFFF'
                        },
                        '&:hover': {
                            backgroundColor: '#EBF2F0',
                        },
                        '&.Mui-selected.Mui-focusVisible': {
                            backgroundColor: '#EBF2F0',
                        },
                        '&.Mui-selected': {
                            backgroundColor: '#EBF2F0',
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor: '#EBF2F0',
                        },
                    }
                } key={index} disabled={item.disabled} value={item.value}>{item.name}</MenuItem>)}
            </Select>
            {props.helperText && <HelperText label={props.helperText}></HelperText>}
        </FormControl >
    )
}

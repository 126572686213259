import { useCallback, useEffect, useState, useRef } from 'react'
import { Image, Dropdown } from 'react-bootstrap'
import { styled } from '@mui/material/styles'
import { Box, Typography, IconButton, Divider, Modal } from '@mui/material'
import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ASSEST */
import iconTime from 'assets/icon/appointment/card/ap-card-clock.svg'
import phone from 'assets/icon/appointment/card/ap-card-phone.svg'
import ClosePopup from 'assets/icon/appointment/close'
import IconEllipsis from 'assets/icon/iconEllipsis'
import IconStar from 'assets/icon/IconStar'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import iconEdit from 'assets/icon/iconEdit.svg'
import print from 'assets/icon/carlendar/print.svg'
import AppointmentApi, { AppointmentInterface, AppointmentOperativeInterface } from 'api/counter/appointment.api'
import { scale } from 'constants/scale'
import _ from 'lodash'
import { routeName } from 'routes/routes-name'
import { fileUrl } from 'api/api';
import { colors, ThemeIcon } from 'constants/theme'
import { getClinicInfo, numberFormat, toBuddhistYear } from 'utils/app.utils'
import { Icons } from 'constants/images'

import { dateTimeToView, dateToView, timeToView } from 'utils/date.utils'
import { selectMe } from 'app/slice/user.slice'
import { useSelector } from 'react-redux'
import { useFormatSizeAppointment } from 'features/counter/appointment/appointment'
import { isCreate, isDelete, isUpdate, isView, isExport } from 'utils/verification.utils'
import UseWindowSize from 'utils/useWindowSize'
import PageModal from 'new-components/modal/page-modal'
import FunctionPrint from 'component/Print/print'

type FixPositionType = {
  top: string
  left?: string
}

type CardSchedule = {
  showPopup: boolean
  hideMenu?: boolean | false
  appointmentId: number
  color?: string
  fixPosition?: FixPositionType
  timeStart?: string
  timeEnd?: string
  status?: string
  branch: any
  onEdit?: (id: number) => void
  onEditSpecialInfo?: (id: number) => void
  onClose?: (id: number) => void
  onClick?: (event: any) => void
  onLoading?: (event: boolean) => void
  key?: any
  isNotShowPhoneNumber?: boolean
  popAutoScroll?: boolean
}

type Position = {
  top: number;
  left: number;
};

const CardViewDetail = styled('div')<{ fixPosition?: FixPositionType; transform: number; dropDown: boolean; appointment?: AppointmentInterface; statusColor: string, positioncard: 'top' | 'bottom', position: 'fixed' | 'absolute', transformx: number }>(({ theme, fixPosition, transform, dropDown, appointment, statusColor, positioncard, position, transformx }) => ({
  position: position,
  zIndex: 999,
  // top: fixPosition?.top ? `${fixPosition.top}px` : 'auto',
  // left: fixPosition?.left ? `${fixPosition.left}px` : 'auto',
  top: fixPosition?.top || `calc(${'2rem'} + ${transform}px)`,
  left: transformx ? `${transformx}px` : fixPosition?.left || '2rem',
  display: dropDown && appointment?.timeStart !== undefined ? 'block' : 'none',
  padding: '40px 15px 15px',
  width: '350px',
  height: 'auto',
  backgroundColor: colors.white80,
  boxShadow: `0px 4px 5px ${colors.black10}`,
  // transform: 'translate3d(8px, 0px, 0px)',
  borderTop: '10px',
  border: `1px solid ${colors.borderHeaderCard}`,
  opacity: '1',
  backdropFilter: 'blur(40px) saturate(1)',
  lineHeight: '1.6rem',
  borderRadius: '8px',
  '&::before': {
    content: '""',
    background: statusColor,
    position: 'absolute',
    height: '30px',
    right: 0,
    left: 0,
    top: 0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  '.action-bars': {
    right: 10,
    '.close-popup': {
      height: '28px',
      svg: {
        width: '16px',
      },
      '&:hover': {
        filter: `drop-shadow(0 0 5px ${colors.black03})`
      }
    },
    '.btn-more': {
      boxShadow: 'none !important',
      border: 'none !important',
      svg: {
        height: '30px',
      },
      path: {
        stroke: `${colors.white} !important`
      },
      '&:hover': {
        filter: `drop-shadow(0 0 4px ${colors.black03})`
      }
    },
  },
  '.spacial-info': {
    '.title': {
      color: colors.themeMainColor,
      fontSize: '14px',
      fontWeight: 500,
      svg: {
        width: '16px'
      }
    },
    '.btn-expanded': {
      flex: 'none',
      width: 16,
      height: 16,
      borderRadius: 100,
      backgroundColor: colors.lightGray
    }
  },
  '.dropdown-menu': {
    '.dropdown-item': {
      '&:hover': {
        backgroundColor: colors.themeSecondColor06,
        color: colors.themeSecondColor
      }
    }
  }
}))

const LabTag = styled(Box)(({ theme }) => ({
  backgroundColor: colors.themeThirdColor,
  color: colors.white,
  borderRadius: 4,
  width: 'max-content',
  height: 'max-content',
  fontSize: '0.75rem',
  lineHeight: 'normal',
  padding: '1px 4px'
}))

const ImageLabel = styled(Image)(({ theme }) => ({
  verticalAlign: 'top',
  marginTop: '5px',
  marginRight: '4px'
}))

const TypographyNote = styled(Typography)(({ theme }) => ({
  width: 'calc(100% - 80px)',
  fontSize: 16,
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word'
}))


export default function CardAppointmentDetail(props: CardSchedule) {
  const { t, i18n } = useTranslation()
  const sizeSectionAppointment = useFormatSizeAppointment();

  const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView(),
    isExport: isExport()
  }

  const user = useSelector(selectMe)
  const specialInfoRef = useRef(null);
  const [dropDown, setDropDown] = useState<boolean>(props.showPopup || false)

  const [appointment, setAppointment] = useState<AppointmentInterface | undefined>()
  const [transform, setTransform] = useState(0)
  const [transformX, setTransformX] = useState(0)
  const [statusColor, setStatusColor] = useState(colors.lightGray)
  const [positionCard, setPositionCard] = useState<'top' | 'bottom'>('top')

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const unit = scale.rangeOfMinute
  const [branch, setBranch] = useState<any>()

  const size = UseWindowSize()

  const branchInfo = getClinicInfo()
  const pdfRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setBranch(props.branch)
  }, [props.branch])

  useEffect(() => {
    setDropDown(props.showPopup)
  }, [props.showPopup])

  const loadAppointmentById = useCallback(async () => {
    if (!props.appointmentId) return setAppointment(undefined)
    const ap: any = await AppointmentApi.findById(props.appointmentId)
    if (ap.status === 200) setAppointment(ap.data)
  }, [props?.appointmentId])

  const handlePatientCode = () => {
    if (branch?.patientCodeAppointment === '1') {
      return appointment?.cnNumber
    }
    if (branch?.patientCodeAppointment === '0') {
      return appointment?.branchCnNumber
    }
    if (branch?.patientCodeAppointment === '2') {
      return appointment?.snCode || ''
    }
  }
  const handleNowPoint = useCallback(() => {
    if (appointment?.timeStart) {
      const start = moment(appointment?.timeStart, 'HH:mm')
      const defaultTime = props.timeStart ? moment(props.timeStart, 'HH:mm') : moment('09:00', 'HH:mm')
      const diffTransform = moment.duration(start.diff(defaultTime)).asMinutes()

      let pos: 'top' | 'bottom' = 'top'
      const conDiv: any = document.querySelector(`.container-schedule [data-value="${props.appointmentId}"]`)
      const conSchedule: any = document.querySelector(`.container-schedule`)
      let translateY
      let newTranslateY = 0
      let newTranslateX = 0

      const conMaxWidth = document.querySelector(`.display-days`)
      const maxWidth = conMaxWidth?.clientWidth || size[0]

      if (conDiv) {
        const style = getComputedStyle(conDiv);
        const transform = style.getPropertyValue('transform');
        const matrix = new DOMMatrix(transform);
        translateY = matrix.m42;

        // sizeSectionAppointment === 284 && 
        if (props?.popAutoScroll) {
          const rect = conDiv.getBoundingClientRect();
          newTranslateY = rect.top
          newTranslateX = rect.left
          if (newTranslateX + 350 > maxWidth) {
            newTranslateX = (maxWidth - 366 < 0) ? 0 : maxWidth - 366
          } else if (newTranslateX < 0) {
            newTranslateX = 0
          }
          setTransformX(newTranslateX)
        }
      }

      let trans = translateY || diffTransform * unit

      const cardDetail: any = document.querySelector(`.card-detail-${props.appointmentId}`)
      const cardHeight: number = cardDetail?.offsetHeight || 0
      if ((cardHeight + newTranslateY) > size[1]) {
        newTranslateY = size[1] - cardHeight - 32 - 10
      }

      if (props.timeEnd) {
        const end = moment(props.timeEnd, 'HH:mm')
        const diffTransformEnd = moment.duration(end.diff(start)).asHours()
        const diffAllTime = moment.duration(end.diff(defaultTime)).asHours()
        if (diffTransformEnd < 3) {
          trans = trans - cardHeight
          pos = 'bottom'
        }

        if (trans <= 0) {
          trans = (translateY || diffTransform * unit)
        }

        if (trans > conSchedule?.offsetHeight) {
          trans = (translateY || diffTransform * unit) - cardHeight
        }

        if (diffAllTime <= 2) {
          trans = 0
        }
      }
      getColorAppointmentStatus()
      setPositionCard(pos)
      setTransform(newTranslateY || trans)
    }
  }, [appointment])

  useEffect(() => {
    if (props.showPopup) loadAppointmentById()
    else setAppointment(undefined)
  }, [props.showPopup, props.appointmentId])

  useEffect(() => {
    if (props.showPopup) handleNowPoint()
  }, [appointment])

  const getColorAppointmentStatus = useCallback(() => {
    let color: any = colors.lightGray
    if (appointment?.statusAppointment === 'CONFIRM') color = colors.appointmentStatus.statusEnter
    if (appointment?.statusAppointment === 'NOT_YET') color = colors.appointmentStatus.statusUnArrived
    if (appointment?.statusAppointment === 'CANCEL') color = colors.appointmentStatus.statusCancel
    if (appointment?.statusAppointment === 'CANCEL_APP') color = colors.appointmentStatus.statusCancel
    if (appointment?.statusAppointment === 'CAN_NOT_CONTACT') color = colors.appointmentStatus.statusUnConnect
    if (appointment?.statusAppointment === 'ARRIVED') color = colors.appointmentStatus.statusArrived

    // 
    if (appointment?.statusAppointment === 'PENDING_PAYMENT') color = colors.appointmentStatus.statusPayment
    if (appointment?.statusAppointment === 'PAYMENT') color = colors.appointmentStatus.statusWaitPayment
    if (appointment?.statusAppointment === 'PENDING_SERVICE') color = colors.appointmentStatus.statusCounterCheckIn
    if (appointment?.statusAppointment === 'SERVICE') color = colors.appointmentStatus.statusService
    //

    if (appointment?.statusAppointment === 'PUT_OFF') color = colors.appointmentStatus.statusChange
    if (appointment?.statusAppointment === 'DONE') color = colors.appointmentStatus.statusDone
    if (appointment?.statusAppointment === 'NORMAL') color = colors.appointmentStatus.statusNormal
    return setStatusColor(color)
  }, [appointment])

  const formatPhoneNumber = (phoneNumberString: string) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`
    }
    return phoneNumberString
  }

  const onPrintAppointment = async () => {
    props?.onClose && props?.onClose(props.appointmentId)
    setDropDown(false)
    window.print()
    // props?.onLoading && props?.onLoading(true)
    // const respPrint = await AppointmentApi.printAppointment(props.appointmentId)
    // if (respPrint.status === 200) {
    //   window.open(`${fileUrl}/${respPrint.data}`, '_blank')
    //   props?.onLoading && props?.onLoading(false)
    // } else {
    //   props?.onLoading && props?.onLoading(false)
    // }
  }

  const sizeSpecialBox = () => {
    const ref: any = specialInfoRef?.current
    return ref?.offsetWidth
  }

  const valPractice = (val: string) => {
    if (val.charAt(0) === ',' || val.charAt(0) === ' ') {
      val = val.substring(1);
    }
    return val
  }

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector('.service-slider-container');
      const conDiv: any = document.querySelector(`.container-schedule [data-value="${props.appointmentId}"]`)
      if (element && conDiv && props?.popAutoScroll) {
        const rect = conDiv.getBoundingClientRect();
        setTransform(rect.top)
        setTransformX(rect.left)
      }
    };

    const element = document.querySelector('.service-slider-container');
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const CardViewDetailBox = () => {
    return (
      <>

        <CardViewDetail
          key={props.key ?? 1}
          fixPosition={props?.fixPosition}
          transform={transform}
          dropDown={dropDown}
          appointment={appointment}
          statusColor={statusColor}
          positioncard={positionCard}
          position={(props?.popAutoScroll) ? 'fixed' : 'absolute'}
          transformx={transformX}
          className={`card-ap-detail card-detail-${props.appointmentId}`}
        >
          <div className='action-bars position-absolute top-0 d-flex align-items-center'>
            {!props.hideMenu
              && <div style={{ marginLeft: 'auto' }}>
                <Dropdown drop={'down'} align={'end'}>
                  <Dropdown.Toggle variant="link" bsPrefix="p-0" className="text-black-50 btn-more">
                    <IconEllipsis />
                  </Dropdown.Toggle>
                  <Dropdown.Menu align={'end'} style={{ fontSize: '16px' }}>
                    {props.status !== 'DONE' &&
                      <>
                        <Dropdown.Item onClick={() => {
                          window.open(`${routeName.treatmentsEmrHistory}?patientId=${appointment?.patientId}`, '_blank')
                          props?.onClose && props?.onClose(props.appointmentId)
                          setDropDown(false)
                        }}>{t('APPOINTMENT.VIEW_EMR')}
                        </Dropdown.Item>
                        <Dropdown.Divider style={{ borderTop: 'none' }} />
                        <Dropdown.Item onClick={(e) => (
                          props?.onEdit ? (
                            props?.onEdit(props.appointmentId),
                            props?.onClose && props?.onClose(props.appointmentId),
                            setDropDown(false)) : (
                            props?.onClose && props?.onClose(props.appointmentId),
                            setDropDown(false)
                          ))}
                        // style={permissions.isUpdate.disabled ? { filter: 'grayscale(1)', opacity: '0.5', pointerEvents: 'none' } : {}}
                        >
                          <ImageLabel src={iconEdit} className="pr-1" />
                          {t('APPOINTMENT.EDIT')}
                        </Dropdown.Item>
                        <FunctionPrint
                          content={() => pdfRef.current}
                          removeIfram
                          trigger={() => <Dropdown.Item onClick={onPrintAppointment}
                          // style={permissions.isExport.disabled ? { filter: 'grayscale(1)', opacity: '0.5', pointerEvents: 'none' } : {}}
                          >
                            <ImageLabel src={print} className="pr-1" />
                            {t('APPOINTMENT.PRINT')}
                          </Dropdown.Item>}
                          style={'@page { size: A5 landscape !important;  margin: 0cm;  } @media print { body { -webkit-print-color-adjust: exact; } '}
                        />
                        {/* <Dropdown.Item onClick={onPrintAppointment}
                          style={permissions.isExport.disabled ? { filter: 'grayscale(1)', opacity: '0.5', pointerEvents: 'none' } : {}}
                        >
                          <ImageLabel src={print} className="pr-1" />
                          {t('APPOINTMENT.PRINT')}
                        </Dropdown.Item> */}
                      </>
                      ||
                      <>
                        <Dropdown.Item onClick={() => {
                          window.open(`${routeName.treatmentsEmrHistory}?patientId=${appointment?.patientId}`, '_blank')
                          props?.onClose && props?.onClose(props.appointmentId)
                          setDropDown(false)
                        }}>{t('APPOINTMENT.VIEW_EMR')}
                        </Dropdown.Item>
                        <Dropdown.Divider style={{ borderTop: 'none' }} />
                        <Dropdown.Item onClick={(e) => (
                          props?.onEditSpecialInfo ? (
                            props?.onEditSpecialInfo(props.appointmentId),
                            props?.onClose && props?.onClose(props.appointmentId),
                            setDropDown(false)) : (
                            props?.onClose && props?.onClose(props.appointmentId),
                            setDropDown(false)
                          ))}>
                          <ImageLabel src={iconEdit} className="pr-1" />
                          {t('APPOINTMENT.EDIT_SPECIALINFO')}
                        </Dropdown.Item>
                      </>
                    }


                  </Dropdown.Menu>
                </Dropdown>
              </div>}
            <div style={{ marginLeft: '10px' }}>
              <div
                className='cursor-pointer close-popup d-flex align-items-center'
                onClick={(e) => {
                  setDropDown(false)
                  if (props?.onClose) props?.onClose(props.appointmentId)
                }}>
                <ClosePopup />
              </div>
            </div>
          </div>
          <div className="position-relative">
            <Typography className='text-ellipsis' sx={{ color: colors.themeMainColor, fontWeight: 500 }}>{handlePatientCode() || ''} {handlePatientCode() ? ' | ' : ''} {appointment?.patientFullname}</Typography>
            <Box className={`spacial-info d-flex`}>
              <Box className={`mr-1 ${!isExpanded ? 'text-ellipsis' : ''}`}>
                <Typography className='title d-inline align-text-bottom' sx={{ svg: { width: '16px', height: '16px' } }}>
                  <IconStar stroke={colors.themeMainColor} />
                  <span className='ml-1 align-middle'>{t('REGISTER.SPACIAL_INFO')}</span>
                  <span className='px-1 align-middle'>:</span>
                </Typography>
                <Typography ref={specialInfoRef} className='d-inline'>
                  {appointment?.specialInfo || '-'}
                </Typography>
              </Box>
              {(appointment?.specialInfo && sizeSpecialBox() > 190) && (
                <IconButton onClick={() => setIsExpanded(!isExpanded)} className='p-0 mt-1 ml-auto btn-expanded d-flex align-items-center' sx={{ boxShadow: 'none !important' }}>
                  <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: colors.textPrimary, transform: `rotate(${isExpanded ? '180deg' : '0deg'})` }} />
                </IconButton>
              ) || <></>}
            </Box>
            <Divider className="mx-0 my-1" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
            <Box className='d-flex align-items-start' sx={{ svg: { width: '16px' } }}>
              <Box sx={{ flex: 'none', width: '16px' }}>
                <Icons.Schedule color={colors.themeMainColor} />
              </Box>
              <Typography className='ml-2' sx={{ flex: 1, paddingTop: '3px' }}>{`${t('DOCTOR_PREFIX')} ${appointment?.doctorFullname}`}</Typography>
            </Box>
            <Box className='d-flex align-items-start'>
              <Box sx={{ flex: 'none', width: '16px' }}><Image src={iconTime} style={{ width: '15px' }} /></Box>
              <Typography className='ml-2' sx={{ flex: 1, paddingTop: '3px' }}>{`${moment(appointment?.timeStart, 'HH:mm').format('HH.mm')} - ${moment(appointment?.timeStart, 'HH:mm').add(appointment?.durationMinute, 'minute').format('HH.mm')} ${t('TIME_UNIT')}`}</Typography>
            </Box>
            {!props.isNotShowPhoneNumber && <Box className='d-flex align-items-start'>
              <Box sx={{ flex: 'none', width: '16px' }}><Image src={phone} style={{ width: '14px' }} /></Box>
              <Typography className='ml-2' sx={{ flex: 1, paddingTop: '3px' }}>{`${!_.isEmpty(appointment?.phone) ? formatPhoneNumber(String(appointment?.phone) || '') : '-'}`}</Typography>
            </Box>}
            <Box className='d-flex align-items-start'>
              <Box sx={{ flex: 'none', width: '16px', svg: { width: '14px', path: { stroke: colors.themeMainColor } } }}>
                <ThemeIcon.Treatment />
              </Box>
              <Typography className='ml-2' sx={{ flex: 1, paddingTop: '3px' }}>{appointment?.appointmentOperatives?.map((op: AppointmentOperativeInterface) => (op.operativeName ? op.operativeName : op.operativeTypeName)).join(', ')}</Typography>
            </Box>
            {appointment?.hasLab === 'HAS' || appointment?.hasXray === 'HAS' && (
              <Box className='d-flex align-items-start'>
                <Box sx={{ opacity: 0, flex: 'none', width: '16px', svg: { width: '14px' } }}>
                  <ThemeIcon.Treatment />
                </Box>
                <Box className='d-flex ml-2 my-1'>
                  <LabTag className='mr-1' sx={{ display: appointment?.hasLab === 'HAS' ? '' : 'none' }}>LAB</LabTag>
                  <LabTag sx={{ display: appointment?.hasXray === 'HAS' ? '' : 'none' }}>X-Ray</LabTag>
                </Box>
              </Box>
            ) || <></>}
            <Box className='d-flex align-items-start'>
              <Typography sx={{ flex: 'none' }}>{t('APPOINTMENT.APPOINT_PRICE')} :</Typography>
              <Typography className='ml-1' sx={{ flex: 1 }}>{`${numberFormat(appointment?.price) || '-'} ${t('CURRENCY_CHANGE.TH')}`}</Typography>
            </Box>
            <Box className='d-flex align-items-start'>
              <Typography sx={{ flex: 'none', width: '75px' }}>{t('APPOINTMENT.FORM.PRACTICE')} :</Typography>
              <TypographyNote className='ml-1' sx={{ flex: 1 }}>{valPractice(appointment?.practice || '-') || '-'}</TypographyNote>
            </Box>
            <Box className='d-flex align-items-start'>
              <Typography sx={{ flex: 'none', width: '75px' }}>{t('APPOINTMENT.FORM.REMARK')} :</Typography>
              <TypographyNote className='ml-1' sx={{ flex: 1 }}>{valPractice(appointment?.remark || '-') || '-'}</TypographyNote>
            </Box>
            <Box className='d-flex align-items-start'>
              <Typography sx={{ flex: 'none', width: '75px' }}>{t('APPOINTMENT.FORM.CREATE_BY')} :</Typography>
              <Typography className='ml-1' sx={{ flex: 1 }}>{appointment?.createdBy || '-'} {t('APPOINTMENT.FORM.DATEOF')} {dateTimeToView(appointment?.createdAt, false)}</Typography>
            </Box>
            <Box className='d-flex align-items-start'>
              <Typography sx={{ flex: 'none', width: '75px' }}>{t('APPOINTMENT.FORM.UPDATE_BY')} :</Typography>
              <Typography className='ml-1' sx={{ flex: 1 }}>{appointment?.updatedBy || '-'} {t('APPOINTMENT.FORM.DATEOF')} {dateTimeToView(appointment?.updatedAt, false)}</Typography>
            </Box>
            {appointment?.statusAppointment === 'CANCEL' &&
              <Box className='d-flex align-items-start'>
                <Typography sx={{ flex: 'none', width: '120px', color: 'red' }}>{t('APPOINTMENT.FORM.REMARK_CANCEL')} :</Typography>
                <TypographyNote className='ml-1' sx={{ flex: 1 }}>{appointment?.remarkCancel || '-'}</TypographyNote>
              </Box>
            }
          </div>
        </CardViewDetail>

        <Box ref={pdfRef} className='print-show' height={'100%'} >
          <Box sx={{ position: 'relative', height: '100%', backgroundColor: 'white', '.MuiTypography-root': { fontSize: '13px', fontWeight: 300 }, '.MuiTypography-root.header-branch-name': { fontSize: '14px', fontWeight: 500 } }}>
            <Box className={'pdf-ap-header'} display={'flex'} justifyContent={'space-between'}>
              <Box sx={{
                flexBasis: '20%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                img: { height: '70px', width: 'fit-content' }
              }}>
                <img src={branchInfo?.branchLogo} />
              </Box>
              <Box textAlign={'center'} padding={'0 20px'} flexBasis={'auto'}>
                <Box marginBottom={'8px'}>
                  <Typography className={'header-branch-name'} >
                    {branchInfo?.branchName}
                  </Typography>
                  <Typography className={'header-branch-name'} >
                    {branchInfo?.branchNameEn}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    {i18n.language === 'th' ? branchInfo?.branchFullAddress : branchInfo?.branchFullAddressEn}
                  </Typography>
                  <Typography>
                    {branchInfo?.branchPhone}
                  </Typography>
                </Box>

              </Box>
              <Box flexBasis={'20%'} display={'flex'} justifyContent={'flex-end'} alignItems={'flex-start'}>
                <Box border={'1px solid #b8b8b8'} padding={'2px 4px'} width={'fit-content'}>
                  <Typography whiteSpace={'nowrap'} className={'header-branch-name'}>
                    {t('APPOINTMENT.PDF.APPOINTMENT_NUMBER', { index: appointment?.appointmentNumber })}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box className={'pdf-ap-body'} margin={'16px 0 0 0'}>
              <Box display={'flex'} justifyContent={'space-between'} marginBottom={'8px'}>
                <Box display={'flex'} gap={'12px'}>
                  <Typography whiteSpace={'nowrap'}>
                    {t('APPOINTMENT.PDF.PATIENT_CN_NUMBER', { number: appointment?.cnNumber })}
                  </Typography>
                  <Typography whiteSpace={'nowrap'}>
                    {t('APPOINTMENT.PDF.PATIENT_NAME', { patientName: `${appointment?.prefixName} ${appointment?.patientFullname}` })}
                  </Typography>
                </Box>
                <Box display={'flex'}>
                  <Typography whiteSpace={'nowrap'}>
                    {t('APPOINTMENT.PDF.DOCTOR_NAME', { doctorName: appointment?.doctorFullname })}
                  </Typography>
                </Box>
              </Box>
              <Box display={'flex'} marginBottom={'8px'} gap={'12px'}>
                <Typography whiteSpace={'nowrap'}>
                  {t('APPOINTMENT.PDF.DATE', { date: appointment?.date ? toBuddhistYear(moment(appointment?.date), 'D MMMM YYYY') : '' })}
                </Typography>
                <Typography whiteSpace={'nowrap'}>
                  {t('APPOINTMENT.PDF.TIME', { time: `${appointment?.timeStart?.substring(0, 5)} ${t('TIME_UNIT')}` })}
                </Typography>
              </Box>
              <Divider sx={{ margin: '0 !important', borderColor: '#b8b8b8 !important', height: '0.5px !important' }} />
            </Box>

            <Box className={'pdf-ap-body-treatment'} display={'flex'} flexDirection={'column'} gap={'24px'} margin={'8px 0 '}>
              <Box display={'flex'}>
                <Box flexBasis={'12%'}>
                  <Typography whiteSpace={'nowrap'}>
                    {t('APPOINTMENT.PDF.TREATMENT')}
                  </Typography>
                </Box>
                <Box flexBasis={'88%'}>
                  <Typography >
                    {appointment?.appointmentOperatives?.map((item, index) => (
                      item?.operativeName
                    )).join(',')}
                  </Typography>
                </Box>
              </Box>
              <Box display={'flex'}>
                <Box flexBasis={'12%'}>
                  <Typography whiteSpace={'nowrap'}>
                    {t('APPOINTMENT.PDF.PRACTICE')}
                  </Typography>
                </Box>
                <Box flexBasis={'88%'}>
                  <Typography >
                    {appointment?.practice}
                  </Typography>
                </Box>
              </Box>
              <Box display={'flex'}>
                <Box flexBasis={'12%'}>
                  <Typography whiteSpace={'nowrap'}>
                    {t('APPOINTMENT.PDF.REMARK')}
                  </Typography>
                </Box>
                <Box flexBasis={'88%'}>
                  <Typography >
                    {appointment?.remark}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box className={'pdf-ap-footer'} textAlign={'right'} paddingTop={'24px'} >
              <Box>
                <Typography >
                  {`${t('APPOINTMENT.PDF.AUTH')}: ${user?.fullname}`}
                </Typography>
              </Box>
              <Box>
                <Typography >
                  {`${dateToView(moment())}  ${timeToView()} ${t('TIME_UNIT')}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

      </>

    )
  }

  if (size[0] > 1366) {
    return (
      <CardViewDetailBox />
    )
  }

  return (
    <>
      <Modal
        open={!!props.appointmentId && props?.showPopup}
        onClose={() => props?.onClose && props?.onClose(props.appointmentId)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '.card-ap-detail': {
            position: 'unset',
            transform: 'none '
          }
        }}
      >
        <CardViewDetailBox />
      </Modal>
    </>
  )
}


import { useEffect, useState, useCallback, useRef } from 'react'
import { Box, Grid, MenuItem } from '@mui/material'
import _ from 'lodash'
import * as useStyles from 'features/admin/clinic-management/style'

//tls
import { useTranslation } from 'react-i18next'

//api
import ComponentApi from 'api/components-api'

//component
import InputTextField from 'component/Input/InputTextField'
import BasicSelect from 'component/Select/BasicSelect'
import SelectAddress from 'component/Select/SelectAddress'
import InputTextarea from 'component/Input/InputTextarea'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'

interface Data {
  onchange?: (value: any) => void
  onkeypress?: (value: any) => void
  error: any
  valArr?: any
  resetErrorMessage: () => void
}

export default function AboutClinic(props: Data) {
  const { t } = useTranslation()
  const [dataABout, setDataAbout] = useState<any>(props.valArr || {})
  const [countries, setCountries] = useState<any[]>([])

  useEffect(() => {
    loadCountries()
  }, [])

  useEffect(() => {
    setDataAbout(props.valArr)
  }, [props.valArr])


  useEffect(() => {
    if (props.onchange) props.onchange(dataABout)
  }, [dataABout])

  const loadCountries = useCallback(async () => {
    if (sessionStorage.getItem('countries')) {
      const items: any = sessionStorage.getItem('countries')
      setCountries(JSON.parse(items))
    } else {
      const resCountries = await ComponentApi.findAllCountries()
      if (resCountries.status === 200) {
        const newOption = []
        const newCountries = resCountries.data
        for (const country of newCountries) {
          const { countryId, countryNameTh } = country
          newOption.push({ type: 'text', name: countryNameTh, value: countryId })
        }
        setCountries(_.orderBy(newOption, ['name'], ['asc', 'desc']))
        sessionStorage.setItem('countries', JSON.stringify(_.orderBy(newOption, ['name'], ['asc', 'desc'])))
      }
    }
  }, [])

  return (
    <div className="px-4 pt-4 pb-4">
      <useStyles.TitleFirst>
        <p className={`d-flex align-items-center mb-3`}>{t('CLINIC_MANAGEMENT.TITLE.GENERAL_INFORMATION')}</p>
      </useStyles.TitleFirst>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <BasicSelect
            name="Package"
            label="Package"
            selectId="Package"
            labelId="Package"
            value={dataABout?.package || ''}
            style={{ fontSize: '16px' }}
            options={[
              <MenuItem key="1" value="STANDARD">
                STANDARD
              </MenuItem>,
              <MenuItem key="2" value="PREMIUM">
                PREMIUM
              </MenuItem>,
              <MenuItem key="3" value="ULTRA">
                ULTRA
              </MenuItem>
            ]}
            onchange={(e) => {
              setDataAbout({ ...dataABout, package: e.target.value, enableApp: e.target.value === 'STANDARD' ? '0' : '1', branchDefaultRegisterApps: (dataABout?.branchDefaultRegisterApps || []).map((val: any) => ({ ...val, isDefaultRegisApp: e.target.value !== 'ULTRA' ? '0' : '1' })) })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <InputTextField
            required
            value={dataABout?.clinicNameTh || ''}
            name="ชื่อภาษาไทย"
            label={t('CLINIC_MANAGEMENT.FORM.NAME_TH')}
            onchange={(e) => {
              setDataAbout({ ...dataABout, clinicNameTh: e.target.value })
              props.resetErrorMessage()
            }}
            helperText={props.error.FIRSTNAME}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <InputTextField
            required
            value={dataABout?.clinicNameEn || ''}
            name="ชื่อภาษาอังกฤษ"
            label={t('CLINIC_MANAGEMENT.FORM.NAME_EN')}
            onchange={(e) => {
              setDataAbout({ ...dataABout, clinicNameEn: e.target.value })
              props.resetErrorMessage()
            }}
            helperText={props.error.LASTNAME}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <InputTextField
            required
            inputProps={{ maxLength: 50 }}
            value={dataABout?.phoneNo || ''}
            name="เบอร์โทรศัพท์"
            label={t('CLINIC_MANAGEMENT.FORM.PHONE')}
            onchange={(e) => {
              setDataAbout({ ...dataABout, phoneNo: e.target.value })
              props.resetErrorMessage()
            }}
            helperText={props.error.PHONE}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <InputTextField
            inputProps={{ maxLength: 50 }}
            value={dataABout?.line || ''}
            name="ไลน์"
            label={t('CLINIC_MANAGEMENT.FORM.LINE')}
            onchange={(e) => {
              setDataAbout({ ...dataABout, line: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <InputTextField
            inputProps={{ maxLength: 50 }}
            value={dataABout?.website || ''}
            name="เว็บไซต์"
            label={t('CLINIC_MANAGEMENT.FORM.WEBSITE')}
            onchange={(e) => {
              setDataAbout({ ...dataABout, website: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <InputTextField
            inputProps={{ maxLength: 50 }}
            value={dataABout?.email || ''}
            name="อีเมล์"
            label={t('CLINIC_MANAGEMENT.FORM.EMAIL')}
            onchange={(e) => {
              setDataAbout({ ...dataABout, email: e.target.value })
            }}
          />
        </Grid>
      </Grid>
      <hr className="hr" style={{ margin: '1.5rem 0' }} />
      <useStyles.TitleFirst>
        <p className={`d-flex align-items-center`}>{t('CLINIC_MANAGEMENT.TITLE.ADDRESS_CLINIC')}</p>
      </useStyles.TitleFirst>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <AutocompleteSelect
            labelId="lb-country"
            inputLabel={t('REGISTER.FORM.COUNTRY')}
            noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
            options={countries}
            getOptionLabel={(option: any) => option.name}
            renderOption={(props, option: any) => (
              <Box key={option} component="li" {...props}>
                {option.name}
              </Box>
            )}
            onchange={(e, value: any) => {
              if (value) {
                const val: number = value.value
                setDataAbout({ ...dataABout, countryId: val })
              }
            }}
            key={'country'}
            value={_.find(countries, (countriesItem) => String(countriesItem.value) === String(dataABout?.countryId)) || null}
            disableClearable
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8} xl={9}>
          <SelectAddress
            patientId=""
            countryId={dataABout?.countryId}
            provinceId={dataABout?.provinceId}
            districtId={dataABout?.districtId}
            subDistrictId={dataABout?.subDistrictId}
            postcode={dataABout?.postcode}
            callbackAddress={(address: any) => {
              setDataAbout({
                ...dataABout,
                provinceId: address.provinceId ? address.provinceId : '',
                districtId: address.districtId ? address.districtId : '',
                subDistrictId: address.subDistrictId ? address.subDistrictId : '',
                postcode: address.postcode ? address.postcode : ''
              })
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextarea
            hookValue={true}
            inputProps={{ maxLength: 255 }}
            name="address"
            key={'address'}
            value={dataABout?.addressTh || ''}
            label={t('CLINIC_MANAGEMENT.FORM.ADDRESS_DETAILS_TH')}
            height={96}
            onchange={(e) => {
              setDataAbout({ ...dataABout, addressTh: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextarea
            hookValue={true}
            inputProps={{ maxLength: 255 }}
            name="address"
            key={'address'}
            value={dataABout?.addressEn || ''}
            label={t('CLINIC_MANAGEMENT.FORM.ADDRESS_DETAILS_EN')}
            height={96}
            onchange={(e) => {
              setDataAbout({ ...dataABout, addressEn: e.target.value })
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

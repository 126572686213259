import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ExportProps } from 'features/report/report-constants'
import TableCustom from 'component/Table/TableCustom'
import TableRowExport from 'component/Pdf/TableRowExport'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { Box, MenuItem, Divider, ClickAwayListener } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import { colors } from 'constants/theme'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import Loading from 'component/Loading'
import TableRowCommon from 'component/Table/TableRowCommon'

/** REPORT */
import OperativeList from './OperativeList'

/** CONSTANTS */
import { getBranch, numberFormat, numberNonDigit, toBuddhistYear } from 'utils/app.utils'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import FucntionPrint from 'component/Print/print'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { routeName } from 'routes/routes-name'
import { dateToView } from 'utils/date.utils'
import InputCheckbox from 'component/Input/InputCheckbox'
import OperativeApi from 'api/setting/treatments/operative.api'
import ButtonCustom from 'component/Button/ButtonCustom'
import { CustomFilterDoctor, ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import { Chip, FormControl, InputLabel, Select } from '@material-ui/core'
import { SettingsSystemDaydreamOutlined } from '@mui/icons-material'
import { handleChangeFilterSelect, renderFilterSelect } from 'features/dashboard/dashboard'

interface OperativeTypeInterface {
  operativeTypeId: number
  operativeTypeName: string
}

export default function OperativeReport() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const componentRefPDF = useRef<HTMLDivElement>(null)
  const momentNowRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  const momentDate = () => momentNowRange

  const [loading, setLoading] = useState<boolean>(true)
  const [branches, setBranches] = useState<any[]>([])

  const [branchId, setBranchId] = useState<any>(getBranch())
  const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
  const [operativeTypes, setOperativeTypes] = useState<any[]>()
  const [operative, setOperative] = useState<any[]>([])
  const [operativeTypeId, setOperativeTypeId] = useState<string>('ALL')
  const [operativeId, setOperativeId] = useState<string>('ALL')
  const [selectOperative, setSelectOperative] = useState<any[]>(['ALL'])

  const [billStatus, setBillStatus] = useState<string>('ALL')
  const [operativePage, setOperativePage] = useState<number>(1)
  const [operativePageLimit, setOperativePageLimit] = useState<number>(10)
  const [operativeRowCount, setOperativeRowCount] = useState<number>(0)
  const [operativeSortBy, setOperativeSortBy] = useState<string>('createAt')
  const [operativeSortType, setOperativeSortType] = useState<string>('ASC')
  const [orderedSelectOperativesType, setOrderedSelectOperativesType] = useState<any[]>([])
  const [operativeTypeMultiple, setOperativeTypeMultiple] = useState<OperativeTypeInterface[]>([])
  const [operativeData, setOperativeData] = useState<any[]>([])
  const [operativeDataSum, setOperativeDataSum] = useState<any[]>([])
  const [item, setItem] = useState<(string | number)[]>(['ALL'])
  const [operativeExport, setOperativeExportData] = useState<any[]>([])
  const [sumOperativeExportData, setSumOperativeExportData] = useState<any[]>([])
  const [fetch, setFetch] = useState<boolean>(false)

  const [openFilterOperative, setOpenFilterOperative] = useState(false)
  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }
    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'OPERATIVE_SUMMARY_MONTH') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })

    /** MASTER DATA */
    ClinicApi.findAllBranches()
      .then(({ data }) => setBranches(data))
      .catch((e) => {
        return
      })

    OperativeTypeApi.findAll({ status: 'ACTIVE', pageLimit: 50 })
      .then(({ data }) => {
        setOperativeTypes(data)
      })
      .catch((e) => {
        return
      })

    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [])

  const fetchOperativeMaster = useEffect(() => {
    let operativeCondition: any = { status: 'ACTIVE', pageLimit: 50 }
    if (operativeTypeId) operativeCondition = { ...operativeCondition, operativeTypeId: operativeTypeId }
    OperativeApi.findAll(operativeCondition)
      .then(({ data }) => {
        setOperative(data)
      })
      .catch((e) => {
        return
      })
  }, [operativeTypeId])

  /** TABLE 1 */
  const fetchOperative = useCallback(() => {
    let condition: any = {
      page: operativePage,
      pageLimit: operativePageLimit,
      branchId,
      billStatus
    }
    if (operativeTypeId !== 'ALL') condition = { ...condition, operativeType: operativeTypeId }
    if (item[0] !== 'ALL') {
      condition = { ...condition, operativeId: item.join(',') }
    }

    if (operativeSortType) condition = { ...condition, sortType: operativeSortType }
    if (operativeSortBy) condition = { ...condition, sortBy: operativeSortBy }

    if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }

    ReportApi.reportOperative(condition)
      .then(({ headers, data }) => {
        setOperativeData(data.rows)
        setOperativeDataSum(data.sumOperative)
        setOperativeRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
    setFetch(false)
  }, [operativePage, operativePageLimit, operativeSortType, operativeSortBy, fetch])

  const onChangeOperativeSort = (sortByVal: string, sortTypeVal: string) => {
    setOperativeSortType(sortTypeVal)
    setOperativeSortBy(sortByVal)
  }

  const onChangeOperativeRowPerPage = (limit: number) => setOperativePageLimit(limit)
  const onChangeOperativePage = (val: number) => setOperativePage(val)

  const loadOperativeExport = useCallback(async () => {
    let condition: any = {
      page: 1,
      pageLimit: 10000,
      branchId,
      billStatus,
      export: 1
    }

    if (operativeTypeId !== 'ALL') condition = { ...condition, operativeType: operativeTypeId }
    if (item[0] !== 'ALL') {
      condition = { ...condition, operativeId: item.join(',') }
    }

    if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }

    ReportApi.reportOperative(condition)
      .then(({ data }) => {
        setOperativeExportData(data.rows)
        setSumOperativeExportData(data.sumOperative)
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
    setFetch(false)
  }, [fetch])

  const renderData = (data: any, no: number) => {
    const num = no + 1
    const objRenderData = {
      key: num.toString(),
      id: no.toString(),
      obj: data,
      columns: [
        { option: 'TEXT', align: 'left', label: t('REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.SUM_QTY') },
        { option: 'TEXT', align: 'right', label: data.operativeTypeName, class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', label: data.sumQty, class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', label: data.treatmentsPrice, class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', label: data.sumTotal, class: 'text-nowrap' }
      ]
    }
    return <TableRowCommon {...objRenderData} />
  }

  const renderOperativeExport = (data: any, no: number) => {
    const num = no + 1
    return (
      <>
        <TableRowExport
          key={num.toString()}
          id={no.toString()}
          obj={data}
          columns={[
            { option: 'TEXT', align: 'center', label: num, },
            { option: 'TEXT', align: 'left', label: toBuddhistYear(moment(data.createdAt), 'DD/MM/YYYY HH:mm น.') },
            { option: 'TEXT', align: 'left', label: data.cnNumber, },
            { option: 'TEXT', align: 'left', label: data.branchCnNumber, },
            { option: 'TEXT', align: 'left', label: data.fullName, },
            { option: 'TEXT', align: 'left', label: data.operativeTypeName },
            { option: 'TEXT', align: 'left', label: data.operativeName, },
            { option: 'TEXT', align: 'right', label: numberFormat(data.price) },
            { option: 'TEXT', align: 'right', label: data.qty, },
            { option: 'TEXT', align: 'right', label: numberFormat(data.discount), },
            { option: 'TEXT', align: 'right', label: numberFormat(data.additional), },
            { option: 'TEXT', align: 'right', label: numberFormat(data.total), },
            { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid) }
          ]}
        />
      </>
    )
  }

  const renderSumOperative = (data: any, no: number) => {
    const num = no + 1
    return (
      <>
        <TableRowCommon
          key={num.toString()}
          id={no.toString()}
          obj={data}
          columns={[
            { option: 'TEXT', align: 'center', label: num },
            { option: 'TEXT', align: 'left', label: data.operativeTypeName },
            { option: 'TEXT', align: 'center', label: data.qty },
            { option: 'TEXT', align: 'right', label: numberFormat(data.discount) },
            { option: 'TEXT', align: 'right', label: numberFormat(data.additional) },
            { option: 'TEXT', align: 'right', label: numberFormat(data.total) },
            { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid) }
          ]}
        />
      </>
    )
  }
  const renderSumOperativeExport = (data: any, no: number) => {
    const num = no + 1
    return (
      <>
        <TableRowExport
          key={num.toString()}
          id={no.toString()}
          obj={data}
          columns={[
            { option: 'TEXT', align: 'center', label: num },
            { option: 'TEXT', align: 'left', label: data.operativeTypeName },
            { option: 'TEXT', align: 'right', label: data.qty },
            { option: 'TEXT', align: 'center', label: numberFormat(data.discount) },
            { option: 'TEXT', align: 'center', label: numberFormat(data.additional) },
            { option: 'TEXT', align: 'center', label: numberFormat(data.total) },
            { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid) }
          ]}
        />
      </>
    )
  }
  /** UseEffect */
  useEffect(() => {
    fetchOperative()
  }, [fetchOperative])

  useEffect(() => {
    loadOperativeExport()
  }, [loadOperativeExport])

  const handleChangeSelectItem = (e: any) => setItem(handleChangeFilterSelect(e))

  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.OPERATIVE_SUMMARY_MONTH`)}`,
    sheetName: `${t(`REPORT.REPORT_NAME.OPERATIVE_SUMMARY_MONTH`)}`
  })

  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.#NO', align: 'center', class: 'text-nowrap', width: 45 },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.DATE', align: 'left', class: 'text-nowrap', width: 110 },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.CN_NUMBER', align: 'left', class: 'text-nowrap', width: 65 },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.BRANCH_CN_NUMBER', align: 'left', class: 'text-nowrap', width: 85 },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.FULL_NAME', align: 'left', },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.OPERATIVE_TYPE', align: 'left', },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.LIST', align: 'left', },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.PRICE', align: 'right', class: 'text-nowrap', width: 65 },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.QTY', align: 'center', class: 'text-nowrap', width: 65 },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.DISCOUNT', align: 'right', class: 'text-nowrap', width: 65 },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.ADDITIONAL', align: 'right', class: 'text-nowrap', width: 65 },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.TOTAL_PRICE', align: 'right', class: 'text-nowrap', width: 65 },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.TOTAL_PAID', align: 'right', class: 'text-nowrap', width: 65 }
  ]

  const headCellTotal = [
    { id: 'message', disablePadding: false, labelKey: '#', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.LIST', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.QTY', align: 'center', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.DISCOUNT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.ADDITIONAL', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.TOTAL_PRICE', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.TOTAL_PAID', align: 'right', class: 'text-nowrap' }
  ]

  const setHeaderSearch = (table: string) => {
    const opType = _.find(operativeTypes, (operativeTypesData: any) => operativeTypesData.operativeTypeId === operativeTypeId)
    const opName = _.find(operative, (operativeData: any) => operativeData.operativeId === operativeId)

    const branchName = _.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')
    const operativeTypeName = opType?.operativeTypeName || t(`REPORT.FILTER.ALL`)
    const operativeName = opName?.operativeName || t(`REPORT.FILTER.ALL`)
    const startDate = rangeDate[0] ? toBuddhistYear(moment(rangeDate[0]), 'DD/MM/YYYY') : '-'
    const endDate = rangeDate[1] ? toBuddhistYear(moment(rangeDate[1]), 'DD/MM/YYYY') : '-'

    const data_table_1 = [
      { name: `${t('REPORT.FILTER.BRANCH')}`, style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '2px' } },
      { name: `${branchName}`, style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '8px' } },
      { name: `${t('REPORT.FILTER.RECEIPT_TYPE')}`, style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '2px' } },
      { name: `${billStatus === 'ALL' ? t(`REPORT.FILTER.ALL`) : t(`REPORT.FILTER.BILL.${billStatus}`)}`, style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '8px' } },
      { name: `${t('REPORT.FILTER.OPERATIVE_TYPE')}`, style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '2px' } },
      { name: `${operativeTypeName}`, style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '8px' } },
      { name: `${t('REPORT.FILTER.OPERATIVE')}`, style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '2px' } },
      {
        name:
          item[0] !== 'ALL'
            ? operative
              .filter((d: any) => item.includes(d.operativeId))
              ?.map((d: any) => d.operativeName)
              .slice(0, 3)
              .join(', ')
            : t(`REPORT.FILTER.ALL`),
        style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '8px' }
      }
    ]

    const data_table_2 = [
      { name: `${t('REPORT.FILTER.DATE_START')}`, style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '2px' } },
      { name: `${startDate}`, style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '5px' } },
      { name: `${t('REPORT.FILTER.DATE_END')}`, style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '2px' } },
      { name: `${endDate}`, style: { width: '1%', whiteSpace: 'nowrap' } }
    ]

    switch (table) {
      case 'table_1':
        return data_table_1
      case 'table_2':
        return data_table_2
      default:
        return [...data_table_1, ...data_table_2, { name: ``, style: {} }]
    }
  }

  return (
    <Card className={'border-0 h-100'}>
      <HeaderCard text={t(`REPORT.REPORT_NAME.OPERATIVE_SUMMARY_MONTH`)} />
      <Card.Body>
        <Card.Title className={'mb-0'}>
          <Row className="mx-0">
            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <FilterSelect
                onchange={(e) => {
                  setBranchId(e.target.value)
                  setOperativePage(1)
                }}
                renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                label={''}
                selectId="select-branch"
                labelId="label-branch"
                value={branchId}
                options={_.map(branches, (b: BranchInterface, index) => {
                  return (
                    <MenuItem key={b.branchId} value={b.branchId}>
                      {b.branchName || b.branchNameEn}
                    </MenuItem>
                  )
                })}
                formControlStyle={{ maxWidth: 'unset !important' }}
                classesOption="style-select-doctors"
              />
            </Col>
            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <FilterSelect
                onchange={(e) => {
                  setBillStatus(e.target.value)
                  setOperativePage(1)
                }}
                renderValue={() => `${t('REPORT.FILTER.RECEIPT_STATUS')}: ${billStatus === 'ALL' ? t(`REPORT.FILTER.ALL`) : t(`REPORT.FILTER.BILL.${billStatus}`)} `}
                label={''}
                selectId="select-bill-status"
                labelId="label-bill-status"
                value={billStatus}
                options={[
                  <MenuItem key="1" value="ALL">
                    {t('REPORT.FILTER.ALL')}
                  </MenuItem>,
                  <MenuItem key="2" value="NORMAL">
                    {t('REPORT.FILTER.BILL.NORMAL')}
                  </MenuItem>,
                  <MenuItem key="3" value="CANCEL">
                    {t('REPORT.FILTER.BILL.CANCEL')}
                  </MenuItem>
                ]}
                formControlStyle={{ maxWidth: 'unset !important' }}
                classesOption="style-select-doctors"
              />
            </Col>

            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <FilterSelect
                onchange={(e) => {
                  setOperativeTypeId(e.target.value)
                  setItem(['ALL'])
                  setOperativePage(1)
                }}
                renderValue={() => `${t('REPORT.FILTER.OPERATIVE_TYPE')}: ${operativeTypeId === 'ALL' ? t('REPORT.FILTER.ALL') : `${_.get(_.find(operativeTypes, { operativeTypeId: operativeTypeId }), 'operativeTypeName' || '')}`}`}
                label={''}
                selectId="select-branch"
                labelId="label-branch"
                value={operativeTypeId}
                options={[
                  <MenuItem key="ALL" value="ALL">
                    {t('REPORT.FILTER.ALL')}
                  </MenuItem>,
                  ..._.map(operativeTypes, (b: OperativeTypeInterface, index) => {
                    return (
                      <MenuItem key={b.operativeTypeId} value={b.operativeTypeId}>
                        {b.operativeTypeName}
                      </MenuItem>
                    )
                  })
                ]}
                formControlStyle={{ maxWidth: 'unset !important' }}
                classesOption="style-select-doctors"
              />
            </Col>

            {/* multiple selections */}
            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <FilterSelect
                multiple
                label={t('')}
                labelId="label-item"
                selectId="select-item"
                onchange={handleChangeSelectItem}
                renderValue={() => `${t('REPORT.FILTER.OPERATIVE')}: ${renderFilterSelect('item', operative, item) === 'ALL' ? t('STATUS.ALL') : renderFilterSelect('item', operative, item)}`}
                value={item}
                options={[
                  <MenuItem
                    key="ALL"
                    value="ALL"
                    sx={{
                      '&.MuiMenuItem-root.Mui-selected': {
                        pointerEvents: _.includes(item, 'ALL') ? `none` : 'auto',
                        backgroundColor: _.includes(item, 'ALL') ? `${colors.themeSecondColor06} !important` : 'transparent',
                        color: _.includes(item, 'ALL') ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                      }
                    }}
                  >
                    {t('STATUS.ALL')}
                  </MenuItem>,
                  _.map(operative, (val: any, indexItem: number) => {
                    return (
                      <MenuItem
                        key={indexItem}
                        value={val.operativeId}
                        sx={{
                          '&.MuiMenuItem-root.Mui-selected': {
                            backgroundColor: _.includes(item, val.operativeId) ? `${colors.themeSecondColor06} !important` : 'transparent',
                            color: _.includes(item, val.operativeId) ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                          }
                        }}
                      >
                        <span className="text-ellipsis" style={{ width: '360px' }} title={val.operativeName}>
                          {val.operativeName}
                        </span>
                      </MenuItem>
                    )
                  })
                ]}
                formControlClass={'not-max-width'}
                classesOption="style-select-item"
                disabled={operativeTypeId === 'ALL'}
              />
            </Col>

            {/* date picker */}
            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <InputSecondNewRangePicker
                inputHeight={32}
                value={rangeDate || emptyRangePicker}
                onchange={(val: any) => {
                  if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                  else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                  setOperativePage(1)
                }}
                label={''}
                allowClear
                onClear={() => setRangeDate(emptyRangePicker)}
              />
            </Col>
            <Col md={1} xl={1} className={'pt-1 '}>
              <ButtonCustom
                onClick={() => {
                  setFetch(true)
                  setOperativePage(1)
                  setLoading(true)
                }}
                textButton={t('INPUT.SEARCH')}
                style={{ height: '30px', marginTop: '5px' }}
              />
            </Col>

            <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
              <UseStyled.DropdownDocument>
                <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                  {t('REGISTER.BUTTON.PRINT_DOCUMENT')}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                  <FucntionPrint
                    content={() => componentRefPDF.current}
                    removeIfram
                    trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                    style={`@page { size: 1122.519685px 793.7007874px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                  />
                </Dropdown.Menu>
              </UseStyled.DropdownDocument>
            </Col>
          </Row>
        </Card.Title>

        <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
          <OperativeList
            page={operativePage}
            pageLimit={operativePageLimit}
            rowCount={operativeRowCount}
            sortBy={operativeSortBy}
            sortType={operativeSortType}
            data={operativeData}
            dataExport={operativeExport}
            onRequestSort={onChangeOperativeSort}
            handleChangeRowsPerPage={onChangeOperativeRowPerPage}
            handleChangePage={onChangeOperativePage}
          />
        </Box>
        <Divider className="mx-0 mt-3 mb-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
        <Box sx={{ maxWidth: '720px' }}>
          {/* <UseStyled.ResponsiveReportTable> */}
          <TableCustom
            page={operativePage}
            pageLimit={operativePageLimit}
            sortBy={operativeSortBy}
            sortType={operativeSortType}
            rowCount={operativeRowCount}
            onSort={onChangeOperativeSort}
            setPageLimit={onChangeOperativeRowPerPage}
            setPage={onChangeOperativePage}
            headCells={headCellTotal}
            rowsData={_.map(sumOperativeExportData, (d: any, index: number) => {
              return renderSumOperative(d, index)
            })}
            hidePagination={true}
            tableMinWidth={720}
            customScroll
          />
          {/* </UseStyled.ResponsiveReportTable> */}
        </Box>
      </Card.Body>
      <Loading show={loading} type="fullLoading" />

      <div className="print-show" ref={componentRef}>
        <ExportData
          headCells={t(`REPORT.REPORT_NAME.OPERATIVE_SUMMARY_MONTH`)}
          componant={
            <>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(setHeaderSearch('table_1'), (val: any) => {
                        return <td style={{ textAlign: 'left', fontSize: 10, fontWeight: 500, border: 'none', ...val.style }}>{t(val.name)}</td>
                      })}
                    </tr>
                  </thead>
                </table>
              </UseStyled.Styles>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(setHeaderSearch('table_2'), (val: any) => {
                        return <td style={{ textAlign: 'left', fontSize: 10, fontWeight: 500, border: 'none', ...val.style }}>{t(val.name)}</td>
                      })}
                    </tr>
                  </thead>
                </table>
              </UseStyled.Styles>
              <br></br>
              <ExportDataComponent
                headCells={headCells}
                operativeExport={operativeExport}
                renderOperativeExport={renderOperativeExport}
                headCellTotal={headCellTotal}
                renderSumOperativeExport={renderSumOperativeExport}
                sumOperativeExportData={sumOperativeExportData}
              />
            </>
          }
        />
      </div>

      <div className="print-show" ref={componentRefPDF}>
        <ExportData
          headCells={t(`REPORT.REPORT_NAME.OPERATIVE_SUMMARY_MONTH`)}
          componant={
            <>
              <UseStyled.Styles className="pt-3">
                <div style={{ fontSize: '14px' }}>
                  {_.map(setHeaderSearch('table_pdf'), (val: any, index: number) => {
                    const isOdd = index % 2 !== 0
                    return <span style={{ fontSize: 10, ...val.style }}>{`${val.name}${!isOdd ? ':' : ''}`}</span>
                  })}
                </div>
              </UseStyled.Styles>
              <ExportDataComponent
                headCells={headCells}
                operativeExport={operativeExport}
                renderOperativeExport={renderOperativeExport}
                headCellTotal={headCellTotal}
                renderSumOperativeExport={renderSumOperativeExport}
                sumOperativeExportData={sumOperativeExportData}
              />
            </>
          }
        />
      </div>
    </Card>
  )
}

export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large" style={{ fontSize: 10, fontWeight: 500 }}>
        {props.headCells}
      </div>
      {props.componant}
    </>
  )
}

export function ExportDataComponent(props: any) {
  const { t } = useTranslation()
  return (
    <>
      <UseStyled.Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              {props.headCells.map((val: any, index: number) => (
                <th key={index} style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.width && { width: val.width } }}>
                  {t(val.labelKey)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.operativeExport.map((d: any, index: number) => props.renderOperativeExport(d, index))}
            <tr>
              <td align="right" colSpan={7} style={{ fontSize: 10 }}>
                {t('REPORT.TITLE.SUM')}
              </td>

              <td align="right" colSpan={2} style={{ fontSize: 10, textAlign: 'right' }}>
                {numberFormat(_.sumBy(props.operativeExport, 'qty'), 0)}
              </td>
              <td align="right" colSpan={1} style={{ fontSize: 10, textAlign: 'right' }}>
                {numberFormat(_.sumBy(props.operativeExport, 'discount'))}
              </td>
              <td align="right" colSpan={1} style={{ fontSize: 10, textAlign: 'right' }}>
                {numberFormat(_.sumBy(props.operativeExport, 'additional'))}
              </td>
              <td align="right" colSpan={1} style={{ fontSize: 10, textAlign: 'right' }}>
                {numberFormat(_.sumBy(props.operativeExport, 'total'))}
              </td>
              <td align="right" colSpan={1} style={{ fontSize: 10, textAlign: 'right' }}>
                {numberFormat(_.sumBy(props.operativeExport, 'amountPaid'))}
              </td>
            </tr>
          </tbody>
        </table>
      </UseStyled.Styles >

      <br></br>
      <UseStyled.Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(props.headCellTotal, (val: any, index: number) => {
                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {_.map(props.sumOperativeExportData, (d: any, index: number) => {
              return props.renderSumOperativeExport(d, index)
            })}
          </tbody>
        </table>
      </UseStyled.Styles>
    </>
  )
}

import { useCallback, useEffect, useRef, useState } from 'react'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { routeName } from 'routes/routes-name'
import { useHistory } from 'react-router-dom'
import { Box, ClickAwayListener, FormControlLabel, MenuItem, RadioGroup, Typography } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'
import * as XLSX from "xlsx";

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/**API */
import { getBranch, toBuddhistYear } from 'utils/app.utils'
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'

/**COMPONENT */
import RevenueTransactionList from './RevenueTransactionList'
import Loading from 'component/Loading'
import FucntionPrint from 'component/Print/print'
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import InputSecondNewDatePicker, { PICKER_VIEWS } from 'component/Input/InputSecondNewDatePicker'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

/**STYLE */
import * as UseStyled from 'features/report/useStyled'
import InputRadio from 'component/Input/InputRadio'
import ButtonCustom from 'component/Button/ButtonCustom'
import InputCheckbox from 'component/Input/InputCheckbox'
import DoctorScheduleApi from 'api/counter/doctor-schedule.api'
import { CustomFilterDoctor, ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { colors } from 'constants/theme'
import { SpanClearFilter } from 'features/report/useStyled'
import { SORT_TYPE } from 'constants/common'
import ComponentApi from 'api/components-api'
import SearchPatients from 'component/SearchPatients'

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

export interface DentistInterface {
  dentistId: number
  dentistFullname: string
}

export default function RevenueTransaction() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const momentNowRange = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  // const momentNowRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  const momentDate = () => momentNowRange
  const [branchId, setBranchId] = useState<any>(getBranch())
  const [branches, setBranches]: any = useState<any[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [sortBy, setSortBy] = useState<string>('createAt')
  const [sortType, setSortType] = useState<string>('ASC')
  const [doctors, setDoctors] = useState<Doctors[]>([])
  const [fetch, setFetch] = useState(false)
  const [dfData, getDfData] = useState<any[]>([])
  const [dfDataExport, setDfDataExport] = useState<any[]>([])
  const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(10)
  const [loading, setLoading] = useState<boolean>(true)
  const [selectDoctors, setSelectDoctors] = useState<any[]>([])
  const [billStatus, setBillStatus] = useState<string>('ALL')
  const [codeForm, setCodeForm] = useState<{ operativeCode: string }>({ operativeCode: '' })
  const [codeTo, setCodeTo] = useState<{ operativeCode: string }>({ operativeCode: '' })
  const [doctorsMultiple, setDoctorsMultiple] = useState<DentistInterface[]>([])
  const [openFilterDoctors, setOpenFilterDoctors] = useState(false)
  const [patientFilter, setPatientFilter] = useState<string>(t('REPORT.REPORT_GROUP.ALL'))
  const [patientId, setPatientId] = useState<number>(0)
  const [operativeCode, setOperativeCode] = useState<any[]>([])

  const handleFilterDoctors = (value: any) => {
    if ((value === 'ALL' && _.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)) || value === 'CLEAR') setSelectDoctors([''])
    else if (value === 'ALL') setSelectDoctors(['ALL', ..._.map(doctors, (d: any) => d.userId)])
    else if (_.includes(selectDoctors, value)) setSelectDoctors(_.filter(selectDoctors, (d) => d !== value))
    else setSelectDoctors([...selectDoctors, value])
  }

  const getFilterUser = () => {
    if (_.includes(selectDoctors, 'ALL')) return ` ${t('REPORT.REPORT_GROUP.ALL')}`
    else {
      return ` ${doctorsMultiple
        .filter((d: DentistInterface) => selectDoctors.includes(d.dentistId))
        ?.map((d: DentistInterface) => d.dentistFullname)
        .join(', ')}`
    }
  }

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }
    // ReportApi.reportList(condition).then(({ data }) => {
    //   const check = _.find(data, (val: any) => String(val.reportKey) === 'DF_LAB') ? true : false
    //   if (!check) {
    //     history.push(routeName.report)
    //     return false
    //   }
    // })

    ClinicApi.findAllBranches()
      .then(({ data }: any) => setBranches(data))
      .catch((e) => {
        return
      })
    ClinicApi.findAllDoctors()
      .then((resp) => {
        setDoctors([...resp.data])
        setSelectDoctors(['ALL', ...resp.data.map((d: any) => d.userId)])
      })
      .catch((e) => {
        return
      })
    ComponentApi.findAllOperativeCode()
      .then(({ data }) => setOperativeCode(data))
      .catch((e) => {
        return
      })
    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [branchId])

  const fetchDF = useCallback(async () => {

    let condition: any = {
      page: page,
      pageLimit: pageLimit,
      branchId: branchId,
      operativeType: null,
      codeForm: codeForm?.operativeCode || '',
      codeTo: codeTo?.operativeCode || '',
      billStatus: billStatus === 'ALL' ? '' : billStatus
    }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }

    if (patientId) condition = { ...condition, patientId: patientId }
    if (rangeDate) {
      if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    }
    condition = { ...condition, doctorId: null }
    if (selectDoctors.length) {
      const doctor = _.filter(selectDoctors, (v: any) => v > 0).join(',')
      if (selectDoctors[0] !== "ALL" && doctor) condition = { ...condition, doctorId: doctor }
    }

    ReportApi.reportRevenueTransaction(condition)
      .then(({ headers, data }) => {
        getDfData(data)
      })
      .catch((e) => setLoading(false))
      .finally(() => {
        setLoading(false)
      })
    setFetch(false)
  }, [page, pageLimit, sortBy, sortType, fetch])

  const fetchDFExport = useCallback(async () => {

    let condition: any = {
      page: page,
      pageLimit: pageLimit,
      branchId: branchId,
      operativeType: null,
      codeForm: codeForm?.operativeCode || '',
      codeTo: codeTo?.operativeCode || '',

      billStatus: billStatus === 'ALL' ? '' : billStatus,
      // operativeCode: null,
      export: 1
    }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }

    if (patientId) condition = { ...condition, patientId: patientId }
    if (rangeDate) {
      if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    }

    if (selectDoctors.length) {
      const doctor = _.filter(selectDoctors, (v: any) => v > 0).join(',')
      if (selectDoctors[0] !== "ALL" && doctor) condition = { ...condition, doctorId: _.filter(selectDoctors, (v: any) => v > 0).join(',') }
    }


    ReportApi.reportRevenueTransaction(condition)
      .then(({ headers, data }) => {
        setDfDataExport(data)
        setRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => {
        setLoading(false)
      })
    setFetch(false)
  }, [page, pageLimit, sortBy, sortType, fetch])

  // const { onDownload } = useDownloadExcel({
  //   tableExportRef: componentRef.current,
  //   filename: `${t(`Revenue Transaction Report`)}`,
  //   sheetName: `${t(`Revenue Transaction Report`)}`
  // })

  const exportTableToExcel = () => {
    if (!componentRef.current) return;

    // Read the table element into a worksheet
    const worksheet = XLSX.utils.table_to_sheet(componentRef.current);
    Object.keys(worksheet).forEach((key) => {
      if (key.startsWith("H")) {
        worksheet[key].v = worksheet[key].v.replace("'", '').replace("'", '')
        if (/^[0-9]{2}-[0-9]{2}$/.test(worksheet[key].v))
          worksheet[key].z = "@";
      }
    });

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Revenue Transaction Report");

    // Trigger the download of the Excel file
    XLSX.writeFile(workbook, "Revenue Transaction Report.xlsx");
  };

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

  const handleChangePage = (val: number) => setPage(val)

  useEffect(() => {
    fetchDF()
  }, [fetchDF])
  useEffect(() => {
    fetchDFExport()
  }, [fetchDFExport])

  return (
    <>
      <Card className={'border-0 h-100'}>
        <HeaderCard text={t(`Revenue Transaction Report `)} />
        <Card.Body>
          <Card.Title className={'mb-0'}>
            <Row className="mx-0">
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setBranchId(e.target.value)
                    setPage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName')} `}
                  label={''}
                  selectId="select-branch"
                  labelId="label-branch"
                  value={branchId}
                  options={_.map(branches, (b: BranchInterface, index) => {
                    return (
                      <MenuItem key={b.branchId} value={b.branchId}>
                        {b.branchName || b.branchNameEn}
                      </MenuItem>
                    )
                  })}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <InputSecondNewRangePicker
                  inputHeight={32}
                  value={rangeDate || emptyRangePicker}
                  onchange={(val: any) => {
                    console.log('val', [moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')]);

                    if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                    else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                  }}
                  label={''}
                  allowClear
                  onClear={() => setRangeDate(emptyRangePicker)}
                />
              </Col>
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setBillStatus(e.target.value)
                    setPage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.RECEIPT_STATUS')}: ${billStatus === 'ALL' ? t(`REPORT.FILTER.ALL`) : t(`REPORT.FILTER.BILL.${billStatus}`)} `}
                  label={''}
                  selectId="select-bill-status"
                  labelId="label-bill-status"
                  value={billStatus}
                  options={[
                    <MenuItem key="1" value="ALL">
                      {t('REPORT.FILTER.ALL')}
                    </MenuItem>,
                    <MenuItem key="2" value="PAID">
                      {t('REPORT.FILTER.BILL.PAID')}
                    </MenuItem>,
                    <MenuItem key="3" value="CANCEL">
                      {t('REPORT.FILTER.BILL.CANCEL')}
                    </MenuItem>

                  ]}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>
              <Col sm={6} md={4} xl={3} className="pl-md-0 pt-2">
                <Box className="position-relative" sx={{ '.MuiOutlinedInput-input': { paddingLeft: '95px !important' } }}>
                  <Typography className="position-absolute top-50 translate-middle-y" sx={{ left: '18px', zIndex: 1 }}>
                    {t('Code Form')}:
                  </Typography>
                  <AutocompleteSelect
                    labelId="code-form"
                    noOptionsText={t('NOT_FOUND')}
                    options={operativeCode}
                    getOptionLabel={(option: any) => option?.operativeCode === "ALL" ? t('REPORT.REPORT_GROUP.ALL') : (option?.operativeCode || '')}
                    renderOption={(props, option: any) => (
                      <Box key={option} component="li" {...props}>
                        {option.operativeCode === "ALL" ? t('REPORT.REPORT_GROUP.ALL') : option.operativeCode}
                      </Box>
                    )}
                    onchange={(e, value: any) => {
                      setPage(1)
                      setCodeForm(value)
                    }}
                    value={codeForm}
                    height={32}
                    disabledBorder
                  // disableClearable
                  />
                </Box>
              </Col>
              <Col sm={6} md={4} xl={3} className="pl-md-0 pt-2">
                <Box className="position-relative" sx={{ '.MuiOutlinedInput-input': { paddingLeft: '70px !important' } }}>
                  <Typography className="position-absolute top-50 translate-middle-y" sx={{ left: '12px', zIndex: 1 }}>
                    {t('Code To')}:
                  </Typography>
                  <AutocompleteSelect
                    labelId="code-to"
                    noOptionsText={t('NOT_FOUND')}
                    options={operativeCode}
                    getOptionLabel={(option: any) => option?.operativeCode === "ALL" ? t('REPORT.REPORT_GROUP.ALL') : (option?.operativeCode || '')}
                    renderOption={(props, option: any) => (
                      <Box key={option} component="li" {...props}>
                        {option.operativeCode === "ALL" ? t('REPORT.REPORT_GROUP.ALL') : option.operativeCode}
                      </Box>
                    )}
                    onchange={(e, value: any) => {
                      setPage(1)
                      setCodeTo(value)
                    }}
                    value={codeTo}
                    height={32}
                    disabledBorder
                  // disableClearable
                  />
                </Box>
              </Col>
              <Col xs={'12'} sm={6} xl={3} xxl={3} className={'pt-2 px-1'}>
                <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
                  <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
                    <AutocompleteSelect
                      open={openFilterDoctors}
                      multiple
                      labelId="label-doctors"
                      options={['ALL', ...doctors, 'CLEAR']}
                      getOptionLabel={(option) => _.find(doctors, { userId: option.userId })?.fullname || '-'}
                      renderOption={(props, option) => {
                        if (option === 'ALL') {
                          return (
                            <ListFilterDoctors {...props} key={option} component="li" value={option}>
                              <InputCheckbox
                                label={t('REPORT.REPORT_GROUP.ALL')}
                                checked={_.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)}
                                indeterminate={_.size(selectDoctors) > 1 && _.size(selectDoctors.filter((item) => typeof item === 'number')) !== _.size(doctors)}
                                className={'pe-none'}
                              />
                            </ListFilterDoctors>
                          )
                        } else if (option === 'CLEAR') {
                          return (
                            <Box {...props} key={option} component="li" value={option} sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
                              <SpanClearFilter className="pe-none">{t('REPORT.FILTER.CLEAR_FILTER')}</SpanClearFilter>
                            </Box>
                          )
                        } else {
                          return (
                            <ListFilterDoctors {...props} key={option.userId} component="li" value={option.userId}>
                              <InputCheckbox label={option.fullname} checked={_.includes(selectDoctors, option.userId)} className={'pe-none'} />
                            </ListFilterDoctors>
                          )
                        }
                      }}
                      renderTags={(tagValue, getTagProps) => {
                        return (
                          <>
                            <span className="pr-1">{t('USER_DOCTOR')}:</span>
                            {!openFilterDoctors &&
                              ((_.size(tagValue.filter((item: any) => typeof item === 'number')) === _.size(doctors) && <span>{t('REPORT.REPORT_GROUP.ALL')}</span>) || (
                                <Box className="text-ellipsis">
                                  {_.map(tagValue, (option: any, index: number) => {
                                    return (
                                      <span>
                                        {_.find(doctors, { userId: option })?.fullname}
                                        {index !== _.findLastIndex(tagValue) && option && `,`}
                                      </span>
                                    )
                                  })}
                                </Box>
                              ))}
                          </>
                        )
                      }}
                      onchange={(e, value) => handleFilterDoctors(e?.target?.value || e?.target?.attributes?.value?.value)}
                      noOptionsText={t('NOT_FOUND')}
                      filterOptions={(option) => 'ALL' + option.fullname + 'CLEAR'}
                      value={selectDoctors}
                      height={32}
                      disableClearable
                    />
                  </CustomFilterDoctor>
                </ClickAwayListener>
              </Col>

              <Col sm={6} md={4} xl={4} className={'pt-2 px-1'}>
                <SearchPatients
                  status="ACTIVE"
                  callbackPatientId={setPatientId}
                  patientId={patientId}
                  onClear={() => setPatientId(0)}
                  callbackPatientName={(value) => {
                    setPatientFilter(value)
                  }}
                />
              </Col>
              <Col md={1} xl={1} className={'pt-0'}>
                <ButtonCustom
                  onClick={() => {
                    setFetch(true)
                    setPage(1)
                    setLoading(true)
                  }}
                  textButton={t('INPUT.SEARCH')}
                  style={{ height: '30px', marginTop: '5px' }}
                />
              </Col>

              <Col md={4} xl={2} className={'d-xl-flex justify-content-xl-end ml-xl-auto'}>
                <UseStyled.DropdownDocument>
                  <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                    {t('REGISTER.BUTTON.PRINT_DOCUMENT')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={exportTableToExcel}>{t('Excel')}</Dropdown.Item>
                    {/* <FucntionPrint
                      content={() => componentRef.current}
                      removeIfram
                      trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                      style={`@page { size: 793.7007874px 1122.519685px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                    /> */}
                  </Dropdown.Menu>
                </UseStyled.DropdownDocument>
              </Col>
            </Row>
          </Card.Title>
          <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
            <RevenueTransactionList
              page={page}
              pageLimit={pageLimit}
              rowCount={rowCount}
              sortBy={sortBy}
              sortType={sortType}
              data={dfData}
              dataExport={dfDataExport}
              onRequestSort={onRequestSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              setSortType={setSortType}
              setSortBy={setSortBy}
              componentRef={componentRef}
              filter={{
                branch: `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`,
                rangeDate: rangeDate,
                doctor: getFilterUser(),
                patient: patientFilter,
                billStatus: billStatus === 'ALL' ? t(`REPORT.FILTER.ALL`) : t(`REPORT.FILTER.BILL.${billStatus}`),
                codeFrom: codeForm?.operativeCode || '-',
                codeTo: codeTo?.operativeCode || '-'
              }}
            />
          </Box>
        </Card.Body>
        <Loading show={loading} type="fullLoading" />
      </Card>
    </>
  )
}

import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Divider, Grid, TableSortLabel, Tooltip, Typography } from '@mui/material'
import { ReportsProps, ExportProps } from 'features/report/report-constants'
import { isBuddhistYear, numberFormat, toBuddhistYear } from 'utils/app.utils'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from 'features/report/component/emptyData'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

import { UseSnCode } from 'utils/useSnCode'
import { SORT_TYPE } from 'constants/common'
import { dateTimeToView } from 'utils/date.utils'

export interface DoctorFeeComponent {
  page: number
  pageLimit: number
  rowCount: number
  data: any
  dataExport: any
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  onRequestSort: (sortBy: string, sortType: string) => void
  setSortType: (val: SORT_TYPE) => void
  setSortBy: (val: string) => void
  sortType: string
  sortBy: string
  componentRef: any
  filter?: any
}

interface AccountingIF {
  createdAt: string
  date: string
  paymentNumber: string
  statusOrder: string
  cnNumber: string
  branchCnNumber: string
  snCode: string
  patientFullName: string
  doctorFullName: string
  code: string
  itemName: string
  price: number
  qty: number
  total: number
  overdue: number
  totalOriginal: number
  dfPercentOriginal: number
  dfOriginal: number
  cfOriginal: number
  dfBahtOriginal: number
  cfPercentOriginal: number
  cfBahtOriginal: number
  dfDiscount: number
  cfDiscount: number
  dfDiscountTr: number
  cfDiscountTr: number
  discountTotal: number
  additionalTotal: number
  dfNet: number
  dfNetOriginal: number
  cfNetOriginal: number

  // totalNonVat: number
  // totalBeforeVat: number
  // totalVat: number
  cfNet: number
  totalNet: number
  amountPaid: number
  amountPaidOverdue: number
  receiptAdvance: number
  amountPaidTotal: number
  amountCash: number
  amountTransfer: number
  amountCredit: number
  amountDebit: number
  amountVoucher: number
  amountAdvance: number
  amountTreatmentRight: number
  amountAdvanceCash: number
  amountAdvanceTransfer: number
  amountAdvanceDebit: number
  amountAdvanceCredit: number
  amountAdvanceVoucher: number
  totalPaidCredit: number
  creditCardFee: number
  dfCreditCost: number
  cfCreditCost: number
  totalCreditPercent: number
  dfTotalNet: number
  cfTotalNet: number
  overdueDf: number
  overdueCf: number
  balance: number
  staff: string
  remark: string
  cancelBy: string
  cancelRemark: string
}
export default function AccountingList(props: DoctorFeeComponent) {
  const { t } = useTranslation()

  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
    props.setSortType(sortTypeVal)
    props.setSortBy(sortByVal)
    props.handleChangePage(1)
  }

  const headCells = [
    { id: '1', disablePadding: false, labelKey: 'REPORT.TABLE.DF.#NO', align: 'center', class: 'text-nowrap', width: '30px' },
    { id: '2', disablePadding: false, labelKey: 'วันที่', align: 'center', class: 'text-nowrap', width: '150px' },
    { id: '3', disablePadding: false, labelKey: 'เลขที่ใบเสร็จ', align: 'center', class: 'text-nowrap', width: '150px' },
    { id: '4', disablePadding: false, labelKey: 'สถานะใบเสร็จ', align: 'center', class: 'text-nowrap', width: '250px' },
    { id: '5', disablePadding: false, labelKey: 'รหัสผู้ป่วย', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '6', disablePadding: false, labelKey: 'รหัสผู้ป่วยในสาขา', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '7', disablePadding: false, labelKey: 'รหัสผู้ป่วยพิเศษ', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '8', disablePadding: false, labelKey: 'ชื่อผู้ป่วย', align: 'left', class: 'text-nowrap', width: '350px' },
    { id: '9', disablePadding: false, labelKey: 'ทันตแพทย์', align: 'left', class: 'text-nowrap', width: '350px' },
    { id: '10', disablePadding: false, labelKey: 'Code', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '11', disablePadding: false, labelKey: 'รายการ', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '12', disablePadding: false, labelKey: 'ราคาต่อหน่วย', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '13', disablePaddisng: false, labelKey: 'จำนวน', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '14', disablePadding: false, labelKey: 'ราคาค่ารักษา', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '15', disablePadding: false, labelKey: 'DF%', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '16', disablePadding: false, labelKey: 'ยอดค่ารักษา DF', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '17', disablePadding: false, labelKey: 'CF%', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '18', disablePadding: false, labelKey: 'ยอดค่ารักษา CF', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '19', disablePadding: false, labelKey: 'ส่วนลดโปรโมชั่น DF', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '20', disablePadding: false, labelKey: 'ส่วนลดโปรโมชั่น CF', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '21', disablePadding: false, labelKey: 'ส่วนลดสิทธิการรักษา DF', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '22', disablePadding: false, labelKey: 'ส่วนลดสิทธิการรักษา CF', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '23', disablePadding: false, labelKey: 'Total Discount', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '24', disablePadding: false, labelKey: 'Total Additional', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '25', disablePadding: false, labelKey: 'ยอดรวมสุทธิ DF', align: 'center', class: 'text-nowrap', width: '350px' },
    // { id: '26', disablePadding: false, labelKey: 'มูลค่าสินค้าที่ไม่มีภาษีมูลค่าเพิ่ม', align: 'center', class: 'text-nowrap', width: '350px' },
    // { id: '27', disablePadding: false, labelKey: 'มูลค่าสินค้าก่อนภาษีมูลค่าเพิ่ม', align: 'center', class: 'text-nowrap', width: '350px' },
    // { id: '28', disablePadding: false, labelKey: 'ภาษีขาย', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '29', disablePadding: false, labelKey: 'ยอดรวมสุทธิ CF', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '30', disablePadding: false, labelKey: 'ยอดรวมสุทธิค่ารักษา', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '31', disablePadding: false, labelKey: 'จ่ายค่ารักษา', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '32', disablePadding: false, labelKey: 'จ่ายค้างชำระ', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '33', disablePadding: false, labelKey: 'Advance', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '34', disablePadding: false, labelKey: 'ยอดรับเงินสุทธิ', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '35', disablePadding: false, labelKey: 'เงินสด', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '36', disablePadding: false, labelKey: 'เงินโอน', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '37', disablePadding: false, labelKey: 'เดบิด', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '38', disablePadding: false, labelKey: 'เครดิต', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '39', disablePadding: false, labelKey: 'Voucher', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '40', disablePadding: false, labelKey: 'สิทธิการรักษา', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Clear Advance', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '42', disablePadding: false, labelKey: 'Adv เงินสด', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '43', disablePadding: false, labelKey: 'Adv เงินโอน', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '44', disablePadding: false, labelKey: 'Adv เดบิต', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '45', disablePadding: false, labelKey: 'Adv เครดิต', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '45', disablePadding: false, labelKey: 'Adv Voucher', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '46', disablePadding: false, labelKey: 'ยอดรวมเครดิต', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '47', disablePadding: false, labelKey: 'Credit%', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '48', disablePadding: false, labelKey: 'DF%', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '49', disablePadding: false, labelKey: 'Clinic%', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '50', disablePadding: false, labelKey: 'ยอดรวมเครดิต%', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '51', disablePadding: false, labelKey: 'DF สุทธิ', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '52', disablePadding: false, labelKey: 'Clinic สุทธิ', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '53', disablePadding: false, labelKey: 'ค้างชำระ DF', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '54', disablePadding: false, labelKey: 'ค้างชำระ CF', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '55', disablePadding: false, labelKey: 'Balance', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '56', disablePadding: false, labelKey: 'ผู้บันทึก', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '57', disablePadding: false, labelKey: 'รายละเอียด', align: 'left', class: 'text-nowrap', width: '350px' },
    { id: '58', disablePadding: false, labelKey: 'หมายเหตุการยกเลิก', align: 'left', class: 'text-nowrap', width: '350px' },
    { id: '59', disablePadding: false, labelKey: 'ผู้ยกเลิก', align: 'center', class: 'text-nowrap', width: '350px' }
  ]
  const headTotalCells = [
    { id: '1', disablePadding: false, labelKey: 'รายการ', align: 'center', class: 'text-nowrap', },
    { id: '2', disablePadding: false, labelKey: 'ยอดรวม', align: 'center', class: 'text-nowrap', },
  ]
  if (!snType) {
    headCells.splice(6, 1)
  }

  const renderData = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
    if (render === 'data') {
      const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
      const accounting: AccountingIF = data
      const objRenderData = {
        key: num.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: num, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: dateTimeToView(accounting.createdAt), class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.paymentNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: t(`REPORT.BILL_STATUS.${accounting.statusOrder}`), class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.cnNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.branchCnNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.snCode, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.patientFullName, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.doctorFullName, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: exportData && accounting.code ? `'${accounting.code}'` : accounting.code || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.itemName, class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.price), class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.qty, class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.totalOriginal), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.dfPercentOriginal), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.dfBahtOriginal), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.cfPercentOriginal), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.cfBahtOriginal), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.dfDiscount), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.cfDiscount), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.dfDiscountTr), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.cfDiscountTr), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.discountTotal), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.additionalTotal), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.dfNetOriginal), class: 'text-nowrap' },
          // { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.totalNonVat), class: 'text-nowrap' },
          // { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.totalBeforeVat), class: 'text-nowrap' },
          // { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.totalVat), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.cfNetOriginal), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.total), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountPaid), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountPaidOverdue), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.receiptAdvance), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat((accounting.amountPaid || 0) + (accounting.amountPaidOverdue || 0) + (accounting.receiptAdvance || 0)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountCash), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountTransfer), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountDebit), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountCredit), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountVoucher), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountTreatmentRight), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountAdvance), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountAdvanceCash), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountAdvanceTransfer), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountAdvanceDebit), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountAdvanceCredit), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountAdvanceVoucher), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountAdvanceCredit + accounting.amountCredit), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.creditCardFee), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.dfCreditCost), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.cfCreditCost), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.dfCreditCost + accounting.cfCreditCost), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.dfNet), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.cfNet), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.overdueDf), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.overdueCf), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.balance), class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.staff, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.remark, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.cancelRemark, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.cancelBy, class: 'text-nowrap' },
        ]
      }
      if (!snType) {
        objRenderData.columns.splice(6, 1)
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    } else {
      const objRenderData = {
        key: no.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'MERGE', align: 'center', colSpan: snType ? 10 : 9 },
          {
            option: 'TEXT',
            align: 'right',
            style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 },
            label: <Typography sx={{ fontWeight: 500, fontSize: exportData ? 10 : 16 }}>{t('REPORT.TITLE.SUM')}</Typography>,
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'price') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'qty') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'totalOriginal') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'dfPercentOriginal') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'dfBahtOriginal') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'cfPercentOriginal') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'cfBahtOriginal') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'dfDiscount') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'cfDiscount') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'dfDiscountTr') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'cfDiscountTr') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'discountTotal') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'additionalTotal') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'dfNetOriginal') || 0), class: 'text-nowrap' },
          // { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'totalNonVat') || 0), class: 'text-nowrap' },
          // { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'totalBeforeVat') || 0), class: 'text-nowrap' },
          // { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'totalVat') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'cfNetOriginal') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'total') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountPaid') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountPaidOverdue') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'receiptAdvance') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountPaid') + _.sumBy(data, 'amountPaidOverdue') + _.sumBy(data, 'receiptAdvance')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountCash') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountTransfer') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountDebit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountCredit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountVoucher') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountTreatmentRight') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountAdvance') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountAdvanceCash') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountAdvanceTransfer') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountAdvanceDebit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountAdvanceCredit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountAdvanceVoucher') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountAdvanceCredit') + _.sumBy(data, 'amountCredit')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'creditCardFee') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'dfCreditCost') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'cfCreditCost') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'dfCreditCost') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'dfNet') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'cfNet') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'overdueDf') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'overdueCf') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'balance') || 0), class: 'text-nowrap' },
          { option: 'MERGE', align: 'center', colSpan: 4 }
        ],
        classes: 'row-summary'
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    }
  }

  const amountPaidOverdue5 = _.sumBy(props.dataExport, 'amountPaidOverdue');
  const amountCash5 = _.sumBy(props.dataExport, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountCash
    else return 0
  })
  const amountTransfer5 = _.sumBy(props.dataExport, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountTransfer
    else return 0
  })
  const amountCredit5 = _.sumBy(props.dataExport, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountCredit
    else return 0
  })
  const amountDebit5 = _.sumBy(props.dataExport, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountDebit
    else return 0
  })
  const amountVoucher5 = _.sumBy(props.dataExport, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountVoucher
    else return 0
  })
  const amountAdvance5 = _.sumBy(props.dataExport, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountAdvance
    else return 0
  })
  const amountTreatmentRight5 = _.sumBy(props.dataExport, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountTreatmentRight
    else return 0
  })

  const amountPaid6 = _.sumBy(props.dataExport, 'amountPaid');
  const amountCash6 = _.sumBy(props.dataExport, (o: any) => {
    if (o.amountPaid > 0) return o.amountCash
    else return 0
  })
  const amountTransfer6 = _.sumBy(props.dataExport, (o: any) => {
    if (o.amountPaid > 0) return o.amountTransfer
    else return 0
  })
  const amountCredit6 = _.sumBy(props.dataExport, (o: any) => {
    if (o.amountPaid > 0) return o.amountCredit
    else return 0
  })
  const amountDebit6 = _.sumBy(props.dataExport, (o: any) => {
    if (o.amountPaid > 0) return o.amountDebit
    else return 0
  })
  const amountVoucher6 = _.sumBy(props.dataExport, (o: any) => {
    if (o.amountPaid > 0) return o.amountVoucher
    else return 0
  })
  const amountAdvance6 = _.sumBy(props.dataExport, (o: any) => {
    if (o.amountPaid > 0) return o.amountAdvance
    else return 0
  })
  const amountTreatmentRight6 = _.sumBy(props.dataExport, (o: any) => {
    if (o.amountPaid > 0) return o.amountTreatmentRight
    else return 0
  })

  const amountPaid7 = _.sumBy(props.dataExport, (o: any) => {
    return (
      (Number(o.amountPaid) - Number(o.amountVoucher)) +
      Number(o.amountPaidOverdue) +
      Number(o.receiptAdvance) +
      Number(o.amountTreatmentRight)
      // Number(o.amountAdvance)
    )
  });
  const amountCash7 = _.sumBy(props.dataExport, (o: any) => { return Number(o.amountCash) + Number(o.receiptAdvanceCash) });
  const amountTransfer7 = _.sumBy(props.dataExport, (o: any) => { return Number(o.amountTransfer) + Number(o.receiptAdvanceTransfer) });
  const amountCredit7 = _.sumBy(props.dataExport, (o: any) => { return Number(o.amountCredit) + Number(o.receiptAdvanceCredit) });
  const amountDebit7 = _.sumBy(props.dataExport, (o: any) => { return Number(o.amountDebit) + Number(o.receiptAdvanceDebit) });
  const amountVoucher7 = _.sumBy(props.dataExport, (o: any) => { return Number(o.amountVoucher) });
  const amountAdvance7 = _.sumBy(props.dataExport, (o: any) => { return Number(o.amountAdvance) });
  const amountTreatmentRight7 = _.sumBy(props.dataExport, 'amountTreatmentRight')
  const balance7 = _.sumBy(props.dataExport, 'balance')


  return (
    <>
      <Box>
        <Typography variant='h6' sx={{ marginRight: "5px" }} >
          Accounting
        </Typography>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            // rowHeadCell={2}
            tableMinWidth={992}
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[
              _.map(props.data, (d: any, index: number) => {
                return renderData(d, index, false, 'data')
              }),
              renderData(props.dataExport, 0, false, 'sum')
            ]}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
      <Box>
        <br />
        <Grid container spacing={2} className="mt-1">
          {renderTableSum({ rowsData: props.dataExport, headCells: headTotalCells })}
          {renderTableSum2({ rowsData: props.dataExport, headCells: headTotalCells })}
          {renderTableSum3({ rowsData: props.dataExport, headCells: headTotalCells })}
          {renderTableSum4({ rowsData: props.dataExport, headCells: headTotalCells })}
          {renderTableSum5({ rowsData: props.dataExport, headCells: headTotalCells })}
          {renderTableSum6({ rowsData: props.dataExport, headCells: headTotalCells })}
          {renderTableSum7({ rowsData: props.dataExport, headCells: headTotalCells })}
        </Grid>
      </Box>
      <div
        className="print-show" ref={props.componentRef}
      >
        <ExportData
          headCells={t(`Accounting Report`)}
          componant={
            <>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <tr>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>Accounting Report</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{t("REPORT.FILTER.BRANCH")}</td>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{props.filter.branch}</td>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{t('REPORT.FILTER.DATE_START')}</td>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{props.filter.rangeDate[0]}</td>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{t('REPORT.FILTER.DATE_END')}</td>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{props.filter.rangeDate[1]}</td>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{t('REPORT.FILTER.DOCTOR')}</td>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{props.filter.doctor}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{t("REPORT.FILTER.PATIENT")}</td>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{props.filter.patient}</td>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{t('REPORT.FILTER.RECEIPT_STATUS')}</td>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{props.filter.billStatus}</td>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{t('Code From')}</td>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{props.filter.codeFrom || '-'}</td>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{t('Code To')}</td>
                    <td style={{ textAlign: 'start', fontSize: 10, fontWeight: 500 }}>{props.filter.codeTo || '-'}</td>
                  </tr>

                  <tr>
                    <td></td>
                  </tr>
                  <thead>
                    <tr>
                      {_.map(headCells, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      _.map(props.dataExport, (d: any, index: number) => {
                        return renderData(d, index, true, 'data')
                      }),
                      renderData(props.dataExport, 0, true, 'sum')
                    ]}
                  </tbody>
                </table>
              </UseStyled.Styles>

              <br />
              <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'left' }}>จำนวนผู้ป่วย</td>
                    <td style={{ textAlign: 'left' }}>{_.uniqBy(props.dataExport, 'patientId').length}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>ยอดรวม DF</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(_.sumBy(props.dataExport, 'dfNet'))}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>จำนวนวัน</td>
                    <td style={{ textAlign: 'left' }}>{_.uniqBy(props.dataExport, 'date').length}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>ยอดรวม CF</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(((_.sumBy(props.dataExport, 'amountCash') + _.sumBy(props.dataExport, 'amountTransfer') + _.sumBy(props.dataExport, 'amountCredit') + _.sumBy(props.dataExport, 'amountDebit') + _.sumBy(props.dataExport, 'amountVoucher') + _.sumBy(props.dataExport, 'amountAdvance') + _.sumBy(props.dataExport, 'amountTreatmentRight')) - _.sumBy(props.dataExport, 'dfNet')) - _.sumBy(props.dataExport, 'creditCost'))}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>จำนวนใบเสร็จ</td>
                    <td style={{ textAlign: 'left' }}>{0}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>ยอดรวม Credit</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(_.sumBy(props.dataExport, 'creditCost'))}</td>
                  </tr>

                  <tr>
                    <td></td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left' }}>ยอดรับเงิน Advance</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(_.sumBy(props.dataExport, 'receiptAdvance'))}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>ยอดใช้เงิน Advance</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(_.sumBy(props.dataExport, 'amountAdvance'))}</td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left' }}>เงินสด (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(_.sumBy(props.dataExport, (o: any) => { return o.receiptAdvance > 0 ? Number(o.amountCash) : 0 }))}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>เงินสด (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(_.sumBy(props.dataExport, 'amountAdvanceCash'))}</td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left' }}>เงินโอน (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(_.sumBy(props.dataExport, (o: any) => { return o.receiptAdvance > 0 ? Number(o.amountTransfer) : 0 }))}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>เงินโอน (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(_.sumBy(props.dataExport, 'amountAdvanceTransfer'))}</td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left' }}>บัตรเดบิต (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(_.sumBy(props.dataExport, (o: any) => { return o.receiptAdvance > 0 ? Number(o.amountDebit) : 0 }))}</td>

                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>บัตรเดบิต (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(_.sumBy(props.dataExport, 'amountAdvanceDebit'))}</td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left' }}>บัตรเครดิต (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(_.sumBy(props.dataExport, (o: any) => { return o.receiptAdvance > 0 ? Number(o.amountCredit) : 0 }))}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>บัตรเครดิต (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(_.sumBy(props.dataExport, 'amountAdvanceCredit'))}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>Voucher (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(_.sumBy(props.dataExport, (o: any) => { return o.receiptAdvance > 0 ? Number(o.amountVoucher) : 0 }))}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>Voucher (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(_.sumBy(props.dataExport, 'amountAdvanceVoucher'))}</td>
                  </tr>

                  <tr>
                    <td></td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left' }}>ยอดรวมจ่ายค้างชำระ</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountPaidOverdue5 || 0)}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>ยอดรวมจ่ายค่ารักษา</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountCash6 + amountTransfer6 + amountCredit6 + amountDebit6 + amountVoucher6 + amountAdvance6 + amountTreatmentRight6)}</td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left' }}>เงินสด (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountCash5 || 0)}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>เงินสด (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountCash6)}</td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left' }}>เงินโอน (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountTransfer5 || 0)}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>เงินโอน (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountTransfer6)}</td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left' }}>บัตรเดบิต (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountDebit5 || 0)}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>บัตรเดบิต (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountDebit6)}</td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left' }}>บัตรเครดิต (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountCredit5 || 0)}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>บัตรเครดิต (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountCredit6)}</td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left' }}>Voucher (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountVoucher5 || 0)}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>Voucher (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountVoucher6)}</td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left' }}>สิทธิการรักษา (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountTreatmentRight5 || 0)}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>สิทธิการรักษา (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountTreatmentRight6)}</td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left' }}>จ่ายด้วย Advance (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountAdvance5 || 0)}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>จ่ายด้วย Advance (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountAdvance6)}</td>
                  </tr>

                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>ยอดรับหน้าร้าน</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountPaid7 || 0)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>เงินสด (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountCash7 || 0)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>เงินโอน (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountTransfer7 || 0)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>บัตรเดบิต (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountDebit7 || 0)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>บัตรเครดิต (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountCredit7 || 0)}</td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: 'left' }}>Voucher (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountVoucher7 || 0)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>สิทธิการรักษา (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountTreatmentRight7 || 0)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>จ่ายด้วย Advance (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(amountAdvance7 || 0)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>ค้างชำระ (THB)</td>
                    <td style={{ textAlign: 'left' }}>{numberFormat(balance7 || 0)}</td>
                  </tr>
                </tbody>
              </table>
            </>
          }
        />
      </div>
    </>
  )
}

export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  )
}
export interface SumProps {
  exportData?: boolean
  headCells?: any
  rowsData?: any
}

const renderTableSum = (props: SumProps, isExport = false) => {
  const countPatient = _.uniqBy(props.rowsData, 'patientId').length;
  const countPate = _.uniqBy(props.rowsData, 'date').length
  const countPaymentNumber = _.uniqBy(props.rowsData, 'paymentNumber').length


  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(props.headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {[
            <TableRowExport key={`total-1`} id={`total-1`} obj={[1]} columns={[
              { option: 'TEXT', align: 'left', label: 'จำนวนผู้ป่วย' },
              { option: 'TEXT', align: 'right', label: (countPatient || 0) }
            ]} />,
            <TableRowExport key={`total-2`} id={`total-2`} obj={[1]} columns={[
              { option: 'TEXT', align: 'left', label: 'จำนวนวัน' },
              { option: 'TEXT', align: 'right', label: (countPate || 0) }
            ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]} columns={
              [
                { option: 'TEXT', align: 'left', label: 'จำนวนใบเสร็จ' },
                { option: 'TEXT', align: 'right', label: (0) }
              ]} />
          ]}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={5} sm={12} className="order-sm-last  ">
    <UseStyled.ResponsiveReportTable  >
      <TableCustom
        tableFixedWidth
        hidePagination
        page={1}
        pageLimit={10}
        sortType={''}
        sortBy={''}
        rowCount={0}
        onSort={() => { return false }}
        setPageLimit={() => { return false }}
        setPage={() => { return false }}
        headCells={props.headCells}
        rowsData={[
          <TableRowCommon key={`total-1`} id={`total-1`} obj={[1]} columns={[
            { option: 'TEXT', align: 'left', label: 'จำนวนผู้ป่วย' },
            { option: 'TEXT', align: 'right', label: (countPatient || 0) }
          ]} />,
          <TableRowCommon key={`total-2`} id={`total-2`} obj={[1]} columns={[
            { option: 'TEXT', align: 'left', label: 'จำนวนวัน' },
            { option: 'TEXT', align: 'right', label: (countPate || 0) }
          ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]} columns={
            [
              { option: 'TEXT', align: 'left', label: 'จำนวนใบเสร็จ' },
              { option: 'TEXT', align: 'right', label: (0) }
            ]} />
        ]}
        customScroll
      />
    </UseStyled.ResponsiveReportTable>
  </Grid>
}

const renderTableSum2 = (props: SumProps, isExport = false) => {
  const dfNet = _.sumBy(props.rowsData, 'dfNet');
  const cfNet = _.sumBy(props.rowsData, 'cfNet')
  const amountCash = _.sumBy(props.rowsData, 'amountCash')
  const amountTransfer = _.sumBy(props.rowsData, 'amountTransfer')
  const amountCredit = _.sumBy(props.rowsData, 'amountCredit')
  const amountDebit = _.sumBy(props.rowsData, 'amountDebit')
  const amountVoucher = _.sumBy(props.rowsData, 'amountVoucher')
  const amountAdvance = _.sumBy(props.rowsData, 'amountAdvance')
  const amountTreatmentRight = _.sumBy(props.rowsData, 'amountTreatmentRight')
  const creditCost = _.sumBy(props.rowsData, 'creditCost')




  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(props.headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {
            [
              <TableRowExport key={`total-1`} id={`total-1`} obj={[1]} columns={[
                { option: 'TEXT', align: 'left', label: 'ยอดรวม DF' },
                { option: 'TEXT', align: 'right', label: numberFormat(dfNet || 0) }
              ]} />,
              <TableRowExport key={`total-2`} id={`total-2`} obj={[1]} columns={[
                { option: 'TEXT', align: 'left', label: 'ยอดรวม CF' },
                { option: 'TEXT', align: 'right', label: numberFormat((((amountCash + amountTransfer + amountCredit + amountDebit + amountVoucher + amountAdvance + amountTreatmentRight) - dfNet) - creditCost) || 0) }
              ]} />,
              <TableRowExport key={`total-3`} id={`total-3`} obj={[1]} columns={
                [
                  { option: 'TEXT', align: 'left', label: 'ยอดรวม Credit' },
                  { option: 'TEXT', align: 'right', label: numberFormat(creditCost || 0) }
                ]} />
            ]
          }
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={5} sm={12} className="order-sm-last  ">
    <UseStyled.ResponsiveReportTable  >
      <TableCustom
        tableFixedWidth
        hidePagination
        page={1}
        pageLimit={10}
        sortType={''}
        sortBy={''}
        rowCount={0}
        onSort={() => { return false }}
        setPageLimit={() => { return false }}
        setPage={() => { return false }}
        headCells={props.headCells}
        rowsData={[
          <TableRowCommon key={`total-1`} id={`total-1`} obj={[1]} columns={[
            { option: 'TEXT', align: 'left', label: 'ยอดรวม DF' },
            { option: 'TEXT', align: 'right', label: numberFormat(dfNet || 0) }
          ]} />,
          <TableRowCommon key={`total-2`} id={`total-2`} obj={[1]} columns={[
            { option: 'TEXT', align: 'left', label: 'ยอดรวม CF' },
            { option: 'TEXT', align: 'right', label: numberFormat((((amountCash + amountTransfer + amountCredit + amountDebit + amountVoucher + amountAdvance + amountTreatmentRight) - dfNet) - creditCost) || 0) }
          ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]} columns={
            [
              { option: 'TEXT', align: 'left', label: 'ยอดรวม Credit' },
              { option: 'TEXT', align: 'right', label: numberFormat(creditCost || 0) }
            ]} />
        ]}
        customScroll
      />
    </UseStyled.ResponsiveReportTable>
  </Grid>
}

const renderTableSum3 = (props: SumProps, isExport = false) => {
  const receiptAdvance = _.sumBy(props.rowsData, 'receiptAdvance');
  const receiptAdvanceCash = _.sumBy(props.rowsData, (o: any) => { return o.receiptAdvance > 0 ? Number(o.amountCash) : 0 })
  const receiptAdvanceTransfer = _.sumBy(props.rowsData, (o: any) => { return o.receiptAdvance > 0 ? Number(o.amountTransfer) : 0 })
  const receiptAdvanceCredit = _.sumBy(props.rowsData, (o: any) => { return o.receiptAdvance > 0 ? Number(o.amountCredit) : 0 })
  const receiptAdvanceDebit = _.sumBy(props.rowsData, (o: any) => { return o.receiptAdvance > 0 ? Number(o.amountDebit) : 0 })
  const receiptAdvanceVoucher = _.sumBy(props.rowsData, (o: any) => { return o.receiptAdvance > 0 ? Number(o.amountVoucher) : 0 })
  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(props.headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {[
            <TableRowExport key={`total-1`} id={`total-1`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'ยอดรับเงิน Advance' },
                { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvance || 0) }
              ]} />,
            <TableRowExport key={`total-2`} id={`total-2`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceCash || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceTransfer || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceCredit || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceDebit || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceVoucher || 0) }
              ]} />
          ]}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={5} sm={12} className="order-sm-last  ">
    <UseStyled.ResponsiveReportTable  >
      <TableCustom
        tableFixedWidth
        hidePagination
        page={1}
        pageLimit={10}
        sortType={''}
        sortBy={''}
        rowCount={0}
        onSort={() => { return false }}
        setPageLimit={() => { return false }}
        setPage={() => { return false }}
        headCells={props.headCells}
        rowsData={[
          <TableRowCommon key={`total-1`} id={`total-1`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'ยอดรับเงิน Advance' },
              { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvance || 0) }
            ]} />,
          <TableRowCommon key={`total-2`} id={`total-2`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceCash || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceTransfer || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceCredit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceDebit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceVoucher || 0) }
            ]} />
        ]}
        customScroll
      />
    </UseStyled.ResponsiveReportTable>
  </Grid>
}

const renderTableSum4 = (props: SumProps, isExport = false) => {
  const amountAdvance = _.sumBy(props.rowsData, 'amountAdvance');
  const amountAdvanceCash = _.sumBy(props.rowsData, 'amountAdvanceCash')
  const amountAdvanceTransfer = _.sumBy(props.rowsData, 'amountAdvanceTransfer')
  const amountAdvanceCredit = _.sumBy(props.rowsData, 'amountAdvanceCredit')
  const amountAdvanceDebit = _.sumBy(props.rowsData, 'amountAdvanceDebit')
  const amountAdvanceVoucher = _.sumBy(props.rowsData, 'amountAdvanceVoucher')

  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(props.headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {[
            <TableRowExport key={`total-1`} id={`total-1`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'ยอดใช้เงิน Advance' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
              ]} />,
            <TableRowExport key={`total-2`} id={`total-2`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceCash || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceTransfer || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceCredit || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceDebit || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceVoucher || 0) }
              ]} />
          ]}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={5} sm={12} className="order-sm-last  ">
    <UseStyled.ResponsiveReportTable  >
      <TableCustom
        tableFixedWidth
        hidePagination
        page={1}
        pageLimit={10}
        sortType={''}
        sortBy={''}
        rowCount={0}
        onSort={() => { return false }}
        setPageLimit={() => { return false }}
        setPage={() => { return false }}
        headCells={props.headCells}
        rowsData={[
          <TableRowCommon key={`total-1`} id={`total-1`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'ยอดใช้เงิน Advance' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
            ]} />,
          <TableRowCommon key={`total-2`} id={`total-2`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceCash || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceTransfer || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceCredit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceDebit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceVoucher || 0) }
            ]} />
        ]}
        customScroll
      />
    </UseStyled.ResponsiveReportTable>
  </Grid>
}
const renderTableSum5 = (props: SumProps, isExport = false) => {
  const amountPaidOverdue = _.sumBy(props.rowsData, 'amountPaidOverdue');
  const amountCash = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountCash
    else return 0
  })
  const amountTransfer = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountTransfer
    else return 0
  })
  const amountCredit = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountCredit
    else return 0
  })
  const amountDebit = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountDebit
    else return 0
  })
  const amountVoucher = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountVoucher
    else return 0
  })
  const amountAdvance = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountAdvance
    else return 0
  })
  const amountTreatmentRight = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountTreatmentRight
    else return 0
  })




  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(props.headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {
            [
              <TableRowExport key={`total-1`} id={`total-1`} obj={[1]}
                columns={[
                  { option: 'TEXT', align: 'left', label: 'ยอดรวมจ่ายค้างชำระ' },
                  { option: 'TEXT', align: 'right', label: numberFormat(amountPaidOverdue || 0) }
                ]} />,
              <TableRowExport key={`total-2`} id={`total-2`} obj={[1]}
                columns={[
                  { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
                  { option: 'TEXT', align: 'right', label: numberFormat(amountCash || 0) }
                ]} />,
              <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
                columns={[
                  { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
                  { option: 'TEXT', align: 'right', label: numberFormat(amountTransfer || 0) }
                ]} />,
              <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
                columns={[
                  { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
                  { option: 'TEXT', align: 'right', label: numberFormat(amountDebit || 0) }
                ]} />,
              <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
                columns={[
                  { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
                  { option: 'TEXT', align: 'right', label: numberFormat(amountCredit || 0) }
                ]} />,
              <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
                columns={[
                  { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
                  { option: 'TEXT', align: 'right', label: numberFormat(amountVoucher || 0) }
                ]} />,
              <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
                columns={[
                  { option: 'TEXT', align: 'left', label: 'สิทธิการรักษา (THB)' },
                  { option: 'TEXT', align: 'right', label: numberFormat(amountTreatmentRight || 0) }
                ]} />,
              <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
                columns={[
                  { option: 'TEXT', align: 'left', label: 'จ่ายด้วย Advance (THB)' },
                  { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
                ]} />
            ]
          }
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={5} sm={12} className="order-sm-last  ">
    <UseStyled.ResponsiveReportTable  >
      <TableCustom
        tableFixedWidth
        hidePagination
        page={1}
        pageLimit={10}
        sortType={''}
        sortBy={''}
        rowCount={0}
        onSort={() => { return false }}
        setPageLimit={() => { return false }}
        setPage={() => { return false }}
        headCells={props.headCells}
        rowsData={[
          <TableRowCommon key={`total-1`} id={`total-1`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'ยอดรวมจ่ายค้างชำระ' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountPaidOverdue || 0) }
            ]} />,
          <TableRowCommon key={`total-2`} id={`total-2`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountCash || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountTransfer || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountDebit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountCredit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountVoucher || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'สิทธิการรักษา (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountTreatmentRight || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'จ่ายด้วย Advance (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
            ]} />
        ]}
        customScroll
      />
    </UseStyled.ResponsiveReportTable>
  </Grid>
}

const renderTableSum6 = (props: SumProps, isExport = false) => {
  const amountPaid = _.sumBy(props.rowsData, 'amountPaid');
  const amountCash = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaid > 0) return o.amountCash
    else return 0
  })
  const amountTransfer = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaid > 0) return o.amountTransfer
    else return 0
  })
  const amountCredit = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaid > 0) return o.amountCredit
    else return 0
  })
  const amountDebit = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaid > 0) return o.amountDebit
    else return 0
  })
  const amountVoucher = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaid > 0) return o.amountVoucher
    else return 0
  })
  const amountAdvance = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaid > 0) return o.amountAdvance
    else return 0
  })
  const amountTreatmentRight = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaid > 0) return o.amountTreatmentRight
    else return 0
  })



  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(props.headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {[
            <TableRowExport key={`total-1`} id={`total-1`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'ยอดรวมจ่ายค่ารักษา' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountCash + amountTransfer + amountCredit + amountDebit + amountVoucher + amountAdvance + amountTreatmentRight) }
              ]} />,
            <TableRowExport key={`total-2`} id={`total-2`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountCash || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountTransfer || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountDebit || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountCredit || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountVoucher || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'สิทธิการรักษา (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountTreatmentRight || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'จ่ายด้วย Advance (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
              ]} />
          ]}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={5} sm={12} className="order-sm-last  ">
    <UseStyled.ResponsiveReportTable  >
      <TableCustom
        tableFixedWidth
        hidePagination
        page={1}
        pageLimit={10}
        sortType={''}
        sortBy={''}
        rowCount={0}
        onSort={() => { return false }}
        setPageLimit={() => { return false }}
        setPage={() => { return false }}
        headCells={props.headCells}
        rowsData={[
          <TableRowCommon key={`total-1`} id={`total-1`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'ยอดรวมจ่ายค่ารักษา' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountCash + amountTransfer + amountCredit + amountDebit + amountVoucher + amountAdvance + amountTreatmentRight) }
            ]} />,
          <TableRowCommon key={`total-2`} id={`total-2`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountCash || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountTransfer || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountDebit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountCredit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountVoucher || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'สิทธิการรักษา (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountTreatmentRight || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'จ่ายด้วย Advance (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
            ]} />
        ]}
        customScroll
      />
    </UseStyled.ResponsiveReportTable>
  </Grid>
}
const renderTableSum7 = (props: SumProps, isExport = false) => {
  const amountPaid = _.sumBy(props.rowsData, (o: any) => {
    return o.statusOrder === "PAID" ? (
      (Number(o.amountPaid) - Number(o.amountVoucher)) +
      Number(o.amountPaidOverdue) +
      Number(o.receiptAdvance) +
      Number(o.amountTreatmentRight)
      // Number(o.amountAdvance)
    ) : 0
  });


  const amountCash = _.sumBy(props.rowsData, (o: any) => { return o.statusOrder === "PAID" ? Number(o.amountCash) : 0 });
  const amountTransfer = _.sumBy(props.rowsData, (o: any) => { return o.statusOrder === "PAID" ? Number(o.amountTransfer) : 0 });
  const amountCredit = _.sumBy(props.rowsData, (o: any) => { return o.statusOrder === "PAID" ? Number(o.amountCredit) : 0 });
  const amountDebit = _.sumBy(props.rowsData, (o: any) => { return o.statusOrder === "PAID" ? Number(o.amountDebit) : 0 });
  const amountVoucher = _.sumBy(props.rowsData, (o: any) => { return o.statusOrder === "PAID" ? Number(o.amountVoucher) : 0 });
  const amountAdvance = _.sumBy(props.rowsData, (o: any) => { return o.statusOrder === "PAID" ? Number(o.amountAdvance) : 0 });
  const amountTreatmentRight = _.sumBy(props.rowsData, (o: any) => { return o.statusOrder === "PAID" ? Number(o.amountTreatmentRight) : 0 });
  const balance = _.sumBy(props.rowsData, (o: any) => { return o.statusOrder === "PAID" ? Number(o.balance) : 0 });




  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(props.headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {[
            <TableRowExport key={`total-1`} id={`total-1`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'ยอดรับหน้าร้าน' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountPaid || 0) }
              ]} />,
            <TableRowExport key={`total-2`} id={`total-2`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountCash || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountTransfer || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountDebit || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountCredit || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountVoucher || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'สิทธิการรักษา (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountTreatmentRight || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'จ่ายด้วย Advance (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
              ]} />,
            <TableRowExport key={`total-9`} id={`total-9`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'ค้างชำระ (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(balance || 0) }
              ]} />
          ]}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={5} sm={12} className="order-sm-last  ">
    <UseStyled.ResponsiveReportTable  >
      <TableCustom
        tableFixedWidth
        hidePagination
        page={1}
        pageLimit={10}
        sortType={''}
        sortBy={''}
        rowCount={0}
        onSort={() => { return false }}
        setPageLimit={() => { return false }}
        setPage={() => { return false }}
        headCells={props.headCells}
        rowsData={[
          <TableRowCommon key={`total-1`} id={`total-1`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'ยอดรับหน้าร้าน' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountPaid || 0) }
            ]} />,
          <TableRowCommon key={`total-2`} id={`total-2`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountCash || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountTransfer || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountDebit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountCredit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountVoucher || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'สิทธิการรักษา (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountTreatmentRight || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'จ่ายด้วย Advance (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
            ]} />,
          <TableRowCommon key={`total-9`} id={`total-9`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'ค้างชำระ (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(balance || 0) }
            ]} />
        ]}
        customScroll
      />
    </UseStyled.ResponsiveReportTable>
  </Grid>
}
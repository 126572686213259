import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface MenuOption {
    label: string;
    icon?: any;
    onClick: () => void;
}

interface CustomMenuProps {
    buttonLabel: string;
    options: MenuOption[];
    buttonColor?: string;
    width?: string
    disabled?: boolean
}

export default function SelectPrimaryInput(props: CustomMenuProps) {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                variant="outlined"
                onClick={handleClick}
                disabled={props.disabled}
                sx={{
                    backgroundColor: props.disabled ? '#E0E0E0' : 'white',
                    color: props.disabled ? '#E0E0E0' : props.buttonColor || '#1CB99A',
                    borderColor: props.disabled ? '#E0E0E0' : props.buttonColor || '#1CB99A !important',
                    '&:hover': { borderColor: props.disabled ? '#E0E0E0' : props.buttonColor || '#1CB99A' },
                    width: props.width || '100%',
                    height: '40px'
                }}
            >
                <Box display="flex" justifyContent="space-between" gap="8px">
                    <Box color={props.disabled ? '#808080' : '#1CB99A'}>{props.buttonLabel}</Box>
                    <ArrowDropDownIcon sx={{ color: props.disabled ? '#808080' : '#1CB99A' }} />
                </Box>

            </Button>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: anchorEl ? anchorEl.clientWidth : 'auto',
                    },
                }}
            >
                {props.options.map((option, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            option.onClick();
                            handleClose();
                        }}
                    >
                        {option.icon}
                        <Typography fontSize="16px" fontWeight={400} sx={{ marginLeft: '8px', color: '#231F20' }}>{option.label}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}
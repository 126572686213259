import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { numberFormat, toBuddhistYear } from "utils/app.utils";

interface RemunerationTotalDoctorProps {
    startDate: string
    endDate: string
    data: any
    page?: any
    pageLimit?: any
    doctors?: any
    snType?: any
}

export default function RemunerationTotalDoctor(props: RemunerationTotalDoctorProps) {
    const { t } = useTranslation()

    const renderSalaryTable = (data: any) => {
        const items = _.chain(data)
            .map((i: any) => {
                i.dateFormat = toBuddhistYear(moment(i.date), 'DD/MM/YYYY')
                return i
            })
            .groupBy('dateFormat')
            .map((value: any, key: string) => ({ date: key, data: value }))
            .value()
        return items
    }
    return (
        <Box marginTop="16px">
            {_.map(props.data?.rows, (item) => {
                return (
                    <>
                        <Box display="flex" flexDirection='row' alignItems="center" gap="8px" marginTop="16px">
                            <Typography>{t('REPORT.FILTER.DOCTOR')}</Typography>
                            {_.get(_.find(props.doctors, { dentistId: Number(item.doctorId) }), 'dentistFullname')}
                            {item.doctor}
                        </Box>
                        <Box sx={{ overflowX: 'auto', overflowY: 'hidden', marginTop: '16px' }}>
                            <TableContainer style={{ boxShadow: 'none', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                                <Table>
                                    <TableHead sx={{ backgroundColor: "#2F4269", color: 'white', borderRadius: "8px" }}>
                                        <TableRow>
                                            <TableCell sx={{ color: "white", fontWeight: 500, fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.#NO')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '110px', fontSize: '16px', textAlign: 'center' }} >{t('REPORT.TABLE.REMUNERATION.BILL_ID')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '120px', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_CN_NUMBER')}</TableCell>
                                            {props.snType && <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '140px', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_SN_CODE')}</TableCell>}
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '100px', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_NAME')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '130px', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.ITEM')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '100px', fontSize: '16px', textAlign: 'right' }}>{t('REPORT.TABLE.REMUNERATION.PAYMENT')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '100px', fontSize: '16px', textAlign: 'right' }}>{t('REPORT.TABLE.REMUNERATION.CASH')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '100px', fontSize: '16px', textAlign: 'right' }}>{t('REPORT.TABLE.REMUNERATION.TRANSFER_')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '80px', fontSize: '16px', textAlign: 'right' }}>{t('REPORT.TABLE.REMUNERATION.DEBIT')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '80px', fontSize: '16px', textAlign: 'right' }}>{t('REPORT.TABLE.REMUNERATION.CREDIT')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '140px', fontSize: '16px', textAlign: 'right' }}>{t('REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '140px', fontSize: '16px', textAlign: 'right' }}>{t('REPORT.TABLE.REMUNERATION.ADVANCE')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '150px', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.NAME_CLINICFEE')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '150px', fontSize: '16px', textAlign: 'right' }}>{t('REPORT.TABLE.REMUNERATION.PRICE_CLINICFEE')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '120px', fontSize: '16px', textAlign: 'right' }}>{t('REPORT.TABLE.REMUNERATION.DF_PERCEN')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '80px', fontSize: '16px', textAlign: 'right' }}>{t('REPORT.TABLE.REMUNERATION.DF')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '100px', fontSize: '16px', textAlign: 'right' }}>{t('REPORT.TABLE.REMUNERATION.CREDIT_PERCEN')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '150px', fontSize: '16px', textAlign: 'right' }}>{t('REPORT.TABLE.REMUNERATION.CREDIT_DF')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '200px', fontSize: '16px', textAlign: 'right' }}>{t('REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT_DF')}</TableCell>
                                            <TableCell sx={{ color: "white", fontWeight: 500, minWidth: '100px', fontSize: '16px', textAlign: 'right' }}>{t('REPORT.TABLE.REMUNERATION.NET_DF')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {_.map(renderSalaryTable(item.table), (data) => (
                                            <>
                                                <TableRow style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    <TableCell colSpan={props.snType ? 21 : 20} sx={{ fontWeight: '500 !important' }}>
                                                        <span className="mr-2">{t('MOMENT_AT')}</span>
                                                        <span>{data?.date}</span>
                                                        <span style={{ fontSize: 12, marginLeft: '8px' }}>{`ค่าประกันลงเวร ${numberFormat(data?.data[0]?.handInsurance || 0, 0, 0)} บาท`}</span>
                                                    </TableCell>
                                                </TableRow>
                                                {_.map(data.data, (data, index) => (
                                                    <TableRow style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                        <TableCell sx={{ fontSize: '16px', fontWeight: 400 }}>{props?.page * props.pageLimit - props.pageLimit + index + 1}</TableCell>
                                                        <TableCell sx={{ fontSize: '16px', fontWeight: 400, minWidth: '170px' }}>{data?.billNo || "-"}</TableCell>
                                                        <TableCell sx={{ fontSize: '16px', fontWeight: 400 }}>{data?.branchCnNumber || "-"}</TableCell>
                                                        {props.snType && <TableCell sx={{ fontSize: '16px', fontWeight: 400 }}>{data?.snCode || "-"}</TableCell>}
                                                        <TableCell sx={{ fontSize: '16px', fontWeight: 400, minWidth: '250px' }}>{data?.patientFullname || "-"}</TableCell>
                                                        <TableCell sx={{ fontSize: '16px', fontWeight: 400, minWidth: '500px' }}>{data?.list || "-"}</TableCell>
                                                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.amountPaid || 0)}</TableCell>
                                                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.amountCash || 0)}</TableCell>
                                                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.amountTransfer || 0)}</TableCell>
                                                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.amountDebit || 0)}</TableCell>
                                                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.amountCredit || 0)}</TableCell>
                                                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.amountTrementRight || 0)}</TableCell>
                                                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.amountAdvance || 0)}</TableCell>
                                                        <TableCell sx={{ fontSize: '16px', fontWeight: 400, minWidth: '200px' }}>{data?.clinicFeeName || "-"}</TableCell>
                                                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.clinicFeePrice || 0)}</TableCell>
                                                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{data?.dfPercentOriginal || ''}</TableCell>
                                                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.dfPaid || 0)}</TableCell>
                                                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.creditCost > 0 ? data?.creditCardFee : 0, 2)}</TableCell>
                                                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.creditCost || 0, 3, 3)}</TableCell>
                                                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.dfLostTreatment || 0)}</TableCell>
                                                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.dfNet || 0)}</TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: '#E5E5E5' }}>
                                                    <TableCell colSpan={5} align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>
                                                        <Typography>รวม</Typography>
                                                    </TableCell>
                                                    <TableCell align="right">{numberFormat(_.sumBy(data?.data, 'amountPaid'))}</TableCell>
                                                    <TableCell align="right">{numberFormat(_.sumBy(data?.data, 'amountCash'))}</TableCell>
                                                    <TableCell align="right">{numberFormat(_.sumBy(data?.data, 'amountTransfer'))}</TableCell>
                                                    <TableCell align="right">{numberFormat(_.sumBy(data?.data, 'amountDebit'))}</TableCell>
                                                    <TableCell align="right">{numberFormat(_.sumBy(data?.data, 'amountCredit'))}</TableCell>
                                                    <TableCell align="right">{numberFormat(_.sumBy(data?.data, 'amountTrementRight'))}</TableCell>
                                                    <TableCell align="right">{numberFormat(_.sumBy(data?.data, 'amountAdvance'))}</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right">{numberFormat(_.sumBy(data?.data, 'clinicFeePrice'))}</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right">{numberFormat(_.sumBy(data?.data, 'dfPaid'))}</TableCell>
                                                    <TableCell align="right">{numberFormat(_.sumBy(data?.data, (o: any) => o.creditCost > 0 ? o.creditCardFee : 0), 2)}</TableCell>
                                                    <TableCell align="right">{numberFormat(_.sumBy(data?.data, 'creditCost'), 3, 3)}</TableCell>
                                                    <TableCell align="right">{numberFormat(_.sumBy(data?.data, 'dfLostTreatment'))}</TableCell>
                                                    <TableCell align="right">{numberFormat(_.sumBy(data?.data, 'dfNet'))}</TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </>
                )
            })}
        </Box>
    )
}
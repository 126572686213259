import BaseAPI from 'api/api'
import { getBranch } from 'utils/app.utils'

export interface PaginationOnlyFilter {
  page?: number
  pageLimit?: number
  userId?: number
  search?: string
  isBookmark?: string
  typeId?: number
}
export interface OperativeAllActiveFilter {
  page?: number
  pageLimit?: number
  sortType?: string
  sortBy?: string
  status?: string
  operativeTypeId?: string
}
export interface FindAllDiagnosisProps {
  page?: number
  pageLimit?: number
  userId?: number
  search?: string
  isBookmark?: string
  typeId?: number
}

export interface MessageTemplate {
  templateTextId: number
  message: string
}
export interface PayloadDoctorNote {
  patientId?: number
  branchId: number
  patientCaseId: number | null
  userId: number
  doctorNoteName: string
  base64Note?: string
  base64New: string
  doctorNoteType: "TEXT" | "WRITE"
  textNote: string
  objectNote: any
}

export interface FindDoctorFeeProps {
  operativeId: number
  branchId: number
  doctorId: number
}

export interface FindClinicFeeProps {
  page: number,
  pageLimit: number
}

export interface TreatmentInVisitParams {
  patientId: number
  vnNumber: string
}

export interface TreatmentPlansInterFace {
  page?: number
  pageLimit?: number
  userId?: number
  search?: string
  isBookmark?: string
  typeId?: number
  group?: string
  branchId?: any
}

export interface PlayLoadTreatmentPlans {
  patientTreatmentPlanId: number,
  treatmentPlanName: string,
  userId: number,
  remark: string,
  treatmentPlanItems: any
}

export interface PlayLoadTreatmentCourse {
  patientTreatmentCourseId: number,
  treatmentCourseName: string,
  userId: number,
  remark: string,
  treatmentCourseItems: any,
  patientCaseId: number,
  paymentDetail?: string,
  paymentType?: string
}

export interface SignatureAttachmentsProps {
  doctorId: number
  servicePointId: number
  doctorSignature: string
  patientSignature: string
}

export interface DoctorNoteProps {
  typeName?: string,
  typeNameEn?: string,
  status?: string
}

export interface LastNumberProps {
  documentKey: string,
  documentDate: string
}

export interface TreatmentCoursesPaidHistoryProps {
  patientTreatmentCourseId?: number
  page: number
  pageLimit: number
}

const path = 'treatments'

export default class TreatmentApi extends BaseAPI {
  static findAllOperativeType(): Promise<any> {
    return this.api.get(`${path}/operative-types`)
  }


  static findOperativeAllActive(props: OperativeAllActiveFilter): Promise<any> {
    return this.api.get(`operatives/all-active`, { params: { ...props } })
  }

  static findAllOperative(props: PaginationOnlyFilter): Promise<any> {
    return this.api.get(`${path}/operatives`, { params: { ...props, branchId: getBranch() } })
  }

  static findOperativeById(id: number): Promise<any> {
    return this.api.get(`${path}/operatives/${id}`)
  }

  static findPatient(patientId: number): Promise<any> {
    return this.api.get(`${path}/patient/${patientId}`).then((res) => res)
  }

  static updateVitalSign(patientId: number, body: any): Promise<any> {
    return this.api.patch(`${path}/vital-sign/${patientId}`, body)
  }

  static findAllDiagnosis(props: FindAllDiagnosisProps): Promise<any> {
    return this.api.get(`${path}/diagnosis`, { params: { ...props } })
  }

  static findAllDiagnosisType(): Promise<any> {
    return this.api.get(`${path}/diagnosis-types`)
  }

  static findAllRecommends(props: FindAllDiagnosisProps): Promise<any> {
    return this.api.get(`${path}/recommends`, { params: { ...props } })
  }

  static templateMessageBranch(): Promise<any> {
    return this.api.get(`${path}/template-text-branch`)
  }

  static templateMessageUser(): Promise<any> {
    return this.api.get(`${path}/template-text-user`)
  }

  static newTemplateMessage(payload: MessageTemplate[]): Promise<any> {
    return this.api.post(`${path}/template-text`, payload)
  }

  static findDoctorFee(props: FindDoctorFeeProps): Promise<any> {
    return this.api.get(`${path}/doctor-fees`, { params: { ...props } })
  }

  static findClinicFee(props: FindClinicFeeProps): Promise<any> {
    return this.api.get(`${path}/clinic-fees`, { params: { ...props } })
  }

  static getTreatmentInVisit(props: TreatmentInVisitParams): Promise<any> {
    return this.api.get(`${path}/patient-treatment`, { params: { ...props } })
  }

  static getItems(props: TreatmentPlansInterFace): Promise<any> {
    return this.api.get(`${path}/items`, { params: { ...props } })
  }

  static getItemsType(props: TreatmentPlansInterFace): Promise<any> {
    return this.api.get(`${path}/item-types`, { params: { ...props } }).then((res) => res)
  }

  static printMedicalSSO(patientId: number, props: any): Promise<any> {
    return this.api.get(`${path}/sso/${patientId}`, { params: { ...props } })
  }
  static printMedicalFiveDisease(patientId: number, props: any): Promise<any> {
    return this.api.post(`${path}/5-diseases-certificate/${patientId}`, props)
  }
  static printMedicalDrivingLicense(patientId: number, props: any): Promise<any> {
    return this.api.post(`${path}/diving-license-certificate/${patientId}`, props)
  }
  static getReMedDate(patientId: number): Promise<any> {
    return this.api.get(`${path}/re-med/date/${patientId}`)
  }
  static getDocumentLastNumber(props: any): Promise<any> {
    return this.api.get(`${path}/last-number-certificate`, { params: { ...props } })
  }
  static getReMedItem(patientId: number, props: any): Promise<any> {
    return this.api.get(`${path}/re-med/item/${patientId}`, { params: { ...props } })
  }
  static updateReMed(patientCaseId: number, props: any): Promise<any> {
    return this.api.patch(`${path}/re-med/${patientCaseId}`, props)
  }

  /** DOCTOR NOTE */
  static doctorNoteList(patientId: number): Promise<any> {
    return this.api.get(`${path}/doctor-note/patient/${patientId}`).then((res) => res)
  }
  static doctorNoteStore(payload: PayloadDoctorNote): Promise<any> {
    return this.api.post(`${path}/doctor-note`, payload)
  }
  static doctorNoteUpdate(payload: PayloadDoctorNote, doctorNoteId: number): Promise<any> {
    return this.api.patch(`${path}/doctor-note/${doctorNoteId}`, payload)
  }
  static doctorNoteDelete(doctorNoteId: number): Promise<any> {
    return this.api.delete(`${path}/doctor-note/${doctorNoteId}`)
  }

  static finishTreatment(patientCaseId: number): Promise<any> {
    return this.api.patch(`${path}/finish/${patientCaseId}`)
  }

  /** TXC TXP */
  static getTxp(patientId: number): Promise<any> {
    return this.api.get(`${path}/patient-treatment-plans/${patientId}`).then((res) => res)
  }

  static getTxpTypes(): Promise<any> {
    return this.api.get(`${path}/treatment-plan-types`).then((res) => res)
  }

  static findAllTreatmentPlans(props: TreatmentPlansInterFace): Promise<any> {
    return this.api.get(`${path}/treatment-plans`, { params: { ...props } })
  }

  static setPatientTreatmentPlans(patientId: number, payload: PlayLoadTreatmentPlans): Promise<any> {
    return this.api.post(`${path}/patient-treatment-plans/${patientId}`, payload)
  }

  static upDatePatientTreatmentPlans(patientTreatmentPlanId: number, payload: any): Promise<any> {
    return this.api.patch(`${path}/patient-treatment-plans/${patientTreatmentPlanId}`, payload)
  }

  static getTxc(patientId: number): Promise<any> {
    return this.api.get(`${path}/patient-treatment-courses/${patientId}`).then((res) => res)
  }

  static getTxcTypes(): Promise<any> {
    return this.api.get(`${path}/treatment-course-types`).then((res) => res)
  }

  static findAllCourses(props: TreatmentPlansInterFace): Promise<any> {
    return this.api.get(`${path}/treatment-courses`, { params: { ...props } })
  }

  static setPatientTreatmentCourses(patientCaseId: number, payload: PlayLoadTreatmentCourse): Promise<any> {
    return this.api.post(`${path}/patient-treatment-courses/${patientCaseId}`, payload)
  }

  static upDatePatientTreatmentCourses(patientTreatmentCoursesId: number, payload: any): Promise<any> {
    return this.api.patch(`${path}/patient-treatment-courses/${patientTreatmentCoursesId}`, payload)
  }

  static findTreatmentCourses(patientTreatmentCourseId: number): Promise<any> {
    return this.api.get(`${path}/patient-treatment-courses-history/${patientTreatmentCourseId}`)
  }

  static findInvoice(patientCaseId: number): Promise<any> {
    return this.api.get(`${path}/invoice/${patientCaseId}`)
  }

  static saveInvoice(patientInvoiceId: number, payload: SignatureAttachmentsProps): Promise<any> {
    return this.api.post(`${path}/invoice/${patientInvoiceId}`, payload)
  }

  static deleteInvoice(patientInvoiceId: number): Promise<any> {
    return this.api.delete(`${path}/invoice/${patientInvoiceId}`)
  }

  static printInvoice(patientInvoiceId: number): Promise<any> {
    return this.api.get(`${path}/invoice/${patientInvoiceId}/print`)
  }
  static lastNumberCertificate(requestData: LastNumberProps): Promise<any> {
    return this.api.get(`${path}/last-number-certificate`, { params: requestData })
  }
  static treatmentCoursesPaidHistory(props: TreatmentCoursesPaidHistoryProps, patienId: number): Promise<any> {
    return this.api.get(`${path}/patient-treatment-courses-paid-history/${patienId}`, { params: { ...props } })
  }

  static findInvoiceTreatment(patientCaseId: number): Promise<any> {
    return this.api.get(`${path}/invoice-treatment/${patientCaseId}`)
  }

  static saveInvoiceTreatment(patientInvoiceId: number, payload: SignatureAttachmentsProps): Promise<any> {
    return this.api.post(`${path}/invoice-treatment/${patientInvoiceId}`, payload)
  }

  static deleteInvoiceTreatment(patientInvoiceId: number): Promise<any> {
    return this.api.delete(`${path}/invoice-treatment/${patientInvoiceId}`)
  }

}

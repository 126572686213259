import { Box, Tooltip } from '@mui/material'
import AboutUs from 'api/admin/clinic-manage/about.api'
import PaymentChannelsApi from 'api/setting/finances/payment-channels.api'
import Loading from 'component/Loading'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import AlertMsg from 'features/admin/clinic-management/alertmsg/form-alertmsg'
import { BillPrefixesType, PaymentChannelsType } from 'features/admin/clinic-management/clinic-management'
import MainButton from 'new-components/buttons/main-button'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { imgs } from 'constants/images'


const MesssageNotification = () => {
    const { t } = useTranslation()

    const [dataAbout, setDataAbout] = useState<any>()
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [billPrefixes, setBillPrefixes] = useState<BillPrefixesType[]>([]);
    const [loading, setLoading] = useState(true)

    const loadAboutUS = async () => {
        setLoading(true)
        const res = await AboutUs.findAboutUs()
        if (res.status === 200) {
            if (res.data.package === 'ULTRA') {
                setDataAbout(res.data)
            } else {
                setDataAbout({
                    ...res.data,
                    notilineAppointmentCreate: 'สวัสดีค่ะ คุณ:PATIENT_FULLNAME คุณได้ทำการนัดหมายกับ:CLINIC_NAME เพื่อ:OPERATIVE_NAME ในวันที่ :DATE_APPOINTMENT เวลา : :TIME_APPOINTMENT เรียบร้อยแล้วค่ะ',
                    notilineAppointmentConfirm: 'สวัสดีค่ะ คุณ:PATIENT_FULLNAME  ทางคลินิกได้ยืนยันนัดหมาย:OPERATIVE_NAMEของคุณ เป็นวันที่ :DATE_APPOINTMENT เวลา : :TIME_APPOINTMENT เรียบร้อยแล้วค่ะ',
                    notilineAppointmentUpdate: 'สวัสดีค่ะ คุณ:PATIENT_FULLNAME ทางคลินิกได้แก้ไขการนัดเพื่อ:OPERATIVE_NAMEของคุณ เป็นวันที่ :DATE_APPOINTMENT เวลา : :TIME_APPOINTMENT เรียบร้อยแล้วค่ะ',
                    notilineAppointmentPreday: 'คุณ:PATIENT_FULLNAME เรามีนัดเพื่อ:OPERATIVE_NAME ที่ :CLINIC_NAME(สาขา:BRANCH_NAME) ในวันที่ :DATE_APPOINTMENT เวลา :TIME_APPOINTMENT แล้วพบกันค่ะ',
                    notilineAppointmentDay: 'วันนี้มีนัดพบแพทย์ ที่ :CLINIC_NAME(สาขา:BRANCH_NAME) เพื่อทำการ:OPERATIVE_NAME เวลา:TIME_APPOINTMENT อย่าลืมเช็คอินก่อนเข้ารับบริการที่คลินิกผ่านลิ้งค์ :LINK_CHECKIN_APPOINTMENT แล้วพบกันค่ะ ขอบคุณค่ะ',
                    notilineAppointmentStatus: 'สวัสดีค่ะ คุณ:PATIENT_FULLNAME คุณได้:STATUS_APPOINTMENT เข้ารับบริการที่ :CLINIC_NAME(สาขา:BRANCH_NAME) เรียบร้อยแล้วค่ะ',
                })
            }

            const items: BillPrefixesType[] = []
            _.map(res.data?.billPrefixes, (bill: BillPrefixesType) => {
                items.push({
                    billPrefixId: bill.billPrefixId || null,
                    prefix: bill.prefix,
                    billType: bill.billType,
                    paymentChannels: _.map(bill.paymentChannels, (payment: PaymentChannelsType) => { return _.omit(payment, ['paymentChannelName', 'sorting']) })
                })
            })
            setBillPrefixes(items)
        }
        setLoading(false)
    }
    const loadPaymentChannels = async () => {
        await PaymentChannelsApi.findAll({}).then((resp) => setPaymentMethods(resp.data))
    }

    useEffect(() => {
        loadAboutUS()
        loadPaymentChannels()
    }, [])

    const handleEmptyBillPrefixes = () => {

        const selectBillPre: number[] = []
        billPrefixes.forEach((item: BillPrefixesType) => {
            item?.paymentChannels?.forEach((info: PaymentChannelsType) => {
                selectBillPre.push(info.paymentChannelId)
            })
        })
        const newPaymentMethods: { paymentChannelId: number }[] = []
        paymentMethods.forEach((item: any) => {
            if (!selectBillPre.includes(item.paymentChannelId)) {
                newPaymentMethods.push({ paymentChannelId: item.paymentChannelId })
            }
        })

        const newData = [...billPrefixes]
        newData[0] = { ...newData[0], paymentChannels: [...newData[0]?.paymentChannels, ...newPaymentMethods] }

        return newData
    }

    const submit = async () => {
        const ele: any[] = dataAbout.branchCnNumbers.map((val: any) => ({ branchId: val.branchId, branchCnNumber: val?.branchCnNumber || '' }))
        const registerApps: any[] = dataAbout.branchDefaultRegisterApps.map((val: any) => ({ branchId: val.branchId, isDefaultRegisApp: val.isDefaultRegisApp }))
        const newBillPrefixes = await handleEmptyBillPrefixes()
        const data = {
            clinicNameTh: dataAbout.clinicNameTh,
            clinicNameEn: dataAbout.clinicNameEn,
            phoneNo: dataAbout.phoneNo,
            line: dataAbout.line,
            website: dataAbout.website,
            email: dataAbout.email,
            package: dataAbout.package,
            countryId: dataAbout.countryId,
            provinceId: dataAbout.provinceId,
            districtId: dataAbout.districtId,
            subDistrictId: dataAbout.subDistrictId,
            postcode: dataAbout.postcode,
            addressTh: dataAbout.addressTh,
            addressEn: dataAbout.addressEn,
            hasCertificate: dataAbout.hasCertificate,
            reBranchCnNumber: dataAbout.reBranchCnNumber,
            enableApp: dataAbout.enableApp,
            formatBranchCnNumber: dataAbout.formatBranchCnNumber,
            notilineAppointmentCreate: dataAbout.notilineAppointmentCreate,
            notilineAppointmentConfirm: dataAbout.notilineAppointmentConfirm,
            notilineAppointmentUpdate: dataAbout.notilineAppointmentUpdate,
            notilineAppointmentPreday: dataAbout.notilineAppointmentPreday,
            notilineAppointmentDay: dataAbout.notilineAppointmentDay,
            notilineAppointmentStatus: dataAbout.notilineAppointmentStatus,
            notilineAppointmentPredayDate: dataAbout.notilineAppointmentPredayDate,
            statusNotilineAppointmentCreate: dataAbout.statusNotilineAppointmentCreate,
            statusNotilineAppointmentConfirm: dataAbout.statusNotilineAppointmentConfirm,
            statusNotilineAppointmentUpdate: dataAbout.statusNotilineAppointmentUpdate,
            statusNotilineAppointmentPreday: dataAbout.statusNotilineAppointmentPreday,
            statusNotilineAppointmentDay: dataAbout.statusNotilineAppointmentDay,
            statusNotilineAppointmentStatus: dataAbout.statusNotilineAppointmentStatus,
            isFullReceipts: dataAbout.isFullReceipts,
            useBranchHead: dataAbout.useBranchHead,
            cnNumber: dataAbout.cnNumber,
            branchCnNumbers: ele,
            billPrefixes: newBillPrefixes,
            medShowType: dataAbout.medShowType,
            pdShowType: dataAbout.pdShowType,
            formatAppointment: dataAbout.formatAppointment,
            branchDefaultRegisterApps: registerApps,
            snType: dataAbout.snType,
            useWeb: dataAbout.useWeb,
            useCrm: dataAbout.useCrm,
            openEmailNotification: dataAbout?.openEmailNotification || '1',
            openLineNotification: dataAbout?.openLineNotification || '1',
            openSmsNotification: dataAbout?.openSmsNotification || '1',
            openNotification: dataAbout.openNotification,
            hasNoDf: dataAbout.hasNoDf
        }
        await AboutUs.updateAboutUs(data)
            .then((res: any) => {
                if (res.status !== undefined && res.status === 201) {
                    loadAboutUS()
                    return notiSuccess(t(`CLINIC_MANAGEMENT.ERROR.SUCCESS`), '', null)
                } else {
                    return notiError(t(`CLINIC_MANAGEMENT.ERROR.UN_SUCCESS`))
                }
            })
            .catch((e) => {
                const err = e.response.data
                const errorMessage = t(`CLINIC_MANAGEMENT.ERROR.${err.message}`);
                return notiError(errorMessage);
            })
    }

    return (
        <Box height={'100%'}>
            <Loading show={loading} />
            <Box height={'100%'}>
                {/* <Box width="100%" height="80px" boxShadow="0px 3px 6px 0px #0000001F" border="1px solid #E5E5E5" paddingLeft="24px" display="flex" alignItems="center" fontSize="20px" fontWeight={500}  >
                    {t('MENU.MESSAGE_NOTIFICATION')}
                </Box> */}
                {console.log(dataAbout)}
                <Box height={'auto'}>
                    <Box padding={'20px 15px'} display={'flex'} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                        <Box fontSize={'1.5rem'} fontWeight={'500'} whiteSpace={'nowrap'}>
                            {t('CLINIC_MANAGEMENT.TITLE.NOTIFICATION_APP')} {dataAbout?.package !== "ULTRA" && <Tooltip title="ฟังก์ชันนี้สามารถแก้ไขได้ในแพคเกจ ULTRA" placement="right-start"><img src={imgs.IconCliniter} /></Tooltip>}
                        </Box>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} gap={'8px'}>
                            {/* <Box width={'100px'}>
                                <MainButton
                                    title={t('BUTTON.CANCEL')}
                                    onClick={() => {
                                        console.log("222 cancel")
                                    }}
                                    type="secondary"
                                    variant="outlined"
                                    style={{ minWidth: '100%' }}
                                />
                            </Box> */}
                            <Box width={'100px'}>
                                <MainButton
                                    title={t('BUTTON.SUBMIT')}
                                    onClick={submit}
                                    type="primary"
                                    variant="contained"
                                    style={{ minWidth: '100%' }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box padding={'0 15px 15px'} minHeight={'100%'}>
                        <Box width="100%" minHeight={'100%'} boxShadow="0px 3px 6px 0px #0000001F" border="1px solid #E5E5E5" borderRadius={'10px'}  >
                            <AlertMsg
                                valArr={dataAbout}
                                onchange={setDataAbout}
                            />
                        </Box>
                    </Box>


                </Box>
            </Box>

        </Box>
    )
}

export default MesssageNotification
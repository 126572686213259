import { useState, useEffect, useCallback } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import _ from 'lodash'

/** API */
import { fileUrl } from 'api/api'
import AdvanceApi, { AdvanceItemsRequest, AdvanceRequest } from 'api/finances/advance.api'
import ClinicApi from 'api/master/clinic.api'
import BranchApi from 'api/setting/branch-service-points/branch.api'

/** COMPONENT */

import InputTextarea from 'component/Input/InputTextarea'

import ModalCustom from 'component/ModalCustom/ModalCustom'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import SearchPatients from 'component/SearchPatients'

import PaymentChannels from '../payment/component/popupPayment/PaymentChannels'

/** SLICE & UTILS & FEATURES */
import { resetModal } from 'app/slice/modal.slice'
import { getBranch } from 'utils/app.utils'
import { dateToView } from 'utils/date.utils'
import Loading from 'component/Loading'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'

import { PatientFinanceInterface } from '../payment/component/popupPayment/Index'

import { summery, valPayment, valBankTransfer, valBankCredit, valBankDebit, setPaymentByKey, summerySub, setSummery, resetPaymentChannelByKey, summeryTreatmentRight } from 'app/slice/payment.slice'
import InputTimePicker from 'component/Input/InputTimePicker'
import moment from 'moment'
import { Box, styled } from '@mui/material'
import ButtonCustom from 'component/Button/ButtonCustom'
import { icons } from 'constants/images'
import PopupMessageAppointMent from 'features/counter/appointment/popup-message-appointment'

export const BoxSelectDate = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '8px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '1rem'
  }
}))
interface DataSend {
  patientId: number
  remark: string
  paymentChannels: DataSendPaymentChannel[]
}
interface DataSendPaymentChannel {
  paymentChannelId: number
  checked: boolean
  bankId: number | null
  bankNumber: string | null
  cardTypeId: number | null
  voucherListId: number | null
  value: number
}

/** VARIABLE */
const initStateErrorMessage = {
  PATIENT_ANY_REQUIRED: '',
  ITEMS: [
    { BANK: '', CARD: '', VALUE: '' },
    { BANK: '', CARD: '', VALUE: '' },
    { BANK: '', CARD: '', VALUE: '' },
    { BANK: '', CARD: '', VALUE: '' }
  ]
}

const initData = {
  patientId: 0,
  remark: '',
  date: moment().format('YYYY-MM-DD'),
  time: moment().format('HH:mm'),
  paymentChannels: [
    {
      paymentChannelId: 1,
      checked: false,
      bankId: null,
      bankNumber: null,
      cardTypeId: null,
      value: 0.0
    },
    {
      paymentChannelId: 2,
      checked: false,
      bankId: null,
      bankNumber: null,
      cardTypeId: null,
      value: 0.0
    },
    {
      paymentChannelId: 3,
      checked: false,
      bankId: null,
      bankNumber: null,
      cardTypeId: null,
      value: 0.0
    },
    {
      paymentChannelId: 4,
      checked: false,
      bankId: null,
      bankNumber: null,
      cardTypeId: null,
      value: 0.0
    },
    {
      paymentChannelId: 7,
      checked: false,
      bankId: null,
      bankNumber: null,
      cardTypeId: null,
      value: 0.0
    }
  ]
}

export interface IFormAdvanceProps {
  isShow: boolean
  onReset?: () => void
  handleSuccess?: () => void
}

export function FormAdvance(props: IFormAdvanceProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const branchId = getBranch()
  const [errorMessage, setErrorMessage] = useState({ ...initStateErrorMessage })
  const valSummery = useSelector(summery)
  const valSummerySub = useSelector(summerySub)
  const bankCredit = useSelector(valBankCredit)
  const bankDebit = useSelector(valBankDebit)
  const bankTransfer = useSelector(valBankTransfer)
  const objPayment = useSelector(valPayment)
  const valSummeryTreatmentRight = useSelector(summeryTreatmentRight)


  /** OPTION */
  const [branchName, setBranchName] = useState<string>('')
  const [banksOption, setBanksOption] = useState<any[]>([])

  /** DATA */
  const [dataSend, setDataSend] = useState<any>()
  const [banks, setBanks] = useState<any[]>([])
  const [patientFinance, setPatientFinance] = useState<PatientFinanceInterface>({ advanceBalance: 0, outstandingBalance: 0, treatmentRights: [] })
  const [billPrefixes, setBillPrefixes] = useState([])
  const [prefix, setPrefix] = useState()

  const [showTemplateText, setShowTemplateText] = useState<boolean>(false)
  const typeText = 'ADVANCE'

  useEffect(() => {
    clearErrorMessage()
  }, [dataSend])



  useEffect(() => {
    ClinicApi.findAllBanks(branchId).then((res) => {
      if (res.data) {
        setBanksOption([...res.data])
        setBanks([...res.data])
      }
    })
    BranchApi.findById(branchId).then((res: any) => setBranchName(res?.data?.branchName || ''))
    ClinicApi.allBillPrefix().then((resBillPrefixes) => {
      setBillPrefixes(resBillPrefixes.data)
      dispatch(setPaymentByKey({ key: 'prefix', value: resBillPrefixes.data[0].prefix || '' }))
    })
    setDataSend(initData)
  }, [])

  useEffect(() => {
    const prefix = billPrefixes.map((e: any) => {
      if (e.prefix === objPayment.prefix) return setPrefix(e.billPrefixId)
    })
  }, [objPayment])


  useEffect(() => {
    const data = dataSend?.paymentChannels.map((pc: DataSendPaymentChannel, pcIndex: number) => {
      // Cash
      if (valSummery[1] && pc.paymentChannelId === 1) {
        pc.checked = valSummery[1] ? true : false
        pc.bankId = null
        pc.bankNumber = null
        pc.cardTypeId = null
        pc.voucherListId = null
        pc.value = valSummery[1]
      }
      //   Bank transfer
      if (valSummery[2] && pc.paymentChannelId === 2) {
        pc.checked = valSummery[2] ? true : false
        pc.bankId = bankTransfer.bankId
        pc.bankNumber = bankTransfer.bankAccountNumber
        pc.cardTypeId = null
        pc.voucherListId = null
        pc.value = valSummery[2]
      }
      // Credit
      if (valSummery[3] && pc.paymentChannelId === 3) {
        pc.checked = valSummery[3] ? true : false
        pc.bankId = bankCredit.bankId
        pc.bankNumber = bankCredit.bankAccountNumber
        pc.cardTypeId = bankCredit.cardTypeId
        pc.voucherListId = null
        pc.value = valSummery[3]
      }
      // Debit
      if (valSummery[4] && pc.paymentChannelId === 4) {
        pc.checked = valSummery[4] ? true : false
        pc.bankId = bankDebit.bankId
        pc.bankNumber = bankDebit.bankAccountNumber
        pc.cardTypeId = bankDebit.cardTypeId
        pc.voucherListId = null
        pc.value = valSummery[4]
      }

      if (pc.paymentChannelId === 7) {

        const voucher = valSummeryTreatmentRight[7]

        pc.checked = valSummeryTreatmentRight[7] ? true : false
        pc.bankId = null
        pc.bankNumber = null
        pc.cardTypeId = null

        for (const key in voucher) {
          if (Object.prototype.hasOwnProperty.call(voucher, key)) {
            const element = voucher[key];
            pc.voucherListId = Number(key)
            pc.value = element.value
          }
        }
      }
    })
  }, [valSummery, bankTransfer])


  const clearErrorMessage = async () => setErrorMessage({ ...initStateErrorMessage })

  const submit = async () => {
    /** VALIDATE */
    if (!dataSend.patientId) {
      const msg: any = {
        PATIENT_ANY_REQUIRED: !dataSend.patientId ? t(`APPOINTMENT.MESSAGE.PATIENT_ANY_REQUIRED`) : ''
      }
      setErrorMessage({ ...initStateErrorMessage, ...msg })
      return false
    }

    let sum = 0
    const errorValue: any[] = []
    let itemsError: any = false
    const dataCheck = objPayment?.orderPaymentChannels
    console.log('dataCheck', dataCheck);

    const filteredData: any = {}
    for (const key in dataCheck) {
      if (key !== '5' && key !== '6') {
        filteredData[key] = dataCheck[key]
      }
    }

    const data: DataSendPaymentChannel[] = dataSend.paymentChannels.map((item: DataSendPaymentChannel, index: number) => {
      if (item.paymentChannelId === 1) {
        return {
          ...item,
          bankId: null,
          bankNumber: null,
          cardTypeId: null,
          voucherListId: null,
          value: valSummery[1],
          checked: filteredData[index + 1]
        }
      }
      if (item.paymentChannelId === 2) {
        return {
          ...item,
          cardTypeId: null,
          voucherListId: null,
          value: valSummery[2],
          checked: filteredData[index + 1],
          bankId: bankTransfer.bankId,
          bankNumber: bankTransfer.bankAccountNumber
        }
      }
      if (item.paymentChannelId === 3) {
        return {
          ...item,
          checked: filteredData[index + 1],
          bankId: bankCredit?.bankId,
          bankNumber: bankCredit?.bankAccountNumber,
          cardTypeId: bankCredit?.cardTypeId,
          voucherListId: null,
          value: valSummery[3]
        }
      }
      if (item.paymentChannelId === 4) {
        return {
          ...item,
          checked: filteredData[index + 1],
          bankId: bankDebit?.bankId,
          bankNumber: bankDebit?.bankAccountNumber,
          cardTypeId: bankDebit?.cardTypeId,
          voucherListId: null,
          value: valSummery[4]
        }
      }
      if (item.paymentChannelId === 7) {

        const voucher = valSummeryTreatmentRight[7]
        let checkVoucher: any = {}
        for (const key in voucher) {
          if (Object.prototype.hasOwnProperty.call(voucher, key)) {
            const element = voucher[key];
            checkVoucher = {
              ...item,
              bankId: null,
              bankNumber: null,
              cardTypeId: null,
              value: element.value,
              voucherListId: Number(key),
              checked: element.isCheck
            }
          }
        }
        return checkVoucher
      }
      return { ...item }
    })

    const items: AdvanceItemsRequest[] = _.map(
      _.filter(data, (pc: DataSendPaymentChannel) => pc.checked),
      (pc: DataSendPaymentChannel) => {
        const pcIndex: any = _.findIndex(data, (pcI: DataSendPaymentChannel) => pcI.paymentChannelId === pc.paymentChannelId)
        const objError: any = {
          TITLE: handleHeaderError(pcIndex),
          BANK: !pc.bankId && [2, 3, 4].includes(pc.paymentChannelId) ? t('ADVANCE.MESSAGE.ERROR.BANK_EMPTY') : '',
          CARD: !pc.cardTypeId && [3, 4].includes(pc.paymentChannelId) ? t('ADVANCE.MESSAGE.ERROR.CARD_EMPTY') : '',
          VALUE: pc.value ? '' : t('ADVANCE.MESSAGE.ERROR.MONEY_EMPTY')
        }
        errorValue[pcIndex] = objError
        itemsError = Boolean(!pc.bankId && [2, 3, 4].includes(pc.paymentChannelId)) && Boolean(!pc.cardTypeId && [3, 4].includes(pc.paymentChannelId)) && Boolean(!pc.value)
        sum += Number(pc.value)

        return {
          paymentChannelId: pc.paymentChannelId,
          bankId: pc.bankId || null,
          cardTypeId: pc.cardTypeId || null,
          voucherListId: pc.voucherListId || null,
          amountRaw: pc.value,
          amount: pc.value
        }
      }
    )

    // if (itemsError) return false
    if (_.isEmpty(valSummery)) return notiError(t('ADVANCE.MESSAGE.ERROR.SELECT_MONEY_EMPTY'))
    if (errorValue) {
      for (const key in errorValue) {
        if (errorValue[key].BANK !== '') {
          return notiError(`${errorValue[key].TITLE} ${errorValue[key].BANK}`)
        }
        if (errorValue[key].CARD !== '') {
          return notiError(`${errorValue[key].TITLE} ${errorValue[key].CARD}`)
        }
        if (errorValue[key].VALUE !== '') {
          return notiError(`${errorValue[key].TITLE} ${errorValue[key].VALUE}`)
        }
      }
    }

    if (items.length === 0) return notiError(t('ADVANCE.MESSAGE.ERROR.SELECT_MONEY_EMPTY'))
    const temp: any = { ...initStateErrorMessage }
    _.map(errorValue, (e: any, index: any) => {
      temp.ITEMS[index] = e
    })
    if (temp) {
      setErrorMessage({ ...temp })
    }
    const payload: AdvanceRequest = {
      billPrefixId: prefix,
      patientId: dataSend.patientId,
      amountTotal: sum,
      remark: dataSend.remark.toString(),
      date: dataSend.date,
      time: dataSend.time,
      advanceItems: items
    }

    const checkErrorValue = errorValue.filter((val: any) => val.BANK !== '' || val.CARD !== '' || val.VALUE !== '')

    if (items.length > 0 && checkErrorValue.length === 0) {
      setLoading(true)
      await AdvanceApi.create(payload)
        .then((res: any) => {
          if (res.status !== undefined && res.status === 201) {
            if (props?.handleSuccess) props?.handleSuccess()
            dispatch(setSummery({}))
            dispatch(resetPaymentChannelByKey())
            dispatch(resetModal())
            resetForm()
            notiSuccess(t(`ADVANCE.MESSAGE.SUCCESS.CREATE`))
            window.open(`${fileUrl}/${res.data}`, '_blank')
          }
        })
        .catch((e) => {
          const err = e.response.data
          if (err.message === 'Internal server error' && err.message?.includes('INVALID_TOKEN')) {
            return
          }
          notiError(t(`ADVANCE.MESSAGE.${err.message}`))
        })
        .finally(() => setLoading(false))
    }
  }
  const handleHeaderError = (num: number) => {
    return num === 0 ? t(`ADVANCE.MESSAGE.MONEY`) : num === 1 ? t(`ADVANCE.MESSAGE.TRANSFER`) : num === 2 ? t(`ADVANCE.MESSAGE.CREDIT`) : t(`ADVANCE.MESSAGE.DEBIT`)
  }
  const resetForm = async () => {
    clearErrorMessage()
    dispatch(resetModal())
    if (props.onReset) props.onReset()
    setDataSend({ ...initData })
  }

  const onSelectPatient = async (id: number) => setDataSend({ ...dataSend, patientId: id })

  return props.isShow ? (
    <ModalCustom
      size={'lg'}
      title={t('ADVANCE.MODAL_ADD.TITLE')}
      alignFooter={'end'}
      fullscreen={'lg-down'}
      component={
        <>
          <Loading show={loading} type="fullLoading" />
          <Row className="mb-2 mt-2" style={{ justifyItems: 'center' }}>
            <Col style={{ margin: 'auto 0', paddingRight: '0px' }}>
              <strong>{t('ADVANCE.MODAL_ADD.BRANCH')}</strong> {branchName}
            </Col>
            <Col sm={'auto'}>
              <BoxSelectDate>
                <Box className="w-50">
                  <InputSecondNewDatePicker
                    disableFuture
                    dateFormat={'DD/MM/YYYY'}
                    label={t('ADVANCE.MODAL_ADD.DATE')}
                    // inputHeight={32}
                    value={dataSend.date}
                    onchange={(e: any) => setDataSend({ ...dataSend, date: moment(e).format('YYYY-MM-DD') })}
                  />
                </Box>
                <Box className="w-50">
                  <InputTimePicker required={true} key={'time'} label={t('ADVANCE.MODAL_ADD.TIME')} value={dataSend.time} onChange={(e: any) => setDataSend({ ...dataSend, time: e.format('HH:mm') })} />
                </Box>
              </BoxSelectDate>
            </Col>
          </Row>
          <div className="mb-4">
            <SearchPatients status="ACTIVE" bodyHeight={43} callbackPatientId={onSelectPatient} patientId={dataSend.patientId} onClear={() => onSelectPatient(0)} helperText={errorMessage.PATIENT_ANY_REQUIRED || ''} />
          </div>
          <div className="d-flex mb-3">
            <InputTextarea
              value={dataSend.remark}
              onchange={(event) => (dataSend.remark.length > 99 ? setDataSend({ ...dataSend, remark: dataSend.remark.slice(0, 99) }) : setDataSend({ ...dataSend, remark: event.target.value }))}
              label={t('ADVANCE.MODAL_ADD.DETAIL_INCOME')}
              helperText={''}
            />
            <ButtonCustom
              onClick={() => setShowTemplateText(true)}
              textButton={
                <img src={icons.iconFeatherEdit}
                  alt=""
                  style={{ width: 24 }}
                />}
              className="h-100"
              style={{ margin: 0, height: '89px' }}
            />
          </div>
          <div className={`px-0 mt-3`}>
            <hr className="my-lg-2 " />
          </div>
          <Row className="custom-scroll" sx={{ overflowX: 'auto' }}>
            <PaymentChannels menu={'PAYMENT'} treatments={dataSend} banks={banks} patientFinanceFull={patientFinance} patientId={dataSend.patientId} advance={true} disableIcon={true} />
          </Row>

          {showTemplateText &&
            <PopupMessageAppointMent
              isShow={showTemplateText}
              type={typeText}
              setIsShow={(show: boolean) => {
                setShowTemplateText(show)
              }}
              setText={(text: any) => {
                if (text.length > 0) {
                  const newText = dataSend.remark.toString() !== "" ? [dataSend.remark.toString(), text] : [text]
                  setDataSend({ ...dataSend, remark: newText })
                }
              }} />}

        </>
      }
      onSubmit={submit}
      onReset={() => resetForm()}
      disabledSubmit={loading}
      textBtnCancel={t('ADVANCE.MODAL_ADD.CANCEL')}
      textBtnConfirm={t('ADVANCE.MODAL_ADD.PAYMENT')}
    />
  ) : (
    <></>
  )
}

import { title } from "process";
import { ContentDataProps } from "./content";

export const contentUpdateNo4: ContentDataProps[] = [
    {
        title: 'FUNCTION UPDATES',
        topic: [
            {
                title: `เมนูรายงาน`,
                sub: [
                    `ปรับเพิ่มการแสดงผลคอลัมน์ ยอดรวมราคาจ่ายจริง ที่ รายงานค่าตอบแทนทันตแพทย์ และ รายงานค่าตอบแทนทนัตแพทย์(รวมแพทย์)`,
                    `ปรับเพิ่มการเลือกรายการโดยไม่ระบุทันตแพทย์ที่ รายงานสรุปรายได้(การเงิน)`,
                    `ปรับเพิ่มการสรุปรายการยา, สินค้าและผลิตภัณฑ์ ทีรายงานการขายยาและผลิตภัณฑ์`,
                    `ปรับแก้ไขรหัสรายงานและจัดเรียงหมวดหมู่ใหม่`,
                    `ปรับเพิ่มตัวเลือก ราคาจ่ายจริง/ราคาหัตถการ ที่ รายงานค่าตอบแทนทันตแพทย์ Clinic Fee (รวมแพทย์)`,
                    `ปรับเพิ่มตัวเลือก ราคาจ่ายจริง/ราคาหัตถการ ที่ รายงานค่าตอบแทนทันตแพทย์ Clinic Fee`,
                    `ปรับเพิ่มตัวเลือก ราคาจ่ายจริง/ราคาหัตถการ ที่ รายงานค่าตอบแทนทันตแพทย์ (รวมแพทย์ )`,
                    `ปรับเพิ่มการกรอง ประเภทผู้ป่วย ที่รายงานข้อมูลคนไข้`,
                    `ปรับเพิ่ม หัวใบเสร็จ(Prefix) รับเงินล่วงหน้าโดยกำหนดเป็น Default ที่ รายงานสรุปรายได้`,
                    `ปรับเพิ่มการแสดงผลข้อมูลในรายงาน กรณีแพทย์มีการลงบันทึกการรักษาแต่ไม่ได้มีการลงตารางเวรแพทย์`,
                    `ปรับรูปแบบฟอร์มใบยินยอมการรักษา`,
                    `ปรับเพิ่ม ใบแจ้งค่าบริการ`,
                    `ปรับเพิ่ม การแก้ไขข้อมูลพิเศษ จากหน้าการรักษา`,
                    `ปรับเพิ่มการแสดงผล รายการรักษาทั้งหมด ที่หน้าการเพิ่มนัดหมาย และแก้ไขนัดหมาย`,
                    `ปรับเพิ่มตัวเลือกวันที่ และเวลา ในการลงรายการย้อนหลัง`,
                    `ปรับเพิ่มคอลัมน์ ทันตแพทย์ ที่เมนู LAB`,
                    `ปรับเพิ่มตัวกรอง ทันตแพทย์ ที่เมนู LAB`,
                    `ปรับเพิ่มการแสดงผลคอมลัมน์ รหัสคำวินิจฉัย และ ประเภทคำวินิจฉัย ที่เมนูจัดการคำวินิจฉัย`,
                    `ปรับแก้ไขการแสดงผลการกำหนดสิทธิ์ที่ เมนูจัดการข้อมูลบุคคล`
                ]
            },
            {
                title: `เมนูการรักษา`,
                sub: [
                    `ปรับรูปแบบฟอร์มใบยินยอมการรักษา`,
                    `ปรับเพิ่ม ใบแจ้งค่าบริการ`,
                    `ปรับเพิ่ม การแก้ไขข้อมูลพิเศษ จากหน้าการรักษา`
                ]
            },
            {
                title: `เมนูนัดหมาย`,
                sub: [
                    `เพิ่มการแสดงผล รายการรักษาทั้งหมด ที่หน้าการเพิ่มนัดหมาย และแก้ไขนัดหมาย`
                ]
            },
            {
                title: `เมนูรับเงินล่วงหน้า`,
                sub: [
                    `ปรับเพิ่มตัวเลือกวันที่ และเวลา ในการลงรายการย้อนหลัง`
                ]
            },
            {
                title: `เมนู LAB`,
                sub: [
                    `ปรับเพิ่มคอลัมน์ ทันตแพทย์`,
                    `ปรับเพิ่มตัวกรอง ทันตแพทย์`
                ]
            },
            {
                title: `เมนูตั้งค่า`,
                sub: [
                    `ปรับเพิ่มการแสดงผลคอมลัมน์ รหัสคําวินิจฉัย และ ประเภทคําวินิจฉัย ที่เมนูจัดการคําวินิจฉัย`,
                    `ปรับแก้ไขการแสดงผลการกําหนดสิทธิ์ที่ เมนูจัดการข้อมูลบุคคล`
                ]
            },
        ]
    },
    {
        title: 'BUG FIXES',
        topic: [
            {
                title: 'BUG FIXES',
                sub: [
                    `ปรับแก้ไขประเภทของรายงาน และรหัสระบุรายงาน`,
                    `ปรับแก้ไขเงื่อนไขการคํานวณวันพักที่ "ใบรับรองแพทย์ลาป่วย"`,
                    `ปรับแก้ไขรายละเอียดการแสดงผลการพิมพ์เอกสารใบรับรองแพทย์`,
                    `ปรับแก้ไขการแสดงผลในส่วนของเมนู "จัดการบุคคล"`,
                    `ปรับแก้ไขหัวคอลัมน์เมนู "จัดการค่า Doctor fee"`,
                    `ปรับแก้ไขการแสดงผล โรคประจําตัว ยาที่ใช้ ยาที่แพ้ ในส่วนของหน้าต่างข้อมูลผู้ป่วยที่หน้าการรักษา`,
                    `ปรับแก้ไขการกําหนดตัวษรท้ายใบเสร็จ Thermal เป็น 100 ตัวอักษร`,
                    `ปรับแก้ไขการนําเลขผู้ป่วยพิเศษ (SN) มาแสดงบนใบเสร็จ`,
                    `ปรับแก้ไขการแสดงผลการเลือกวันที่และเวลา ที่เมนูรับเงินล่วงหน้า`,
                    `ปรับแก้ไขกรณีเลือกสินค้าใหม่แล้วไม่สามารถชําระเงินได้`,
                    `ปรับแก้ไขการแสดงผลหน้าขาว เมื่อเลือกสิทธิ์การรักษา`,
                    `ปรับแก้ไขการแสดงชื่อรายการ OPD Card ที่หน้าลงทะเบียน`,
                    `ปรับแก้ไขการแสดงผลเอกสารตามการเลือกใช้งานจากเมนูตั้งค่า`,
                    `ปรับแก้ไขการแสดงผลชื่อรายการที่เมนูจัดการจุดบริการ`,
                    `ปรับแก้ไขการกําหนดสิทธิ์ Doctor note ที่หน้าชําระเงิน ซึ่งส่งผลกระทบต่อหน้าการรักษา`,
                    `ปรับแก้ไขข้อมูลการจัดการรูปแบบเอกสาร`,
                    `ปรับแก้ไขปัญหาการกรอกรายละเอียดเพิ่มเติมในส่วนของยาเมื่อกรอกเกิน 100 ตัวอักษร`,
                    `ปรับแก้ไขเมื่อมีการบันทึกต้นแบบค่าธรรมเนียมบัตรเครดิตแต่ระบบไม่อัพเดทตาม`,
                    `ปรับแก้ไขนําช่องทางการชําระเงินบางส่วนออกจากเมนู รายรับรายจ่าย`,
                    `ปรับเพิ่มเอกสาร "ปก OPD Card" จากหน้าลงทะเบียน`,
                    `ปรับแก้ไขการแสดงยอดค้างที่ใบเสร็จ Thermal`,
                    `ปรับแก้ไขการบันทึกข้อมูลซ้ําที่เมนู LAB`,
                    `ปรับแก้ไขปัญหาไม่สามารถเพิ่มรายการสินค้าที่หน้าการรักษา`,
                    `ปรับแก้ไขการแสดงผล เหตุผลการยกเลิกใบเสร็จเมื่อมีข้อความที่ยาวเกินไป`,
                    `ปรับแก้ไขการแสดงผลข้อมูลจาก "รายงานสรุปรายได้" เมื่อพิมพ์ PDF`,
                    `ปรับแก้ไขการหน้าต่างการแสดงผลเส้นกรอบ "รายงานค่า DF หลังตัด LAB" ราคาหัตถการ`,
                    `ปรับแก้ไขการแสดงผลจากแจ้งเตือนที่เมนูจัดการบุคคล`,
                    `ปรับการแก้ไขการนําค่าประกันลงเวรออกจากตารางเวรแพทย์ เมื่อมีการกําหนดค่าประกันลงเวรออกจากทันตแพทย์`,
                    `ปรับเพิ่มการแสดงผลใบเสร็จรับเงินล่วงหน้าที่รายงานสรุปรายได้(การเงิน)`,
                    `ปรับแก้ไขการกรอกวันเกิดจากเมนูลงทะเบียนเป็นจําเป็นต้องกรอก`,
                    `ปรับแก้ไขการแสดงผลเมนูนัดหมาย เมื่อเลือกดูแบบภาพรวมนัดหมายให้มีการแสดงผลทุก 15 นาที`,
                    `ปรับแก้ไขปัญหาการแสดงผลในส่วนของประเมินค่าใช้จ่าย จากเมนูนัดหมาย`,
                    `ปรับแก้ไขการเปลี่ยนประเภทสินค้า โดยเมื่อต้องการเปลี่ยนประเภทไม่สามารแก้ไขได้`,
                    `ปรับเพิ่มการพิมพ์ค้นหารายการคําวินิจฉัยในส่วนของ มนูจัดการรายการหัตถการ`,
                    `ปรับแก้ไขปัญหาการแสดงรหัสล็อตผิด เมื่อนําเข้าสินค้าครั้งแรก`,
                    `ปรับแก้ไขการแสดงผลจํานวนสินค้าไม่ถูกต้อง`,
                    `ปรับแก้ไขปัญหาการแจ้งเตือนกรณีกรอกรหัสยาซ้ํา`,
                    `ปรับแก้ไขปัญหาไม่สามารถลงบันทึกคอร์สที่เมนูลงรายการย้อนหลังได้`,
                    `ปรับแก้ไขปัญหาการแสดงผลยอดรวมไม่ถูกต้องที่ รายงานสรุปรายได้(การเงิน)`,
                    `ปรับแก้ไขรูปแบบใบเสร็จ Thermal`,
                    `ปรับแก้ไขปัญหาไม่สามารถค้นหาชื่อสินค้าได้จาก เมนูปรับเปลี่ยนยอด`,
                    `ปรับแก้ไขปัญหาการนำเข้าสินค้าแบบไม่มีรหัสล็อต แต่ระบบแจ้งเตือนว่ามีรหัสล็อตซ้ำ`,
                    `ปรับแก้ไขการแสดงผลยอดไม่ตรงเมื่อ Export รายงานค่า DF หลังตัด LAB จ่ายจริง`,
                    `ปรับแก้ไขการแสดงผล เมนูนัดหมาย`,
                    `ปรับแก้ไขการแสดงผลค่า DF`,
                    `ปรับการแก้ไขการแสดงผลค่า DF ที่ เมนูจัดการ Doctor fee`,
                    `ปรับแก้ไขการแสดงผล รายงานใบส่งเงิน`,
                    `ปรับแก้ไขปัญหากรณีเลือกสินค้าจากหน้าการรักษา แต่ไม่แสดงที่หน้าชำระเงิน`,
                    `ปรับแก้ไขการแสดงผลยอดรวมที่ เมนูจัดการค่ารักษา`,
                    `ปรับแก้ไขการแสดงผลข้อมูลนัดหมายคนไข้เมื่อส่งตรวจจาก เมนูลงทะเบียน`,
                    `ปรับแก้ไขการแสดงประเภทสินค้าไม่ครบที่ เมนูจัดการสินค้าและผลิตภัณฑ์`,
                    `ปรับแก้ไขการแสดงผลกรณีปรับสิทธิ์การมองเห็นรายงาน`,
                    `ปรับแก้ไขการขึ้นบรรทัดใหม่จากการกด Enter ในส่วนของ จัดการคำแนะนำ`,
                    `ปรับแก้ไขการเรียงรูปภาพเมื่ออัพโหลด`,
                    `ปรับแก้ไขการแสดงผล รายงานสรุปรายได้ เมื่อ Export PDF แล้วข้อมูลแสดงไม่ถูกต้อง`,
                    `ปรับแก้ไขการแสดงผลผิดกรณีที่ใส่ส่วนลดสินค้าเป็น %`,
                    `ปรับแก้ไขการแสดงผลค่า DF กรณีมีการ Fix DF เป็น 0 บาท และมีการปรับแก้ไขราคาค่ารักษาให้ระบบแสดงค่า DF เป็น 0 บาท ตามที่มีการกำหนดไว้`,
                    `ปรับแก้ไขการอัพเดทเบอร์คนไข้ โดยจะมีผลต่อรายละเอียดนัดหมายในอดีต`,
                    `ปรับแก้ไขการแสดงผลวันที่ในใบเสร็จ`,
                    `ปรับแก้ไขปัญหากรณีปิดการใช้งานผู้ป่วยและไม่สามารถปิดได้`,
                    `ปรับเพิ่ม ยาที่ใช้ ที่หน้าต่างข้อมูลผู้ป่วย`,
                    `ปรับแก้ไขการแสดงผล รายงานค่าตอบแทนทันตแพทย์หัก Clinic Fee (รวมแพทย์)`,
                    `ปรับแก้ไขการแสดงผล รายงานค่าตอบแทนทันตแพทย์ (รวมแพทย์)`,
                    `ปรับแก้ไขการแสดงผล รายงานค่าตอบแทนทันตแพทย์หัก Clinic Fee`,
                    `ปรับแก้ไขการแสดงผล รายงานค่าตอบแทนทันตแพทย์`,
                    `ปรับแก้ไขการแสดงผล รายงานใบส่งเงิน New`,
                    `ปรับแก้ไขปัญหาการแสดงผลรายการที่ เมนูจัดการค่า DF LAB Fee กรณีที่มีการยกเลิกรายการ LAB แต่ระบบยังแสดงรายการอยู่`,
                    `ปรับแก้ไขการแสดงผลการคำนวณค่า DF ที่รายงานค่าตอบแทนทันตแพทย์หัก Clinic Fee ตามการตั้งค่าที่กำหนดจาก เมนูจัดการสาขา`,
                    `ปรับแก้ไขการแสดงผลการคำนวณค่า DF ที่รายงานค่าตอบแทนทันตแพทย์หัก Clinic Fee (รวมแพทย์) ตามการตั้งค่าที่กำหนดจาก เมนูจัดการสาขา`,
                    `ปรับแก้ไขตำแหน่งการแสดงผลข้อมูลที่ รายงานค่าตอบแทนทันตแพทย์`,
                    `ปรับการแก้ไขการแสดงผลชื่อเมนูไม่ถูกต้องที่ เมนูจัดการบทบาท`,
                    `ปรับแก้ไขการแสดงชือสถานะไม่ถูกต้องที่ เมนูปรับเปลี่ยนยอด`,
                    `ปรับเพิ่มการนำเข้าสินค้าจำนวน 0`,
                    `ปรับแก้ไขกรณีบันทึกข้อมูลโดยไม่ระบุชื่อ ทันตแพทย์ ให้มีการแจ้งเตือนที่ เมนูจัดการค่า Doctor fee`,
                    `ปรับแก้ไขปัญหาการไม่แสดงผลคำนำหน้า ที่เมนูลงทะเบียน`,
                    `ปรับแก้ไขการแสดงผลชื่อและนามสกุลแสดงซ้อนกัน 2 บรรทัด ที่ใบเสร็จรับเงินล่วงหน้า`,
                    `ปรับเพิ่มฟังก์ชั่น พิมพ์ใบแลป`,
                    `ปรับเพิ่มการแสดงผลรหัสผู้ป่วยต่างสาขาที่ เมนูรับเงินล่วงหน้า`,
                    `ปรับแก้ไขกรณีกดบันทึกหรือส่งตรวจคนไข้หใม่พร้อมกันหลายสาขา ทำให้ไม่สามารถบันทึกหรือส่งตรวจได้`,
                    `ปรับแก้ไขในส่วนข้อมูลพิเศษคนไข้ ให้เป็นข้อมูลปัจจุบันที่รายงานสรุปนัดหมายแยกตามทันแพทย์`,
                    `ปรับแก้ไขการคำนวณ Clinic fee และการแสดงผลค่า DF ที่เมนูจัดการค่า Doctor fee`,
                    `ปรับแก้ไขการแสดงผลชื่อตัวเลือก Fix Code เป็น แสดงรายการอัตโนมัติ ที่เมนูจัดการรายการหัตถการ`,
                    `ปรับเงื่อนไขการระบุยอดหักบัตรเครดิตจาก เมนูจัดการต้นแบบค่าธรรมเนียมบัตรเครดิต จากเดิมกำหนดไว้ที่ 3% เป็นสามารถกำหนดได้ตามต้องการ`,
                    `ปรับแก้ไขหน้าต่างการแสดงผลการแก้ไข Username และ Password ที่เมนูจัดการบุคคล`
                ]
            }
        ]
    }

]

export const contentFeatureNo4: ContentDataProps[] = [
    {
        title: 'NEW FEATURE',
        topic: [
            {
                title: `รายงาน`,
                sub: [
                    `รายงานเงินล่วงหน้าคงเหลือ`,
                    `รายงานการใช้เงินล่วงหน้า`,
                    `รายงานการรับเงินล่วงหน้า`
                ]
            }
        ]
    }

]

export const contentUpdaeNo27Jan = [
    {
        title: 'Update Feature',
        topic: [
            {
                title: 'Application',
                sub: [
                    'ปรับการใช้งานแอปพลิเคชั่น ให้รองรับสำหรับแพ็คเกจ Premium (เฉพาะฟังก์ชั่นการแจ้งเตือนนัดหมาย)',
                    'ปรับเพิ่มฟังก์ชั่น การจองนัดหมายผ่านแอปพลิเคชั่น (Ultra)',
                    'ปรับเพิ่มฟังก์ชั่น การจัดการข้อความการแจ้งเตือนนัดหมาย (Ultra)'
                ]
            },
            {
                title: 'การนัดหมาย',
                sub: [
                    'ปรับแก้ไข เงื่อนไขการแสดงสีนัดหมายที่เมนู “ตารางนัด“ กรณีมีการเลือกหัตถการมากกว่า 1 หัตถการ ให้แสดงผลเป็นสีขาว เมนูนัดหมาย',
                ]
            },
            {
                title: 'การคลัง',
                sub: [
                    'เพิ่ม ฟังก์ชั่นรองรับการเชื่อมต่อกับ เครื่องอ่านบาร์โค้ดสำหรับการจ่ายสินค้าและยา',
                    'เพิ่ม หมวดหมู่ “วัสดุอุปกรณ์ (PD)“ ในระบบ',
                    'เพิ่ม ฟังก์ชั่นการผูกยา, สินค้าและผลิตภัณฑ์, วัสดุอุปกรณ์เข้ากับรายการหัตถการ'
                ]
            },
            {
                title: 'รายงาน',
                sub: [
                    'เพิ่ม “CLD-A16 รายงานสรุปรายรับรายจ่าย“',
                    'เพิ่ม “CLD-A17 รายงานสรุปรายได้ (แบบแจกแจงช่องทางการชำระเงิน)“',
                    'เพิ่มตัวกรอง ประเภทการชำระเงิน ที่ “รายงานสรุปรายได้“'
                ]
            },
            {
                title: 'การตั้งค่า',
                sub: [
                    'เพิ่ม ฟังก์ชั่นการปรับแต่งช่องการกรอกข้อมูลและการกำหนดความจำเป็นในการกรอกที่เมนู ลงทะเบียน',
                    'เพิ่ม เงื่อนไขการตั้งค่า การคำนวณรายได้ของร้าน ตามเงินรับล่วงหน้าหรือการชำระด้วยเงินล่วงหน้า',
                    'เพิ่ม เงื่อนไขการตั้งค่า สำหรับการได้รับค่า DF กรณีมีการ Fix ค่า DF',
                    'Fix ค่า DF แต่ รายการหัตถการดังกล่าว ไม่มีค่าใช้จ่าย จะยังได้รับค่า DF ตามที่กำหนด',
                    'Fix ค่า DF แต่ รายการหัตถการดังกล่าว ไม่มีค่าใช้จ่าย จะไม่ได้รับค่า DF',
                    'เพิ่ม การแสดงผลตัวอย่างเอกสารที่แท็ป “รูปแบบเอกสาร“',
                    'เพิ่ม ตัวเลือกเงื่อนไขการแสดงเวลาการทำนัด จากเวลาเปิดปิดคลินิกหรือเวลาเข้างานทันตแพทย์',
                    'เพิ่ม ตัวเลือกเงื่อนไขการแสดงรายการหัตถการ ในการทำนัดโดยแสดงเฉพาะประเภทหัตถการ หรือแสดงประเภทและรายการหัตถการ',
                    'เพิ่ม ตัวเลือกการกำหนดสิทธิ์ การอนุมัติการปรับเปลี่ยนยอดและการอนุมัติรายรับรายจ่าย',
                    'เพิ่ม ตัวเลือกการ ทำซ้ำ สำหรับต้นแบบค่า DF/ค่า Lab',
                    'เพิ่ม ตัวเลือกการกำหนดต้นแบบค่า DF/ค่า Labเป็นต้นแบบหลักสำหรับใช้ทุกสาขา (กรณีมีมากกว่า 1 สาขา)',
                    'เพิ่ม ตัวเลือกการกำหนดราคาหัตถการ แยกตามสาขากรณีแต่ละสาขาราคาหัตถการไม่เท่ากัน (กรณีมีมากกว่า 1 สาขา)',
                    'เพิ่ม ตัวเลือกการกำหนดลำดับรายการหัตถการในคอร์ส',
                    'ปรับแก้ไข การแสดงผลการใช้งาน',
                ]
            },
            {
                title: 'อื่นๆ',
                sub: [
                    'ปรับแก้ไข Icon ปุ่ม “ลบ“ ในระบบ'
                ]
            }
        ]
    },
    {
        title: 'Bug Fix',
        topic: [
            {
                sub: [
                    'ปรับเงื่อนไขการคำนวณค่า DF แบบที่ 1, 2, 3 ที่“รายงานค่าตอบแทนทันตแพทย์หัก clinic fee” ให้มีการหัก %บัตรเครดิต กรณีFix ค่า DF',
                    'ปรับแก้ไข การรีเซ็ตสิทธิ์การรักษา ต่อปี',
                    'ปรับแก้ไข ปัญหาการแสดงผลผิดเมื่อเมื่อเลือกใบเสร็จ Thermal แบบที่ 3',
                    'ปรับแก้ไข ปัญหาการแสดงผลค่าประกันมือไม่แสดง ที่“รายงานค่าตอบแทนทันตแพทย์หัก clinic fee”',
                    'ปรับแก้ไข ในส่วนของ “ยอดรายได้สุทธิ” ที่ “รายงานสรุปรายได้“มีการแสดงผลไม่ถูกต้อง (นำยอดยกเลิกมาคำนวณ)',
                    'ปรับแก้ไข ปัญหาการแสดงผลไม่ถูกต้องในส่วนของ Clinic fee ที่“รายงานค่าตอบแทนทันตแพทย์หัก Clinic fee“',
                    'ปรับแก้ไข ปัญหาการแสดงผลที่ “รายงานสรุปนัดหมายแยกตามทันตแพทย์“ มีการแสดงผลไม่ถูกต้อง',
                    'ปรับแก้ไข ปัญหากรณีอัพโหลดรูปภาพลายเซ็น ที่ “เมนูจัดการบุคคล“ แล้วเกิดการบีบอัดภาพ',
                    'ปรับแก้ไข ปัญหาไม่สามารถแก้ไขนัดลอยระหว่างสาขา',
                    'ปรับแก้ไข กรณีคลิกนัดหมายคนไข้ ในส่วนของทันตแพทย์ทางขวาสุดของจอระบบจะแสดงรายละเอียดนัดหมายไม่ครบ',
                    'ปรับแก้ไข กรณีกดเพิ่มนัดหมาย หน้าต่างเพิ่มนัดหมายจะซ้อนทับตารางทันตแพทย์ทางด้านขวาสุด',
                    'ปรับแก้ไข ปัญหา CN คนไข้ซ้ำ เมื่อมีการกดลงทะเบียนพร้อมกันหลายเครื่อง',
                    'ปรับแก้ไข ปัญหาฟันน้ำนม ฝั่ง Q2 และ Q3 ด้าน M และ D สลับกัน',
                    'ปรับแก้ไข ปัญหารหัสประจำตัวคนไข้ (CN) และรหัสประจำตัวคนไข้ในสาขา (PT) ไม่ตรงกัน กรณีคลินิก 1 สาขา มีการลงทะเบียนคนไข้พร้อมกันหลายเครื่อง',
                    'ปรับแก้ไข ปัญหาการแสดงผลชื่อคลินิก เมื่อมีการแจ้งเตือนนัดหมายผ่านอีเมล์'
                ]

            }
        ]
    }
]
import { Grid, Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputTextarea from 'component/Input/InputTextarea'
import { colors } from 'constants/theme'
import * as useStyles from 'features/admin/clinic-management/style'
import { ButtonInfoSimple } from 'component/Button/ButtonInfo'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import SwitchButton from 'new-components/buttons/switch-button'
import InputTextEditor from 'component/Input/InputTextEditor'
import SelectInput from 'new-components/inputs/select-input'

interface Data {
  onchange?: (value: any) => void
  onkeypress?: (value: any) => void
  valArr?: any
}

export default function AlertMsg(props: Data) {
  const { t } = useTranslation()
  const [dataABout, setDataAbout] = useState<any>(props.valArr)
  const [copy, setCopy] = useState(0)

  const dataUseTemplate = [
    {
      template: 'OPERATIVE_NAME',
      text: ':OPERATIVE_NAME',
      dataDisplay: t('CLINIC_MANAGEMENT.TEMPLATE.SERVICE_NAME')
    },
    {
      template: 'PATIENT_FULLNAME',
      text: ':PATIENT_FULLNAME',
      dataDisplay: t('CLINIC_MANAGEMENT.TEMPLATE.PATIEN_NAME')
    },
    {
      template: 'CLINIC_NAME',
      text: ':CLINIC_NAME',
      dataDisplay: t('CLINIC_MANAGEMENT.TEMPLATE.CLINIC_NAME')
    },
    {
      template: 'BRANCH_NAME',
      text: ':BRANCH_NAME',
      dataDisplay: t('CLINIC_MANAGEMENT.TEMPLATE.BRANCH_NAME')
    },
    {
      template: 'DATE_APPOINTMENT',
      text: ':DATE_APPOINTMENT',
      dataDisplay: t('CLINIC_MANAGEMENT.TEMPLATE.APPOINTMENT_DAY')
    },
    {
      template: 'TIME_APPOINTMENT',
      text: ':TIME_APPOINTMENT',
      dataDisplay: t('CLINIC_MANAGEMENT.TEMPLATE.APPOINTMENT_TIME')
    },
    {
      template: 'STATUS_APPOINTMENT',
      text: ':STATUS_APPOINTMENT',
      dataDisplay: t('CLINIC_MANAGEMENT.TEMPLATE.APPOINTMENT_STATUS')
    },
    {
      template: 'LINK_CHECKIN_APPOINTMENT',
      text: ':LINK_CHECKIN_APPOINTMENT',
      dataDisplay: t('CLINIC_MANAGEMENT.TEMPLATE.APPOINTMENT_LINK')
    },
  ]

  useEffect(() => {
    if (props.onchange) props.onchange(dataABout)
  }, [dataABout])

  useEffect(() => {
    setDataAbout(props.valArr)
  }, [props.valArr])

  const handleCopyClick = (text: string, index: number) => {
    setCopy(index);
    navigator?.clipboard?.writeText(text)
    setTimeout(() => {
      setCopy(0);
    }, 2000);
  };

  return (
    <Box className="px-4 pt-4 pb-4">
      <Grid container spacing={2} className="m-0 h-100 w-100">
        <Grid lg={8} className={`w-100 pr-3`} sx={{ borderRight: { lg: `1px solid ${colors.lightGray}` } }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
            <Typography fontSize="16px" fontWeight={400}>{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_CREATE')}</Typography>
            {dataABout?.package === "ULTRA" && <Box display="flex" flexDirection="row" gap="16px">
              <Typography fontSize="16px" fontWeight={400}>{t('INPUT.LABEL_STATUS')}</Typography>
              {dataABout?.statusNotilineAppointmentCreate && <SwitchButton checked={dataABout?.statusNotilineAppointmentCreate === "ACTIVE"} onChange={() => setDataAbout({ ...dataABout, statusNotilineAppointmentCreate: dataABout.statusNotilineAppointmentCreate === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' })} />}
            </Box>}
          </Box>
          {dataABout?.notilineAppointmentCreate && <InputTextEditor value={dataABout?.notilineAppointmentCreate} setValue={(val) => setDataAbout({ ...dataABout, notilineAppointmentCreate: val })} disabled={dataABout.package !== 'ULTRA' || dataABout.statusNotilineAppointmentCreate === 'INACTIVE'} />}

          <Box display="flex" justifyContent="space-between" alignItems="center" marginY="16px">
            <Typography fontSize="16px" fontWeight={400}>{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_CONFIRM')}</Typography>
            {dataABout?.package === 'ULTRA' && <Box display="flex" flexDirection="row" gap="16px">
              <Typography fontSize="16px" fontWeight={400}>{t('INPUT.LABEL_STATUS')}</Typography>
              {dataABout.statusNotilineAppointmentConfirm && <SwitchButton checked={dataABout?.statusNotilineAppointmentConfirm === "ACTIVE"} onChange={() => setDataAbout({ ...dataABout, statusNotilineAppointmentConfirm: dataABout?.statusNotilineAppointmentConfirm === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' })} />}
            </Box>}
          </Box>
          {dataABout?.notilineAppointmentConfirm && <InputTextEditor value={dataABout?.notilineAppointmentConfirm} setValue={(val) => setDataAbout({ ...dataABout, notilineAppointmentConfirm: val })} disabled={dataABout?.package !== 'ULTRA' || dataABout?.statusNotilineAppointmentConfirm === 'INACTIVE'} />}

          <Box display="flex" justifyContent="space-between" alignItems="center" marginY="16px">
            <Typography fontSize="16px" fontWeight={400}>{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_EDIT')}</Typography>
            {dataABout?.package === 'ULTRA' && <Box display="flex" flexDirection="row" gap="16px">
              <Typography fontSize="16px" fontWeight={400}>{t('INPUT.LABEL_STATUS')}</Typography>
              {dataABout?.statusNotilineAppointmentUpdate && <SwitchButton checked={dataABout?.statusNotilineAppointmentUpdate === "ACTIVE"} onChange={() => setDataAbout({ ...dataABout, statusNotilineAppointmentUpdate: dataABout.statusNotilineAppointmentUpdate === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' })} />}
            </Box>}
          </Box>
          {dataABout?.notilineAppointmentUpdate && <InputTextEditor value={dataABout?.notilineAppointmentUpdate} setValue={(val) => setDataAbout({ ...dataABout, notilineAppointmentUpdate: val })} disabled={dataABout.package !== 'ULTRA' || dataABout.statusNotilineAppointmentUpdate === 'INACTIVE'} />}
          <Box display="flex" justifyContent="space-between" alignItems="center" marginY="16px">
            <Typography fontSize="16px" fontWeight={400}>{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_1DAY')}</Typography>
            {dataABout?.package === "ULTRA" && <Box display="flex" flexDirection="row" gap="16px">
              <Typography fontSize="16px" fontWeight={400}>{t('INPUT.LABEL_STATUS')}</Typography>
              {dataABout?.statusNotilineAppointmentPreday && <SwitchButton checked={dataABout?.statusNotilineAppointmentPreday === "ACTIVE"} onChange={() => setDataAbout({ ...dataABout, statusNotilineAppointmentPreday: dataABout.statusNotilineAppointmentPreday === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' })} />}
            </Box>}
          </Box>
          {dataABout?.package === "ULTRA" && <>
            {dataABout?.notilineAppointmentPredayDate && <Box marginBottom="16px">
              <SelectInput
                onChange={(e) => setDataAbout({ ...dataABout, notilineAppointmentPredayDate: e.target.value })}
                options={[
                  { value: 1, name: '1 วัน' },
                  { value: 2, name: '2 วัน' },
                  { value: 3, name: '3 วัน' },
                  { value: 4, name: '4 วัน' }
                ]}
                width={170}
                value={dataABout?.notilineAppointmentPredayDate}
              />
            </Box>}
          </>}


          {dataABout?.notilineAppointmentPreday && <InputTextEditor value={dataABout?.notilineAppointmentPreday} setValue={(val) => setDataAbout({ ...dataABout, notilineAppointmentPreday: val })} disabled={dataABout.package !== 'ULTRA' || dataABout.statusNotilineAppointmentPreday === 'INACTIVE'} />}
          <Box display="flex" justifyContent="space-between" alignItems="center" marginY="16px">
            <Typography fontSize="16px" fontWeight={400}>{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_TODAY')}</Typography>
            {dataABout?.package === "ULTRA" && <Box display="flex" flexDirection="row" gap="16px">
              <Typography fontSize="16px" fontWeight={400}>{t('INPUT.LABEL_STATUS')}</Typography>
              {dataABout?.statusNotilineAppointmentDay && <SwitchButton checked={dataABout?.statusNotilineAppointmentDay === "ACTIVE"} onChange={() => setDataAbout({ ...dataABout, statusNotilineAppointmentDay: dataABout.statusNotilineAppointmentDay === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' })} />}
            </Box>}
          </Box>
          {dataABout?.notilineAppointmentDay && <InputTextEditor value={dataABout?.notilineAppointmentDay} setValue={(val) => setDataAbout({ ...dataABout, notilineAppointmentDay: val })} disabled={dataABout.package !== 'ULTRA' || dataABout.statusNotilineAppointmentDay === 'INACTIVE'} />}
          <Box display="flex" justifyContent="space-between" alignItems="center" marginY="16px">
            <Typography fontSize="16px" fontWeight={400}>{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_STATUS')}</Typography>
            {dataABout?.package === "ULTRA" && <Box display="flex" flexDirection="row" gap="16px">
              <Typography fontSize="16px" fontWeight={400}>{t('INPUT.LABEL_STATUS')}</Typography>
              {dataABout?.statusNotilineAppointmentStatus && <SwitchButton checked={dataABout?.statusNotilineAppointmentStatus === "ACTIVE"} onChange={() => setDataAbout({ ...dataABout, statusNotilineAppointmentStatus: dataABout.statusNotilineAppointmentStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' })} />}
            </Box>}
          </Box>
          {dataABout?.notilineAppointmentStatus && <InputTextEditor value={dataABout?.notilineAppointmentStatus} setValue={(val) => setDataAbout({ ...dataABout, notilineAppointmentStatus: val })} disabled={dataABout.package !== 'ULTRA' || dataABout.statusNotilineAppointmentStatus === 'INACTIVE'} />}
          {/* <Grid className="d-flex">
            <Box className="col-sm-3">{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_CREATE')}
              <br />
              <Box className='d-flex align-content-center'>
                ({dataABout?.notilineAppointmentCreate?.length || 0}/1000)
                <ButtonInfoSimple text={t('CLINIC_MANAGEMENT.MESSAGE.ALERT_LINE_NOTI')} />
              </Box>
            </Box>
            <InputTextarea
              hookValue={true}
              inputProps={{ maxLength: 1000 }}
              name="appoinment"
              key={'appoinment'}
              value={dataABout?.notilineAppointmentCreate || ''}
              label={t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_CREATE')}
              height={96}
              onchange={(e) => {
                setDataAbout({ ...dataABout, notilineAppointmentCreate: e.target.value })
              }}
            />
          </Grid>
          <Grid className="d-flex pt-4">
            <Box className="col-sm-3">{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_EDIT')}
              <br />
              <Box className='d-flex align-content-center'>
                ({dataABout?.notilineAppointmentUpdate?.length || 0}/1000)
                <ButtonInfoSimple text={t('CLINIC_MANAGEMENT.MESSAGE.ALERT_LINE_NOTI')} />
              </Box>
            </Box>
            <InputTextarea
              hookValue={true}
              inputProps={{ maxLength: 1000 }}
              name="appoinment"
              key={'appoinment'}
              value={dataABout?.notilineAppointmentUpdate || ''}
              label={t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_EDIT')}
              height={96}
              onchange={(e) => {
                setDataAbout({ ...dataABout, notilineAppointmentUpdate: e.target.value })
              }}
            />
          </Grid>
          <Grid className="d-flex pt-4">
            <Box className="col-sm-3">{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_1DAY')}
              <br />
              <Box className='d-flex align-content-center'>
                ({dataABout?.notilineAppointmentPreday?.length || 0}/1000)
                <ButtonInfoSimple text={t('CLINIC_MANAGEMENT.MESSAGE.ALERT_LINE_NOTI')} />
              </Box>
            </Box>
            <InputTextarea
              hookValue={true}
              inputProps={{ maxLength: 1000 }}
              name="appoinment"
              key={'appoinment'}
              value={dataABout?.notilineAppointmentPreday || ''}
              label={t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_1DAY')}
              height={96}
              onchange={(e) => {
                setDataAbout({ ...dataABout, notilineAppointmentPreday: e.target.value })
              }}
            />
          </Grid>
          <Grid className="d-flex pt-4">
            <Box className="col-sm-3">{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_TODAY')}
              <br />
              <Box className='d-flex align-content-center'>
                ({dataABout?.notilineAppointmentDay?.length || 0}/1000)
                <ButtonInfoSimple text={t('CLINIC_MANAGEMENT.MESSAGE.ALERT_LINE_NOTI')} />
              </Box>
            </Box>
            <InputTextarea
              hookValue={true}
              inputProps={{ maxLength: 1000 }}
              name="appoinment"
              key={'appoinment'}
              value={dataABout?.notilineAppointmentDay || ''}
              label={t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_TODAY')}
              height={96}
              onchange={(e) => {
                setDataAbout({ ...dataABout, notilineAppointmentDay: e.target.value })
              }}
            />
          </Grid>
          <Grid className="d-flex pt-4">
            <Box className="col-sm-3">{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_STATUS')}
              <br />
              <Box className='d-flex align-content-center'>
                ({dataABout?.notilineAppointmentStatus?.length || 0}/1000)
                <ButtonInfoSimple text={t('CLINIC_MANAGEMENT.MESSAGE.ALERT_LINE_NOTI')} />
              </Box>
            </Box>
            <InputTextarea
              hookValue={true}
              inputProps={{ maxLength: 1000 }}
              name="appoinment"
              key={'appoinment'}
              value={dataABout?.notilineAppointmentStatus || ''}
              label={t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_STATUS')}
              height={96}
              onchange={(e) => {
                setDataAbout({ ...dataABout, notilineAppointmentStatus: e.target.value })
              }}
            />
          </Grid> */}
        </Grid>
        <Grid lg={4} className={`w-100 px-3`}>
          <useStyles.TitleFirst className={`d-flex align-items-center`}>{t('CLINIC_MANAGEMENT.TITLE.TEMPLATE')}</useStyles.TitleFirst>
          <Box display={'flex'} flexDirection={'column'} gap={'8px'} sx={{ '.text-p': { marginBottom: '0px !Important' } }}>
            <Grid container className="d-flex">
              <Grid item xs={7.7}>
                <p className={"text-p"}>{t('CLINIC_MANAGEMENT.TEMPLATE.USE_TEMPLATE')}</p>
              </Grid>
              <p className={"text-p"}>{t('CLINIC_MANAGEMENT.TEMPLATE.DATA_DISPLAY')}</p>
            </Grid>
            {
              dataUseTemplate.map((item, index) => {
                return (
                  <Grid container className="d-flex" key={index} >
                    <Grid item xs={7.7} display={'flex'} alignContent={'center'} position="relative" gap={'4px'} >
                      <p className={"text-p"}>{item.text}</p>
                      {dataABout?.package === "ULTRA" && <Box onClick={() => handleCopyClick(item.template, index + 1)} sx={{ cursor: 'pointer', 'svg': { fill: '#808080' } }}>
                        <FileCopyOutlinedIcon />
                        <Box>
                          {copy === index + 1 &&
                            <Box position="absolute" padding="10px" bgcolor="#5A5A5A" color="#FFFFFF" borderRadius="2px" top="-40px" fontSize="14px" fontWeight={400}>Copied</Box>
                          }
                        </Box>
                      </Box>}
                    </Grid>
                    <useStyles.bgMsg className={"text-p"} >{item.dataDisplay}</useStyles.bgMsg>
                  </Grid>
                )
              })
            }
          </Box>
        </Grid>
      </Grid>
    </Box >
  )
}

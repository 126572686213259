import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
const url = 'clinic'
export interface UserInterface {
  userId: string
  firstname?: string
  lastname: string
}

export interface FindPatientInterface {
  pageLimit: number
  search?: string
  allBranch?: any
  condition: 'cnNumber' | 'branchCnNumber' | 'idCard' | 'phone'
  patientId?: number
  appointment?: boolean
}

export interface PatientInterface {
  branchCnNumber: string
  branchId: number
  cnNumber: string
  firstname: string
  fullname: string
  idCard: string
  lastname: string
  patientId: number
  phone: string
  specialInfo: null | string
}

export interface BranchInterface {
  branchId: number
  branchName: string
  branchNameEn: string
  branchCode: string
}

export interface DentistInterface {
  dentistId: number
  dentistFullname: string
}

export interface BillInterface {
  billPrefixId: number
  prefix: string
}

export default class ClinicApi extends BaseAPI {

  static updatePatientInfo(body: any, id?: number,) {
    return this.api.patch(`${url}/patients/${id}/special-info`, { ...body })
  }

  static updateAutoIncremet(body: any, id?: number,) {
    return this.api.patch(`branches/${id}/auto-increment`, { ...body })
  }

  static getAutoIncremet(id: number) {
    return this.api.get(`branches/${id}`)
  }

  static findAllDoctors(date = '', scheduleType = ''): Promise<any> {
    return this.api.get(`${url}/doctor`, { params: { date, scheduleType } })
  }


  static findUsers(props?: FindAllInterface): Promise<any> {
    return this.api.get(`${url}/user`, { params: { ...props } })
  }

  static findAllTreatmentRight(): Promise<any> {
    return this.api.get(`${url}/treatment-right`)
  }

  static findAllPatients(props: FindPatientInterface): Promise<any> {
    return this.api.get(`${url}/patients`, { params: { ...props } }).then((res) => res)
  }

  static findPatientsById(patientTd: number): Promise<any> {
    return this.api.get(`${url}/patients/${patientTd}`).then((res) => res)
  }

  static findAllBranches(params?: { search?: string }): Promise<any> {
    return this.api.get(`${url}/branches`, { params: { ...params } })
  }
  static findAllActiveBranches(params?: { search?: string }): Promise<any> {
    return this.api.get(`${url}/branches/all-role`, { params: { ...params } })
  }
  static findAllOfficeTimeBranches(branchId: number): Promise<any> {
    return this.api.get(`/branches/${branchId}/office-time`)
  }

  static findAllDentists(): Promise<any> {
    return this.api.get(`${url}/dentists`)
  }

  static findAllBanks(branchId: number): Promise<any> {
    return this.api.get(`${url}/banks/${branchId}`).then((res) => res)
  }

  static serviceRoom(): Promise<any> {
    return this.api.get(`${url}/room-service`).then((res) => res)
  }

  static allTreatment(): Promise<any> {
    return this.api.get(`${url}/treatment-list`).then((res) => res)
  }
  static allBillPrefix(): Promise<any> {
    return this.api.get(`${url}/bill-prefix`).then((res) => res)
  }
  static allTemplateDoctorNote(): Promise<any> {
    return this.api.get(`${url}/template-doctor-notes`).then((res) => res)
  }
  static backUpData(): Promise<any> {
    return this.api.get(`export-csv`).then((res) => res)
  }
}

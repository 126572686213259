import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { numberFormat, toBuddhistYear } from "utils/app.utils";
import { UseSnCode } from "utils/useSnCode";

interface RemunerationDentistFeeProps {
    startDate: string
    endDate: string
    data: any
    page?: any
    pageLimit?: any
    snType?: any
    doctors?: any
}

export default function RemunerationDentistFee(props: RemunerationDentistFeeProps) {

    const { t } = useTranslation()

    return (
        <Box marginY="16px">
            <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>{t(`REPORT.TABLE.REMUNERATION.DENTIST_FREE`)}</Typography>
            <Typography>{t('MOMENT_AT')} {toBuddhistYear(moment(props.startDate), 'DD/MM/YYYY')} -  {toBuddhistYear(moment(props.endDate), 'DD/MM/YYYY')}</Typography>
            <Box sx={{ overflowX: 'auto', overflowY: 'hidden', marginTop: '16px' }}>
                <TableContainer className={'custom-scroll'} style={{ boxShadow: 'none', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                    <Table aria-label="simple table" sx={{ width: '100%' }}>
                        <TableHead sx={{ backgroundColor: "#2F4269", color: 'white', borderRadius: "8px", }}>
                            <TableRow>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'center', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.#NO')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.DOCTOR')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.NET_PRICE')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('ค่าประกันมือ')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.SUM_PAID')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.DF')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.CREDIT_DF')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT_DF')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.SUM_DF')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('DF LAB')}</TableCell>
                                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('Net DF')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(props.data?.rowDf, (data, index) => (
                                <TableRow style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <TableCell sx={{ fontSize: '16px', fontWeight: 400 }}>{props?.page * props.pageLimit - props.pageLimit + index + 1}</TableCell>
                                    <TableCell sx={{ fontSize: '16px', fontWeight: 400 }}>{_.get(_.find(props.doctors, { dentistId: Number(data?.doctorId) }), 'dentistFullname') || '-'}</TableCell>
                                    <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.totalAmount || 0)}</TableCell>
                                    <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.handInsurance || 0)}</TableCell>
                                    <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.amountPaid || 0)}</TableCell>
                                    <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.amountDf || 0)}</TableCell>
                                    <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.dfCredit || 0)}</TableCell>
                                    <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.dfLostTreatment || 0)}</TableCell>
                                    <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.dfLostCreditAndTreatment || 0)}</TableCell>
                                    <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.dfLabFee || 0)}</TableCell>
                                    <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.dfNet || 0)}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: '#E5E5E5' }}>
                                <TableCell colSpan={2} align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>
                                    <Typography>รวม</Typography>
                                </TableCell>
                                <TableCell align="right">{numberFormat(_.sumBy(props.data?.rowDf, 'totalAmount'))}</TableCell>
                                <TableCell align="right">{numberFormat(_.sumBy(props.data?.rowDf, 'handInsurance'))}</TableCell>
                                <TableCell align="right">{numberFormat(_.sumBy(props.data?.rowDf, 'amountPaid'))}</TableCell>
                                <TableCell align="right">{numberFormat(_.sumBy(props.data?.rowDf, 'amountDf'))}</TableCell>
                                <TableCell align="right">{numberFormat(_.sumBy(props.data?.rowDf, 'dfCredit'))}</TableCell>
                                <TableCell align="right">{numberFormat(_.sumBy(props.data?.rowDf, 'dfLostTreatment'))}</TableCell>
                                <TableCell align="right">{numberFormat(_.sumBy(props.data?.rowDf, 'dfLostCreditAndTreatment'))}</TableCell>
                                <TableCell align="right">{numberFormat(_.sumBy(props.data?.rowDf, 'dfLabFee'))}</TableCell>
                                <TableCell align="right">{numberFormat(_.sumBy(props.data?.rowDf, 'dfNet'))}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { numberFormat, toBuddhistYear } from "utils/app.utils";
import { UseSnCode } from "utils/useSnCode";

interface RemunerationLabReportProps {
  startDate: string
  endDate: string
  data: any
  page?: any
  pageLimit?: any
  snType?: any
}

export default function RemunerationLabReport(props: RemunerationLabReportProps) {

  const { t } = useTranslation()



  return (
    <Box paddingY="16px">
      <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>LAB Report</Typography>
      <Typography>{t('MOMENT_AT')} {toBuddhistYear(moment(props.startDate), 'DD/MM/YYYY')} -  {toBuddhistYear(moment(props.endDate), 'DD/MM/YYYY')}</Typography>
      <Box sx={{ overflowX: 'auto', overflowY: 'hidden', marginTop: '16px' }}>
        <TableContainer style={{ boxShadow: 'none', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
          <Table aria-label="simple table" sx={{ width: '100%' }}>
            <TableHead sx={{ backgroundColor: "#2F4269", color: 'white', borderRadius: "8px", }}>
              <TableRow>
                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'center', fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.#NO')}</TableCell>
                <TableCell sx={{ color: "white", fontWeight: 500, fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.DOCTOR')}</TableCell>
                <TableCell sx={{ color: "white", fontWeight: 500, fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_CN_NUMBER')}</TableCell>
                {props.snType && <TableCell sx={{ color: "white", fontWeight: 500, fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_SN_CODE')}</TableCell>}
                <TableCell sx={{ color: "white", fontWeight: 500, fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.PATIENT_NAME')}</TableCell>
                <TableCell sx={{ color: "white", fontWeight: 500, fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.DESCRIPTION')}</TableCell>
                <TableCell sx={{ color: "white", fontWeight: 500, fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.LAB_NAME')}</TableCell>
                <TableCell sx={{ color: "white", fontWeight: 500, fontSize: '16px' }}>{t('REPORT.TABLE.REMUNERATION.JOB')}</TableCell>
                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('LAB Fee')}</TableCell>
                <TableCell sx={{ color: "white", fontWeight: 500, textAlign: 'right', fontSize: '16px' }}>{t('DF LAB Fee')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data?.rowLab.length === 0 ?
                <TableRow style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                  <TableCell colSpan={10} align="center">
                    <Typography>{t('NOT_FOUND')}</Typography>
                  </TableCell>
                </TableRow>
                : _.map(props.data?.rowLab, (item) => (
                  <>
                    <TableRow style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                      <TableCell colSpan={10} sx={{ fontWeight: '500 !important' }}>
                        <span className="mr-2">{t('MOMENT_AT')}</span>
                        {toBuddhistYear(moment(item?.date), 'DD/MM/YYYY')}
                      </TableCell>
                    </TableRow>
                    {_.map(item.table, (data, index) => (
                      <TableRow style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        <TableCell sx={{ fontSize: '16px', fontWeight: 400 }}>{props?.page * props.pageLimit - props.pageLimit + index + 1}</TableCell>
                        <TableCell sx={{ fontSize: '16px', fontWeight: 400 }}>{data?.dentist || "-"}</TableCell>
                        <TableCell sx={{ fontSize: '16px', fontWeight: 400 }}>{data?.branchCnNumber || "-"}</TableCell>
                        {props.snType && <TableCell sx={{ fontSize: '16px', fontWeight: 400 }}>{data?.snCode || "-"}</TableCell>}
                        <TableCell sx={{ fontSize: '16px', fontWeight: 400 }}>{data?.patientFullname || "-"}</TableCell>
                        <TableCell sx={{ fontSize: '16px', fontWeight: 400 }}>{data?.remark || "-"}</TableCell>
                        <TableCell sx={{ fontSize: '16px', fontWeight: 400 }}>{data?.lab}</TableCell>
                        <TableCell sx={{ fontSize: '16px', fontWeight: 400 }}>{data.operativeName || '-'}</TableCell>
                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.labFee || 0)}</TableCell>
                        <TableCell align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>{numberFormat(data?.dfLabFee || 0)}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: '#E5E5E5' }}>
                      <TableCell colSpan={props.snType ? 8 : 7} align="right" sx={{ fontSize: '16px', fontWeight: 400 }}>
                        <Typography>รวม</Typography>
                      </TableCell>
                      <TableCell align="right">{numberFormat(_.sumBy(item?.table, 'labFee'))}</TableCell>
                      <TableCell align="right">{numberFormat(_.sumBy(item?.table, 'dfLabFee'))}</TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
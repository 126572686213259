import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import sticker_med_1 from 'assets/branch/receipt/sticker_med_1.png'
import sticker_med_2 from 'assets/branch/receipt/sticker_med_2.png'
import sticker_med_3 from 'assets/branch/receipt/sticker_med_3.png'

const OpStickerMed = () => {
    const { t } = useTranslation()
    const optionStickerMed = [
        {
            name: t('BRANCH.TAB.FORMAT_1'), value: "FORMAT_1",
            component: <StickerMedBox textFormat={t('BRANCH.TAB.FORMAT_1')} images={[sticker_med_1]} />,
        },
        {
            name: t('BRANCH.TAB.FORMAT_2'), value: "FORMAT_2",
            component: <StickerMedBox textFormat={t('BRANCH.TAB.FORMAT_2')} images={[sticker_med_2]} />,
        },
        {
            name: t('BRANCH.TAB.FORMAT_3'), value: "FORMAT_3",
            component: <StickerMedBox textFormat={t('BRANCH.TAB.FORMAT_3')} images={[sticker_med_3]} />,
        },
    ]
    return (optionStickerMed)
}

export default OpStickerMed

interface StickerMedBoxProps {
    textFormat: string
    images: string[]
}

const StickerMedBox = (props: StickerMedBoxProps) => {
    const { textFormat, images } = props
    const { t } = useTranslation()
    return (
        <Box display='flex' flexDirection='column' gap='16px' height='100%'>
            <Box >
                <Typography fontSize={'20px'} fontWeight={'500'}>
                    {t('BRANCH.TAB.SENTTING.STICKER_MEDICINE')}
                </Typography>
            </Box>

            <Box >
                <Box marginBottom='16px'>
                    <Typography fontWeight={'500'} marginBottom='8px'>
                        {textFormat}
                    </Typography>
                </Box>

                <Box display='flex' alignItems={'center'} gap={'16px'} flexDirection={'column'} >
                    {images.map((item, index) => (
                        <Box key={index} textAlign='center' sx={{ img: { width: '80%' } }}>
                            <img src={item} alt="" />
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}
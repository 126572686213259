
import { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import { ButtonGroup, Button, Box, ClickAwayListener, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { routeName } from 'routes/routes-name'
import { Col, Dropdown, DropdownButton, Image, Row } from 'react-bootstrap'
import { getNameMonthByNo } from 'constants/date-time'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import FilterSelect from 'component/Select/FilterSelect'
import ClinicApi, { BranchInterface, DentistInterface } from 'api/master/clinic.api'
import AppointmentApi from 'api/counter/appointment.api'
import { CountAppointmentDto } from 'features/counter/appointment/appointment-interface'
import iconGoTo from 'assets/icon/iconGoTo.svg'
import iconFilter from 'assets/icon/iconFilter.svg'
import SearchPatients from 'component/SearchPatients'
import { colors } from 'constants/theme'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import { showModal, handleShow } from 'app/slice/modal.slice'
import { getApp, getBranch, getPackage, isBuddhistYear, toBuddhistYear } from 'utils/app.utils'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import InputSecondNewDatePicker, { PICKER_VIEWS } from 'component/Input/InputSecondNewDatePicker'
import { selectMe } from 'app/slice/user.slice'
import InputCheckbox from 'component/Input/InputCheckbox'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { clinicTheme } from 'api/api'
import IconBell from 'assets/icon/appointment/icon-bell'

/** STYLE */
const DateDisplaySelect = styled('div')(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 700,
  '& .arrow': {
    width: '30px',
    height: '30px',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'inline-block',
    '& svg': {
      height: '16px',
      verticalAlign: 'top',
      marginTop: '7px',
      '&.arrowNext': {
        transform: 'rotate(180deg)'
      }
    },
    '&:hover': {
      backgroundColor: colors.extraLightGray,
      borderRadius: '50%'
    }
  },
  '& .monthPicker': {

    cursor: 'pointer',

    padding: '0px',
    display: 'inline-block',
    '& .ant-picker ': {
      verticalAlign: 'top !important',
      padding: '0px'
    },
    '& .ant-picker-input ': {
      padding: '0.25rem',
      '&:hover': {
        backgroundColor: colors.extraLightGray,
        borderRadius: '50%'
      },
      '& input:placeholder-shown': {
        display: 'none'
      },
      '& input': {
        display: 'none'
      },
      '& .ant-picker-borderless': {
        verticalAlign: 'top'
      }
    },
    '& .ant-picker-suffix': {
      margin: '0'
    },

  }
}))

const ButtonAdd = styled(Button)(({ theme }) => ({
  fontSize: '16px !important',
  border: `1px solid ${colors.themeSecondColor} !important`,
  backgroundColor: `${colors.themeSecondColor} !important`,
  color: `${colors.white} !important`,
  '&.Mui-disabled': {
    border: `1px solid ${colors.disabledLightGray} !important`,
    backgroundColor: `${colors.disabledLightGray} !important`,
    opacity: 0.7
  }
}))

const ButtonViewHistory = styled(Button)(({ theme }) => ({
  fontSize: '16px !important',
  border: `1px solid ${colors.themeSecondColor} !important`,
  backgroundColor: `${colors.white} !important`,
  color: `${colors.themeSecondColor} !important`
}))

const ButtonViewDisplay = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  minWidth: '72px !important',
  border: `1px solid ${colors.themeSecondColor} !important`,
  paddingTop: '0px',
  paddingBottom: '0px',
  whiteSpace: 'nowrap',
  '&.btnTypeDateSelectOnclick': {
    color: `${colors.white} !important`,
    backgroundColor: `${colors.themeSecondColor} !important`
  },
  '&.btnTypeDateSelect': {
    color: `${colors.themeSecondColor} !important`,
    backgroundColor: `${colors.white} !important`,
    '&:hover': {
      backgroundColor: `${colors.themeSecondColor10} !important`
    }
  }
}))

const DropdownButtonCustom = styled(DropdownButton)(({ theme }) => ({
  '& button': {
    width: '110px',
    height: '42px',
    backgroundColor: `${colors.themeSecondColor} !important`,
    border: 'none',
    textAlign: 'left',
    paddingLeft: 8,
    paddingRight: 6,
    boxShadow: 'none !important',
    '&:focus': {
      boxShadow: 'none !important'
    },
    '&:hover': {
      backgroundColor: `${colors.themeSecondColor} !important`
    },
    '&:after': {
      position: 'absolute',
      top: '50%',
      right: '1rem'
    },
    '& +.dropdown-menu': {
      zIndex: 1021
    }
  }
}))

const ButtonFilter = styled('button')(({ theme }) => ({
  width: '40px !important',
  height: '42px',
  border: `1px solid ${colors.themeSecondColor}`,
  borderRadius: '0.2rem',
  cursor: 'pointer',
  backgroundColor: colors.white,
  color: colors.themeSecondColor
}))
const MenuItemClearFilter = styled(MenuItem)(({ theme }) => ({ '&:hover': { height: '30px', backgroundColor: `${colors.white} !important` } }))
const SpanClearFilter = styled('span')(({ theme }) => ({ cursor: 'pointer', color: colors.themeSecondColor, '&:hover': { textDecoration: 'underline' } }))

export const CustomFilterDoctor = styled(Box)<{ open?: boolean, type?: string }>(({ theme, open, type }) => ({
  '.MuiOutlinedInput-root': {
    paddingTop: '6px !important',
    paddingBottom: '6px !important'
  },
  '.MuiOutlinedInput-input': {
    marginTop: '-1px',
    padding: `0 0 0 ${clinicTheme.theme === clinicTheme.type.DENTAL ? '90px' : '60px'}  !important`
  },
  '.Mui-multiple': {
    '.MuiFormControl-root': {
      '.MuiOutlinedInput-root': {
        marginTop: 0,
        padding: type === 'templateDF' ? '10px' : '4px 24px 4px 12px !important',
        flexWrap: 'nowrap'
      },
      '.MuiOutlinedInput-input': {
        height: open ? 'auto' : 0,
        minWidth: 'unset',
        padding: '0 !important'
      },
    }
  },
  '.edit-filter-doctor': {
    zIndex: 1,
    top: '50%',
    transform: 'translateY(-50%)',
    left: '12px'
  }
}))

export const ListFilterDoctors = styled(Box)(({ theme }) => ({
  '.MuiFormControlLabel-root': {
    margin: 0,
    overflow: 'hidden',
    '.MuiCheckbox-root': {
      padding: '0 8px 0 0',
    },
    '.MuiTypography-root': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  }
}))

const CustomHeadAppointment = styled('div')(({ theme }) => ({
  padding: '1rem',
  boxShadow: `0px 3px 6px ${colors.black08}`,
  backgroundColor: colors.white,
  position: 'relative',
  zIndex: 3,
}))

export type ViewDisplayType = 'DATE' | 'WEEK' | 'MONTH' | 'OVERALL'

export interface IHeadAppointmentProps {
  viewDisplay: ViewDisplayType
  branchId: number
  date: DateInterface
  doctor: string
  handleViewDisplay: (display: ViewDisplayType) => void
  handleBranchId: (branchId: number) => void
  handleDate: (objDate: DateInterface) => void
  handlePatient: (patientId: number) => void
  handleDoctor: (dictors: string) => void
  handleClickAdd: (val: any) => void
  handleClickView: (val: any) => void
  statusShowForm: boolean
  statusPopApp: boolean
  handleOpenAMApp: (statusPopApp: boolean) => void
  countApp?: number
}

export interface DateInterface {
  date: number
  month: number
  year: number
  day: string
}

export default function HeadAppointment(props: IHeadAppointmentProps) {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(selectMe)
  const modal = useSelector(handleShow)
  const datePickerRef: any = useRef()
  const containerRef: any = useRef()
  const branchIdCurrent = getBranch()

  const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView()
  }

  const [branches, setBranches] = useState<BranchInterface[]>([])
  const [activeBranches, setActiveBranches] = useState<BranchInterface[]>([])
  const [doctors, setDoctors] = useState<DentistInterface[]>([])

  const [branchId, setBranchId] = useState<number>(getBranch() || 1)
  const [selectDate, setSelectDate] = useState<DateInterface>(props.date)
  const [selectDoctors, setSelectDoctors] = useState<any[]>([])
  const [selectPatient, setSelectPatient] = useState<number>(0)

  const [countDisplay, setCountDisplay] = useState<CountAppointmentDto>({ countAppointments: 0, countDoctors: 0 })
  const [showPopupFilter, setShowPopupFilter] = useState<boolean>(false)
  const [openFilterDoctors, setOpenFilterDoctors] = useState(false);

  const [isPackage, setIsPackage] = useState<any>({})
  const [packageUse, setPackageUse] = useState<any>()
  const [isApp, setIsApp] = useState<boolean>(false)

  useEffect(() => {
    setPackageUse(localStorage.getItem('PACKAGE_USE'))
    setSelectDate(props.date)
    renderDateAppointment(props.date.day)
  }, [props.date])

  useEffect(() => {
    setBranchId(props.branchId)
  }, [props.branchId])

  const loadData = useCallback(() => {
    ClinicApi.findAllBranches().then(({ data }) => setBranches(data))
    ClinicApi.findAllActiveBranches().then(({ data }) => setActiveBranches(data))
    ClinicApi.findAllDentists().then(async ({ data }) => {
      if (data) {
        let users: any[] = data
        const meOnly: boolean = user?.ownAppointment === "1" ? true : false
        if (meOnly) users = _.filter(data, (usersData: any) => Number(usersData.dentistId) === Number(usersData?.userId))
        setDoctors(users)
        const arrayDoctor = [...users.map((d: DentistInterface) => d.dentistId)]
        setSelectDoctors(arrayDoctor)
        props.handleDoctor(arrayDoctor.filter((v: any) => v > 0).join(',') || '')
      }
    }
    )
  }, [props.branchId, props.date])

  useEffect(() => {
    loadData()
  }, [])

  const loadCountAppointment = useCallback(() => {
    let condition: any = {}
    condition = { ...condition, display: props.viewDisplay === 'OVERALL' ? 'MONTH' : props.viewDisplay }
    condition = { ...condition, branchId: branchId }
    condition = { ...condition, date: `${selectDate.year}-${String(selectDate.month).padStart(2, '0')}-${String(selectDate.date).padStart(2, '0')}` }
    condition = { ...condition, doctorId: selectDoctors.filter((v: any) => v > 0).join(',') !== '' ? selectDoctors.filter((v: any) => v > 0).join(',') : '0' }
    if (selectPatient !== 0) condition = { ...condition, patientId: selectPatient }
    if (condition.doctorId !== "0") AppointmentApi.countAppointment(condition).then(({ data }) => data && (setCountDisplay(data)))
  }, [props.viewDisplay, branchId, selectDate, selectDoctors, selectPatient])


  useEffect(() => {
    loadCountAppointment()
  }, [loadCountAppointment])

  const handleClickPrevious = () => {
    let unit: any = 'day'
    if (props.viewDisplay === 'WEEK') unit = 'week'
    else if (props.viewDisplay === 'MONTH' || props.viewDisplay === 'OVERALL') unit = 'month'
    const dateBefore: string = moment(`${selectDate.year}-${selectDate.month}-${selectDate.date}`, 'YYYY-M-D').format('YYYY-MM-DD')
    const date: string = moment(dateBefore).subtract(1, unit).format('YYYY-MM-DD-ddd')
    const dateSlip: any = date.split('-')
    setSelectDate({ year: parseInt(dateSlip[0]), month: parseInt(dateSlip[1]), date: parseInt(dateSlip[2]), day: String(dateSlip[3]) })
    props.handleDate({ year: parseInt(dateSlip[0]), month: parseInt(dateSlip[1]), date: parseInt(dateSlip[2]), day: String(dateSlip[3]) })
    renderDateAppointment(String(dateSlip[3]))
  }

  const handleClickNext = () => {
    let unit: any = 'day'
    if (props.viewDisplay === 'WEEK') unit = 'week'
    else if (props.viewDisplay === 'MONTH' || props.viewDisplay === 'OVERALL') unit = 'month'
    const dateBefore: string = moment(`${selectDate.year}-${selectDate.month}-${selectDate.date}`, 'YYYY-M-D').format('YYYY-MM-DD')
    const date: string = moment(dateBefore).add(1, unit).format('YYYY-MM-DD-ddd')
    const dateSlip: any = date.split('-')
    setSelectDate({ year: parseInt(dateSlip[0]), month: parseInt(dateSlip[1]), date: parseInt(dateSlip[2]), day: String(dateSlip[3]) })
    props.handleDate({ year: parseInt(dateSlip[0]), month: parseInt(dateSlip[1]), date: parseInt(dateSlip[2]), day: String(dateSlip[3]) })
    renderDateAppointment(String(dateSlip[3]))
  }

  const handleFilterDoctors = (value: any) => {
    let arrayDoctor = []
    if ((value === 'ALL' && _.size(selectDoctors) === _.size(doctors)) || value === 'CLEAR') arrayDoctor = ['']
    else if (value === 'ALL') arrayDoctor = [..._.map(doctors, (d: DentistInterface) => d.dentistId)]
    else if (_.includes(selectDoctors, value)) arrayDoctor = _.filter(selectDoctors, (d) => d !== value)
    else arrayDoctor = [...selectDoctors, value]
    setSelectDoctors(arrayDoctor)
    props.handleDoctor(arrayDoctor.filter((v: any) => v > 0).join(',') || '')
  }

  const getNameDisplayButton = (display: string, size?: 'small' | 'full') => {
    if (display === 'DATE') return t('APPOINTMENT.HEADER.DAY')
    if (display === 'WEEK') return t('APPOINTMENT.HEADER.WEEK')
    if (display === 'MONTH') return t('APPOINTMENT.HEADER.MONTH')
    if (display === 'OVERALL') return size === 'small' ? t('APPOINTMENT.HEADER.OVERALL_SMALL') : t('APPOINTMENT.HEADER.OVERALL_FULL')
    return ''
  }

  const [state, setState] = useState({
    mainPosition: "bottom",
    relativePosition: "center",
    fixMainPosition: false,
    fixRelativePosition: false,
    offsetY: 0,
    offsetX: 0
  })


  const updateState = (key: any, value: any) => setState({
    ...state,
    [key]: value
  })
  useEffect(() => {
    if (containerRef?.current && containerRef?.current?.clientWidth && containerRef?.current?.clientHeight) containerRef.current.scrollTo(
      (containerRef.current.clientWidth),
      (containerRef.current.clientHeight / 2)
    )

    if (datePickerRef?.current) datePickerRef.current.openCalendar()
  }, [])

  useEffect(() => {
    if (!modal) setShowPopupFilter(false)
  }, [modal]);

  const [dateDayAppointment, setDateDayAppointment] = useState<string>('')
  const renderDateAppointment = useCallback((day?: string) => {
    moment.locale(i18n.language)

    const dateBefore: string = moment(`${selectDate.year}-${selectDate.month}-${selectDate.date}`, 'YYYY-M-D').format('YYYY-MM-DD-ddd')
    const dateSlip: any = dateBefore.split('-')
    if (!day) {
      setSelectDate({ ...selectDate, day: String(dateSlip[3]) })
      props.handleDate({ ...selectDate, day: String(dateSlip[3]) })
    }

    const newDate = { ...selectDate, day: day || String(dateSlip[3]) }
    if (i18n.language === 'th') {
      return setDateDayAppointment(`${t('APPOINTMENT.HEADER.DATE')}${newDate.day}${t('APPOINTMENT.HEADER.AT')}`)
    } else {
      return setDateDayAppointment(`${newDate.day}`)
    }
  }, [i18n.language])

  useEffect(() => {
    renderDateAppointment()
  }, [i18n.language])

  useEffect(() => {
    console.log(packageUse, 'iiiiii')
    setIsPackage(getPackage())
    setIsApp(getApp() === "PERMISS")
  }, [])

  return (
    <CustomHeadAppointment className="head-appointment">
      <Row className="mx-0 mb-1 mb-xl-0 ">
        <Col xs={'12'} xl={'auto'} className="px-1 pb-3 pb-xl-0">
          <DateDisplaySelect ref={containerRef} onChange={(e: any) => updateState("offsetX", Number(e.target.value))} className='d-flex align-items-center'>
            <div className="arrow">
              <ArrowBackIosNewIcon onClick={handleClickPrevious} />
            </div>
            {`${props.viewDisplay === 'DATE' ? `${dateDayAppointment}  ${selectDate.date}` : ''} ${getNameMonthByNo(selectDate.month, i18n.language)} ${selectDate.year + isBuddhistYear()}`}
            <div className="arrow">
              <ArrowBackIosNewIcon onClick={handleClickNext} className={`arrowNext`} />
            </div>
            <div className="monthPicker">
              <InputSecondNewDatePicker
                menu='appointment'
                position={{ calendarPosition: `${state.mainPosition}-right` }}
                disabledTextField
                views={(props.viewDisplay === 'DATE' && PICKER_VIEWS.DAY) || (props.viewDisplay === 'WEEK' && PICKER_VIEWS.WEEK) || PICKER_VIEWS.MONTH}
                value={moment(`${selectDate.year}-${String(selectDate.month).padStart(2, '0')}-${String(selectDate.date).padStart(2, '0')}`, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                onchange={(e) => {
                  const objDate = {
                    date: moment(e)?.date() || moment()?.date(),
                    month: (moment(e)?.month()) + 1,
                    year: moment(e)?.year() || moment()?.year(),
                    day: props.viewDisplay === 'DATE' ? moment(e).format('ddd') : (String(moment(e)?.format('ddd')) || String(moment()?.format('ddd')))
                  }
                  setSelectDate({
                    ...selectDate,
                    ...objDate
                  })
                  props.handleDate(objDate)
                  renderDateAppointment(objDate.day)
                }}
              />
            </div>
          </DateDisplaySelect>
          <div className='pt-1'>
            <p className="d-sm-inline-block m-0 pt-1 pr-1">{t('APPOINTMENT.HEADER.COUNT_APPOINTMENT')} {countDisplay.countAppointments} {t('APPOINTMENT.HEADER.APPOINT')},</p>
            <p className="d-sm-inline-block m-0 pt-1">{t('USER_DOCTOR')}{t('APPOINTMENT.HEADER.ON_DUTY')} {countDisplay.countDoctors} {t('APPOINTMENT.HEADER.PERSON')} <Image src={iconGoTo} onClick={() => history.push(routeName.doctorSchedule)} style={{ cursor: 'pointer' }} /></p>
          </div>
        </Col>
        <Col xs={'12'} xl={4} xxl={4} className="col-3xl-3 px-1 d-sm-block mt-2 ml-auto">
          <SearchPatients rootStyle={{ height: '35px' }}
            status="ACTIVE"
            callbackPatientId={(value) => {
              setSelectPatient(value)
              props.handlePatient(value)
            }}
            patientId={selectPatient}
            onClear={() => {
              setSelectPatient(0)
              props.handlePatient(0)
            }}
          />
        </Col>
        <Col xs={'12'} sm={6} xl={2} className="px-1 d-none d-sm-block mt-2">
          <FilterSelect
            formControlClass={'filter filter-branch'}
            fullWidth={true}
            onchange={(e) => {
              setBranchId(e.target.value)
              props.handleBranchId(e.target.value)
            }}
            renderValue={() => (_.find(branches, (b: BranchInterface) => b.branchId === branchId)?.branchName) ? `${t('APPOINTMENT.HEADER.BRANCH')}: ${t(`${_.find(branches, (b: BranchInterface) => b.branchId === branchId)?.branchName}`)}` : `${t('APPOINTMENT.HEADER.BRANCH')}: ${t(`${_.find(activeBranches, (b: BranchInterface) => b.branchId === branchId)?.branchName}`)}`}
            label={t('APPOINTMENT.HEADER.BRANCH')}
            selectId="select-branchId"
            value={branchId}
            labelId="label-branchId"
            options={_.map(branches, (branch: BranchInterface, i: number) => (
              <MenuItem className="py-1" key={i} value={branch.branchId}>
                {branch.branchName}
              </MenuItem>
            ))}
          />
        </Col>
        <Col xs={'12'} sm={6} xl={2} className="px-1 d-none d-sm-block mt-2">
          {!showPopupFilter && (
            <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
              <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
                <AutocompleteSelect
                  open={openFilterDoctors}
                  multiple
                  labelId="label-doctors"
                  options={['ALL', ...doctors, 'CLEAR']}
                  getOptionLabel={(option) => _.find(doctors, { dentistId: option })?.dentistFullname || '-'}
                  renderOption={(props, option) => {
                    if (option === 'ALL') {
                      return (
                        <ListFilterDoctors
                          {...props}
                          key={option}
                          component="li"
                          value={option}>
                          <InputCheckbox label={t('ALL')} checked={_.size(selectDoctors) === _.size(doctors)} indeterminate={_.size(selectDoctors) > 1 && _.size(selectDoctors) !== _.size(doctors)} className={'pe-none'} />
                        </ListFilterDoctors>
                      )
                    } else if (option === 'CLEAR') {
                      return (
                        <Box
                          {...props}
                          key={option}
                          component="li"
                          value={option}
                          sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
                          <SpanClearFilter className='pe-none'>{t('APPOINTMENT.HEADER.CLEAR')}</SpanClearFilter>
                        </Box>
                      )
                    } else {
                      return (
                        <div title={option.dentistFullname} className="text-ellipsis">
                          <ListFilterDoctors
                            {...props}
                            key={option.dentistId}
                            component="li"
                            value={option.dentistId}>
                            <InputCheckbox label={option.dentistFullname} checked={_.includes(selectDoctors, option.dentistId)} className={'pe-none'} />
                          </ListFilterDoctors>
                        </div>
                      )
                    }
                  }}
                  renderTags={(tagValue, getTagProps) => {
                    return (
                      <>
                        <span className='pr-1'>{t('USER_DOCTOR')}:</span>
                        {!openFilterDoctors && (
                          _.size(tagValue) === _.size(doctors) && (
                            <span>{t('ALL')}</span>
                          ) || (
                            <Box className='text-ellipsis'>
                              {
                                _.map(tagValue, (option: any, index: number) => {
                                  return (
                                    <span>
                                      {_.find(doctors, { dentistId: option })?.dentistFullname}
                                      {index !== _.findLastIndex(tagValue) && option && `,`}
                                    </span>
                                  )
                                })
                              }
                            </Box>
                          )
                        )}
                      </>
                    )
                  }}
                  onchange={(e, value) => handleFilterDoctors(e?.target?.value || e?.target?.attributes?.value?.value)}
                  noOptionsText={t('NOT_FOUND')}
                  filterOptions={(option) => 'ALL' + option.dentistFullname + 'CLEAR'}
                  value={selectDoctors}
                  height={32}
                  disableClearable
                />
              </CustomFilterDoctor>
            </ClickAwayListener>
          )}
        </Col>
        <Col xs={'auto'} className="d-none d-sm-block col-sm col-3xl-auto col-lg  px-1 mt-2 order-2 order-xl-2 order-3xl-1 text-right text-nowrap">
          <ButtonViewHistory className="mx-1" onClick={props.handleClickView}>
            {t('APPOINTMENT.BUTTON.VIEW_APPOINTMENT_HISTORY')}
          </ButtonViewHistory>
          {isApp &&
            packageUse === "ULTRA" &&
            <ButtonAdd className="mx-1 " disabled={permissions.isCreate.disabled || (modal && props.statusShowForm)} onClick={() => props.handleOpenAMApp(props.statusPopApp)}>
              {t('APPOINTMENT.BUTTON.VIEW_APPOINTMENT_APP')}<span style={{ display: 'flex', alignItems: 'center' }}>{`(${props.countApp})`}</span>
            </ButtonAdd>
          }
          <ButtonAdd className="mx-1" disabled={permissions.isCreate.disabled || (modal && props.statusShowForm) || props.statusPopApp} onClick={props.handleClickAdd}>
            {t('APPOINTMENT.BUTTON.ADD_APPOINTMENT')}
          </ButtonAdd>
        </Col>
        <Col sm={'auto'} xxl={'auto'} className="col-lg-auto col-3xl-12 px-1 mt-sm-2 mt-3xl-0 ml-auto ml-sm-0 order-1 order-xl-1 order-3xl-2 pt-sm-2 text-right">
          <ButtonGroup variant="contained" className="d-none d-sm-inline-flex shadow-none">
            <ButtonViewDisplay className={`${props.viewDisplay === 'DATE' ? 'btnTypeDateSelectOnclick' : 'btnTypeDateSelect'}`} onClick={(e) => props.handleViewDisplay('DATE')}>
              {t('APPOINTMENT.HEADER.DAY')}
            </ButtonViewDisplay>
            <ButtonViewDisplay className={`${props.viewDisplay === 'WEEK' ? 'btnTypeDateSelectOnclick' : 'btnTypeDateSelect'}`} onClick={(e) => props.handleViewDisplay('WEEK')}>
              {t('APPOINTMENT.HEADER.WEEK')}
            </ButtonViewDisplay>
            <ButtonViewDisplay className={`${props.viewDisplay === 'MONTH' ? 'btnTypeDateSelectOnclick' : 'btnTypeDateSelect'}`} onClick={(e) => props.handleViewDisplay('MONTH')}>
              {t('APPOINTMENT.HEADER.MONTH')}
            </ButtonViewDisplay>
            <ButtonViewDisplay className={`${props.viewDisplay === 'OVERALL' ? 'btnTypeDateSelectOnclick' : 'btnTypeDateSelect'}`} onClick={(e) => props.handleViewDisplay('OVERALL')}>
              {t('APPOINTMENT.HEADER.OVERALL_FULL')}
            </ButtonViewDisplay>
          </ButtonGroup>
        </Col>
        <Col xs={'auto'} className="d-block d-sm-none order-1 order-xl-1 order-3xl-2 px-1">
          <DropdownButtonCustom className="d-inline-flex mx-1 mt-2" onSelect={(val: any) => props.handleViewDisplay(val)} title={getNameDisplayButton(props.viewDisplay, 'small')}>
            <Dropdown.Item eventKey={'DATE'}>    {t('APPOINTMENT.HEADER.DAY')} </Dropdown.Item>
            <Dropdown.Item eventKey={'WEEK'}> {t('APPOINTMENT.HEADER.WEEK')} </Dropdown.Item>
            <Dropdown.Item eventKey={'MONTH'}> {t('APPOINTMENT.HEADER.MONTH')} </Dropdown.Item>
            <Dropdown.Item eventKey={'OVERALL'}>  {t('APPOINTMENT.HEADER.OVERALL_SMALL')} </Dropdown.Item>
          </DropdownButtonCustom>
          <ButtonFilter className="mx-1 mt-2" onClick={() => {
            dispatch(showModal())
            setShowPopupFilter(true)
          }}>
            <Image src={iconFilter} />
          </ButtonFilter>
          <ButtonViewHistory className="mx-1 align-top mt-2" onClick={props.handleClickView}>
            {t('APPOINTMENT.BUTTON.APPOINTMENT_HISTORY')}
          </ButtonViewHistory>
          {isApp &&
            packageUse === "ULTRA" &&
            <ButtonAdd className="mx-1 align-top mt-2" disabled={permissions.isCreate.disabled || (modal && props.statusShowForm)} onClick={() => props.handleOpenAMApp(props.statusPopApp)}>
              {t('APPOINTMENT.BUTTON.VIEW_APPOINTMENT_APP')}<span style={{ display: 'flex', alignItems: 'center' }}>{`(${props.countApp})`}</span>
            </ButtonAdd>
          }
          <ButtonAdd className="mx-1 align-top mt-2" disabled={permissions.isCreate.disabled || (modal && props.statusShowForm) || props.statusPopApp} onClick={props.handleClickAdd}>
            {t('APPOINTMENT.BUTTON.ADD_APPOINT')}
          </ButtonAdd>
        </Col>
      </Row>
      {showPopupFilter && (
        <ModalCustom
          title={t("APPOINTMENT.HEADER.TITLE_MODAL")}
          size={'sm'}
          closeButton
          component={
            <Row className="mx-0">
              <Col xs={12} className="px-0 mb-2">
                <SearchPatients status="ACTIVE" rootStyle={{ height: '35px' }} patientId={selectPatient} onClear={() => setSelectPatient(0)} />
              </Col>
              <Col xs={12} className="px-0 mb-2">
                <FilterSelect
                  formControlClass={'filter filter-branch'}
                  fullWidth={true}
                  onchange={(e) => {
                    setBranchId(e.target.value)
                    props.handleBranchId(e.target.value)
                  }}
                  renderValue={() => `${t('APPOINTMENT.HEADER.BRANCH')}: ${t(`${_.find(branches, (b: BranchInterface) => b.branchId === branchId)?.branchName}`)}`}
                  label={t('APPOINTMENT.HEADER.BRANCH')}
                  selectId="select-branchId"
                  value={branchId}
                  labelId="label-branchId"
                  options={_.map(branches, (branch: BranchInterface, i: number) => (
                    <MenuItem className="py-1" key={i} value={branch.branchId}>
                      {branch.branchName}
                    </MenuItem>
                  ))}
                />
              </Col>
              <Col xs={12} className="px-0 mb-2">
                <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
                  <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
                    <AutocompleteSelect
                      open={openFilterDoctors}
                      multiple
                      labelId="label-doctors"
                      options={['ALL', ...doctors, 'CLEAR']}
                      getOptionLabel={(option) => _.find(doctors, { dentistId: option })?.dentistFullname || '-'}
                      renderOption={(props, option) => {
                        if (option === 'ALL') {
                          return (
                            <ListFilterDoctors
                              {...props}
                              key={option}
                              component="li"
                              value={option}>
                              <InputCheckbox label={t('ALL')} checked={_.size(selectDoctors) === _.size(doctors)} indeterminate={_.size(selectDoctors) > 1 && _.size(selectDoctors) !== _.size(doctors)} className={'pe-none'} />
                            </ListFilterDoctors>
                          )
                        } else if (option === 'CLEAR') {
                          return (
                            <Box
                              {...props}
                              key={option}
                              component="li"
                              value={option}
                              sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
                              <SpanClearFilter className='pe-none'>{t('APPOINTMENT.HEADER.CLEAR')}</SpanClearFilter>
                            </Box>
                          )
                        } else {
                          return (
                            <ListFilterDoctors
                              {...props}
                              key={option.dentistId}
                              component="li"
                              value={option.dentistId}>
                              <InputCheckbox label={option.dentistFullname} checked={_.includes(selectDoctors, option.dentistId)} className={'pe-none'} />
                            </ListFilterDoctors>
                          )
                        }
                      }}
                      renderTags={(tagValue, getTagProps) => {
                        return (
                          <>
                            <span className='pr-1'>{t('USER_DOCTOR')}:</span>
                            {!openFilterDoctors && (
                              _.size(tagValue) === _.size(doctors) && (
                                <span>{t('ALL')}</span>
                              ) || (
                                <Box className='text-ellipsis'>
                                  {
                                    _.map(tagValue, (option: any, index: number) => {
                                      return (
                                        <span>
                                          {_.find(doctors, { dentistId: option })?.dentistFullname}
                                          {index !== _.findLastIndex(tagValue) && option && `,`}
                                        </span>
                                      )
                                    })
                                  }
                                </Box>
                              )
                            )}
                          </>
                        )
                      }}
                      onchange={(e, value) => handleFilterDoctors(e?.target?.value || e?.target?.attributes?.value?.value)}
                      noOptionsText={t('NOT_FOUND')}
                      filterOptions={(option) => 'ALL' + option.dentistFullname + 'CLEAR'}
                      value={selectDoctors}
                      height={32}
                      disableClearable
                    />
                  </CustomFilterDoctor>
                </ClickAwayListener>
              </Col>
            </Row>
          }
          onReset={() => {
            setShowPopupFilter(false)
            setBranchId(1)
            loadData()
            setSelectPatient(0)
          }}
          onSubmit={() => { return }}
          textBtnCancel={t('APPOINTMENT.BUTTON.CLEAR')}
          textBtnConfirm={t('APPOINTMENT.BUTTON.SEARCH')}
        />
      )}
    </CustomHeadAppointment>
  )
}
